const StockSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <title>{'BE9EE876-18BD-4A77-A47A-84E5D2E43D0A'}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#1A3154"
        fillRule="nonzero"
        d="M21 6h-3.17L16 4h-5a1 1 0 0 0 0 2h4.12l1.83 2H21v12H5v-8a1 1 0 0 0-2 0v8c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2Z"
      />
      <path
        fill="#1A3154"
        fillRule="nonzero"
        d="M13 9c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5Zm0 2c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3Z"
      />
      <path
        fill="#FF696D"
        fillRule="nonzero"
        d="M5 6h2a1 1 0 1 0 0-2H5V2a1 1 0 1 0-2 0v2H1a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0V6Z"
      />
    </g>
  </svg>
)
export default StockSvg
