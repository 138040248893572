import { ImageUploaderProps } from './types'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import {
  container,
  descriptionStyle,
  errorStyle,
  iconStyle,
  imageContainer,
  imageContainerOutline,
  cursorStyle,
  infoStyle,
  innerContainer,
  innerContainerBorderColor,
  inputStyle,
  titleStyle,
} from './index.css.ts'
import { Button } from '/@index'
import { ArrowUp } from 'lucide-react'
import StockSvg from './stock-svg.tsx'
import { ElementError } from '/@form/error/index.tsx'
import { vars } from '/@theme/theme.css.ts'
import CloseComponent from './close.tsx'
import { isValidElement, useState } from 'react'

const ImageUploader = (props: ImageUploaderProps) => {
  const {
    error,
    acceptedMimeTypes = ['image/png', 'image/jpeg', 'image/svg'],
    image,
    btnLabel,
    title,
    description,
    inputProps,
    onImageRemove,
    ...rest
  } = props

  const [hover, setHover] = useState(false)

  const parseMimeTypes = acceptedMimeTypes.join(',')

  const renderImage = () => {
    if (typeof image === 'string') {
      return <StockSvg />
    } else if (isValidElement(image)) {
      return image
    }

    return image
  }

  const hasError = () => {
    if (typeof error === 'string') {
      return !!error
    }

    return !!error?.message
  }

  const renderError = () => {
    if (!error) return null

    if (typeof error === 'string') {
      return <span className={errorStyle}>{error}</span>
    }

    return (
      <ElementError errors={error} name={inputProps?.name} showErrorBoard />
    )
  }

  const setCusorStyle = () => {
    if (inputProps?.disabled) return 'not-allowed'
    if (!image) return 'auto'

    return 'pointer'
  }

  const setImageContainerOutline = () => {
    if (!image || inputProps?.disabled) return '1px rgba(203,208,223,0.3)'

    return `2px ${vars.color.blue['40']}`
  }

  const imageContainerStyle = assignInlineVars({
    [imageContainerOutline]: setImageContainerOutline(),
    [cursorStyle]: setCusorStyle(),
  })

  return (
    <div className={container} {...rest}>
      <div
        className={innerContainer}
        style={assignInlineVars({
          [innerContainerBorderColor]: hasError()
            ? 'rgba(255,105,109,0.5)'
            : 'rgba(203, 208, 223, 0.3)',
        })}
      >
        <div
          className={imageContainer}
          style={imageContainerStyle}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {hover && image && !inputProps?.disabled && (
            <CloseComponent
              style={{
                position: 'absolute',
                top: -10,
                right: -10,
              }}
              onClick={onImageRemove}
            />
          )}
          {renderImage()}
        </div>
        <div className={infoStyle}>
          <div>
            <p className={titleStyle}>{title}</p>
            <p className={descriptionStyle}>{description}</p>
          </div>
          <Button
            size="md"
            variant="secondary-blue"
            disabled={inputProps?.disabled}
          >
            {/* @todo move icon to button comp */}
            <ArrowUp className={iconStyle} size={18} />
            <span>{btnLabel}</span>
            <input
              className={inputStyle}
              type="file"
              name="file"
              accept={parseMimeTypes}
              {...inputProps}
            />
          </Button>
        </div>
      </div>
      {renderError()}
    </div>
  )
}

export { ImageUploader }
