const CloseComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <rect width={18} height={18} x={1} y={1} fill="#3F6DB4" rx={9} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M14.72 6.628 11.35 10l3.37 3.373a.954.954 0 0 1-1.348 1.349L10 11.349 6.628 14.72a.954.954 0 0 1-1.349-1.35L8.651 10 5.28 6.63a.954.954 0 1 1 1.35-1.35L10 8.651l3.37-3.372a.954.954 0 0 1 1.35 1.35Z"
      clipRule="evenodd"
    />
    <rect
      width={18}
      height={18}
      x={1}
      y={1}
      stroke="#fff"
      strokeWidth={2}
      rx={9}
    />
  </svg>
)
export default CloseComponent
