import { forwardRef } from 'react'
import { Card } from '/@card'
import { DialogContentProps } from './types'
import {
  dialogContentCard,
  dialogContentHeader,
  dialogContentHeaderButton,
} from './dialog.css.ts'
import { PopperContent } from '/@popper'
import { X } from 'lucide-react'
import { useFloatingUiContext } from '@palqee/utils'
import { Button } from '/@index.ts'
import { vars } from '/@theme/theme.css.ts'

const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  (props, forwardedRef) => {
    const { cardStyle, ...popperContentProps } = props

    const { setOpen } = useFloatingUiContext()

    const renderPopperWithCard = () => {
      return (
        <PopperContent
          useOverlay
          useModal
          {...popperContentProps}
          ref={forwardedRef}
        >
          <Card className={dialogContentCard} style={cardStyle}>
            <Card.Header>
              <div className={dialogContentHeader}>
                <Button
                  role="button"
                  className={dialogContentHeaderButton}
                  onKeyDown={(e) => {
                    switch (e.key) {
                      case 'Escape':
                        setOpen(false)
                        break
                    }
                  }}
                  onClick={() => {
                    setOpen(false)
                  }}
                  style={{
                    position: 'absolute',
                  }}
                  variant="default"
                >
                  <X color={vars.color.gray['30']} />
                </Button>
              </div>
            </Card.Header>
            <Card.Content>{props.children}</Card.Content>
          </Card>
        </PopperContent>
      )
    }
    return renderPopperWithCard()
  },
)

DialogContent.displayName = 'DialogContent'

export { DialogContent }
