import classNames from 'classnames'
import { ISpinnerProps } from './types'
import { SpinnerCss } from './styles'

const Spinner = (props: ISpinnerProps) => {
  const { className, width, height, ...rest } = props

  const classes = classNames('pq-spinner', className)

  return (
    <span
      data-cy="pq-spinner"
      className={classes}
      css={SpinnerCss({
        width,
        height,
      })}
      {...rest}
    />
  )
}

export { Spinner }
