import * as Types from '../../../../../__generated__/types.d'

import { gql } from '@apollo/client'
export type CompanySelectedRegulationFragment = {
  __typename: 'Regulation'
  id: string
  regulationId: string
  name: string
  uiCode?: any | null
  logoPath?: string | null
  description?: string | null
}

export type DepartmentFragment = {
  __typename: 'Department'
  id: string
  name: string
  description?: string | null
}

export type CompanyBinaryFileInfo_BinaryFileInfo_Fragment = {
  __typename: 'BinaryFileInfo'
  id: string
  isPublic: boolean
  originalFilename?: string | null
  mimetype: string
  url: string
}

export type CompanyBinaryFileInfo_BinaryFileInfoPublic_Fragment = {
  __typename: 'BinaryFileInfoPublic'
  publicUrl?: string | null
  id: string
  isPublic: boolean
  originalFilename?: string | null
  mimetype: string
  url: string
}

export type CompanyBinaryFileInfoFragment =
  | CompanyBinaryFileInfo_BinaryFileInfo_Fragment
  | CompanyBinaryFileInfo_BinaryFileInfoPublic_Fragment

export type CompanyMainFragment = {
  __typename: 'Company'
  id: string
  name?: string | null
  logo?: {
    __typename: 'BinaryFileInfoPublic'
    publicUrl?: string | null
    id: string
    isPublic: boolean
    originalFilename?: string | null
    mimetype: string
    url: string
  } | null
}

export type CompanyFullDetailsFragment = {
  __typename: 'Company'
  numberOfEmployees: any
  id: string
  name?: string | null
  structure: {
    __typename: 'CompanyStructure'
    id: string
    name: string
    uiCode?: any | null
  }
  address?: {
    __typename: 'Address'
    id: string
    streetAddress?: string | null
    streetAddress2?: string | null
    locality?: string | null
    subAdministrativeArea?: string | null
    administrativeArea?: string | null
    postalCode?: any | null
    country: { __typename: 'Country'; id: string; name: string }
  } | null
  industries: Array<{ __typename: 'Industry'; id: string; name: string }>
  regions: Array<{ __typename: 'Region'; id: string; name: string }>
  logo?: {
    __typename: 'BinaryFileInfoPublic'
    publicUrl?: string | null
    id: string
    isPublic: boolean
    originalFilename?: string | null
    mimetype: string
    url: string
  } | null
  currentSubscriptionTier?: {
    __typename: 'SubscriptionTier'
    id: string
    name: string
    features?: Array<
      | {
          __typename: 'SubscriptionFeatureBoolean'
          available: boolean
          id: string
          name: string
          code: string
          description?: string | null
        }
      | {
          __typename: 'SubscriptionFeatureIntRange'
          minValue?: number | null
          maxValue?: number | null
          id: string
          name: string
          code: string
          description?: string | null
        }
    > | null
  } | null
}

export type CompanyBasicFragment = {
  __typename: 'Company'
  id: string
  name?: string | null
  numberOfEmployees: any
  industries: Array<{ __typename: 'Industry'; id: string; name: string }>
  address?: {
    __typename: 'Address'
    id: string
    streetAddress?: string | null
    streetAddress2?: string | null
    locality?: string | null
    subAdministrativeArea?: string | null
    administrativeArea?: string | null
    postalCode?: any | null
    country: { __typename: 'Country'; id: string; name: string }
  } | null
  regions: Array<{ __typename: 'Region'; id: string; name: string }>
  logo?: {
    __typename: 'BinaryFileInfoPublic'
    publicUrl?: string | null
    id: string
    isPublic: boolean
    originalFilename?: string | null
    mimetype: string
    url: string
  } | null
  currentSubscriptionTier?: {
    __typename: 'SubscriptionTier'
    id: string
    name: string
    features?: Array<
      | {
          __typename: 'SubscriptionFeatureBoolean'
          available: boolean
          id: string
          name: string
          code: string
          description?: string | null
        }
      | {
          __typename: 'SubscriptionFeatureIntRange'
          minValue?: number | null
          maxValue?: number | null
          id: string
          name: string
          code: string
          description?: string | null
        }
    > | null
  } | null
}

export type CompanyBinaryFileInfo_BinaryFileInfo_Fragment = {
  __typename: 'BinaryFileInfo'
  id: string
  isPublic: boolean
  originalFilename?: string | null
  mimetype: string
  url: string
}

export type CompanyBinaryFileInfo_BinaryFileInfoPublic_Fragment = {
  __typename: 'BinaryFileInfoPublic'
  publicUrl?: string | null
  id: string
  isPublic: boolean
  originalFilename?: string | null
  mimetype: string
  url: string
}

export type CompanyBinaryFileInfoFragment =
  | CompanyBinaryFileInfo_BinaryFileInfo_Fragment
  | CompanyBinaryFileInfo_BinaryFileInfoPublic_Fragment

export type CompanyBasicFragment = {
  __typename: 'Company'
  id: string
  name?: string | null
  numberOfEmployees: any
  industries: Array<{ __typename: 'Industry'; id: string; name: string }>
  address?: {
    __typename: 'Address'
    id: string
    streetAddress?: string | null
    streetAddress2?: string | null
    locality?: string | null
    subAdministrativeArea?: string | null
    administrativeArea?: string | null
    postalCode?: any | null
    country: { __typename: 'Country'; id: string; name: string }
  } | null
  regions: Array<{ __typename: 'Region'; id: string; name: string }>
  logo?: {
    __typename: 'BinaryFileInfoPublic'
    publicUrl?: string | null
    id: string
    isPublic: boolean
    originalFilename?: string | null
    mimetype: string
    url: string
  } | null
  currentSubscriptionTier?: {
    __typename: 'SubscriptionTier'
    id: string
    name: string
    features?: Array<
      | {
          __typename: 'SubscriptionFeatureBoolean'
          available: boolean
          id: string
          name: string
          code: string
          description?: string | null
        }
      | {
          __typename: 'SubscriptionFeatureIntRange'
          minValue?: number | null
          maxValue?: number | null
          id: string
          name: string
          code: string
          description?: string | null
        }
    > | null
  } | null
}

export type CompanyFullDetailsFragment = {
  __typename: 'Company'
  numberOfEmployees: any
  id: string
  name?: string | null
  structure: {
    __typename: 'CompanyStructure'
    id: string
    name: string
    uiCode?: any | null
  }
  address?: {
    __typename: 'Address'
    id: string
    streetAddress?: string | null
    streetAddress2?: string | null
    locality?: string | null
    subAdministrativeArea?: string | null
    administrativeArea?: string | null
    postalCode?: any | null
    country: { __typename: 'Country'; id: string; name: string }
  } | null
  industries: Array<{ __typename: 'Industry'; id: string; name: string }>
  regions: Array<{ __typename: 'Region'; id: string; name: string }>
  logo?: {
    __typename: 'BinaryFileInfoPublic'
    publicUrl?: string | null
    id: string
    isPublic: boolean
    originalFilename?: string | null
    mimetype: string
    url: string
  } | null
  currentSubscriptionTier?: {
    __typename: 'SubscriptionTier'
    id: string
    name: string
    features?: Array<
      | {
          __typename: 'SubscriptionFeatureBoolean'
          available: boolean
          id: string
          name: string
          code: string
          description?: string | null
        }
      | {
          __typename: 'SubscriptionFeatureIntRange'
          minValue?: number | null
          maxValue?: number | null
          id: string
          name: string
          code: string
          description?: string | null
        }
    > | null
  } | null
}

export const CompanySelectedRegulationFragmentDoc = gql`
  fragment CompanySelectedRegulation on Regulation {
    id
    regulationId
    name
    uiCode
    name
    logoPath
    description
  }
`
export const DepartmentFragmentDoc = gql`
  fragment Department on Department {
    id
    name
    description
  }
`
export const CompanyBinaryFileInfoFragmentDoc = gql`
  fragment CompanyBinaryFileInfo on IBinaryFileInfo {
    id
    isPublic
    originalFilename
    mimetype
    url
    ... on BinaryFileInfoPublic {
      publicUrl
    }
  }
`
export const CompanyMainFragmentDoc = gql`
  fragment CompanyMain on Company {
    id
    name
    logo {
      ...CompanyBinaryFileInfo
    }
  }
  ${CompanyBinaryFileInfoFragmentDoc}
`
export const CompanyBasicFragmentDoc = gql`
  fragment CompanyBasic on Company {
    id
    name
    numberOfEmployees
    industries {
      id
      name
    }
    address {
      id
      streetAddress
      streetAddress2
      locality
      subAdministrativeArea
      administrativeArea
      postalCode
      country {
        id
        name
      }
    }
    regions {
      id
      name
    }
    logo {
      ...CompanyBinaryFileInfo
    }
    currentSubscriptionTier {
      id
      name
      features {
        id
        name
        code
        description
        ... on SubscriptionFeatureIntRange {
          minValue
          maxValue
        }
        ... on SubscriptionFeatureBoolean {
          available
        }
      }
    }
  }
  ${CompanyBinaryFileInfoFragmentDoc}
`
export const CompanyFullDetailsFragmentDoc = gql`
  fragment CompanyFullDetails on Company {
    ...CompanyBasic
    numberOfEmployees
    structure {
      id
      name
      uiCode
    }
    address {
      id
      country {
        id
        name
      }
    }
  }
  ${CompanyBasicFragmentDoc}
`
export const namedOperations = {
  Fragment: {
    CompanySelectedRegulation: 'CompanySelectedRegulation',
    Department: 'Department',
    CompanyBinaryFileInfo: 'CompanyBinaryFileInfo',
    CompanyMain: 'CompanyMain',
    CompanyFullDetails: 'CompanyFullDetails',
    CompanyBasic: 'CompanyBasic',
  },
}
