import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any }
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  Duration: { input: any; output: any }
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: { input: any; output: any }
  HexColorCode: { input: any; output: any }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any }
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: any; output: any }
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: { input: any; output: any }
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: { input: any; output: any }
  /** Almost restrictions-free phone number, like `+0 (178) 95-551-234` */
  SloppyPhoneNumber: { input: any; output: any }
  /** A free-form postal code (no validation) */
  SloppyPostalCode: { input: any; output: any }
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: { input: any; output: any }
  /** UI Code (use it for I18N and L10N) */
  UICode: { input: any; output: any }
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: any; output: any }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any }
  /** Represents NULL values */
  Void: { input: any; output: any }
}

export type Address = {
  __typename?: 'Address'
  addressId: Scalars['ID']['output']
  /** State / Province / Region (ISO code when available) */
  administrativeArea?: Maybe<Scalars['String']['output']>
  /** Country */
  country: Country
  id: Scalars['ID']['output']
  /** City / Town */
  locality?: Maybe<Scalars['String']['output']>
  /** Postal code / ZIP Code */
  postalCode?: Maybe<Scalars['SloppyPostalCode']['output']>
  /** Street address */
  streetAddress?: Maybe<Scalars['String']['output']>
  /** Street address (line 2), or Apartment, Suite, Box number, etc. */
  streetAddress2?: Maybe<Scalars['String']['output']>
  /** County / District (unused) */
  subAdministrativeArea?: Maybe<Scalars['String']['output']>
}

export type AddressArg = {
  /** State / Province / Region (ISO code when available) */
  administrativeArea?: InputMaybe<Scalars['String']['input']>
  countryId?: InputMaybe<Scalars['ID']['input']>
  /** City / Town */
  locality?: InputMaybe<Scalars['String']['input']>
  /** Postal code / ZIP Code */
  postalCode?: InputMaybe<Scalars['SloppyPostalCode']['input']>
  /** Street address */
  streetAddress?: InputMaybe<Scalars['String']['input']>
  /** Street address (line 2), or Apartment, Suite, Box number, etc. */
  streetAddress2?: InputMaybe<Scalars['String']['input']>
  /** County / District (unused) */
  subAdministrativeArea?: InputMaybe<Scalars['String']['input']>
}

export type AdminRegionCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type AdminRegionCreatePayload = {
  __typename?: 'AdminRegionCreatePayload'
  region: Region
}

export type AnnualSalesRevenue = {
  __typename?: 'AnnualSalesRevenue'
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  maxValue?: Maybe<Scalars['Float']['output']>
  minValue?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  sortOrder: Scalars['NonNegativeInt']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type Asset = IDatamapEntity & {
  __typename?: 'Asset'
  assetCategory: AssetCategory
  assetCategoryId: Scalars['ID']['output']
  assetId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type AssetCategory = {
  __typename?: 'AssetCategory'
  assetCategoryId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type AssetConnection = {
  __typename?: 'AssetConnection'
  asset?: Maybe<Asset>
  assetConnectionId: Scalars['ID']['output']
  assetId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  key?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  secret?: Maybe<Scalars['String']['output']>
  /** ALWAYS NULL for this entity, but it is required for the interface */
  uiCode?: Maybe<Scalars['UICode']['output']>
  url?: Maybe<Scalars['String']['output']>
  username?: Maybe<Scalars['String']['output']>
}

export type Audience = {
  __typename?: 'Audience'
  audienceId: Scalars['ID']['output']
  company: Company
  computingMethod: CompanyAudienceComputingMethodEnum
  createdAt: Scalars['DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** @deprecated we do not use isSingleUser audiences */
  isSingleUser: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  users: Array<User>
}

export type AudienceUsersArgs = {
  input?: InputMaybe<UserGetInput>
}

export enum AuthCodeDeliveryMediumEnum {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Unknown = 'UNKNOWN',
}

export type AuthIdentityApplication = {
  __typename?: 'AuthIdentityApplication'
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  identityApplicationId: Scalars['ID']['output']
  identityProtocolCode: AuthIdentityProtocolCodeEnum
  identityProviderCode: AuthIdentityProviderCodeEnum
  /** Name will be available even for anonymous users */
  name: Scalars['String']['output']
  specificConfig?: Maybe<Scalars['JSON']['output']>
  /**
   * The URI used to init the "authentication flow".
   *
   * _At the moment - the only URI available for the application, so the name is pretty vague._
   */
  uriInit?: Maybe<Scalars['URL']['output']>
  uriLogout?: Maybe<Scalars['URL']['output']>
  uriProviderRedirect?: Maybe<Scalars['URL']['output']>
}

export type AuthIdentityApplicationUpsertInput = {
  /** Not null for `create` */
  companyId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** Not null for `create` */
  identityProviderCode?: InputMaybe<AuthIdentityProviderCodeEnum>
  /**
   * Name will be available even for anonymous users
   *
   * Not null for `create`
   */
  name?: InputMaybe<Scalars['String']['input']>
  specificConfig?: InputMaybe<Scalars['JSON']['input']>
}

export type AuthIdentityApplicationUpsertPayload = {
  __typename?: 'AuthIdentityApplicationUpsertPayload'
  identityApplication: AuthIdentityApplication
  query: Query
}

export type AuthIdentityApplicationsGetFilterArg = {
  applicationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyId?: InputMaybe<Scalars['ID']['input']>
  companySlug?: InputMaybe<Scalars['String']['input']>
  companyUsersPrimaryEmailDomain?: InputMaybe<Scalars['String']['input']>
  /**
   * Filter IdP using their "isSocial" nature. Example:
   *   - social: Google, Facebook, ..
   *   - private SSO: MS Entra, AWS Cognito
   * Valid values are:
   *   - `null` - no filtering by this param
   *   - `true` - only social IdP
   *   - `false` - only private SSO IdP
   */
  isSocial?: InputMaybe<Scalars['Boolean']['input']>
}

export type AuthIdentityApplicationsGetInput = {
  filter?: InputMaybe<AuthIdentityApplicationsGetFilterArg>
}

export type AuthIdentityApplicationsGetPayload = {
  __typename?: 'AuthIdentityApplicationsGetPayload'
  /**
   * **IMPORTANT!**
   * this field can be empty even if there is a matching company
   *
   * it depends on `identityApplications`
   */
  companies: Array<Company>
  identityApplications: Array<AuthIdentityApplication>
  query: Query
}

export enum AuthIdentityProtocolCodeEnum {
  Oidc = 'OIDC',
}

export enum AuthIdentityProviderCodeEnum {
  AwsCognito = 'AWS_COGNITO',
  MicrosoftEntra = 'MICROSOFT_ENTRA',
}

export type AuthLoginConfirmInput = {
  confirmationCode: Scalars['String']['input']
  mfaChallengeKey: Scalars['String']['input']
  mfaType: AuthMfaTypeEnum
  username: Scalars['EmailAddress']['input']
}

export type AuthLoginConfirmPayload =
  | AuthLoginNexChallengePayload
  | AuthLoginSuccessPayload

export type AuthLoginInput = {
  password: Scalars['String']['input']
  username: Scalars['EmailAddress']['input']
}

export type AuthLoginNexChallengePayload = {
  __typename?: 'AuthLoginNexChallengePayload'
  mfaChallengeKey: Scalars['String']['output']
  mfaType: AuthMfaTypeEnum
}

export type AuthLoginPayload =
  | AuthLoginNexChallengePayload
  | AuthLoginSuccessPayload

export type AuthLoginSuccessPayload = {
  __typename?: 'AuthLoginSuccessPayload'
  accessToken: Scalars['String']['output']
  /**
   * Expiration time for **Access Token**
   * @deprecated use token itself
   */
  expiresAfterSeconds: Scalars['NonNegativeInt']['output']
  identity: AuthUserIdentity
  refreshToken: Scalars['String']['output']
  user: User
}

export type AuthLogoutPayload = {
  __typename?: 'AuthLogoutPayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
}

export type AuthMfaConfigureConfirmInput = {
  confirmationCode: Scalars['String']['input']
  mfaType: AuthMfaTypeEnum
}

export type AuthMfaConfigureConfirmPayload = {
  __typename?: 'AuthMfaConfigureConfirmPayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
  user: User
}

export type AuthMfaConfigureInput = {
  contact?: InputMaybe<Scalars['String']['input']>
  mfaType: AuthMfaTypeEnum
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
}

export type AuthMfaConfigurePayload = {
  __typename?: 'AuthMfaConfigurePayload'
  query: Query
  secretKey?: Maybe<Scalars['String']['output']>
  user: User
}

export type AuthMfaDisablePayload = {
  __typename?: 'AuthMfaDisablePayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
  user: User
}

export enum AuthMfaTypeEnum {
  Sms = 'SMS',
  Totp = 'TOTP',
}

export type AuthPasswordChangeInput = {
  newPassword: Scalars['String']['input']
  oldPassword: Scalars['String']['input']
}

export type AuthPasswordChangePayload = {
  __typename?: 'AuthPasswordChangePayload'
  query: Query
  user: User
}

export type AuthPasswordRestoreConfirmInput = {
  confirmationCode: Scalars['String']['input']
  confirmationToken: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}

export type AuthPasswordRestoreConfirmPayload = {
  __typename?: 'AuthPasswordRestoreConfirmPayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
}

export type AuthPasswordRestoreInitInput = {
  username: Scalars['EmailAddress']['input']
}

export type AuthPasswordRestoreInitPayload = {
  __typename?: 'AuthPasswordRestoreInitPayload'
  codeDeliveryInfo: CodeDeliveryInfo
  query: Query
}

/** Security role */
export type AuthRole = {
  __typename?: 'AuthRole'
  code: AuthRoleCodeEnum
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode: Scalars['UICode']['output']
}

export enum AuthRoleCodeEnum {
  /**
   * Company's administrator.
   *
   * Usually it is the first user in company. By default this role is assigned
   * to the user on sign-up
   */
  Administrator = 'ADMINISTRATOR',
  /**
   * Data Protection Officer
   *
   * Usually, it is the main user of Palqee Services
   */
  Dpo = 'DPO',
  Employee = 'EMPLOYEE',
  PalqeeManager = 'PALQEE_MANAGER',
  /** Owner of the business-process in company */
  ProcessOwner = 'PROCESS_OWNER',
  Vendor = 'VENDOR',
}

export type AuthSignUpConfirmInput = {
  confirmationCode: Scalars['String']['input']
  confirmationToken: Scalars['String']['input']
}

export type AuthSignUpConfirmPayload = {
  __typename?: 'AuthSignUpConfirmPayload'
  identity: AuthUserIdentity
  query: Query
  user: User
}

export type AuthSignUpInput = {
  /**
   * Name of a deparment where the current signing-up user works in the company.
   *
   * **NOTE**: You have to provide both `positionName` and `deparmentName` to
   * make it possible to add the user to the deparment automatically after
   * creating a company.
   */
  departmentName?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  lastName?: InputMaybe<Scalars['String']['input']>
  password: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  /**
   * Name of a position in the company for the current signing-up user.
   *
   * **NOTE**: You have to provide both `positionName` and `deparmentName` to
   * make it possible to add the user to the deparment automatically after
   * creating a company.
   */
  positionName?: InputMaybe<Scalars['String']['input']>
  /** this username will be used as the main email */
  username: Scalars['EmailAddress']['input']
}

export type AuthSignUpPayload = {
  __typename?: 'AuthSignUpPayload'
  codeDeliveryInfo: CodeDeliveryInfo
  identity: AuthUserIdentity
  query: Query
  user: User
}

export type AuthSignUpResendCodeInput = {
  usernames: Array<Scalars['EmailAddress']['input']>
}

export type AuthSignUpResendCodePayload = {
  __typename?: 'AuthSignUpResendCodePayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
}

export type AuthSignUpResendCodeToPendingUsersInput = {
  companyIds: Array<Scalars['ID']['input']>
}

export type AuthSignUpResendCodeToPendingUsersPayload = {
  __typename?: 'AuthSignUpResendCodeToPendingUsersPayload'
  nothing?: Maybe<Scalars['Void']['output']>
  query: Query
}

export type AuthTokenRefreshInput = {
  refreshToken: Scalars['String']['input']
}

export type AuthTokenRefreshPayload = {
  __typename?: 'AuthTokenRefreshPayload'
  accessToken: Scalars['String']['output']
  /**
   * Expiration time for the **Access Token**
   *
   * # DEPRECATED: #tokenExpiresAfterSeconds
   * @deprecated use token itself
   */
  expiresAfterSeconds: Scalars['NonNegativeInt']['output']
  identity: AuthUserIdentity
  refreshToken: Scalars['String']['output']
  user: User
}

export type AuthUserCurrentInput = {
  fakeInput?: InputMaybe<Scalars['Boolean']['input']>
}

export type AuthUserCurrentPayload = {
  __typename?: 'AuthUserCurrentPayload'
  identity?: Maybe<AuthUserIdentity>
  user?: Maybe<User>
}

export type AuthUserIdentity = {
  __typename?: 'AuthUserIdentity'
  id: Scalars['ID']['output']
  identityApplication: AuthIdentityApplication
  identityApplicationId: Scalars['ID']['output']
  user: User
  userId: Scalars['ID']['output']
}

export type BinaryFileInfo = IBinaryFileInfo & {
  __typename?: 'BinaryFileInfo'
  binaryFileInfoId: Scalars['ID']['output']
  /** Length is unknown for OLD files */
  contentLength?: Maybe<Scalars['NonNegativeInt']['output']>
  id: Scalars['ID']['output']
  isPublic: Scalars['Boolean']['output']
  mimetype: Scalars['String']['output']
  originalFilename?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type BinaryFileInfoUrlArgs = {
  input?: InputMaybe<BinaryFileInfoUrlInput>
}

export type BinaryFileInfoPublic = IBinaryFileInfo & {
  __typename?: 'BinaryFileInfoPublic'
  binaryFileInfoPublicId: Scalars['ID']['output']
  /** Length is unknown for OLD files */
  contentLength?: Maybe<Scalars['NonNegativeInt']['output']>
  id: Scalars['ID']['output']
  isPublic: Scalars['Boolean']['output']
  mimetype: Scalars['String']['output']
  originalFilename?: Maybe<Scalars['String']['output']>
  publicUrl?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type BinaryFileInfoPublicUrlArgs = {
  input?: InputMaybe<BinaryFileInfoUrlInput>
}

export type BinaryFileInfoUrlInput = {
  redirectTtlSeconds?: InputMaybe<Scalars['NonNegativeInt']['input']>
  urlTargetType?: BinaryFileInfoUrlTargetTypeEnum
}

export enum BinaryFileInfoUrlTargetTypeEnum {
  Base64Payload = 'BASE64_PAYLOAD',
  BinaryPayload = 'BINARY_PAYLOAD',
  Null = 'NULL',
  PublicUrl = 'PUBLIC_URL',
  SignedJsonUrl = 'SIGNED_JSON_URL',
  SignedRedirect = 'SIGNED_REDIRECT',
  /**
   * This one generates URL for our `/files/redirect` endpoint (we use it for
   * more or less static redirects for file-access
   */
  SignedUrlHttpRedirect = 'SIGNED_URL_HTTP_REDIRECT',
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CodeDeliveryInfo = {
  __typename?: 'CodeDeliveryInfo'
  codeDeliveryDestination: Scalars['String']['output']
  codeDeliveryMedium: AuthCodeDeliveryMediumEnum
  isUserConfirmed?: Maybe<Scalars['Boolean']['output']>
}

export type Company = {
  __typename?: 'Company'
  address?: Maybe<Address>
  annualSalesRevenue: AnnualSalesRevenue
  audiences: Array<Audience>
  companyFeatures: Array<ISubscriptionFeature>
  companyId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  currency?: Maybe<Currency>
  currentSubscriptionTier?: Maybe<SubscriptionTier>
  departments: Array<Department>
  id: Scalars['ID']['output']
  industries: Array<Industry>
  logo?: Maybe<BinaryFileInfoPublic>
  name?: Maybe<Scalars['String']['output']>
  numberOfEmployees: Scalars['NonNegativeInt']['output']
  positions: Array<Position>
  regions: Array<Region>
  representatives: Array<Representative>
  /** A list of regulations, marked as **selected** for the company */
  selectedRegulations: Array<Regulation>
  slug: Scalars['String']['output']
  structure: CompanyStructure
  tenant: Tenant
  tenantId: Scalars['ID']['output']
  type: CompanyType
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * This field is used in the SSO authentication flow. When ANONYMOUS user
   * tries to login, they can ask for available Identity Providers, and since
   * most providers are linked to companies - we do company lookup using this
   * field.
   *
   * Set it to `null` if you don't want to make this search possible (when it is
   * null no IdP configured for this company will be shown).
   *
   * The field must be unique.
   *
   * Recomended value:
   *   - company name (simplified: "acme" instead of "Public antarctic company ACME LTD")
   *
   * The field is case-insensitive (`AaB === aab`)
   */
  usersPrimaryEmailDomain?: Maybe<Scalars['String']['output']>
}

export type CompanyAudiencesArgs = {
  input?: InputMaybe<CompanyAudiencesInput>
}

export type CompanyRepresentativesArgs = {
  input?: InputMaybe<CompanyRepresentativesInput>
}

export type CompanyAddressSetInput = {
  addressInfo?: InputMaybe<AddressArg>
  companyId: Scalars['ID']['input']
}

export type CompanyAddressSetPayload = {
  __typename?: 'CompanyAddressSetPayload'
  address: Address
  company: Company
  query: Query
}

export type CompanyAssetConnectionCreateInput = {
  assetId?: InputMaybe<Scalars['ID']['input']>
  companyId: Scalars['ID']['input']
  key?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  secret?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
  username?: InputMaybe<Scalars['String']['input']>
}

export type CompanyAssetConnectionCreatePayload = {
  __typename?: 'CompanyAssetConnectionCreatePayload'
  assetConnection: AssetConnection
  query: Query
}

export enum CompanyAudienceComputingMethodEnum {
  /** For regular audiences with users added manually */
  None = 'NONE',
  RoleAdministrator = 'ROLE_ADMINISTRATOR',
  RoleDpo = 'ROLE_DPO',
  RoleEmployee = 'ROLE_EMPLOYEE',
  RoleProcessOwner = 'ROLE_PROCESS_OWNER',
  RoleVendor = 'ROLE_VENDOR',
}

export type CompanyAudienceCreateInput = {
  companyId: Scalars['ID']['input']
  computingMethod?: CompanyAudienceComputingMethodEnum
  description?: InputMaybe<Scalars['String']['input']>
  isSingleUser?: Scalars['Boolean']['input']
  name: Scalars['String']['input']
}

export type CompanyAudienceCreatePayload = {
  __typename?: 'CompanyAudienceCreatePayload'
  audience: Audience
  query: Query
}

export type CompanyAudienceDeleteFilterArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  computingMethods?: InputMaybe<Array<CompanyAudienceComputingMethodEnum>>
  isSingleUser?: InputMaybe<Scalars['Boolean']['input']>
}

export type CompanyAudienceDeleteInput = {
  filter?: InputMaybe<CompanyAudienceDeleteFilterArg>
}

export type CompanyAudienceDeletePayload = {
  __typename?: 'CompanyAudienceDeletePayload'
  audiences: Array<Audience>
  query: Query
}

export type CompanyAudienceUpdateInput = {
  audienceId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  isSingleUser?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type CompanyAudienceUpdatePayload = {
  __typename?: 'CompanyAudienceUpdatePayload'
  audience: Audience
  query: Query
}

export type CompanyAudienceUsersLinkInput = {
  audienceId: Scalars['ID']['input']
  onUserAlreadyLinkedBehavior?: InputMaybe<OnErrorBehaviorEnum>
  sendRegistrationEmailsToPendingUsers?: Scalars['Boolean']['input']
  users: CompanyAudienceUsersLinkUsersArg
}

export type CompanyAudienceUsersLinkInvitedUserItem = {
  __typename?: 'CompanyAudienceUsersLinkInvitedUserItem'
  codeDeliveryInfo: CodeDeliveryInfo
  user: User
}

export type CompanyAudienceUsersLinkPayload = {
  __typename?: 'CompanyAudienceUsersLinkPayload'
  audience: Audience
  /** @deprecated long time ago we stopped to support this feature */
  invitedUsers?: Maybe<Array<Maybe<User>>>
  invitedUsersInfo?: Maybe<Array<CompanyAudienceUsersLinkInvitedUserItem>>
  query: Query
}

export type CompanyAudienceUsersLinkUsersArg = {
  exclude?: InputMaybe<CompanyAudienceUsersLinkUsersExcludeArg>
  filter?: InputMaybe<CompanyAudienceUsersLinkUsersFilterArg>
}

export type CompanyAudienceUsersLinkUsersExcludeArg = {
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyAudienceUsersLinkUsersFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyAudienceUsersUnlinkInput = {
  audienceId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']>
}

export type CompanyAudienceUsersUnlinkPayload = {
  __typename?: 'CompanyAudienceUsersUnlinkPayload'
  audience: Audience
  query: Query
}

export type CompanyAudiencesFilterArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  computingMethods?: InputMaybe<Array<CompanyAudienceComputingMethodEnum>>
  isSingleUser?: InputMaybe<Scalars['Boolean']['input']>
}

export type CompanyAudiencesGetFilterArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  computingMethods?: InputMaybe<Array<CompanyAudienceComputingMethodEnum>>
  isSingleUser?: InputMaybe<Scalars['Boolean']['input']>
}

export type CompanyAudiencesGetInput = {
  filter?: InputMaybe<CompanyAudiencesGetFilterArg>
  order?: InputMaybe<Array<CompanyAudiencesGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<CompanyAudiencesGetSearchArg>
}

export type CompanyAudiencesGetOrderArg = {
  by: CompanyAudiencesGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum CompanyAudiencesGetOrderEnum {
  ComputingMethod = 'computingMethod',
  CreatedAt = 'createdAt',
  Description = 'description',
  Name = 'name',
}

export type CompanyAudiencesGetPayload = {
  __typename?: 'CompanyAudiencesGetPayload'
  audiences: Array<Audience>
  paginationInfo: PaginationInfo
  query: Query
}

export type CompanyAudiencesGetSearchArg = {
  pattern: Scalars['String']['input']
}

export type CompanyAudiencesInput = {
  filter?: InputMaybe<CompanyAudiencesFilterArg>
  order?: InputMaybe<Array<CompanyAudiencesGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type CompanyCreateInput = {
  addressInfo?: InputMaybe<AddressArg>
  annualSalesRevenueId: Scalars['ID']['input']
  currencyId: Scalars['ID']['input']
  isSubscriptionTrialEnabled?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  numberOfEmployees: Scalars['NonNegativeInt']['input']
  /** Temporary is used to generate "path" part of the URLs for the company in the UI */
  slug?: InputMaybe<Scalars['String']['input']>
  structureId: Scalars['ID']['input']
  subscriptionTierId?: InputMaybe<Scalars['ID']['input']>
  typeId: Scalars['ID']['input']
  usersPrimaryEmailDomain?: InputMaybe<Scalars['String']['input']>
}

export type CompanyCreatePayload = {
  __typename?: 'CompanyCreatePayload'
  company: Company
  query: Query
}

export type CompanyDeleteFilterArgument = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyDeleteInput = {
  filter: CompanyDeleteFilterArgument
}

export type CompanyDeletePayload = {
  __typename?: 'CompanyDeletePayload'
  companies: Array<Company>
  query: Query
}

export type CompanyDepartmentCreateInput = {
  companyId: Scalars['ID']['input']
  departmentTemplateId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /**
   * if `true` the method just returns the existing department, instead of throwing an NON-UNIQUE exception
   * allows to bypass only name-unique exception
   */
  doSuppressException?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
}

export type CompanyDepartmentCreatePayload = {
  __typename?: 'CompanyDepartmentCreatePayload'
  department: Department
  query: Query
}

export type CompanyDepartmentsGetInput = {
  filter?: InputMaybe<DepartmentFilterArg>
}

export type CompanyDepartmentsGetPayload = {
  __typename?: 'CompanyDepartmentsGetPayload'
  departments: Array<Department>
  query: Query
}

export type CompanyFeatureUsagesGetInput = {
  companyId: Scalars['ID']['input']
  featureCodes?: InputMaybe<Array<Scalars['String']['input']>>
}

export type CompanyFeatureUsagesGetPayload = {
  __typename?: 'CompanyFeatureUsagesGetPayload'
  company: Company
  featureUsages: Array<SubscriptionFeatureUsage>
  query: Query
}

export type CompanyFeaturesGetInput = {
  companyId: Scalars['ID']['input']
}

export type CompanyFeaturesGetPayload = {
  __typename?: 'CompanyFeaturesGetPayload'
  company: Company
  features: Array<ISubscriptionFeature>
  query: Query
}

export type CompanyGetInput = {
  companyId: Scalars['ID']['input']
}

export type CompanyGetPayload = {
  __typename?: 'CompanyGetPayload'
  company: Company
  query: Query
}

export type CompanyIndustriesSetInput = {
  companyId: Scalars['ID']['input']
  industryIds: Array<Scalars['ID']['input']>
}

export type CompanyIndustriesSetPayload = {
  __typename?: 'CompanyIndustriesSetPayload'
  company: Company
  query: Query
}

export type CompanyLogoSetInput = {
  companyId: Scalars['ID']['input']
  file?: InputMaybe<Scalars['Upload']['input']>
}

export type CompanyLogoSetPayload = {
  __typename?: 'CompanyLogoSetPayload'
  company: Company
  logo: IBinaryFileInfo
  query: Query
}

export type CompanyOnboardingStepsGetInput = {
  companyId: Scalars['ID']['input']
}

export type CompanyOnboardingStepsGetPayload = {
  __typename?: 'CompanyOnboardingStepsGetPayload'
  stepCodes: Array<OnboardingProgressStepCodeEnum>
}

export type CompanyOnboardingStepsSaveInput = {
  companyId: Scalars['ID']['input']
  isCompleted: Scalars['Boolean']['input']
  stepCode: OnboardingProgressStepCodeEnum
}

export type CompanyOnboardingStepsSavePayload = {
  __typename?: 'CompanyOnboardingStepsSavePayload'
  stepCodes: Array<OnboardingProgressStepCodeEnum>
}

export type CompanyPositionCreateInput = {
  companyId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  /**
   * if `true` the method just returns the existing position, instead of throwing an NON-UNIQUE exception
   * allows to bypass only name-unique exception
   */
  doSuppressException?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  positionTemplateId?: InputMaybe<Scalars['ID']['input']>
}

export type CompanyPositionCreatePayload = {
  __typename?: 'CompanyPositionCreatePayload'
  position: Position
  query: Query
}

export type CompanyPositionsGetInput = {
  filter?: InputMaybe<PositionFilterArg>
}

export type CompanyPositionsGetPayload = {
  __typename?: 'CompanyPositionsGetPayload'
  positions: Array<Position>
  query: Query
}

export type CompanyProcessCreateInput = {
  companyId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  processTemplateId?: InputMaybe<Scalars['ID']['input']>
}

export type CompanyProcessCreatePayload = {
  __typename?: 'CompanyProcessCreatePayload'
  process: Process
  query: Query
}

export type CompanyProcessesGetInput = {
  filter?: InputMaybe<ProcessesGetFilterArg>
}

export type CompanyProcessesGetPayload = {
  __typename?: 'CompanyProcessesGetPayload'
  processes: Array<Process>
  query: Query
}

export type CompanyRegulationLinkInput = {
  companyId: Scalars['ID']['input']
  regulationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyRegulationLinkPayload = {
  __typename?: 'CompanyRegulationLinkPayload'
  company: Company
  query: Query
  regulations: Array<Regulation>
}

export type CompanyRegulationUnLinkInput = {
  companyId: Scalars['ID']['input']
  regulationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyRegulationUnLinkPayload = {
  __typename?: 'CompanyRegulationUnLinkPayload'
  company: Company
  query: Query
  regulations?: Maybe<Array<Regulation>>
}

export type CompanyRepresentativeAddressSetInput = {
  address?: InputMaybe<AddressArg>
  representativeId: Scalars['ID']['input']
}

export type CompanyRepresentativeAddressSetPayload = {
  __typename?: 'CompanyRepresentativeAddressSetPayload'
  query: Query
  representative: Representative
}

export type CompanyRepresentativeCreateInput = {
  companyId: Scalars['ID']['input']
  email?: InputMaybe<Scalars['EmailAddress']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['SloppyPhoneNumber']['input']>
  representativeGroup: RepresentativeGroupEnum
}

export type CompanyRepresentativeCreatePayload = {
  __typename?: 'CompanyRepresentativeCreatePayload'
  query: Query
  representative: Representative
}

export type CompanyRepresentativeDeleteInput = {
  filter: RepresentativeFilterArg
}

export type CompanyRepresentativeDeletePayload = {
  __typename?: 'CompanyRepresentativeDeletePayload'
  query: Query
  representatives: Array<Representative>
}

export type CompanyRepresentativeFilterArg = {
  representativeGroups?: InputMaybe<Array<RepresentativeGroupEnum>>
  representativeIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CompanyRepresentativeUpdateInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  email?: InputMaybe<Scalars['EmailAddress']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['SloppyPhoneNumber']['input']>
  representativeGroup?: InputMaybe<RepresentativeGroupEnum>
  representativeId: Scalars['ID']['input']
}

export type CompanyRepresentativeUpdatePayload = {
  __typename?: 'CompanyRepresentativeUpdatePayload'
  query: Query
  representative: Representative
}

export type CompanyRepresentativesGetInput = {
  filter?: InputMaybe<RepresentativeFilterArg>
}

export type CompanyRepresentativesGetPayload = {
  __typename?: 'CompanyRepresentativesGetPayload'
  query: Query
  representatives: Array<Representative>
}

export type CompanyRepresentativesInput = {
  filter?: InputMaybe<CompanyRepresentativeFilterArg>
}

export type CompanyStructure = {
  __typename?: 'CompanyStructure'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type CompanySubscriptionTierSetInput = {
  companyId: Scalars['ID']['input']
  subscriptionTierId: Scalars['ID']['input']
}

export type CompanySubscriptionTierSetPayload = {
  __typename?: 'CompanySubscriptionTierSetPayload'
  company: Company
  query: Query
  subscriptionTier: SubscriptionTier
}

export type CompanySubscriptionTrialStartPayload = {
  __typename?: 'CompanySubscriptionTrialStartPayload'
  query: Query
  tenant: Tenant
}

export type CompanyType = {
  __typename?: 'CompanyType'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type CompanyUpdateInput = {
  annualSalesRevenueId?: InputMaybe<Scalars['ID']['input']>
  companyId: Scalars['ID']['input']
  currencyId?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  numberOfEmployees?: InputMaybe<Scalars['NonNegativeInt']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  structureId?: InputMaybe<Scalars['ID']['input']>
  typeId?: InputMaybe<Scalars['ID']['input']>
  usersPrimaryEmailDomain?: InputMaybe<Scalars['String']['input']>
}

export type CompanyUpdatePayload = {
  __typename?: 'CompanyUpdatePayload'
  company: Company
  query: Query
}

export enum CompareOperationEnum {
  EqualsTo = 'EqualsTo',
  GreaterOrEqualsTo = 'GreaterOrEqualsTo',
  GreaterThan = 'GreaterThan',
  /**
   * this operation will check if the operand is in the right open
   * interval `[a; b)` -- including `a` but not `b`
   *
   * It is a very common operation for `Datetime` ranges
   */
  InIntervalRightOpen = 'InIntervalRightOpen',
  LessOrEqualsTo = 'LessOrEqualsTo',
  LessThan = 'LessThan',
}

export type ConsentCondition = {
  __typename?: 'ConsentCondition'
  conditionId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  form: ConsentForm
  formId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isArchived: Scalars['Boolean']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ConsentConditionArchivedSetFilterArg = {
  conditionIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ConsentConditionArchivedSetInput = {
  filter?: InputMaybe<ConsentConditionArchivedSetFilterArg>
  isArchived: Scalars['Boolean']['input']
}

export type ConsentConditionArchivedSetPayload = {
  __typename?: 'ConsentConditionArchivedSetPayload'
  conditions: Array<ConsentCondition>
  query: Query
}

export type ConsentConditionCreateInfoItemArg = {
  description: Scalars['String']['input']
}

export type ConsentConditionCreateInput = {
  conditionInfos: Array<ConsentConditionCreateInfoItemArg>
  formId: Scalars['ID']['input']
}

export type ConsentConditionCreatePayload = {
  __typename?: 'ConsentConditionCreatePayload'
  conditions?: Maybe<Array<ConsentCondition>>
  form: ConsentForm
  query: Query
}

export type ConsentConditionDeleteInput = {
  conditionId: Scalars['ID']['input']
}

export type ConsentConditionDeletePayload = {
  __typename?: 'ConsentConditionDeletePayload'
  condition: ConsentCondition
  query: Query
}

export type ConsentConditionUpdateInput = {
  conditionId: Scalars['ID']['input']
  description: Scalars['String']['input']
}

export type ConsentConditionUpdatePayload = {
  __typename?: 'ConsentConditionUpdatePayload'
  condition: ConsentCondition
  query: Query
}

export type ConsentConsentIsHiddenSetConsentInfosItemArg = {
  conditionId: Scalars['ID']['input']
  isHidden: Scalars['Boolean']['input']
  personId: Scalars['ID']['input']
}

export type ConsentConsentIsHiddenSetInput = {
  consentInfos: Array<ConsentConsentIsHiddenSetConsentInfosItemArg>
}

export type ConsentConsentIsHiddenSetPayload = {
  __typename?: 'ConsentConsentIsHiddenSetPayload'
  query: Query
}

export type ConsentConsentResponseSaveConsentsItemArg = {
  conditionId: Scalars['ID']['input']
  doAgree: Scalars['Boolean']['input']
}

export type ConsentConsentResponseSaveInput = {
  actorEmail?: InputMaybe<Scalars['EmailAddress']['input']>
  actorFirstName?: InputMaybe<Scalars['String']['input']>
  actorLastName?: InputMaybe<Scalars['String']['input']>
  consents: Array<ConsentConsentResponseSaveConsentsItemArg>
  email: Scalars['EmailAddress']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['SloppyPhoneNumber']['input']>
}

export type ConsentConsentResponseSavePayload = {
  __typename?: 'ConsentConsentResponseSavePayload'
  deedConsentResponse: DeedConsentResponse
  query: Query
}

export type ConsentConsentResponseVerifyInput = {
  token: Scalars['String']['input']
}

export type ConsentConsentResponseVerifyPayload = {
  __typename?: 'ConsentConsentResponseVerifyPayload'
  query: Query
}

export type ConsentCurrent = {
  __typename?: 'ConsentCurrent'
  condition: ConsentCondition
  conditionId: Scalars['ID']['output']
  endedAt?: Maybe<Scalars['DateTime']['output']>
  firstGrantedAt: Scalars['DateTime']['output']
  grantedAt: Scalars['DateTime']['output']
  grantedByPerson: Person
  grantedByPersonId: Scalars['ID']['output']
  isHidden: Scalars['Boolean']['output']
  /**
   * Shows if the last consent-form-submission was confirmed using a secret token
   *
   * can be null for expired/revoked consents
   */
  isLastVerified?: Maybe<Scalars['Boolean']['output']>
  person: Person
  personId: Scalars['ID']['output']
  revokedByPerson?: Maybe<Person>
  revokedByPersonId?: Maybe<Scalars['ID']['output']>
  statusCode: ConsentStatusCodeEnum
}

export type ConsentForm = {
  __typename?: 'ConsentForm'
  allowConsentOnBehalf: Scalars['Boolean']['output']
  company: Company
  companyId: Scalars['ID']['output']
  conditions: Array<ConsentCondition>
  /**
   * The expiration/renew period of the consent. If it is 1Y, it means that consent
   * granted/accepted to this form will expire in 1 year and must be renewed.
   */
  consentExpiresAfterPeriod?: Maybe<Scalars['Duration']['output']>
  createdAt: Scalars['DateTime']['output']
  createdByUser?: Maybe<User>
  createdByUserId: Scalars['ID']['output']
  defaultLanguage: LanguageCodeEnum
  description?: Maybe<Scalars['String']['output']>
  draftAccessToken: Scalars['String']['output']
  formId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isArchived: Scalars['Boolean']['output']
  isDraft: Scalars['Boolean']['output']
  isEditable: Scalars['Boolean']['output']
  lastUpdatedAt: Scalars['DateTime']['output']
  logo?: Maybe<IBinaryFileInfo>
  privacyPolicyLink?: Maybe<Scalars['String']['output']>
  reference: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ConsentFormArchivedSetInput = {
  formId: Scalars['ID']['input']
  isArchived: Scalars['Boolean']['input']
}

export type ConsentFormArchivedSetPayload = {
  __typename?: 'ConsentFormArchivedSetPayload'
  form: ConsentForm
  query: Query
}

export type ConsentFormCreateInput = {
  allowConsentOnBehalf: Scalars['Boolean']['input']
  companyId: Scalars['ID']['input']
  conditionInfos?: InputMaybe<Array<ConsentConditionCreateInfoItemArg>>
  consentExpiresAfterPeriod?: InputMaybe<Scalars['Duration']['input']>
  defaultLanguage: LanguageCodeEnum
  description?: InputMaybe<Scalars['String']['input']>
  isDraft: Scalars['Boolean']['input']
  logo?: InputMaybe<Scalars['Upload']['input']>
  privacyPolicyLink?: InputMaybe<Scalars['String']['input']>
  reference: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type ConsentFormCreatePayload = {
  __typename?: 'ConsentFormCreatePayload'
  form: ConsentForm
  query: Query
}

export type ConsentFormLogoSetInput = {
  formId: Scalars['ID']['input']
  logo?: InputMaybe<Scalars['Upload']['input']>
}

export type ConsentFormLogoSetPayload = {
  __typename?: 'ConsentFormLogoSetPayload'
  form: ConsentForm
  query: Query
}

export type ConsentFormUpdateInput = {
  allowConsentOnBehalf?: InputMaybe<Scalars['Boolean']['input']>
  consentExpiresAfterPeriod?: InputMaybe<Scalars['Duration']['input']>
  defaultLanguage?: InputMaybe<LanguageCodeEnum>
  description?: InputMaybe<Scalars['String']['input']>
  formId: Scalars['ID']['input']
  isDraft?: InputMaybe<Scalars['Boolean']['input']>
  privacyPolicyLink?: InputMaybe<Scalars['String']['input']>
  reference?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ConsentFormUpdatePayload = {
  __typename?: 'ConsentFormUpdatePayload'
  form: ConsentForm
  query: Query
}

export type ConsentFormsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  isArchived?: InputMaybe<Scalars['Boolean']['input']>
  isDraft?: InputMaybe<Scalars['Boolean']['input']>
  lastUpdatedAt?: InputMaybe<DateTimeFilterArg>
  updatedAt?: InputMaybe<DateTimeFilterArg>
}

export type ConsentFormsGetInput = {
  draftAccessToken?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<ConsentFormsGetFilterArg>
  order?: InputMaybe<Array<ConsentFormsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<ConsentFormsGetSearchArg>
}

export type ConsentFormsGetOrderArg = {
  by: ConsentFormsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum ConsentFormsGetOrderEnum {
  CreatedAt = 'createdAt',
  IsArchived = 'isArchived',
  IsDraft = 'isDraft',
  LastUpdatedAt = 'lastUpdatedAt',
  Reference = 'reference',
  Title = 'title',
  UpdatedAt = 'updatedAt',
}

export type ConsentFormsGetPayload = {
  __typename?: 'ConsentFormsGetPayload'
  forms: Array<ConsentForm>
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
}

export type ConsentFormsGetSearchArg = {
  pattern: Scalars['String']['input']
}

export type ConsentGetFilterArg = {
  actorIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  conditionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  endedAt?: InputMaybe<DateTimeFilterArg>
  firstGrantedAt?: InputMaybe<DateTimeFilterArg>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  grantedAt?: InputMaybe<DateTimeFilterArg>
  /**
   * filtering by `isHidden` flag
   *   - `null` means _"return ALL consents"_
   *   - `true` means _"return only isHidden consents"
   *   - `false` means _"return only NOT isHidden consents"
   * default behavior : `false` (omit hidden)
   */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>
  isLastVerified?: InputMaybe<Scalars['Boolean']['input']>
  personEmails?: InputMaybe<Array<Scalars['EmailAddress']['input']>>
  personIds?: InputMaybe<Array<Scalars['ID']['input']>>
  statusCodes?: InputMaybe<Array<ConsentStatusCodeEnum>>
}

export type ConsentGetInput = {
  filter?: InputMaybe<ConsentGetFilterArg>
  filterOperator?: InputMaybe<FilterBoolOperatorEnum>
  order?: InputMaybe<Array<ConsentGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<ConsentGetSearchArg>
}

export type ConsentGetOrderArg = {
  by: ConsentGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum ConsentGetOrderEnum {
  ConditionDescription = 'conditionDescription',
  EndedAt = 'endedAt',
  FirstGrantedAt = 'firstGrantedAt',
  FormDescription = 'formDescription',
  FormReference = 'formReference',
  FormTitle = 'formTitle',
  /**
   * Order by expiration date. It has no relation with `consent status`,
   * the expiration date exists for both: expired and acttve consents
   */
  GrantedAt = 'grantedAt',
  IsHidden = 'isHidden',
  PersonEmail = 'personEmail',
  PersonFirstName = 'personFirstName',
  PersonLastName = 'personLastName',
  Status = 'status',
}

export type ConsentGetPayload = {
  __typename?: 'ConsentGetPayload'
  consents: Array<ConsentCurrent>
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
}

/**
 * Searches for matches on
 * - Form title
 * - Data subject name and email
 */
export type ConsentGetSearchArg = {
  pattern: Scalars['String']['input']
}

export type ConsentManualRevokeInput = {
  conditionIds: Array<Scalars['ID']['input']>
  personId: Scalars['ID']['input']
}

export type ConsentManualRevokePayload = {
  __typename?: 'ConsentManualRevokePayload'
  deedConsentManualRevoke: DeedConsentManualRevoke
  query: Query
}

export enum ConsentStatusCodeEnum {
  Expired = 'EXPIRED',
  Granted = 'GRANTED',
  Revoked = 'REVOKED',
}

export type Country = {
  __typename?: 'Country'
  countryId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isoCode: Scalars['String']['output']
  name: Scalars['String']['output']
  uiCode: Scalars['UICode']['output']
}

export type Currency = {
  __typename?: 'Currency'
  id: Scalars['ID']['output']
  isoCode: Scalars['String']['output']
  name: Scalars['String']['output']
  symbol: Scalars['String']['output']
}

export type DataElement = IDatamapEntity & {
  __typename?: 'DataElement'
  dataElementId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isSensitive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type DataLifecycle = IDatamapEntity & {
  __typename?: 'DataLifecycle'
  dataLifecycleId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type DataOwnership = IDatamapEntity & {
  __typename?: 'DataOwnership'
  dataOwnershipId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type DataSharing = IDatamapEntity & {
  __typename?: 'DataSharing'
  dataSharingId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  needsValue: Scalars['Boolean']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type DataSource = IDatamapEntity & {
  __typename?: 'DataSource'
  dataSourceId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  needsValue: Scalars['Boolean']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type DataSubject = IDatamapEntity & {
  __typename?: 'DataSubject'
  dataSubjectId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type Datamap = {
  __typename?: 'Datamap'
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  datamapId: Scalars['ID']['output']
  datamapResponses: Array<IDatamapResponse>
  id: Scalars['ID']['output']
  session?: Maybe<SurveySession>
  sessionId?: Maybe<Scalars['ID']['output']>
  survey: Survey
  surveyId: Scalars['ID']['output']
  user?: Maybe<User>
  userId?: Maybe<Scalars['ID']['output']>
}

export type DatamapAnswerOption = ISurveyAnswerOption & {
  __typename?: 'DatamapAnswerOption'
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  entity?: Maybe<IDatamapEntity>
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  isSensitive?: Maybe<Scalars['Boolean']['output']>
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type DatamapCustomAnswerOption = ISurveyAnswerOption & {
  __typename?: 'DatamapCustomAnswerOption'
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  entity?: Maybe<IDatamapEntity>
  /** always `NULL` */
  id?: Maybe<Scalars['ID']['output']>
  isCustom: Scalars['Boolean']['output']
  isSensitive?: Maybe<Scalars['Boolean']['output']>
  /** always `NULL` */
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  /** always `NULL` */
  uiCode?: Maybe<Scalars['UICode']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type DatamapDataElementResponse = IDatamapResponse & {
  __typename?: 'DatamapDataElementResponse'
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  /**
   * For DM-question this field describes the `DatamapEntityType` of the parent
   * question - to make it possible to build the hierarchy of questions for the
   * DM-survey
   *
   * `null` value for DM-questions means that the parent for the question is
   * `Datamap` entity itself (see `survey.datamapInit()`)
   */
  datamapParentEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  entity?: Maybe<IDatamapEntity>
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  isSensitive?: Maybe<Scalars['Boolean']['output']>
  parentResponseId: Scalars['ID']['output']
  response: SurveyResponse
  responseId: Scalars['ID']['output']
}

export type DatamapDataSharingResponse = IDatamapResponse & {
  __typename?: 'DatamapDataSharingResponse'
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  /**
   * For DM-question this field describes the `DatamapEntityType` of the parent
   * question - to make it possible to build the hierarchy of questions for the
   * DM-survey
   *
   * `null` value for DM-questions means that the parent for the question is
   * `Datamap` entity itself (see `survey.datamapInit()`)
   */
  datamapParentEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  entity?: Maybe<IDatamapEntity>
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  parentResponseId: Scalars['ID']['output']
  response: SurveyResponse
  responseId: Scalars['ID']['output']
  sharedWith3rdParty?: Maybe<Scalars['String']['output']>
  sharedWithDepartment?: Maybe<Department>
  sharedWithDepartmentId?: Maybe<Scalars['ID']['output']>
}

export type DatamapDataSourceResponse = IDatamapResponse & {
  __typename?: 'DatamapDataSourceResponse'
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  /**
   * For DM-question this field describes the `DatamapEntityType` of the parent
   * question - to make it possible to build the hierarchy of questions for the
   * DM-survey
   *
   * `null` value for DM-questions means that the parent for the question is
   * `Datamap` entity itself (see `survey.datamapInit()`)
   */
  datamapParentEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  entity?: Maybe<IDatamapEntity>
  id: Scalars['ID']['output']
  isCustom: Scalars['Boolean']['output']
  parentResponseId: Scalars['ID']['output']
  providedBy3rdParty?: Maybe<Scalars['String']['output']>
  providedByDepartment?: Maybe<Department>
  providedByDepartmentId?: Maybe<Scalars['ID']['output']>
  response: SurveyResponse
  responseId: Scalars['ID']['output']
}

export type DatamapResponse = IDatamapResponse & {
  __typename?: 'DatamapResponse'
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  /**
   * For DM-question this field describes the `DatamapEntityType` of the parent
   * question - to make it possible to build the hierarchy of questions for the
   * DM-survey
   *
   * `null` value for DM-questions means that the parent for the question is
   * `Datamap` entity itself (see `survey.datamapInit()`)
   */
  datamapParentEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  entity?: Maybe<IDatamapEntity>
  id: Scalars['ID']['output']
  parentResponseId: Scalars['ID']['output']
  response: SurveyResponse
  responseId: Scalars['ID']['output']
}

/**
 * we implemented two kind of filters:
 * - with "operation" and "values"
 * - with every operation as a separate value
 *
 * both approaches results the same queries, the difference - readability
 */
export type DateTimeFilterArg = {
  eq?: InputMaybe<Scalars['DateTime']['input']>
  ge?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  le?: InputMaybe<Scalars['DateTime']['input']>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  operation: CompareOperationEnum
  values?: InputMaybe<Array<Scalars['DateTime']['input']>>
}

export type DeedConsentManualRevoke = {
  __typename?: 'DeedConsentManualRevoke'
  actor: Person
  actorId: Scalars['ID']['output']
  deedConsentManualRevokeId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  person: Person
  personId: Scalars['ID']['output']
}

export type DeedConsentResponse = {
  __typename?: 'DeedConsentResponse'
  actor: Person
  actorId: Scalars['ID']['output']
  consentResponseId: Scalars['ID']['output']
  form: ConsentForm
  formId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  person: Person
  personId: Scalars['ID']['output']
}

export type Department = IDatamapEntity & {
  __typename?: 'Department'
  company: Company
  companyId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  departmentId: Scalars['ID']['output']
  departmentTemplate?: Maybe<DepartmentTemplate>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type DepartmentFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  departmentIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DepartmentTemplate = {
  __typename?: 'DepartmentTemplate'
  departmentTemplateId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  uiCode: Scalars['UICode']['output']
}

export type DocboxDocument = {
  __typename?: 'DocboxDocument'
  createdAt: Scalars['DateTime']['output']
  createdByUser: User
  createdByUserId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  documentId: Scalars['ID']['output']
  /** Nullable only FOR DELETED files (output from `documentDelete`) */
  fileInfo?: Maybe<BinaryFileInfo>
  folder: DocboxFolder
  folderId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isPrivate: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  owner: User
  ownerId: Scalars['ID']['output']
  validUntil?: Maybe<Scalars['DateTime']['output']>
}

export type DocboxDocumentCreateInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  file: Scalars['Upload']['input']
  folderId?: InputMaybe<Scalars['ID']['input']>
  isPrivate?: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  /** By default - current user */
  ownerId?: InputMaybe<Scalars['ID']['input']>
  validUntil?: InputMaybe<Scalars['DateTime']['input']>
}

export type DocboxDocumentCreatePayload = {
  __typename?: 'DocboxDocumentCreatePayload'
  document: DocboxDocument
  query: Query
}

export type DocboxDocumentDeleteFilterArg = {
  documentIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DocboxDocumentDeleteInput = {
  filter?: InputMaybe<DocboxDocumentDeleteFilterArg>
}

export type DocboxDocumentDeletePayload = {
  __typename?: 'DocboxDocumentDeletePayload'
  documents: Array<DocboxDocument>
  query: Query
}

export type DocboxDocumentUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  documentId: Scalars['ID']['input']
  folderId?: InputMaybe<Scalars['ID']['input']>
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  ownerId?: InputMaybe<Scalars['ID']['input']>
  validUntil?: InputMaybe<Scalars['DateTime']['input']>
}

export type DocboxDocumentUpdatePayload = {
  __typename?: 'DocboxDocumentUpdatePayload'
  document: DocboxDocument
  query: Query
}

export type DocboxFolder = {
  __typename?: 'DocboxFolder'
  company: Company
  companyId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  createdByUser: User
  createdByUserId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  folderId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isRoot: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  owner: User
  ownerId: Scalars['ID']['output']
  parentFolder?: Maybe<DocboxFolder>
  parentFolderId?: Maybe<Scalars['ID']['output']>
}

export type DocboxFolderCreateInput = {
  companyId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  /** By default - current user */
  ownerId?: InputMaybe<Scalars['ID']['input']>
  parentFolderId?: InputMaybe<Scalars['ID']['input']>
}

export type DocboxFolderCreatePayload = {
  __typename?: 'DocboxFolderCreatePayload'
  folder: DocboxFolder
  query: Query
}

export type DocboxFolderDeleteFilterArg = {
  folderIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DocboxFolderDeleteInput = {
  /** NOT IMPLEMENTED */
  deleteRecursive?: Scalars['Boolean']['input']
  filter?: InputMaybe<DocboxFolderDeleteFilterArg>
}

export type DocboxFolderDeletePayload = {
  __typename?: 'DocboxFolderDeletePayload'
  folders: Array<DocboxFolder>
  query: Query
}

export type DocboxFolderUpdateInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  folderId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  ownerId?: InputMaybe<Scalars['ID']['input']>
  parentFolderId?: InputMaybe<Scalars['ID']['input']>
}

export type DocboxFolderUpdatePayload = {
  __typename?: 'DocboxFolderUpdatePayload'
  folder: DocboxFolder
  query: Query
}

export type DocboxFoldersGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  folderIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DocboxFoldersGetInput = {
  filter?: InputMaybe<DocboxFoldersGetFilterArg>
}

export type DocboxFoldersGetPayload = {
  __typename?: 'DocboxFoldersGetPayload'
  folders?: Maybe<Array<DocboxFolder>>
}

export type DocboxGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  createdByUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  documentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  folderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Ignores case */
  mimetypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Ignores case */
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type DocboxGetInput = {
  filter?: InputMaybe<DocboxGetFilterArg>
  order?: InputMaybe<Array<DocboxGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<DocboxGetSearchArg>
}

export type DocboxGetOrderArg = {
  by: DocboxGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DocboxGetOrderEnum {
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  FolderCreatedAt = 'folderCreatedAt',
  FolderName = 'folderName',
  Mimetype = 'mimetype',
  Name = 'name',
  OwnerId = 'ownerId',
  ValidUntil = 'validUntil',
}

export type DocboxGetPayload = {
  __typename?: 'DocboxGetPayload'
  documents?: Maybe<Array<DocboxDocument>>
  paginationInfo: PaginationInfo
}

export type DocboxGetSearchArg = {
  /**
   * Searches in:
   *   - document.name
   */
  pattern: Scalars['String']['input']
}

export type DsarDataSubjectTypeArg = {
  dataSubjectTypeId?: InputMaybe<Scalars['ID']['input']>
  translationInfos?: InputMaybe<Array<DsarDataSubjectTypeTranslationArg>>
}

export type DsarDataSubjectTypeTranslationArg = {
  language: LanguageCodeEnum
  name: Scalars['String']['input']
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
}

export type DsarForm = {
  __typename?: 'DsarForm'
  availableLanguages: Array<LanguageCodeEnum>
  company: Company
  companyCountryIsoCode: Scalars['String']['output']
  companyId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  defaultLanguage: LanguageCodeEnum
  draftAccessToken: Scalars['String']['output']
  formId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  lastUpdatedAt: Scalars['DateTime']['output']
  logo?: Maybe<BinaryFileInfoPublic>
  name?: Maybe<Scalars['String']['output']>
  regulation: Regulation
  regulationId: Scalars['ID']['output']
  requests: Array<DsarRequest>
  statusCode: DsarFormStatusCodeEnum
  translations: Array<DsarFormTranslation>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type DsarFormCreateInput = {
  companyId: Scalars['ID']['input']
  dataSubjectTypeInfos?: InputMaybe<Array<DsarDataSubjectTypeArg>>
  defaultLanguage: LanguageCodeEnum
  formTranslationInfos?: InputMaybe<Array<DsarFormTranslationArg>>
  logo?: InputMaybe<Scalars['Upload']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  regulationId: Scalars['ID']['input']
}

export type DsarFormCreatePayload = {
  __typename?: 'DsarFormCreatePayload'
  form: DsarForm
  query: Query
}

export type DsarFormDataSubjectTypeTranslation = {
  __typename?: 'DsarFormDataSubjectTypeTranslation'
  dataSubjectTypeId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  language: LanguageCodeEnum
  name: Scalars['String']['output']
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
}

export type DsarFormDeleteInput = {
  formId: Scalars['ID']['input']
  withRequestsRecursively?: InputMaybe<Scalars['Boolean']['input']>
}

export type DsarFormDeletePayload = {
  __typename?: 'DsarFormDeletePayload'
  form: DsarForm
  query: Query
}

export type DsarFormGetOrderArg = {
  by: DsarFormGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DsarFormGetOrderEnum {
  CreatedAt = 'createdAt',
  LastUpdatedAt = 'lastUpdatedAt',
  Name = 'name',
  StatusCode = 'statusCode',
  UpdatedAt = 'updatedAt',
}

export type DsarFormLogoSetInput = {
  formId: Scalars['ID']['input']
  logo?: InputMaybe<Scalars['Upload']['input']>
}

export type DsarFormLogoSetPayload = {
  __typename?: 'DsarFormLogoSetPayload'
  form: DsarForm
  query: Query
}

export enum DsarFormStatusCodeEnum {
  Draft = 'DRAFT',
  Final = 'FINAL',
}

export type DsarFormTranslation = {
  __typename?: 'DsarFormTranslation'
  description?: Maybe<Scalars['String']['output']>
  font?: Maybe<Scalars['String']['output']>
  form: DsarForm
  formId: Scalars['ID']['output']
  formTranslationId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  language: LanguageCodeEnum
  privacyCookiesLink?: Maybe<Scalars['String']['output']>
  privacyPolicyLink?: Maybe<Scalars['String']['output']>
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type DsarFormTranslationArg = {
  description?: InputMaybe<Scalars['String']['input']>
  font?: InputMaybe<Scalars['String']['input']>
  language: LanguageCodeEnum
  privacyCookiesLink?: InputMaybe<Scalars['String']['input']>
  privacyPolicyLink?: InputMaybe<Scalars['String']['input']>
  /** the order of the translation in the list of translations for the DSAR-form */
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type DsarFormTranslationCreateInput = {
  formId: Scalars['ID']['input']
  formTranslationInfo: DsarFormTranslationArg
}

export type DsarFormTranslationCreatePayload = {
  __typename?: 'DsarFormTranslationCreatePayload'
  formTranslation: DsarFormTranslation
  query: Query
}

export type DsarFormTranslationDeleteFilterArg = {
  formTranslationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DsarFormTranslationDeleteInput = {
  filter?: InputMaybe<DsarFormTranslationDeleteFilterArg>
}

export type DsarFormTranslationDeletePayload = {
  __typename?: 'DsarFormTranslationDeletePayload'
  formTranslations: Array<DsarFormTranslation>
  query: Query
}

export type DsarFormTranslationUpdateInput = {
  formTranslationId: Scalars['ID']['input']
  formTranslationInfo: DsarFormTranslationArg
}

export type DsarFormTranslationUpdatePayload = {
  __typename?: 'DsarFormTranslationUpdatePayload'
  formTranslation: DsarFormTranslation
  query: Query
}

export type DsarFormUpdateInput = {
  defaultLanguage?: InputMaybe<LanguageCodeEnum>
  formId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  statusCode?: InputMaybe<DsarFormStatusCodeEnum>
}

export type DsarFormUpdatePayload = {
  __typename?: 'DsarFormUpdatePayload'
  form: DsarForm
  query: Query
}

export type DsarFormsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  statusCodes?: InputMaybe<Array<DsarFormStatusCodeEnum>>
}

export type DsarFormsGetInput = {
  draftAccessToken?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<DsarFormsGetFilterArg>
  order?: InputMaybe<Array<DsarFormGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type DsarFormsGetPayload = {
  __typename?: 'DsarFormsGetPayload'
  forms: Array<DsarForm>
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
}

export type DsarRequest = {
  __typename?: 'DsarRequest'
  administrativeArea?: Maybe<Scalars['String']['output']>
  channel?: Maybe<DsarRequestChannel>
  channelCode?: Maybe<DsarRequestChannelCodeEnum>
  comments: Array<DsarRequestComment>
  company: Company
  companyId: Scalars['ID']['output']
  completedAt?: Maybe<Scalars['DateTime']['output']>
  country: Country
  countryId: Scalars['ID']['output']
  dataSubjectTypeId: Scalars['ID']['output']
  /**
   * Return the translation for `dataSubjectType` for this request. One can
   * select the desired language explicitly. The final tranlation will be chosen:
   * 0. using `input.language`
   * 1. using `request.language` (the language of the request, as the
   *   requestor filled it
   * 2. using `currentUser.language`
   * 3. first non-empty translation
   *
   * First **NON-empty** (by `name` field) translation will be returned.
   */
  dataSubjectTypeTranslation: DsarFormDataSubjectTypeTranslation
  description?: Maybe<Scalars['String']['output']>
  email: Scalars['EmailAddress']['output']
  expiredAt?: Maybe<Scalars['DateTime']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  form?: Maybe<DsarForm>
  formId?: Maybe<Scalars['ID']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  historyItems: Array<DsarRequestHistoryItem>
  id: Scalars['ID']['output']
  isIdVerified: Scalars['Boolean']['output']
  language: LanguageCodeEnum
  lastName?: Maybe<Scalars['String']['output']>
  messages: Array<DsarRequestMessage>
  phoneNumber?: Maybe<Scalars['SloppyPhoneNumber']['output']>
  requestId: Scalars['ID']['output']
  requestedAt: Scalars['DateTime']['output']
  statusCode: DsarRequestStatusCodeEnum
  type: DsarRequestType
  typeId: Scalars['ID']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type DsarRequestDataSubjectTypeTranslationArgs = {
  input?: InputMaybe<DsarRequestDataSubjectTypeTranslationArg>
}

export type DsarRequestChannel = {
  __typename?: 'DsarRequestChannel'
  code: DsarRequestChannelCodeEnum
  name: Scalars['String']['output']
  uiCode: Scalars['UICode']['output']
}

export enum DsarRequestChannelCodeEnum {
  Chatbox = 'CHATBOX',
  Email = 'EMAIL',
  Form = 'FORM',
  InPerson = 'IN_PERSON',
  Letter = 'LETTER',
  MessagingApp = 'MESSAGING_APP',
  Phone = 'PHONE',
  Sms = 'SMS',
}

export type DsarRequestChannelsGetPayload = {
  __typename?: 'DsarRequestChannelsGetPayload'
  query: Query
  requestChannels: Array<DsarRequestChannel>
}

export type DsarRequestComment = {
  __typename?: 'DsarRequestComment'
  content?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  id?: Maybe<Scalars['ID']['output']>
  isFirstComment: Scalars['Boolean']['output']
  request: DsarRequest
  requestCommentId?: Maybe<Scalars['ID']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  user: IUserWithEmail
}

export type DsarRequestCommentCreateInput = {
  content: Scalars['String']['input']
  requestId: Scalars['ID']['input']
}

export type DsarRequestCommentCreatePayload = {
  __typename?: 'DsarRequestCommentCreatePayload'
  query: Query
  requestComment: DsarRequestComment
}

export type DsarRequestCommentUpdateInput = {
  content: Scalars['String']['input']
  requestCommentId: Scalars['ID']['input']
}

export type DsarRequestCommentUpdatePayload = {
  __typename?: 'DsarRequestCommentUpdatePayload'
  query: Query
  requestComment: DsarRequestComment
}

export type DsarRequestCommentsFilterArg = {
  commentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  requestIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DsarRequestCommentsGetInput = {
  filter?: InputMaybe<DsarRequestCommentsFilterArg>
  order?: InputMaybe<Array<DsarRequestCommentsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type DsarRequestCommentsGetOrderArg = {
  by: DsarRequestCommentsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DsarRequestCommentsGetOrderEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  RequestId = 'requestId',
  UserId = 'userId',
}

export type DsarRequestCommentsGetPayload = {
  __typename?: 'DsarRequestCommentsGetPayload'
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
  requestComments: Array<DsarRequestComment>
}

export type DsarRequestConversationUnion =
  | DsarRequestComment
  | DsarRequestMessage

export type DsarRequestConversationsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  requestCommentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  requestIds?: InputMaybe<Array<Scalars['ID']['input']>>
  requestMessageIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DsarRequestConversationsGetInput = {
  filter?: InputMaybe<DsarRequestConversationsGetFilterArg>
  order?: InputMaybe<Array<DsarRequestConversationsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type DsarRequestConversationsGetOrderArg = {
  by: DsarRequestConversationsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DsarRequestConversationsGetOrderEnum {
  Content = 'content',
  CreatedAt = 'createdAt',
  RequestId = 'requestId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type DsarRequestConversationsGetPayload = {
  __typename?: 'DsarRequestConversationsGetPayload'
  conversations: Array<DsarRequestConversationUnion>
  paginationInfo: PaginationInfo
  query: Query
}

export type DsarRequestCreateInput = {
  administrativeArea?: InputMaybe<Scalars['String']['input']>
  channelCode?: InputMaybe<DsarRequestChannelCodeEnum>
  companyId?: InputMaybe<Scalars['ID']['input']>
  countryOfResidenceId?: InputMaybe<Scalars['ID']['input']>
  description: Scalars['String']['input']
  email: Scalars['EmailAddress']['input']
  firstName: Scalars['String']['input']
  formId?: InputMaybe<Scalars['ID']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['SloppyPhoneNumber']['input']>
  requestDataSubjectTypeId: Scalars['ID']['input']
  requestTypeId: Scalars['ID']['input']
  /** by default - current date */
  requestedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type DsarRequestCreatePayload = {
  __typename?: 'DsarRequestCreatePayload'
  query: Query
  request: DsarRequest
}

export type DsarRequestDataSubjectTypeTranslationArg = {
  language?: InputMaybe<LanguageCodeEnum>
}

export type DsarRequestDataSubjectTypeTranslationsDeleteFilterArg = {
  dataSubjectTypeTranslationIds: Array<Scalars['ID']['input']>
}

export type DsarRequestDataSubjectTypeTranslationsDeleteInput = {
  filter?: InputMaybe<DsarRequestDataSubjectTypeTranslationsDeleteFilterArg>
}

export type DsarRequestDataSubjectTypeTranslationsDeletePayload = {
  __typename?: 'DsarRequestDataSubjectTypeTranslationsDeletePayload'
  query: Query
}

export type DsarRequestDataSubjectTypeTranslationsGetFilterArg = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  formId?: InputMaybe<Scalars['ID']['input']>
  languages?: InputMaybe<Array<LanguageCodeEnum>>
}

export type DsarRequestDataSubjectTypeTranslationsGetInput = {
  filter?: InputMaybe<DsarRequestDataSubjectTypeTranslationsGetFilterArg>
}

export type DsarRequestDataSubjectTypeTranslationsGetPayload = {
  __typename?: 'DsarRequestDataSubjectTypeTranslationsGetPayload'
  dataSubjectTypeTranslations: Array<DsarFormDataSubjectTypeTranslation>
  query: Query
}

export type DsarRequestDataSubjectTypeTranslationsUpsertInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  dataSubjectTypeInfos: Array<DsarDataSubjectTypeArg>
  formId?: InputMaybe<Scalars['ID']['input']>
}

export type DsarRequestDataSubjectTypeTranslationsUpsertPayload = {
  __typename?: 'DsarRequestDataSubjectTypeTranslationsUpsertPayload'
  query: Query
}

export type DsarRequestHistoryItem = {
  __typename?: 'DsarRequestHistoryItem'
  comment?: Maybe<DsarRequestComment>
  createdAt: Scalars['DateTime']['output']
  id?: Maybe<Scalars['ID']['output']>
  /** @deprecated use Templates on the client side */
  message?: Maybe<Scalars['String']['output']>
  request: DsarRequest
  requestHistoryItemId?: Maybe<Scalars['ID']['output']>
  requestMessage?: Maybe<DsarRequestMessage>
  uiCode: Scalars['UICode']['output']
  user: IUserWithEmail
}

export type DsarRequestHistoryItemsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  requestIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DsarRequestHistoryItemsGetInput = {
  filter?: InputMaybe<DsarRequestHistoryItemsGetFilterArg>
  order?: InputMaybe<Array<DsarRequestHistoryItemsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type DsarRequestHistoryItemsGetOrderArg = {
  by: DsarRequestHistoryItemsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DsarRequestHistoryItemsGetOrderEnum {
  CreatedAt = 'createdAt',
  FormId = 'formId',
  RequestId = 'requestId',
}

export type DsarRequestHistoryItemsGetPayload = {
  __typename?: 'DsarRequestHistoryItemsGetPayload'
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
  requestHistoryItems: Array<DsarRequestHistoryItem>
}

export type DsarRequestMessage = {
  __typename?: 'DsarRequestMessage'
  body: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  fromUser: IUserWithEmail
  /**
   * A request message can be created by two types of users:
   *
   * 1. Company Administrator (Authenticated)
   * 	In this case, field `fromUserID` contains Palqee User Id and `fromUser` resolves to GQL `User`
   *
   *
   * 2. Requestor (Anonymous)
   * 	Field `fromUserId` is null and `fromUser` is filled with data from Request. (email, firstName and lastName)
   */
  fromUserId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  inReplyToRequestMessage?: Maybe<DsarRequestMessage>
  inReplyToRequestMessageId?: Maybe<Scalars['ID']['output']>
  request: DsarRequest
  requestId: Scalars['ID']['output']
  requestMessageId: Scalars['ID']['output']
  senderKind: DsarRequestMessageSenderKindEnum
  subject: Scalars['String']['output']
}

export type DsarRequestMessageSendInput = {
  body: Scalars['String']['input']
  inReplyToRequestMessageId?: InputMaybe<Scalars['ID']['input']>
  requestId: Scalars['ID']['input']
  subject: Scalars['String']['input']
}

export type DsarRequestMessageSendPayload = {
  __typename?: 'DsarRequestMessageSendPayload'
  query: Query
  requestMessage: DsarRequestMessage
}

export enum DsarRequestMessageSenderKindEnum {
  CompanyAdministrator = 'COMPANY_ADMINISTRATOR',
  Requestor = 'REQUESTOR',
}

export enum DsarRequestStatusCodeEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  OnHold = 'ON_HOLD',
  Open = 'OPEN',
}

export type DsarRequestType = {
  __typename?: 'DsarRequestType'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  regulation: Regulation
  regulationId: Scalars['ID']['output']
  requestTypeId: Scalars['ID']['output']
  sortOrder: Scalars['Int']['output']
  uiCode: Scalars['UICode']['output']
}

export type DsarRequestTypesFilterArg = {
  regulationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DsarRequestTypesGetInput = {
  filter?: InputMaybe<DsarRequestTypesFilterArg>
}

export type DsarRequestTypesGetPayload = {
  __typename?: 'DsarRequestTypesGetPayload'
  query: Query
  requestTypes: Array<DsarRequestType>
}

export type DsarRequestUpdateInput = {
  isIdVerified?: InputMaybe<Scalars['Boolean']['input']>
  requestId: Scalars['ID']['input']
  statusCode?: InputMaybe<DsarRequestStatusCodeEnum>
}

export type DsarRequestUpdatePayload = {
  __typename?: 'DsarRequestUpdatePayload'
  query: Query
  request: DsarRequest
}

export type DsarRequestor = IUserWithEmail & {
  __typename?: 'DsarRequestor'
  email: Scalars['EmailAddress']['output']
  firstName?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
}

export type DsarRequestsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  countryIds?: InputMaybe<Array<Scalars['ID']['input']>>
  requestIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type DsarRequestsGetInput = {
  filter?: InputMaybe<DsarRequestsGetFilterArg>
  order?: InputMaybe<Array<DsarRequestsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<DsarRequestsGetSearchArg>
}

export type DsarRequestsGetOrderArg = {
  by: DsarRequestsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum DsarRequestsGetOrderEnum {
  Channel = 'channel',
  CompanyId = 'companyId',
  CompanyName = 'companyName',
  CompletedAt = 'completedAt',
  CountryId = 'countryId',
  CreatedAt = 'createdAt',
  Email = 'email',
  ExpiredAt = 'expiredAt',
  FirstName = 'firstName',
  FormId = 'formId',
  FormName = 'formName',
  IsIdVerified = 'isIdVerified',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  RequestedAt = 'requestedAt',
  StatusCode = 'statusCode',
  TypeId = 'typeId',
}

export type DsarRequestsGetPayload = {
  __typename?: 'DsarRequestsGetPayload'
  paginationInfo?: Maybe<PaginationInfo>
  requests: Array<DsarRequest>
}

export type DsarRequestsGetSearchArg = {
  /**
   * Searches in:
   *   - request.firstName
   *   - request.lastName
   *   - request.description
   */
  pattern: Scalars['String']['input']
}

export enum FilterBoolOperatorEnum {
  And = 'AND',
  Or = 'OR',
}

export type Fraction = {
  __typename?: 'Fraction'
  A?: Maybe<Scalars['Int']['output']>
  B?: Maybe<Scalars['Int']['output']>
  decimal?: Maybe<Scalars['Float']['output']>
}

export type FractionFloat = {
  __typename?: 'FractionFloat'
  A?: Maybe<Scalars['Float']['output']>
  B?: Maybe<Scalars['Float']['output']>
  decimal?: Maybe<Scalars['Float']['output']>
}

export type GeneralAnnualSalesRevenueListPayload = {
  __typename?: 'GeneralAnnualSalesRevenueListPayload'
  annualSalesRevenues?: Maybe<Array<AnnualSalesRevenue>>
}

export type GeneralAssetListPayload = {
  __typename?: 'GeneralAssetListPayload'
  assets: Array<Asset>
}

export type GeneralCompanyOnboardingProgressListInput = {
  companyId: Scalars['ID']['input']
}

export type GeneralCompanyOnboardingProgressStepPayload = {
  __typename?: 'GeneralCompanyOnboardingProgressStepPayload'
  stepCodes: Array<OnboardingProgressStepCodeEnum>
}

export type GeneralCompanyRegionLinkInput = {
  companyId: Scalars['ID']['input']
  regionIds: Array<Scalars['ID']['input']>
}

export type GeneralCompanyRegionLinkPayload = {
  __typename?: 'GeneralCompanyRegionLinkPayload'
  company: Company
}

export type GeneralCompanyRegionUnlinkInput = {
  companyId: Scalars['ID']['input']
  regionIds: Array<Scalars['ID']['input']>
}

export type GeneralCompanyRegionUnlinkPayload = {
  __typename?: 'GeneralCompanyRegionUnlinkPayload'
  company: Company
}

export type GeneralCompanyStructureListPayload = {
  __typename?: 'GeneralCompanyStructureListPayload'
  companyStructures?: Maybe<Array<CompanyStructure>>
}

export type GeneralCompanyTypeListPayload = {
  __typename?: 'GeneralCompanyTypeListPayload'
  companyTypes?: Maybe<Array<CompanyType>>
}

export type GeneralCountryListFilterArg = {
  countryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type GeneralCountryListInput = {
  filter?: InputMaybe<GeneralCountryListFilterArg>
  order?: InputMaybe<Array<GeneralCountryListOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type GeneralCountryListOrderArg = {
  by: GeneralCountryListOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum GeneralCountryListOrderEnum {
  IsoCode = 'isoCode',
  Name = 'name',
}

export type GeneralCountryListPayload = {
  __typename?: 'GeneralCountryListPayload'
  countries: Array<Country>
  paginationInfo?: Maybe<PaginationInfo>
  query: Query
}

export type GeneralCurrencyListPayload = {
  __typename?: 'GeneralCurrencyListPayload'
  currencies?: Maybe<Array<Currency>>
}

export type GeneralDepartmentTemplatesGetPayload = {
  __typename?: 'GeneralDepartmentTemplatesGetPayload'
  departmentTemplates: Array<DepartmentTemplate>
}

export type GeneralEmailSendInput = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  body: Scalars['String']['input']
  subject: Scalars['String']['input']
  to?: InputMaybe<Array<Scalars['EmailAddress']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GeneralEmailSendPayload = {
  __typename?: 'GeneralEmailSendPayload'
  messagesSentCount: Scalars['NonNegativeInt']['output']
  query: Query
}

export type GeneralIndustryListPayload = {
  __typename?: 'GeneralIndustryListPayload'
  industries: Array<Industry>
}

export type GeneralModulesGetPayload = {
  __typename?: 'GeneralModulesGetPayload'
  modules: Array<PalqeeModule>
  query: Query
}

export type GeneralPositionTemplatesGetPayload = {
  __typename?: 'GeneralPositionTemplatesGetPayload'
  positionTemplates: Array<PositionTemplate>
}

export type GeneralProcessTemplateListPayload = {
  __typename?: 'GeneralProcessTemplateListPayload'
  processTemplates: Array<ProcessTemplate>
}

export type GeneralRegionGroupListPayload = {
  __typename?: 'GeneralRegionGroupListPayload'
  regionGroups: Array<RegionGroup>
}

export type GeneralRegionListFilterArg = {
  companyId: Scalars['ID']['input']
}

export type GeneralRegionListInput = {
  filter?: InputMaybe<GeneralRegionListFilterArg>
}

export type GeneralRegionListPayload = {
  __typename?: 'GeneralRegionListPayload'
  regions: Array<Region>
}

export type GeneralRegulationsPayload = {
  __typename?: 'GeneralRegulationsPayload'
  regulations: Array<Regulation>
}

export type GeneralSearchFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  palqeeModuleCodes?: InputMaybe<Array<PalqeeModuleCodeEnum>>
}

export type GeneralSearchInput = {
  filter?: InputMaybe<GeneralSearchFilterArg>
  order?: InputMaybe<Array<GeneralSearchOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<GeneralSearchSearchArg>
}

export type GeneralSearchMainEntitiesInput = {
  filter?: InputMaybe<GeneralSearchFilterArg>
  order?: InputMaybe<Array<GeneralSearchOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<GeneralSearchSearchArg>
}

export type GeneralSearchMainEntitiesPayload = {
  __typename?: 'GeneralSearchMainEntitiesPayload'
  paginationInfo: PaginationInfo
  query: Query
  results: Array<GeneralSearchMainEntitiesResultsItem>
}

export type GeneralSearchMainEntitiesResultEntityUnion =
  | DocboxDocument
  | DsarForm
  | DsarRequest
  | Survey
  | SurveySession
  | Task

export type GeneralSearchMainEntitiesResultsItem = {
  __typename?: 'GeneralSearchMainEntitiesResultsItem'
  entity: GeneralSearchMainEntitiesResultEntityUnion
}

export type GeneralSearchOrderArg = {
  by: GeneralSearchOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum GeneralSearchOrderEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
}

export type GeneralSearchPayload = {
  __typename?: 'GeneralSearchPayload'
  paginationInfo: PaginationInfo
  query: Query
  results: Array<GeneralSearchResultsItem>
}

export type GeneralSearchResultEntityUnion =
  | DocboxDocument
  | DsarForm
  | DsarRequest
  | DsarRequestType
  | Survey
  | SurveySubcategory
  | Task
  | TaskCategory
  | TaskPriority
  | TaskStatus

export type GeneralSearchResultsItem = {
  __typename?: 'GeneralSearchResultsItem'
  entity: GeneralSearchResultEntityUnion
}

export type GeneralSearchSearchArg = {
  pattern: Scalars['String']['input']
}

export type GeneralSubscriptionTiersFilterArg = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type GeneralSubscriptionTiersGetInput = {
  filter?: InputMaybe<GeneralSubscriptionTiersFilterArg>
}

export type GeneralSubscriptionTiersGetPayload = {
  __typename?: 'GeneralSubscriptionTiersGetPayload'
  subscriptionTiers?: Maybe<Array<SubscriptionTier>>
}

/**
 * Palqee specification for CSV-file import:
 *
 * What is CSV: http://super-csv.github.io/super-csv/csv_specification.html
 *
 * Palqee's rules:
 *
 *   1. csv file
 *   2. encoding : utf8
 *   3. field separator/delimiter - `,` (comma). Line separator `\r\n` or `\n` (line break, windows or unix style)
 *   4. first line in the file - HEADERS, list of available headers below (and they depends on the language now)
 *   5. empty lines are ignored
 *   6. if a line starts with `#` - it is considered a comment, and it is ignored
 *   7. if one of the fields needs a `,` (comma) as a part of the value (for example, to pass the list of `roleCodes`) - wrap the field with `"` (double quotes), like this: `"  ADMINISTRATOR   ,  EMPLOYEE "`. If you want to use both `,` (comma) and `"` (double quote) as a value - wrap the value with `"` (double quote) and use two `"` , like this (CSV): `"Bernard ""Junior"", The second"` (the value is `Bernard "Junior", The second`)
 *   8. most leading and trailing white spaces (between commas) are ignored (see field specs below, for more details)
 */
export type GqlDocs_CsvFileUpload = {
  none?: InputMaybe<Scalars['Void']['input']>
}

export type HttpHeader = {
  __typename?: 'HttpHeader'
  header: Scalars['String']['output']
  value?: Maybe<Scalars['String']['output']>
}

export type IBinaryFileInfo = {
  /** Length is unknown for OLD files */
  contentLength?: Maybe<Scalars['NonNegativeInt']['output']>
  id: Scalars['ID']['output']
  isPublic: Scalars['Boolean']['output']
  mimetype: Scalars['String']['output']
  originalFilename?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type IBinaryFileInfoUrlArgs = {
  input?: InputMaybe<BinaryFileInfoUrlInput>
}

export type IDatamapEntity = {
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

/**
 * This is a part of the response, the generic part is stored in the
 * `SurveyReponse`, and this is information of the response, related to the
 * Datamap
 */
export type IDatamapResponse = {
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  entity?: Maybe<IDatamapEntity>
  id: Scalars['ID']['output']
  parentResponseId: Scalars['ID']['output']
  response: SurveyResponse
  responseId: Scalars['ID']['output']
}

export type ISubscriptionFeature = {
  code: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  subscriptionFeatureId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type ISurveyAnswerOption = {
  id?: Maybe<Scalars['ID']['output']>
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type IUserWithEmail = {
  email: Scalars['EmailAddress']['output']
  firstName?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
}

export type Industry = {
  __typename?: 'Industry'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type Invitation = {
  __typename?: 'Invitation'
  createdAt: Scalars['DateTime']['output']
  invitationId: Scalars['ID']['output']
  invitedByUser?: Maybe<User>
  invitedByUserId?: Maybe<Scalars['ID']['output']>
  isCompleted: Scalars['Boolean']['output']
  lastRespondedAt?: Maybe<Scalars['DateTime']['output']>
  session: SurveySession
  sessionId: Scalars['ID']['output']
  /** @deprecated will be removed */
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  user: User
  userId: Scalars['ID']['output']
}

export type KnowledgeBaseArticle = {
  __typename?: 'KnowledgeBaseArticle'
  description?: Maybe<Scalars['String']['output']>
  externalArticleInfo?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  knowledgeBaseArticleId: Scalars['ID']['output']
  label: Scalars['String']['output']
}

export enum LanguageCodeEnum {
  De = 'de',
  En = 'en',
  Es = 'es',
  Pt = 'pt',
}

export type LegalBasis = IDatamapEntity & {
  __typename?: 'LegalBasis'
  id: Scalars['ID']['output']
  legalBasisId: Scalars['ID']['output']
  name: Scalars['String']['output']
  regulation?: Maybe<Regulation>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type MaturityScore = {
  __typename?: 'MaturityScore'
  maturityScore: Scalars['NonNegativeInt']['output']
  question: SurveyQuestion
  questionId: Scalars['ID']['output']
  session: SurveySession
  sessionId: Scalars['ID']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  admin: NamespaceAdminMutation
  auth: NamespaceAuthMutation
  company: NamespaceCompanyMutation
  consent: NamespaceConsentMutation
  document: NamespaceDocumentMutation
  dsar: NamespaceDsarMutation
  general: NamespaceGeneralMutation
  survey: NamespaceSurveyMutation
  task: NamespaceTaskMutation
  user: NamespaceUserMutation
}

/**
 * Namespace: **admin**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAdminMutation = {
  __typename?: 'NamespaceAdminMutation'
  regionCreate: AdminRegionCreatePayload
}

/**
 * Namespace: **admin**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAdminMutationRegionCreateArgs = {
  input: AdminRegionCreateInput
}

/**
 * Namespace: **admin**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAdminQuery = {
  __typename?: 'NamespaceAdminQuery'
  messageTemplateRender: Scalars['Void']['output']
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutation = {
  __typename?: 'NamespaceAuthMutation'
  identityApplicationUpsert: AuthIdentityApplicationUpsertPayload
  login: AuthLoginPayload
  loginConfirm: AuthLoginConfirmPayload
  logout: AuthLogoutPayload
  /**
   * Set up MFA for an authenticated user.
   *
   * ### TOTP
   * Use a TOTP-application as a second factor.
   * For debug-purposes you could use WEB app.
   * See also: https://qoomon.github.io/otp-authenticator-webapp/
   *
   * ### SMS
   * Use phone as a second factor for an authentication.
   */
  mfaConfigure: AuthMfaConfigurePayload
  mfaConfigureConfirm: AuthMfaConfigureConfirmPayload
  mfaDisable: AuthMfaDisablePayload
  passwordChange: AuthPasswordChangePayload
  passwordRestoreConfirm: AuthPasswordRestoreConfirmPayload
  passwordRestoreInit: AuthPasswordRestoreInitPayload
  /**
   * For signing up on Palqee
   * If you want to add a new user to your company use `createUser()`
   */
  signUp: AuthSignUpPayload
  signUpConfirm: AuthSignUpConfirmPayload
  signUpResendCode: AuthSignUpResendCodePayload
  signUpResendCodeToPendingUsers: AuthSignUpResendCodeToPendingUsersPayload
  tokenRefresh: AuthTokenRefreshPayload
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationIdentityApplicationUpsertArgs = {
  input: AuthIdentityApplicationUpsertInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationLoginArgs = {
  input: AuthLoginInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationLoginConfirmArgs = {
  input: AuthLoginConfirmInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationMfaConfigureArgs = {
  input: AuthMfaConfigureInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationMfaConfigureConfirmArgs = {
  input: AuthMfaConfigureConfirmInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationPasswordChangeArgs = {
  input: AuthPasswordChangeInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationPasswordRestoreConfirmArgs = {
  input: AuthPasswordRestoreConfirmInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationPasswordRestoreInitArgs = {
  input: AuthPasswordRestoreInitInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationSignUpArgs = {
  input: AuthSignUpInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationSignUpConfirmArgs = {
  input: AuthSignUpConfirmInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationSignUpResendCodeArgs = {
  input: AuthSignUpResendCodeInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationSignUpResendCodeToPendingUsersArgs = {
  input: AuthSignUpResendCodeToPendingUsersInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthMutationTokenRefreshArgs = {
  input: AuthTokenRefreshInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthQuery = {
  __typename?: 'NamespaceAuthQuery'
  identityApplicationsGet: AuthIdentityApplicationsGetPayload
  /**
   * `userCurrent` checks the content of the HTTP-header `Authorization`. If
   * there is a valid `accessToken` then the request is considered
   * **authenticated**, and method returns information about authenticated user.
   *
   * If there is no `accessToken` in the HTTP-header — the request is considered
   * **anonymous**.
   *
   * If accessToken is invalid — an exception is raised.
   */
  userCurrent: AuthUserCurrentPayload
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthQueryIdentityApplicationsGetArgs = {
  input: AuthIdentityApplicationsGetInput
}

/**
 * Namespace: **auth**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceAuthQueryUserCurrentArgs = {
  input?: InputMaybe<AuthUserCurrentInput>
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutation = {
  __typename?: 'NamespaceCompanyMutation'
  addressSet: CompanyAddressSetPayload
  assetConnectionCreate: CompanyAssetConnectionCreatePayload
  audienceCreate: CompanyAudienceCreatePayload
  audienceDelete: CompanyAudienceDeletePayload
  audienceUpdate: CompanyAudienceUpdatePayload
  audienceUsersLink: CompanyAudienceUsersLinkPayload
  audienceUsersUnlink: CompanyAudienceUsersUnlinkPayload
  create: CompanyCreatePayload
  delete: CompanyDeletePayload
  /**
   * Create a new department-entity for a company.
   *
   * If you provide the departmentTemplateId, then `name`, `description` and `uiCode` are copied to the new entity from
   * the template, and the new entity will be translated (just as the template itself)
   */
  departmentCreate: CompanyDepartmentCreatePayload
  industriesSet: CompanyIndustriesSetPayload
  logoSet: CompanyLogoSetPayload
  /** TODO: Pretty specific `save` behavior: it updates only the steps you pass in, but do not affect other steps */
  onboardingStepsSave: CompanyOnboardingStepsSavePayload
  /**
   * Create a new position-entity for a company.
   *
   * If you provide the positionTemplateId, then `name`, `description` and `uiCode` are copied to the new entity from
   * the template, and the new entity will be translated (just as the template itself)
   */
  positionCreate: CompanyPositionCreatePayload
  processCreate: CompanyProcessCreatePayload
  regulationLink: CompanyRegulationLinkPayload
  regulationUnlink: CompanyRegulationUnLinkPayload
  representativeAddressSet: CompanyRepresentativeAddressSetPayload
  representativeCreate: CompanyRepresentativeCreatePayload
  representativeDelete: CompanyRepresentativeDeletePayload
  representativeUpdate: CompanyRepresentativeUpdatePayload
  subscriptionTierSet: CompanySubscriptionTierSetPayload
  subscriptionTrialStart?: Maybe<CompanySubscriptionTrialStartPayload>
  update: CompanyUpdatePayload
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAddressSetArgs = {
  input: CompanyAddressSetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAssetConnectionCreateArgs = {
  input: CompanyAssetConnectionCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAudienceCreateArgs = {
  input: CompanyAudienceCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAudienceDeleteArgs = {
  input: CompanyAudienceDeleteInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAudienceUpdateArgs = {
  input: CompanyAudienceUpdateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAudienceUsersLinkArgs = {
  input: CompanyAudienceUsersLinkInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationAudienceUsersUnlinkArgs = {
  input: CompanyAudienceUsersUnlinkInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationCreateArgs = {
  input: CompanyCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationDeleteArgs = {
  input: CompanyDeleteInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationDepartmentCreateArgs = {
  input: CompanyDepartmentCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationIndustriesSetArgs = {
  input: CompanyIndustriesSetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationLogoSetArgs = {
  input: CompanyLogoSetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationOnboardingStepsSaveArgs = {
  input: CompanyOnboardingStepsSaveInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationPositionCreateArgs = {
  input: CompanyPositionCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationProcessCreateArgs = {
  input: CompanyProcessCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationRegulationLinkArgs = {
  input: CompanyRegulationLinkInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationRegulationUnlinkArgs = {
  input: CompanyRegulationUnLinkInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationRepresentativeAddressSetArgs = {
  input: CompanyRepresentativeAddressSetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationRepresentativeCreateArgs = {
  input: CompanyRepresentativeCreateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationRepresentativeDeleteArgs = {
  input: CompanyRepresentativeDeleteInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationRepresentativeUpdateArgs = {
  input: CompanyRepresentativeUpdateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationSubscriptionTierSetArgs = {
  input: CompanySubscriptionTierSetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyMutationUpdateArgs = {
  input: CompanyUpdateInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQuery = {
  __typename?: 'NamespaceCompanyQuery'
  audiencesGet: CompanyAudiencesGetPayload
  companyFeatureUsagesGet: CompanyFeatureUsagesGetPayload
  companyFeaturesGet: CompanyFeaturesGetPayload
  departmentsGet: CompanyDepartmentsGetPayload
  get: CompanyGetPayload
  onboardingStepsGet: CompanyOnboardingStepsGetPayload
  positionsGet: CompanyPositionsGetPayload
  processesGet: CompanyProcessesGetPayload
  representativesGet: CompanyRepresentativesGetPayload
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryAudiencesGetArgs = {
  input: CompanyAudiencesGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryCompanyFeatureUsagesGetArgs = {
  input: CompanyFeatureUsagesGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryCompanyFeaturesGetArgs = {
  input: CompanyFeaturesGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryDepartmentsGetArgs = {
  input: CompanyDepartmentsGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryGetArgs = {
  input: CompanyGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryOnboardingStepsGetArgs = {
  input: CompanyOnboardingStepsGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryPositionsGetArgs = {
  input: CompanyPositionsGetInput
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryProcessesGetArgs = {
  input?: InputMaybe<CompanyProcessesGetInput>
}

/**
 * Namespace: **company**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceCompanyQueryRepresentativesGetArgs = {
  input: CompanyRepresentativesGetInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutation = {
  __typename?: 'NamespaceConsentMutation'
  conditionArchivedSet: ConsentConditionArchivedSetPayload
  conditionCreate: ConsentConditionCreatePayload
  conditionDelete: ConsentConditionDeletePayload
  conditionUpdate: ConsentConditionUpdatePayload
  consentIsHiddenSet: ConsentConsentIsHiddenSetPayload
  consentManualRevoke: ConsentManualRevokePayload
  consentResponseSave: ConsentConsentResponseSavePayload
  consentResponseVerify: ConsentConsentResponseVerifyPayload
  formArchivedSet: ConsentFormArchivedSetPayload
  formCreate: ConsentFormCreatePayload
  formLogoSet: ConsentFormLogoSetPayload
  formUpdate: ConsentFormUpdatePayload
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConditionArchivedSetArgs = {
  input: ConsentConditionArchivedSetInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConditionCreateArgs = {
  input: ConsentConditionCreateInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConditionDeleteArgs = {
  input: ConsentConditionDeleteInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConditionUpdateArgs = {
  input: ConsentConditionUpdateInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConsentIsHiddenSetArgs = {
  input: ConsentConsentIsHiddenSetInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConsentManualRevokeArgs = {
  input: ConsentManualRevokeInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConsentResponseSaveArgs = {
  input: ConsentConsentResponseSaveInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationConsentResponseVerifyArgs = {
  input: ConsentConsentResponseVerifyInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationFormArchivedSetArgs = {
  input: ConsentFormArchivedSetInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationFormCreateArgs = {
  input: ConsentFormCreateInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationFormLogoSetArgs = {
  input: ConsentFormLogoSetInput
}

/** Namespace: **consent** */
export type NamespaceConsentMutationFormUpdateArgs = {
  input: ConsentFormUpdateInput
}

/**
 * Namespace `consent`
 *
 * It contains all **Consent manager** functionality
 */
export type NamespaceConsentQuery = {
  __typename?: 'NamespaceConsentQuery'
  formsGet: ConsentFormsGetPayload
  get: ConsentGetPayload
}

/**
 * Namespace `consent`
 *
 * It contains all **Consent manager** functionality
 */
export type NamespaceConsentQueryFormsGetArgs = {
  input: ConsentFormsGetInput
}

/**
 * Namespace `consent`
 *
 * It contains all **Consent manager** functionality
 */
export type NamespaceConsentQueryGetArgs = {
  input: ConsentGetInput
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocumentMutation = {
  __typename?: 'NamespaceDocumentMutation'
  create: DocboxDocumentCreatePayload
  delete: DocboxDocumentDeletePayload
  folderCreate: DocboxFolderCreatePayload
  folderDelete: DocboxFolderDeletePayload
  folderUpdate: DocboxFolderUpdatePayload
  update: DocboxDocumentUpdatePayload
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocumentMutationCreateArgs = {
  input: DocboxDocumentCreateInput
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocumentMutationDeleteArgs = {
  input: DocboxDocumentDeleteInput
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocumentMutationFolderCreateArgs = {
  input: DocboxFolderCreateInput
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocumentMutationFolderDeleteArgs = {
  input: DocboxFolderDeleteInput
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocumentMutationFolderUpdateArgs = {
  input: DocboxFolderUpdateInput
}

/**
 * Namespace: **document**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocumentMutationUpdateArgs = {
  input: DocboxDocumentUpdateInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocumentQuery = {
  __typename?: 'NamespaceDocumentQuery'
  foldersGet: DocboxFoldersGetPayload
  /**
   * Gets the list of DocBox-files
   *
   * By default returns files JUST FROM the root folder (not all files).
   */
  get: DocboxGetPayload
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocumentQueryFoldersGetArgs = {
  input?: InputMaybe<DocboxFoldersGetInput>
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDocumentQueryGetArgs = {
  input?: InputMaybe<DocboxGetInput>
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutation = {
  __typename?: 'NamespaceDsarMutation'
  formCreate: DsarFormCreatePayload
  formDelete: DsarFormDeletePayload
  formLogoSet: DsarFormLogoSetPayload
  formTranslationCreate: DsarFormTranslationCreatePayload
  formTranslationDelete: DsarFormTranslationDeletePayload
  formTranslationUpdate: DsarFormTranslationUpdatePayload
  formUpdate: DsarFormUpdatePayload
  requestCommentCreate: DsarRequestCommentCreatePayload
  requestCommentUpdate: DsarRequestCommentUpdatePayload
  requestCreate: DsarRequestCreatePayload
  requestDataSubjectTypeTranslationsDelete: DsarRequestDataSubjectTypeTranslationsDeletePayload
  requestDataSubjectTypeTranslationsUpsert: DsarRequestDataSubjectTypeTranslationsUpsertPayload
  requestMessageSend: DsarRequestMessageSendPayload
  requestUpdate: DsarRequestUpdatePayload
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormCreateArgs = {
  input: DsarFormCreateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormDeleteArgs = {
  input: DsarFormDeleteInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormLogoSetArgs = {
  input: DsarFormLogoSetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormTranslationCreateArgs = {
  input: DsarFormTranslationCreateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormTranslationDeleteArgs = {
  input: DsarFormTranslationDeleteInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormTranslationUpdateArgs = {
  input: DsarFormTranslationUpdateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationFormUpdateArgs = {
  input: DsarFormUpdateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestCommentCreateArgs = {
  input: DsarRequestCommentCreateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestCommentUpdateArgs = {
  input: DsarRequestCommentUpdateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestCreateArgs = {
  input: DsarRequestCreateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestDataSubjectTypeTranslationsDeleteArgs =
  {
    input: DsarRequestDataSubjectTypeTranslationsDeleteInput
  }

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestDataSubjectTypeTranslationsUpsertArgs =
  {
    input: DsarRequestDataSubjectTypeTranslationsUpsertInput
  }

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestMessageSendArgs = {
  input: DsarRequestMessageSendInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceDsarMutationRequestUpdateArgs = {
  input: DsarRequestUpdateInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQuery = {
  __typename?: 'NamespaceDsarQuery'
  formsGet: DsarFormsGetPayload
  requestChannelsGet: DsarRequestChannelsGetPayload
  requestCommentsGet: DsarRequestCommentsGetPayload
  requestConversationsGet: DsarRequestConversationsGetPayload
  requestDataSubjectTypeTranslationsGet: DsarRequestDataSubjectTypeTranslationsGetPayload
  requestHistoryItemsGet: DsarRequestHistoryItemsGetPayload
  requestTypesGet: DsarRequestTypesGetPayload
  requestsGet: DsarRequestsGetPayload
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryFormsGetArgs = {
  input: DsarFormsGetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryRequestCommentsGetArgs = {
  input: DsarRequestCommentsGetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryRequestConversationsGetArgs = {
  input: DsarRequestConversationsGetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryRequestDataSubjectTypeTranslationsGetArgs = {
  input: DsarRequestDataSubjectTypeTranslationsGetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryRequestHistoryItemsGetArgs = {
  input: DsarRequestHistoryItemsGetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryRequestTypesGetArgs = {
  input: DsarRequestTypesGetInput
}

/**
 * Namespace: **dsar**
 *
 * > _You will never use this this class directly — use on'ly its fields!_
 */
export type NamespaceDsarQueryRequestsGetArgs = {
  input: DsarRequestsGetInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralMutation = {
  __typename?: 'NamespaceGeneralMutation'
  /**  TODO: MOVE TO COMPANY  */
  companyRegionLink: GeneralCompanyRegionLinkPayload
  /**  TODO: MOVE TO COMPANY  */
  companyRegionUnlink: GeneralCompanyRegionUnlinkPayload
  /** Sends free-form notifications to users. */
  emailSend: GeneralEmailSendPayload
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralMutationCompanyRegionLinkArgs = {
  input: GeneralCompanyRegionLinkInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralMutationCompanyRegionUnlinkArgs = {
  input: GeneralCompanyRegionUnlinkInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralMutationEmailSendArgs = {
  input: GeneralEmailSendInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralQuery = {
  __typename?: 'NamespaceGeneralQuery'
  annualSalesRevenueList: GeneralAnnualSalesRevenueListPayload
  assetList: GeneralAssetListPayload
  companyStructureList: GeneralCompanyStructureListPayload
  companyTypeList: GeneralCompanyTypeListPayload
  countryList: GeneralCountryListPayload
  currencyList: GeneralCurrencyListPayload
  departmentTemplatesGet: GeneralDepartmentTemplatesGetPayload
  industryList: GeneralIndustryListPayload
  modulesGet: GeneralModulesGetPayload
  positionTemplatesGet: GeneralPositionTemplatesGetPayload
  processTemplateList: GeneralProcessTemplateListPayload
  regionGroupList: GeneralRegionGroupListPayload
  regionList: GeneralRegionListPayload
  regulationsGet: GeneralRegulationsPayload
  /**
   * Performs "search" inside the requested Palqee-modules and returns the
   * matching information.
   */
  search: GeneralSearchPayload
  searchMainEntities: GeneralSearchMainEntitiesPayload
  subscriptionTiersGet: GeneralSubscriptionTiersGetPayload
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralQueryCountryListArgs = {
  input?: InputMaybe<GeneralCountryListInput>
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralQueryRegionListArgs = {
  input?: InputMaybe<GeneralRegionListInput>
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralQuerySearchArgs = {
  input: GeneralSearchInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralQuerySearchMainEntitiesArgs = {
  input: GeneralSearchMainEntitiesInput
}

/**
 * Namespace: **general**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceGeneralQuerySubscriptionTiersGetArgs = {
  input?: InputMaybe<GeneralSubscriptionTiersGetInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQuery = {
  __typename?: 'NamespaceStatisticsQuery'
  completedSurveys: StatisticsCompletedSurveysPayload
  dsarRequests: StatisticsDsarRequestsPayload
  maturityScoreAverages: StatisticsMaturityScoreAveragesPayload
  respondedQuestions: StatisticsRespondedQuestionsPayload
  /** number of users of total company size */
  workforcePercentage: StatisticsWorkforcePercentagePayload
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryCompletedSurveysArgs = {
  input?: InputMaybe<StatisticsCompletedSurveysInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryDsarRequestsArgs = {
  input?: InputMaybe<StatisticsDsarRequestsInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryMaturityScoreAveragesArgs = {
  input?: InputMaybe<StatisticsMaturityScoreAveragesInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryRespondedQuestionsArgs = {
  input?: InputMaybe<StatisticsRespondedQuestionsInput>
}

/**
 * Namespace: **statistics**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceStatisticsQueryWorkforcePercentageArgs = {
  input?: InputMaybe<StatisticsWorkforcePercentageInput>
}

export type NamespaceSurveyMutation = {
  __typename?: 'NamespaceSurveyMutation'
  answerOptionCreate: SurveyAnswerOptionCreatePayload
  answerOptionDelete: SurveyAnswerOptionDeletePayload
  answerOptionUpdate: SurveyAnswerOptionUpdatePayload
  copy: SurveyCopyPayload
  create: SurveyCreatePayload
  datamapInit: SurveyDatamapInitPayload
  delete: SurveyDeletePayload
  guidedStepCreate: SurveyGuidedStepCreatePayload
  guidedStepDelete: SurveyGuidedStepDeletePayload
  guidedStepUpdate: SurveyGuidedStepUpdatePayload
  invitationDelete: SurveyInvitationDeletePayload
  invitationsCreate: SurveyInvitationsCreatePayload
  /**
   * this method invites all users from provided audiences `audienceIds` to a survey session.
   *
   * **NOTE**: the method **DOES NOT** throw exceptions if some users from target audiences were already invited, and invites only new users.
   * @deprecated use `invitationsCreate`
   */
  inviteAudiences: SurveyInviteAudiencesPayload
  /**
   * this method invites particular users `userIds` to a survey session.
   *
   * **NOTE**: the method throws exceptions when on attempt to invite already invited users
   * @deprecated use `invitationsCreate`
   */
  inviteUsers: SurveyInviteUsersPayload
  maturityScoreSet: SurveyMaturityScoreSetPayload
  questionCreate: SurveyQuestionCreatePayload
  questionDelete: SurveyQuestionDeletePayload
  questionMatrixCreate: SurveyQuestionMatrixCreatePayload
  questionMatrixDelete: SurveyQuestionMatrixDeletePayload
  questionMatrixQuestionsLink: SurveyQuestionMatrixQuestionsLinkPayload
  questionMatrixQuestionsUnlink: SurveyQuestionMatrixQuestionsUnlinkPayload
  questionMatrixUpdate: SurveyQuestionMatrixUpdatePayload
  questionUpdate: SurveyQuestionUpdatePayload
  reminderToInvitedNotRespondedSend: SurveyReminderToInvitedNotRespondedSendPayload
  /** @deprecated use `responsesCreate` */
  responseCreate: SurveyResponseCreatePayload
  responseDelete: SurveyResponseDeletePayload
  responseDocumentRemove: SurveyResponseDocumentRemovePayload
  /** Uploads and attaches a document to the existing response */
  responseDocumentUpload: SurveyResponseDocumentUploadPayload
  responseUpdate: SurveyResponseUpdatePayload
  responsesCreate: SurveyResponsesCreatePayload
  /**
   * This method will prepare a URL for secure uploading a CSV file with responses for further import to the DB.
   *
   * One has to upload the import file to the provided URL. This file must have a CSV format (see GqlDocs_CsvFileUpload type)
   *
   * Available HEADERS (fields) in the CSV:
   *
   * (a) for Datamap surveys:
   *
   * - `departmentName`           (spaces trimmed, empty value => field ignored)
   * - `processName`              (spaces trimmed, empty value => field ignored)
   * - `processingType`           (spaces trimmed, empty value => field ignored)
   * - `dataProcessingRegion`     (spaces trimmed, empty value => field ignored)
   * - `legalBasis`               (spaces trimmed, empty value => field ignored)
   * - `dataOwnership`            (spaces trimmed, empty value => field ignored)
   * - `dataSubject`              (spaces trimmed, empty value => field ignored)
   * - `dataLifecycle`            (spaces trimmed, empty value => field ignored)
   * - `asset`                    (spaces trimmed, empty value => field ignored)
   * - `dataSharing`              (spaces trimmed, empty value => field ignored)
   * - `dataSource`               (spaces trimmed, empty value => field ignored)
   * - `dataElement`              (spaces trimmed, empty value => field ignored)
   * - `customQuestionProcess1`   (spaces trimmed, empty value => field ignored)
   * - ...
   * - `customQuestionProcessN`   (spaces trimmed, empty value => field ignored)
   * - `dataElementIsSensitive`   (boolean, empty/unrecognized value => field ignored, availale values:
   *     `'yes', '1', 'on', 'enable', 'enabled', 'true'` and
   *     `'no', '0', 'off', 'disable', 'disabled', 'false'`)
   * - `palqeeProcessingResult` (will be ignored during processing, but will be added to the report, with the description of errors)
   *
   * (b) for other surveys:
   *
   * - `questionNumber`           number of the question (1-based) (spaces trimmed, empty value => field ignored)
   * - `value`                    (spaces trimmed, empty value => field ignored)
   * - `palqeeProcessingResult` (will be ignored during processing, but will be added to the report, with the description of errors)
   */
  responsesImportPrepareUrl: SurveyResponsesImportPrepareUrlPayload
  scheduleCreate: SurveyScheduleCreatePayload
  scheduleDelete: SurveyScheduleDeletePayload
  scheduleUpdate: SurveyScheduleUpdatePayload
  sessionCreate: SurveySessionCreatePayload
  sessionDelete: SurveySessionDeletePayload
  sessionUpdate: SurveySessionUpdatePayload
  subcategoryCreate: SurveySubcategoryCreatePayload
  subcategoryCreateFromTemplate: SurveySubcategoryCreateFromTemplatePayload
  subcategoryDelete: SurveySubcategoryDeletePayload
  subcategoryTemplateUpdate: SurveySubcategoryTemplateUpdatePayload
  subcategoryUpdate: SurveySubcategoryUpdatePayload
  surveyAudiencesSet: SurveySurveyAudiencesSetPayload
  surveyConvertIntoTemplate: SurveySurveyConvertIntoTemplatePayload
  update: SurveyUpdatePayload
}

export type NamespaceSurveyMutationAnswerOptionCreateArgs = {
  input: SurveyAnswerOptionCreateInput
}

export type NamespaceSurveyMutationAnswerOptionDeleteArgs = {
  input: SurveyAnswerOptionDeleteInput
}

export type NamespaceSurveyMutationAnswerOptionUpdateArgs = {
  input: SurveyAnswerOptionUpdateInput
}

export type NamespaceSurveyMutationCopyArgs = {
  input: SurveyCopyInput
}

export type NamespaceSurveyMutationCreateArgs = {
  input: SurveyCreateInput
}

export type NamespaceSurveyMutationDatamapInitArgs = {
  input: SurveyDatamapInitInput
}

export type NamespaceSurveyMutationDeleteArgs = {
  input: SurveyDeleteInput
}

export type NamespaceSurveyMutationGuidedStepCreateArgs = {
  input: SurveyGuidedStepCreateInput
}

export type NamespaceSurveyMutationGuidedStepDeleteArgs = {
  input: SurveyGuidedStepDeleteInput
}

export type NamespaceSurveyMutationGuidedStepUpdateArgs = {
  input: SurveyGuidedStepUpdateInput
}

export type NamespaceSurveyMutationInvitationDeleteArgs = {
  input: SurveyInvitationDeleteInput
}

export type NamespaceSurveyMutationInvitationsCreateArgs = {
  input: SurveyInvitationsCreateInput
}

export type NamespaceSurveyMutationInviteAudiencesArgs = {
  input: SurveyInviteAudiencesInput
}

export type NamespaceSurveyMutationInviteUsersArgs = {
  input: SurveyInviteUsersInput
}

export type NamespaceSurveyMutationMaturityScoreSetArgs = {
  input: SurveyMaturityScoreSetInput
}

export type NamespaceSurveyMutationQuestionCreateArgs = {
  input: SurveyQuestionCreateInput
}

export type NamespaceSurveyMutationQuestionDeleteArgs = {
  input: SurveyQuestionDeleteInput
}

export type NamespaceSurveyMutationQuestionMatrixCreateArgs = {
  input: SurveyQuestionMatrixCreateInput
}

export type NamespaceSurveyMutationQuestionMatrixDeleteArgs = {
  input: SurveyQuestionMatrixDeleteInput
}

export type NamespaceSurveyMutationQuestionMatrixQuestionsLinkArgs = {
  input: SurveyQuestionMatrixQuestionsLinkInput
}

export type NamespaceSurveyMutationQuestionMatrixQuestionsUnlinkArgs = {
  input: SurveyQuestionMatrixQuestionsUnlinkInput
}

export type NamespaceSurveyMutationQuestionMatrixUpdateArgs = {
  input: SurveyQuestionMatrixUpdateInput
}

export type NamespaceSurveyMutationQuestionUpdateArgs = {
  input: SurveyQuestionUpdateInput
}

export type NamespaceSurveyMutationReminderToInvitedNotRespondedSendArgs = {
  input: SurveyReminderToInvitedNotRespondedSendInput
}

export type NamespaceSurveyMutationResponseCreateArgs = {
  input: SurveyResponseCreateInput
}

export type NamespaceSurveyMutationResponseDeleteArgs = {
  input: SurveyResponseDeleteInput
}

export type NamespaceSurveyMutationResponseDocumentRemoveArgs = {
  input: SurveyResponseDocumentRemoveInput
}

export type NamespaceSurveyMutationResponseDocumentUploadArgs = {
  input: SurveyResponseDocumentUploadInput
}

export type NamespaceSurveyMutationResponseUpdateArgs = {
  input: SurveyResponseUpdateInput
}

export type NamespaceSurveyMutationResponsesCreateArgs = {
  input: SurveyResponsesCreateInput
}

export type NamespaceSurveyMutationResponsesImportPrepareUrlArgs = {
  input: SurveyResponsesImportPrepareUrlInput
}

export type NamespaceSurveyMutationScheduleCreateArgs = {
  input: SurveyScheduleCreateInput
}

export type NamespaceSurveyMutationScheduleDeleteArgs = {
  input: SurveyScheduleDeleteInput
}

export type NamespaceSurveyMutationScheduleUpdateArgs = {
  input: SurveyScheduleUpdateInput
}

export type NamespaceSurveyMutationSessionCreateArgs = {
  input: SurveySessionCreateInput
}

export type NamespaceSurveyMutationSessionDeleteArgs = {
  input: SurveySessionDeleteInput
}

export type NamespaceSurveyMutationSessionUpdateArgs = {
  input: SurveySessionUpdateInput
}

export type NamespaceSurveyMutationSubcategoryCreateArgs = {
  input: SurveySubcategoryCreateInput
}

export type NamespaceSurveyMutationSubcategoryCreateFromTemplateArgs = {
  input: SurveySubcategoryCreateFromTemplateInput
}

export type NamespaceSurveyMutationSubcategoryDeleteArgs = {
  input: SurveySubcategoryDeleteInput
}

export type NamespaceSurveyMutationSubcategoryTemplateUpdateArgs = {
  input: SurveySubcategoryTemplateUpdateInput
}

export type NamespaceSurveyMutationSubcategoryUpdateArgs = {
  input: SurveySubcategoryUpdateInput
}

export type NamespaceSurveyMutationSurveyAudiencesSetArgs = {
  input: SurveySurveyAudiencesSetInput
}

export type NamespaceSurveyMutationSurveyConvertIntoTemplateArgs = {
  input: SurveySurveyConvertIntoTemplateInput
}

export type NamespaceSurveyMutationUpdateArgs = {
  input: SurveyUpdateInput
}

export type NamespaceSurveyQuery = {
  __typename?: 'NamespaceSurveyQuery'
  categoriesGet: SurveyCategoriesGetPayload
  datamapResponsesGet: SurveyDatamapResponsesGetPayload
  datamapsGet: SurveyDatamapsGetPayload
  /** Get the list of surveys for a company */
  get: SurveyGetPayload
  invitationsGet: SurveyInvitationsGetPayload
  maturityScoresGet: SurveyMaturityScoresGetPayload
  /**
   * The method takes `sessionIds` as input and:
   * - applies a filter to input `userIds` - returning only users who are not invited to the provided sessions
   * - applies a filter to input `audienceIds` - returning audiences that **during runtime** have users without invitations to the provided sessions
   * * `userIds` and `audienceIds` are independent inputs that don't interfere on each other
   */
  notInvitedGet: SurveyNotInvitedGetPayload
  questionTypesGet: SurveyQuestionTypesGetPayload
  questionsGet: SurveyQuestionsGetPayload
  responsesGet: SurveyResponsesGetPayload
  sessionsGet: SurveySessionsGetPayload
  subcategoryTemplatesGet: SurveySubcategoryTemplatesGetPayload
}

export type NamespaceSurveyQueryCategoriesGetArgs = {
  input?: InputMaybe<SurveyCategoriesGetInput>
}

export type NamespaceSurveyQueryDatamapResponsesGetArgs = {
  input?: InputMaybe<SurveyDatamapResponsesGetInput>
}

export type NamespaceSurveyQueryDatamapsGetArgs = {
  input?: InputMaybe<SurveyDatamapsGetInput>
}

export type NamespaceSurveyQueryGetArgs = {
  input?: InputMaybe<SurveyGetInput>
}

export type NamespaceSurveyQueryInvitationsGetArgs = {
  input: SurveyInvitationsGetInput
}

export type NamespaceSurveyQueryMaturityScoresGetArgs = {
  input: SurveyMaturityScoresGetInput
}

export type NamespaceSurveyQueryNotInvitedGetArgs = {
  input: SurveyNotInvitedGetInput
}

export type NamespaceSurveyQueryQuestionTypesGetArgs = {
  input?: InputMaybe<SurveyQuestionTypesGetInput>
}

export type NamespaceSurveyQueryQuestionsGetArgs = {
  input: SurveyQuestionsGetInput
}

export type NamespaceSurveyQueryResponsesGetArgs = {
  input?: InputMaybe<SurveyResponsesGetInput>
}

export type NamespaceSurveyQuerySessionsGetArgs = {
  input?: InputMaybe<SurveySessionsGetInput>
}

export type NamespaceSurveyQuerySubcategoryTemplatesGetArgs = {
  input?: InputMaybe<SurveySubcategoryTemplatesGetInput>
}

/** Namespace: **task** */
export type NamespaceTaskMutation = {
  __typename?: 'NamespaceTaskMutation'
  /**
   * **NOTES**: this method affects just `isEmbed === false` attachments, in
   * order to change other attachments - use `task.update`
   */
  attachmentRemove: TaskAttachmentRemovePayload
  attachmentUpload: TaskAttachmentUploadPayload
  categoryCreate: TaskCategoryCreatePayload
  categoryDelete: TaskCategoryDeletePayload
  categoryUpdate: TaskCategoryUpdatePayload
  commentCreate: TaskCommentCreatePayload
  copy: TaskCopyPayload
  create: TaskCreatePayload
  delete: TaskDeletePayload
  statusCreate: TaskStatusCreatePayload
  statusDelete: TaskStatusDeletePayload
  statusUpdate: TaskStatusUpdatePayload
  statusesModify: TaskStatusesModifyPayload
  /**
   * Set tasks "archived"
   *
   * **NOTE 1**
   *
   * The method **silently ignore** the tasks that already are in the desired state,
   * this means:
   *
   * ```
   * taskArchivedSet(true, taskIds)
   * ```
   *
   * will ignore already archived tasks, **without throwing errors**.
   *
   * **NOTE 2**
   * At the moment UN-archive doesn't cause the RE-ordering the tasks, even though
   * the un-archived task goes to company's NEW status
   */
  taskArchivedSet: TaskTaskArchivedSetPayload
  taskAssigneeAudienceLink: TaskTaskAssigneeAudienceLinkPayload
  taskAssigneeAudienceUnlink: TaskTaskAssigneeAudienceUnlinkPayload
  /**  The method ignores already linked `assigneeIds` */
  taskAssigneeUserLink: TaskTaskAssigneeUserLinkPayload
  taskAssigneeUserUnlink: TaskTaskAssigneeUserUnlinkPayload
  /**  The method ignores already linked `categoryIds` */
  taskCategoryLink: TaskCategoryLinkPayload
  taskCategoryUnlink: TaskCategoryUnlinkPayload
  /**  The method ignores already linked `documentIds` */
  taskDocboxDocumentLink: TaskDocboxDocumentLinkPayload
  taskDocboxDocumentUnlink: TaskDocboxDocumentUnlinkPayload
  /**  The method ignores already linked `requestIds` */
  taskDsarRequestLink: TaskDsarRequestLinkPayload
  taskDsarRequestUnlink: TaskDsarRequestUnlinkPayload
  /**  The method ignores already linked `linkUrlIds` */
  taskLinkedUrlCreate: TaskLinkedUrlCreatePayload
  taskLinkedUrlDelete: TaskLinkedUrlDeletePayload
  /**  The method ignores already linked `sessionIds` */
  taskSurveySessionLink: TaskSurveySessionLinkPayload
  taskSurveySessionUnlink: TaskSurveySessionUnlinkPayload
  update: TaskUpdatePayload
}

/** Namespace: **task** */
export type NamespaceTaskMutationAttachmentRemoveArgs = {
  input: TaskAttachmentRemoveInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationAttachmentUploadArgs = {
  input: TaskAttachmentUploadInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationCategoryCreateArgs = {
  input: TaskCategoryCreateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationCategoryDeleteArgs = {
  input: TaskCategoryDeleteInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationCategoryUpdateArgs = {
  input: TaskCategoryUpdateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationCommentCreateArgs = {
  input: TaskCommentCreateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationCopyArgs = {
  input: TaskCopyInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationCreateArgs = {
  input: TaskCreateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationDeleteArgs = {
  input: TaskDeleteInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationStatusCreateArgs = {
  input: TaskStatusCreateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationStatusDeleteArgs = {
  input: TaskStatusDeleteInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationStatusUpdateArgs = {
  input: TaskStatusUpdateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationStatusesModifyArgs = {
  input: TaskStatusesModifyInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskArchivedSetArgs = {
  input: TaskTaskArchivedSetInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskAssigneeAudienceLinkArgs = {
  input: TaskTaskAssigneeAudienceLinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskAssigneeAudienceUnlinkArgs = {
  input: TaskTaskAssigneeAudienceUnlinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskAssigneeUserLinkArgs = {
  input: TaskTaskAssigneeUserLinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskAssigneeUserUnlinkArgs = {
  input: TaskTaskAssigneeUserUnlinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskCategoryLinkArgs = {
  input: TaskCategoryLinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskCategoryUnlinkArgs = {
  input: TaskCategoryUnlinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskDocboxDocumentLinkArgs = {
  input: TaskDocboxDocumentLinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskDocboxDocumentUnlinkArgs = {
  input: TaskDocboxDocumentUnlinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskDsarRequestLinkArgs = {
  input: TaskDsarRequestLinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskDsarRequestUnlinkArgs = {
  input: TaskDsarRequestUnlinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskLinkedUrlCreateArgs = {
  input: TaskLinkedUrlCreateInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskLinkedUrlDeleteArgs = {
  input: TaskLinkedUrlDeleteInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskSurveySessionLinkArgs = {
  input: TaskSurveySessionLinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationTaskSurveySessionUnlinkArgs = {
  input: TaskSurveySessionUnlinkInput
}

/** Namespace: **task** */
export type NamespaceTaskMutationUpdateArgs = {
  input: TaskUpdateInput
}

/** Namespace: **task** */
export type NamespaceTaskQuery = {
  __typename?: 'NamespaceTaskQuery'
  categoriesGet: TaskCategoriesGetPayload
  /** Get the list of tasks for a company */
  get: TaskGetPayload
  historyItemsGet: TaskHistoryItemsGetPayload
  prioritiesGet: TaskPrioritiesGetPayload
  statusesGet: TaskStatusesGetPaylod
}

/** Namespace: **task** */
export type NamespaceTaskQueryCategoriesGetArgs = {
  input: TaskCategoriesGetInput
}

/** Namespace: **task** */
export type NamespaceTaskQueryGetArgs = {
  input: TaskGetInput
}

/** Namespace: **task** */
export type NamespaceTaskQueryHistoryItemsGetArgs = {
  input: TaskHistoryItemsGetInput
}

/** Namespace: **task** */
export type NamespaceTaskQueryStatusesGetArgs = {
  input: TaskStatusesGetInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutation = {
  __typename?: 'NamespaceUserMutation'
  create: UserCreatePayload
  /** **WARNING**: the method deletes users (and related data) and you can not rollback this changes. */
  delete: UserDeletePayload
  positionSet: UserPositionSetPayload
  rolesLink: UserRolesLinkPayload
  rolesSet: UserRolesSetPayload
  rolesUnlink: UserRolesUnlinkPayload
  /**
   * **`upsert`** - inserts a new user or update the existing one.
   *
   * When `userId` is not empty the server will try to find the user using only
   * `userId` (_ignoring email_ - whenever it was provided or not), and then:
   *
   *   - if the user was found — will update the record (if `email` contains
   *     non-empty value — it will be updated too)
   *   - if the user was not found - will create a new user — common behavior for
   *     any `upsert` methods (and this update could lead to conflict of the
   *     email uniquness - then methods will `throw`)
   *
   * **NON STANDARD BEHAVIOR** for `upsert` methods: if you pass non empty
   * `email` and empty `userId` (which is a primary key) - then the method will
   * try to find the user **by email** and then:
   *
   *  - if the user was found — update other fields of the user-record;
   *  - if the user was not found - will create a new user;
   *
   * When both `userId` and `email` are empty - error will be thrown
   */
  upsert: UserUpsertPayload
  /**
   * This method will prepare an URL for secure uploading the CSV file with users' data for further processing.
   *
   * One has to upload the import file to the provided URL. This file must have a CSV format (see GqlDocs_CsvFileUpload type)
   *
   * Available HEADERS (fields) in the CSV:
   *
   * - `email`          (**mandatory**, spaces trimmed, empty value causes error)
   * - `phoneNumber`    (spaces trimmed, empty value => field ignored)
   * - `positionName`   (spaces trimmed, empty value => field ignored)
   * - `departmentName` (spaces trimmed, empty value => field ignored)
   * - `vendorName`     (spaces trimmed, empty value => field ignored)
   * - `defaultCompanyId` (spaces trimmed, empty value => field ignored, if not empty - must be UUID of the company)
   * - `language`         (spaces trimmed, empty value => field ignored, available values (`en`, `pt`, `de`))
   *
   * - `firstName` (spaces trimmed, empty value => NULL - will erase existing values)
   * - `lastName`  (spaces trimmed, empty value => NULL - will erase existing values)
   * - `title`     (spaces trimmed, empty value => NULL - will erase existing values)
   *
   * - `sendRegistrationEmail` (boolean, empty/unrecognized value => field ignored, availale values:
   *     `'yes', '1', 'on', 'enable', 'enabled', 'true'` and
   *     `'no', '0', 'off', 'disable', 'disabled', 'false'`
   * - `roleCodes` (list of role codes, separated by `,` or by `;`, all whitespaces are ignored for this field, available values:
   *     `ADMINISTRATOR, EMPLOYEE, VENDOR, DPO, PROCESS_OWNER`
   * - `palqeeProcessingResult` (will be ignored during processing, but will be added to the report, with the description of errors)
   */
  upsertBulkPrepareUrl: UserUpsertBulkPrepareUrlPayload
  vendorInfoSet: UserVendorInfoSetPayload
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationCreateArgs = {
  input: UserCreateInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationDeleteArgs = {
  input: UserDeleteInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationPositionSetArgs = {
  input: UserPositionSetInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationRolesLinkArgs = {
  input: UserRolesLinkInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationRolesSetArgs = {
  input: UserRolesSetInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationRolesUnlinkArgs = {
  input: UserRolesUnlinkInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationUpsertArgs = {
  input: UserUpsertInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationUpsertBulkPrepareUrlArgs = {
  input: UserUpsertBulkPrepareUrlInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this class directly — use only its fields!_
 */
export type NamespaceUserMutationVendorInfoSetArgs = {
  input: UserVendorInfoSetInput
}

/**
 * Namespace: **user**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceUserQuery = {
  __typename?: 'NamespaceUserQuery'
  get: UserGetPayload
}

/**
 * Namespace: **user**
 *
 * > _You will never use this this class directly — use only its fields!_
 */
export type NamespaceUserQueryGetArgs = {
  input: UserGetInput
}

export enum OnErrorBehaviorEnum {
  Ignore = 'IGNORE',
  Throw = 'THROW',
}

export enum OnboardingProgressStepCodeEnum {
  Dpo = 'DPO',
  GeneralInfo = 'GENERAL_INFO',
  ProcessOwners = 'PROCESS_OWNERS',
  Regions = 'REGIONS',
}

export type PaginationArg = {
  /** Number of items in one page */
  limit?: InputMaybe<Scalars['NonNegativeInt']['input']>
  /** Number of ITEMS to skip (not pages) before returning the result */
  offset?: InputMaybe<Scalars['NonNegativeInt']['input']>
}

export type PaginationInfo = {
  __typename?: 'PaginationInfo'
  limit?: Maybe<Scalars['NonNegativeInt']['output']>
  offset?: Maybe<Scalars['NonNegativeInt']['output']>
  totalItems: Scalars['NonNegativeInt']['output']
  totalPages: Scalars['NonNegativeInt']['output']
}

export type PalqeeModule = {
  __typename?: 'PalqeeModule'
  code: PalqeeModuleCodeEnum
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode: Scalars['UICode']['output']
}

export enum PalqeeModuleCodeEnum {
  Docbox = 'DOCBOX',
  Dsar = 'DSAR',
  Survey = 'SURVEY',
  Task = 'TASK',
}

export type Person = IUserWithEmail & {
  __typename?: 'Person'
  email: Scalars['EmailAddress']['output']
  firstName: Scalars['String']['output']
  fullName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  personId: Scalars['ID']['output']
  phoneNumber?: Maybe<Scalars['SloppyPhoneNumber']['output']>
}

export type PingDevInput = {
  payload?: InputMaybe<Scalars['String']['input']>
  touchDb?: InputMaybe<Scalars['Boolean']['input']>
  touchLog?: InputMaybe<Scalars['Boolean']['input']>
}

export type PingDevPayload = {
  __typename?: 'PingDevPayload'
  payload?: Maybe<Scalars['String']['output']>
}

/**
 * Params for checking the translation system
 *
 * `language` is taken from the HTTP-headers or from the user settings (just like
 * for all other requests)
 */
export type PingLangInput = {
  field: Scalars['String']['input']
  uiCode: Scalars['UICode']['input']
}

export type PingLangPayload = {
  __typename?: 'PingLangPayload'
  field: Scalars['String']['output']
  language: Scalars['String']['output']
  /** Translated string (directive) */
  translatedByDirective?: Maybe<Scalars['String']['output']>
  /** Translated string (directly from i18n services) */
  translatedByService?: Maybe<Scalars['String']['output']>
  uiCode: Scalars['UICode']['output']
}

export type Position = {
  __typename?: 'Position'
  company: Company
  companyId: Scalars['ID']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  positionId: Scalars['ID']['output']
  positionTemplate?: Maybe<PositionTemplate>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type PositionFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  positionIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type PositionTemplate = {
  __typename?: 'PositionTemplate'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  positionTemplateId: Scalars['ID']['output']
  uiCode: Scalars['UICode']['output']
}

export type Process = IDatamapEntity & {
  __typename?: 'Process'
  company: Company
  createdAt?: Maybe<Scalars['DateTime']['output']>
  departments: Array<Department>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  processId: Scalars['ID']['output']
  processTemplate?: Maybe<ProcessTemplate>
  uiCode?: Maybe<Scalars['UICode']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ProcessTemplate = {
  __typename?: 'ProcessTemplate'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  processTemplateId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type ProcessesGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  processIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ProcessingType = IDatamapEntity & {
  __typename?: 'ProcessingType'
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  processingTypeId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type Query = {
  __typename?: 'Query'
  admin: NamespaceAdminQuery
  auth: NamespaceAuthQuery
  company: NamespaceCompanyQuery
  consent: NamespaceConsentQuery
  document: NamespaceDocumentQuery
  dsar: NamespaceDsarQuery
  general: NamespaceGeneralQuery
  /** Does nothing, just pings back, you could use it for a heartbeat-service */
  ping?: Maybe<Scalars['String']['output']>
  /** Does nothing, just pings back _and leave record in logs_, you could use it for debuging _something_. */
  pingDev: PingDevPayload
  /** Does nothing, just checks the LANGUAGE settings and translations (I18N). */
  pingLang: PingLangPayload
  statistics: NamespaceStatisticsQuery
  survey: NamespaceSurveyQuery
  task: NamespaceTaskQuery
  user: NamespaceUserQuery
}

export type QueryPingArgs = {
  payload?: InputMaybe<Scalars['String']['input']>
}

export type QueryPingDevArgs = {
  input?: InputMaybe<PingDevInput>
}

export type QueryPingLangArgs = {
  input?: InputMaybe<PingLangInput>
}

export type QuestionMatrixTemplate = {
  __typename?: 'QuestionMatrixTemplate'
  description?: Maybe<Scalars['String']['output']>
  header?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  questionMatrixTemplateId: Scalars['ID']['output']
  questionTemplates: Array<SurveyQuestionTemplate>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type QuestionType = {
  __typename?: 'QuestionType'
  availableModifiers?: Maybe<Scalars['JSONObject']['output']>
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  uiCode: Scalars['UICode']['output']
}

export type QuestionTypeFilterArg = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
}

export type Region = IDatamapEntity & {
  __typename?: 'Region'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  regionGroup?: Maybe<RegionGroup>
  regionId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type RegionGroup = {
  __typename?: 'RegionGroup'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  regionGroupId: Scalars['ID']['output']
  regions: Array<Region>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type Regulation = {
  __typename?: 'Regulation'
  categories: Array<SurveyCategory>
  code: RegulationCodeEnum
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  logoPath?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  regulationId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export enum RegulationCodeEnum {
  Ccpa = 'CCPA',
  EuAiAct = 'EU_AI_ACT',
  Gdpr = 'GDPR',
  GdprUk = 'GDPR_UK',
  Lgpd = 'LGPD',
  Tdpsa = 'TDPSA',
}

export type Representative = {
  __typename?: 'Representative'
  address?: Maybe<Address>
  company: Company
  email?: Maybe<Scalars['EmailAddress']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['SloppyPhoneNumber']['output']>
  representativeGroup: RepresentativeGroupEnum
  representativeId: Scalars['ID']['output']
}

export type RepresentativeFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  representativeGroups?: InputMaybe<Array<RepresentativeGroupEnum>>
  representativeIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum RepresentativeGroupEnum {
  Dpo = 'DPO',
}

export type SelectedRegulation = {
  __typename?: 'SelectedRegulation'
  company: Company
  regulation: Regulation
}

export type StatisticsCompletedSurveysFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  departmentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum StatisticsCompletedSurveysGroupByEnum {
  Company = 'COMPANY',
  Department = 'DEPARTMENT',
  Session = 'SESSION',
  Survey = 'SURVEY',
}

export type StatisticsCompletedSurveysInput = {
  filter?: InputMaybe<StatisticsCompletedSurveysFilterArg>
  groupBy?: InputMaybe<Array<StatisticsCompletedSurveysGroupByEnum>>
}

export type StatisticsCompletedSurveysItem = {
  __typename?: 'StatisticsCompletedSurveysItem'
  /**
   * Can contain NULL values, when there is nobody who finished the survey
   * @deprecated se `completionDurationAverage`
   */
  averageResponseTime?: Maybe<Scalars['Duration']['output']>
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  completionDurationAverage?: Maybe<Scalars['Duration']['output']>
  department?: Maybe<Department>
  departmentId?: Maybe<Scalars['ID']['output']>
  /** Number of employees COMPLETED the survey / Number of employees invited */
  employeeEngagementRate: Fraction
  isLatest: Scalars['Boolean']['output']
  previous?: Maybe<StatisticsCompletedSurveysItem>
  session?: Maybe<SurveySession>
  sessionId?: Maybe<Scalars['ID']['output']>
  survey?: Maybe<Survey>
  surveyId?: Maybe<Scalars['ID']['output']>
}

export type StatisticsCompletedSurveysPayload = {
  __typename?: 'StatisticsCompletedSurveysPayload'
  items: Array<Maybe<StatisticsCompletedSurveysItem>>
  query: Query
}

export type StatisticsDsarRequestsFilterArg = {
  channelCodes?: InputMaybe<Array<DsarRequestChannelCodeEnum>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  formIds?: InputMaybe<Array<Scalars['ID']['input']>>
  requestIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum StatisticsDsarRequestsGroupByEnum {
  ChannelCode = 'CHANNEL_CODE',
  Company = 'COMPANY',
  Form = 'FORM',
  Tenant = 'TENANT',
}

export type StatisticsDsarRequestsInput = {
  filter?: InputMaybe<StatisticsDsarRequestsFilterArg>
  groupBy?: InputMaybe<Array<StatisticsDsarRequestsGroupByEnum>>
}

export type StatisticsDsarRequestsItem = {
  __typename?: 'StatisticsDsarRequestsItem'
  /** average requests per month */
  averageNewPerMonth?: Maybe<Scalars['Float']['output']>
  /** average processing time per request (days) */
  averageProcessingTime?: Maybe<Scalars['Duration']['output']>
  channelCode?: Maybe<DsarRequestChannelCodeEnum>
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  form?: Maybe<DsarForm>
  formId?: Maybe<Scalars['ID']['output']>
  tenant?: Maybe<Tenant>
  tenantId?: Maybe<Scalars['ID']['output']>
}

export type StatisticsDsarRequestsPayload = {
  __typename?: 'StatisticsDsarRequestsPayload'
  items: Array<StatisticsDsarRequestsItem>
  query: Query
}

export type StatisticsMaturityScoreAveragesFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum StatisticsMaturityScoreAveragesGroupByEnum {
  Company = 'COMPANY',
  Session = 'SESSION',
  Survey = 'SURVEY',
  Tenant = 'TENANT',
}

export type StatisticsMaturityScoreAveragesInput = {
  filter?: InputMaybe<StatisticsMaturityScoreAveragesFilterArg>
  groupBy?: InputMaybe<Array<StatisticsMaturityScoreAveragesGroupByEnum>>
}

export type StatisticsMaturityScoreAveragesItem = {
  __typename?: 'StatisticsMaturityScoreAveragesItem'
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  /** Average of averages - works good for company */
  maturityScoreAverage?: Maybe<Scalars['Float']['output']>
  /** Sum of averages (avg for survey) */
  maturityScoreAverageSum?: Maybe<Scalars['Float']['output']>
  /** Sum of maturity scores */
  maturityScoreSum?: Maybe<Scalars['NonNegativeInt']['output']>
  questionsCount: Scalars['NonNegativeInt']['output']
  session?: Maybe<SurveySession>
  sessionId?: Maybe<Scalars['ID']['output']>
  survey?: Maybe<Survey>
  surveyId?: Maybe<Scalars['ID']['output']>
  surveysCount: Scalars['NonNegativeInt']['output']
  tenant: Tenant
  tenantId: Scalars['ID']['output']
}

export type StatisticsMaturityScoreAveragesPayload = {
  __typename?: 'StatisticsMaturityScoreAveragesPayload'
  items: Array<StatisticsMaturityScoreAveragesItem>
  query: Query
}

export type StatisticsRespondedQuestionsFilterArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum StatisticsRespondedQuestionsGroupByEnum {
  Audience = 'AUDIENCE',
  Company = 'COMPANY',
  Session = 'SESSION',
  Survey = 'SURVEY',
  User = 'USER',
}

export type StatisticsRespondedQuestionsInput = {
  filter?: InputMaybe<StatisticsRespondedQuestionsFilterArg>
  groupBy?: InputMaybe<Array<StatisticsRespondedQuestionsGroupByEnum>>
}

export type StatisticsRespondedQuestionsItem = {
  __typename?: 'StatisticsRespondedQuestionsItem'
  audience?: Maybe<Audience>
  audienceId?: Maybe<Scalars['ID']['output']>
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  respondedQuestionsRate: Fraction
  session?: Maybe<SurveySession>
  sessionId?: Maybe<Scalars['ID']['output']>
  survey?: Maybe<Survey>
  surveyId?: Maybe<Scalars['ID']['output']>
  user?: Maybe<User>
  userId?: Maybe<Scalars['ID']['output']>
}

export type StatisticsRespondedQuestionsPayload = {
  __typename?: 'StatisticsRespondedQuestionsPayload'
  items: Array<Maybe<StatisticsRespondedQuestionsItem>>
  query: Query
}

export type StatisticsWorkforcePercentageFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type StatisticsWorkforcePercentageInput = {
  filter?: InputMaybe<StatisticsWorkforcePercentageFilterArg>
}

export type StatisticsWorkforcePercentageItem = {
  __typename?: 'StatisticsWorkforcePercentageItem'
  company: Company
  percentage: Fraction
}

export type StatisticsWorkforcePercentagePayload = {
  __typename?: 'StatisticsWorkforcePercentagePayload'
  items: Array<StatisticsWorkforcePercentageItem>
  query: Query
}

export type SubscriptionFeatureBoolean = ISubscriptionFeature & {
  __typename?: 'SubscriptionFeatureBoolean'
  available: Scalars['Boolean']['output']
  code: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  subscriptionFeatureId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SubscriptionFeatureIntRange = ISubscriptionFeature & {
  __typename?: 'SubscriptionFeatureIntRange'
  code: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  maxValue?: Maybe<Scalars['Int']['output']>
  minValue?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  subscriptionFeatureId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
  unitOfMeasurementCode: UnitOfMeasurementEnum
}

export type SubscriptionFeatureUsage = {
  __typename?: 'SubscriptionFeatureUsage'
  feature: ISubscriptionFeature
  percentage?: Maybe<Fraction>
  unitOfMeasurementCode?: Maybe<UnitOfMeasurementEnum>
}

export type SubscriptionTier = {
  __typename?: 'SubscriptionTier'
  description?: Maybe<Scalars['String']['output']>
  features?: Maybe<Array<ISubscriptionFeature>>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  subscriptionTierId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type Survey = {
  __typename?: 'Survey'
  audiences: Array<Audience>
  categories: Array<SurveyCategory>
  company: Company
  companyId: Scalars['ID']['output']
  copiedFromSurvey?: Maybe<Survey>
  copiedFromSurveyId?: Maybe<Scalars['ID']['output']>
  createdAt: Scalars['DateTime']['output']
  guidedSteps: Array<SurveyGuidedStep>
  id: Scalars['ID']['output']
  isCopy: Scalars['Boolean']['output']
  isDraft: Scalars['Boolean']['output']
  isTemplate: Scalars['Boolean']['output']
  lastUsedAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  questionsCount: Scalars['NonNegativeInt']['output']
  schedules: Array<SurveySchedule>
  sessions: Array<SurveySession>
  subcategories: Array<SurveySubcategory>
  surveyId: Scalars['ID']['output']
  type: SurveyType
}

export type SurveyAnswerOption = IDatamapEntity &
  ISurveyAnswerOption & {
    __typename?: 'SurveyAnswerOption'
    answerOptionId: Scalars['ID']['output']
    answerOptionTemplate?: Maybe<SurveyAnswerOptionTemplate>
    dependents: Array<SurveyDependency>
    id: Scalars['ID']['output']
    /** alias for `value` */
    name?: Maybe<Scalars['String']['output']>
    question: SurveyQuestion
    questionId: Scalars['ID']['output']
    sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
    uiCode?: Maybe<Scalars['UICode']['output']>
    value?: Maybe<Scalars['String']['output']>
  }

export type SurveyAnswerOptionCreateInput = {
  answerOptionTemplateId?: InputMaybe<Scalars['ID']['input']>
  questionId: Scalars['ID']['input']
  sortOrder?: InputMaybe<Scalars['Int']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type SurveyAnswerOptionCreatePayload = {
  __typename?: 'SurveyAnswerOptionCreatePayload'
  answerOption: SurveyAnswerOption
  query: Query
  question: SurveyQuestion
}

export type SurveyAnswerOptionDeleteInput = {
  answerOptionId: Scalars['ID']['input']
}

export type SurveyAnswerOptionDeletePayload = {
  __typename?: 'SurveyAnswerOptionDeletePayload'
  answerOption: SurveyAnswerOption
  query: Query
}

export type SurveyAnswerOptionTemplate = {
  __typename?: 'SurveyAnswerOptionTemplate'
  answerOptionTemplateId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  questionTemplate: SurveyQuestionTemplate
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type SurveyAnswerOptionUpdateInput = {
  answerOptionId: Scalars['ID']['input']
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  sortOrder?: InputMaybe<Scalars['Int']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type SurveyAnswerOptionUpdatePayload = {
  __typename?: 'SurveyAnswerOptionUpdatePayload'
  answerOption: SurveyAnswerOption
  query: Query
}

export enum SurveyBuilderQuestionMode {
  Answerable = 'ANSWERABLE',
  AnswerableTable = 'ANSWERABLE_TABLE',
  AnswersSummary = 'ANSWERS_SUMMARY',
  AnswerReadOnly = 'ANSWER_READ_ONLY',
  Editable = 'EDITABLE',
  ReadOnly = 'READ_ONLY',
}

export type SurveyCategoriesGetInput = {
  filter?: InputMaybe<SurveyCategoryFilterArg>
}

export type SurveyCategoriesGetPayload = {
  __typename?: 'SurveyCategoriesGetPayload'
  categories: Array<SurveyCategory>
  query: Query
}

/**
 * **TOP** level grouping for question-templates in Palqee-survey
 *
 * Categories doesn't exist in the resulting survey. It is pure _create-survey_
 * entities.
 *
 * ----
 *
 * Survey Template Hierarchy:
 *
 * - **Category**
 * - Subcategory Template
 * - Group Template
 * - Question Template
 */
export type SurveyCategory = {
  __typename?: 'SurveyCategory'
  categoryId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name?: Maybe<Scalars['String']['output']>
  regulation?: Maybe<Regulation>
  subcategoryTemplates: Array<SurveySubcategoryTemplate>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveyCategoryFilterArg = {
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  regulationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  surveyType?: InputMaybe<SurveyType>
}

export type SurveyCopyInput = {
  companyId: Scalars['ID']['input']
  isDraft: Scalars['Boolean']['input']
  isTemplate: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  surveyId: Scalars['ID']['input']
}

export type SurveyCopyPayload = {
  __typename?: 'SurveyCopyPayload'
  query: Query
  survey: Survey
}

export type SurveyCreateInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyId: Scalars['ID']['input']
  isDraft: Scalars['Boolean']['input']
  isTemplate: Scalars['Boolean']['input']
  name: Scalars['String']['input']
}

export type SurveyCreatePayload = {
  __typename?: 'SurveyCreatePayload'
  query: Query
  survey: Survey
}

export enum SurveyDatamapEntityTypeEnum {
  Asset = 'ASSET',
  CustomQuestionProcess = 'CUSTOM_QUESTION_PROCESS',
  DataElement = 'DATA_ELEMENT',
  DataLifecycle = 'DATA_LIFECYCLE',
  DataOwnership = 'DATA_OWNERSHIP',
  DataProcessingRegion = 'DATA_PROCESSING_REGION',
  DataSharing = 'DATA_SHARING',
  DataSource = 'DATA_SOURCE',
  DataSubject = 'DATA_SUBJECT',
  Department = 'DEPARTMENT',
  LegalBasis = 'LEGAL_BASIS',
  Process = 'PROCESS',
  ProcessingType = 'PROCESSING_TYPE',
}

export type SurveyDatamapInitInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  sessionId?: InputMaybe<Scalars['ID']['input']>
}

export type SurveyDatamapInitPayload = {
  __typename?: 'SurveyDatamapInitPayload'
  datamap: Datamap
  datamapId: Scalars['ID']['output']
  query: Query
}

export type SurveyDatamapResponsesGetFilterArg = {
  datamapEntityTypeCodes?: InputMaybe<Array<SurveyDatamapEntityTypeEnum>>
  doIncludeDatamapEntity?: Scalars['Boolean']['input']
  respondentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * filter the responses by taking only the DM-subtree part
   * below the response with provided ID
   */
  subtreeOfResponseIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyDatamapResponsesGetInput = {
  filter?: InputMaybe<SurveyDatamapResponsesGetFilterArg>
}

export type SurveyDatamapResponsesGetOrderArg = {
  by: SurveyDatamapResponsesGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyDatamapResponsesGetOrderEnum {
  CreatedAt = 'createdAt',
  RespondentId = 'respondentId',
}

export type SurveyDatamapResponsesGetPayload = {
  __typename?: 'SurveyDatamapResponsesGetPayload'
  datamapResponses?: Maybe<Array<IDatamapResponse>>
  datamaps?: Maybe<Array<Maybe<Datamap>>>
  query: Query
}

export type SurveyDatamapsGetFilterArg = {
  /**
   * Use this filter `companyIds` to fetch only the whole company's DataMaps
   * (in contrast to the DataMaps provided by users in their responses)
   */
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * Use this filter `userIds` to fetch only DataMaps from users' responses
   * (in contrast to the whole company's DataMap, created by the admin)
   */
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyDatamapsGetInput = {
  filter?: InputMaybe<SurveyDatamapsGetFilterArg>
  order?: InputMaybe<Array<SurveyDatamapsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type SurveyDatamapsGetOrderArg = {
  by: SurveyDatamapsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyDatamapsGetOrderEnum {
  CompanyId = 'companyId',
  CompanyName = 'companyName',
  CreatedAt = 'createdAt',
  Id = 'id',
  SessionDeadlineAt = 'sessionDeadlineAt',
  SessionId = 'sessionId',
  UserId = 'userId',
}

export type SurveyDatamapsGetPayload = {
  __typename?: 'SurveyDatamapsGetPayload'
  datamaps: Array<Datamap>
  paginationInfo: PaginationInfo
  query: Query
}

export type SurveyDeleteInput = {
  surveyId: Scalars['ID']['input']
}

export type SurveyDeletePayload = {
  __typename?: 'SurveyDeletePayload'
  query: Query
  survey: Survey
}

export type SurveyDependency = {
  __typename?: 'SurveyDependency'
  answerOption: SurveyAnswerOption
  answerOptionId: Scalars['ID']['output']
  dependencyId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  question: SurveyQuestion
  questionId: Scalars['ID']['output']
}

export type SurveyFilterArg = {
  audienceUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  invitationUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  isDraft?: InputMaybe<Scalars['Boolean']['input']>
  isLastSessionFinished?: InputMaybe<Scalars['Boolean']['input']>
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>
  regulationIds?: InputMaybe<Array<Scalars['ID']['input']>>
  respondentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyGetInput = {
  filter?: InputMaybe<SurveyFilterArg>
  order?: InputMaybe<Array<SurveyGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type SurveyGetOrderArg = {
  by: SurveyGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyGetOrderEnum {
  CreatedAt = 'createdAt',
  IsDraft = 'isDraft',
  IsTemplate = 'isTemplate',
  /** it is possible to use this for sorting by `status` (instead of `status`) */
  LastSessionFinishedAt = 'lastSessionFinishedAt',
  Name = 'name',
  QuestionsCount = 'questionsCount',
}

export type SurveyGetPayload = {
  __typename?: 'SurveyGetPayload'
  paginationInfo: PaginationInfo
  query: Query
  surveys: Array<Survey>
}

export type SurveyGuidedStep = {
  __typename?: 'SurveyGuidedStep'
  description?: Maybe<Scalars['String']['output']>
  guidedStepId: Scalars['ID']['output']
  guidedStepTemplate?: Maybe<SurveyGuidedStepTemplate>
  id: Scalars['ID']['output']
  /**
   * Indicates whether the user with this `userId` (by default - currentUser)
   * answered all questions of this `GuidedStep`
   *
   *  - `true` - yes
   *  - `false` - no
   *  - `undefined` - there are 0 questions in this `GuidedStep`
   * @deprecated use `SurveySession.isCompleted` or `Invitation.isCompleted`
   */
  isCompletedByUser?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  questions: Array<SurveyQuestion>
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  survey: Survey
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveyGuidedStepIsCompletedByUserArgs = {
  input?: InputMaybe<SurveyIsCompletedByUserInput>
}

export type SurveyGuidedStepCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  surveyId: Scalars['ID']['input']
}

export type SurveyGuidedStepCreatePayload = {
  __typename?: 'SurveyGuidedStepCreatePayload'
  guidedStep: SurveyGuidedStep
  query: Query
}

export type SurveyGuidedStepDeleteInput = {
  guidedStepId: Scalars['ID']['input']
}

export type SurveyGuidedStepDeletePayload = {
  __typename?: 'SurveyGuidedStepDeletePayload'
  /** Deleted group. Nested fields **ARE NOT AVAILABLE** */
  guidedStep: SurveyGuidedStep
  query: Query
}

export type SurveyGuidedStepTemplate = {
  __typename?: 'SurveyGuidedStepTemplate'
  description?: Maybe<Scalars['String']['output']>
  guidedStepTemplateId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  /** @deprecated do we need this field at all? */
  isOnlyForManyRegulations?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  questionTemplates: Array<SurveyQuestionTemplate>
  sortOrder: Scalars['NonNegativeInt']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveyGuidedStepUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  guidedStepId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
}

export type SurveyGuidedStepUpdatePayload = {
  __typename?: 'SurveyGuidedStepUpdatePayload'
  guidedStep: SurveyGuidedStep
  query: Query
}

export type SurveyInvitationDeleteFilterArg = {
  invitationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyInvitationDeleteInput = {
  filter?: InputMaybe<SurveyInvitationDeleteFilterArg>
}

export type SurveyInvitationDeletePayload = {
  __typename?: 'SurveyInvitationDeletePayload'
  invitations: Array<Invitation>
  query: Query
}

export type SurveyInvitationsCreateInput = {
  onDuplicateInvitationBehavior?: InputMaybe<OnErrorBehaviorEnum>
  sessionIds: Array<Scalars['ID']['input']>
  suppressNotifications?: InputMaybe<Scalars['Boolean']['input']>
  users: SurveyInvitationsCreateUsersArg
}

export type SurveyInvitationsCreatePayload = {
  __typename?: 'SurveyInvitationsCreatePayload'
  invitations: Array<Invitation>
  query: Query
}

export type SurveyInvitationsCreateUsersArg = {
  filter: SurveyInvitationsCreateUsersFilterArg
}

export type SurveyInvitationsCreateUsersFilterArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyInvitationsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyInvitationsGetInput = {
  filter?: InputMaybe<SurveyInvitationsGetFilterArg>
  order?: InputMaybe<Array<SurveyInvitationsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<SurveyInvitationsGetSearchArg>
}

export type SurveyInvitationsGetOrderArg = {
  by: SurveyInvitationsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyInvitationsGetOrderEnum {
  CreatedAt = 'createdAt',
  SessionCreatedAt = 'sessionCreatedAt',
  SessionDeadlineAt = 'sessionDeadlineAt',
  SessionFinishedAt = 'sessionFinishedAt',
  UserEmail = 'userEmail',
  UserFirstName = 'userFirstName',
  UserLastName = 'userLastName',
}

export type SurveyInvitationsGetPayload = {
  __typename?: 'SurveyInvitationsGetPayload'
  invitations: Array<Invitation>
  paginationInfo: PaginationInfo
  query: Query
}

export type SurveyInvitationsGetSearchArg = {
  bySameResponse?: InputMaybe<Scalars['ID']['input']>
}

export type SurveyInviteAudiencesInput = {
  audienceIds: Array<Scalars['ID']['input']>
  sessionId: Scalars['ID']['input']
}

export type SurveyInviteAudiencesPayload = {
  __typename?: 'SurveyInviteAudiencesPayload'
  /** Only newly created invitations */
  invitations: Array<Invitation>
  query: Query
  session: SurveySession
}

export type SurveyInviteUsersInput = {
  sessionId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']>
}

export type SurveyInviteUsersPayload = {
  __typename?: 'SurveyInviteUsersPayload'
  /** Only newly created invitations */
  invitations: Array<Invitation>
  query: Query
  session: SurveySession
}

export type SurveyIsCompletedByUserInput = {
  sessionId: Scalars['ID']['input']
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type SurveyMaturityScoreSetInput = {
  maturityScore?: InputMaybe<Scalars['NonNegativeInt']['input']>
  questionId: Scalars['ID']['input']
  sessionId: Scalars['ID']['input']
}

export type SurveyMaturityScoreSetPayload = {
  __typename?: 'SurveyMaturityScoreSetPayload'
  maturityScore?: Maybe<MaturityScore>
  query: Query
}

export type SurveyMaturityScoresGetFilterArg = {
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyMaturityScoresGetInput = {
  filter?: InputMaybe<SurveyMaturityScoresGetFilterArg>
}

export type SurveyMaturityScoresGetPayload = {
  __typename?: 'SurveyMaturityScoresGetPayload'
  maturityScores: Array<MaturityScore>
  query: Query
}

export type SurveyNotInvitedGetInput = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds: Array<Scalars['ID']['input']>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyNotInvitedGetItem = {
  __typename?: 'SurveyNotInvitedGetItem'
  audienceIds: Array<Scalars['ID']['output']>
  audiences: Array<Audience>
  session: SurveySession
  sessionId: Scalars['ID']['output']
  userIds: Array<Scalars['ID']['output']>
  users: Array<User>
}

export type SurveyNotInvitedGetPayload = {
  __typename?: 'SurveyNotInvitedGetPayload'
  items: Array<SurveyNotInvitedGetItem>
  query: Query
}

export type SurveyQuestion = {
  __typename?: 'SurveyQuestion'
  answerOptions: Array<ISurveyAnswerOption>
  datamapEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  /**
   * For DM-question this field describes the `DatamapEntityType` of the parent
   * question - to make it possible to build the hierarchy of questions for the
   * DM-survey
   *
   * `null` value for DM-questions means that the parent for the question is
   * `Datamap` entity itself (see `survey.datamapInit()`)
   */
  datamapParentEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  datamapResponses?: Maybe<Array<Maybe<DatamapResponse>>>
  /** The list of dependencies for this question. */
  dependsOn: Array<SurveyDependency>
  description?: Maybe<Scalars['String']['output']>
  displayMode?: Maybe<SurveyBuilderQuestionMode>
  guidedStep?: Maybe<SurveyGuidedStep>
  guidedStepId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  isTemplateQuestion?: Maybe<Scalars['Boolean']['output']>
  knowledgeBaseArticle?: Maybe<KnowledgeBaseArticle>
  label: Scalars['String']['output']
  matrix?: Maybe<SurveyQuestionMatrix>
  maturityScore?: Maybe<Scalars['NonNegativeInt']['output']>
  modifiers?: Maybe<Scalars['JSONObject']['output']>
  parentResponseId?: Maybe<Scalars['String']['output']>
  questionId: Scalars['ID']['output']
  questionTemplate?: Maybe<SurveyQuestionTemplate>
  questionTemplateId?: Maybe<Scalars['ID']['output']>
  responses: Array<SurveyResponse>
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  subcategory: SurveySubcategory
  subcategoryId: Scalars['ID']['output']
  type: QuestionType
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveyQuestionMaturityScoreArgs = {
  sessionId: Scalars['ID']['input']
}

export type SurveyQuestionCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  guidedStepId?: InputMaybe<Scalars['ID']['input']>
  label: Scalars['String']['input']
  modifiers?: InputMaybe<Scalars['JSONObject']['input']>
  questionTypeId: Scalars['ID']['input']
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  subcategoryId: Scalars['ID']['input']
}

export type SurveyQuestionCreatePayload = {
  __typename?: 'SurveyQuestionCreatePayload'
  query: Query
  question: SurveyQuestion
  survey: Survey
}

export type SurveyQuestionDeleteInput = {
  questionId: Scalars['ID']['input']
}

export type SurveyQuestionDeletePayload = {
  __typename?: 'SurveyQuestionDeletePayload'
  query: Query
  /** Deleted question. Nested fields **ARE NOT AVAILABLE** */
  question: SurveyQuestion
}

export type SurveyQuestionMatrix = {
  __typename?: 'SurveyQuestionMatrix'
  description?: Maybe<Scalars['String']['output']>
  header?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  questionMatrixId: Scalars['ID']['output']
  questionMatrixTemplate?: Maybe<QuestionMatrixTemplate>
  questions: Array<SurveyQuestion>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveyQuestionMatrixCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  header?: InputMaybe<Scalars['String']['input']>
  /**
   * Questions to matrix-ify (join into a matrix-question). Convenient replacent for questionMatrixQuestions.
   *
   * One of `questionIds` or `surveyId` - is required
   */
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /** One of `questionIds` or `surveyId` - is required */
  surveyId?: InputMaybe<Scalars['ID']['input']>
}

export type SurveyQuestionMatrixCreatePayload = {
  __typename?: 'SurveyQuestionMatrixCreatePayload'
  query: Query
  questionMatrix: SurveyQuestionMatrix
}

export type SurveyQuestionMatrixDeleteInput = {
  questionMatrixId: Scalars['ID']['input']
}

export type SurveyQuestionMatrixDeletePayload = {
  __typename?: 'SurveyQuestionMatrixDeletePayload'
  query: Query
  questionMatrix: SurveyQuestionMatrix
}

export type SurveyQuestionMatrixQuestionsLinkInput = {
  questionIds: Array<Scalars['ID']['input']>
  questionMatrixId: Scalars['ID']['input']
}

export type SurveyQuestionMatrixQuestionsLinkPayload = {
  __typename?: 'SurveyQuestionMatrixQuestionsLinkPayload'
  query: Query
  questionMatrix: SurveyQuestionMatrix
}

export type SurveyQuestionMatrixQuestionsUnlinkInput = {
  questionIds: Array<Scalars['ID']['input']>
  questionMatrixId: Scalars['ID']['input']
}

export type SurveyQuestionMatrixQuestionsUnlinkPayload = {
  __typename?: 'SurveyQuestionMatrixQuestionsUnlinkPayload'
  query: Query
  questionMatrix: SurveyQuestionMatrix
}

export type SurveyQuestionMatrixUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  header?: InputMaybe<Scalars['String']['input']>
  questionMatrixId: Scalars['ID']['input']
}

export type SurveyQuestionMatrixUpdatePayload = {
  __typename?: 'SurveyQuestionMatrixUpdatePayload'
  query: Query
  questionMatrix: SurveyQuestionMatrix
}

export type SurveyQuestionTemplate = {
  __typename?: 'SurveyQuestionTemplate'
  answerOptionTemplates?: Maybe<Array<SurveyAnswerOptionTemplate>>
  datamapEntityTypeCode?: Maybe<SurveyDatamapEntityTypeEnum>
  description?: Maybe<Scalars['String']['output']>
  displayMode?: Maybe<SurveyBuilderQuestionMode>
  guidedStepTemplate?: Maybe<SurveyGuidedStepTemplate>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  knowledgeBaseArticle?: Maybe<KnowledgeBaseArticle>
  label: Scalars['String']['output']
  modifiers?: Maybe<Scalars['JSONObject']['output']>
  questionTemplateId: Scalars['ID']['output']
  sortOrder: Scalars['NonNegativeInt']['output']
  subcategoryTemplate: SurveySubcategoryTemplate
  type: QuestionType
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveyQuestionTypesGetInput = {
  filter?: InputMaybe<QuestionTypeFilterArg>
}

export type SurveyQuestionTypesGetPayload = {
  __typename?: 'SurveyQuestionTypesGetPayload'
  query: Query
  questionTypes: Array<Maybe<QuestionType>>
}

export type SurveyQuestionUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  guidedStepId?: InputMaybe<Scalars['ID']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  modifiers?: InputMaybe<Scalars['JSONObject']['input']>
  questionId: Scalars['ID']['input']
  questionTypeId?: InputMaybe<Scalars['ID']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  subcategoryId?: InputMaybe<Scalars['ID']['input']>
}

export type SurveyQuestionUpdatePayload = {
  __typename?: 'SurveyQuestionUpdatePayload'
  query: Query
  question: SurveyQuestion
}

export type SurveyQuestionsGetFilterArg = {
  guidedStepIds?: InputMaybe<Array<Scalars['ID']['input']>>
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  subcategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyQuestionsGetInput = {
  filter?: InputMaybe<SurveyQuestionsGetFilterArg>
  order?: InputMaybe<Array<SurveyQuestionsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type SurveyQuestionsGetOrderArg = {
  by: SurveyQuestionsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyQuestionsGetOrderEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Label = 'label',
  SortOrder = 'sortOrder',
  SubcategorySortOrder = 'subcategorySortOrder',
}

export type SurveyQuestionsGetPayload = {
  __typename?: 'SurveyQuestionsGetPayload'
  paginationInfo: PaginationInfo
  query: Query
  questions: Array<SurveyQuestion>
}

export type SurveyReminderToInvitedNotRespondedSendInput = {
  sessionIds: Array<Scalars['ID']['input']>
}

export type SurveyReminderToInvitedNotRespondedSendPayload = {
  __typename?: 'SurveyReminderToInvitedNotRespondedSendPayload'
  query: Query
}

export type SurveyResponse = {
  __typename?: 'SurveyResponse'
  answerOption?: Maybe<ISurveyAnswerOption>
  /** @deprecated use `respondedAt` */
  answeredAt: Scalars['DateTime']['output']
  files: Array<BinaryFileInfo>
  id: Scalars['ID']['output']
  question: SurveyQuestion
  questionId: Scalars['ID']['output']
  respondedAt: Scalars['DateTime']['output']
  respondent: User
  respondentId: Scalars['ID']['output']
  responseId: Scalars['ID']['output']
  session: SurveySession
  sessionId: Scalars['ID']['output']
  value?: Maybe<Scalars['String']['output']>
}

export type SurveyResponseAnswerArg = {
  answerOptionId?: InputMaybe<Scalars['ID']['input']>
  files?: InputMaybe<Array<Scalars['Upload']['input']>>
  /**
   * Shows if the user's response is sensitive
   * available only for certain Datamap questions
   */
  isSensitive?: InputMaybe<Scalars['Boolean']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type SurveyResponseCreateInput = {
  answerOptionId?: InputMaybe<Scalars['ID']['input']>
  /**
   * actual values of the response (you can provide several)
   * TODO: will be NOT NULL :   answers: [SurveyResponseAnswerArg!]!
   */
  answers?: InputMaybe<Array<SurveyResponseAnswerArg>>
  deleteExistingResponses?: Scalars['Boolean']['input']
  file?: InputMaybe<Scalars['Upload']['input']>
  isSensitive?: InputMaybe<Scalars['Boolean']['input']>
  /** only for DM questions, identifier of the parent response */
  parentResponseId?: InputMaybe<Scalars['ID']['input']>
  questionId: Scalars['ID']['input']
  sessionId: Scalars['ID']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type SurveyResponseCreatePayload = {
  __typename?: 'SurveyResponseCreatePayload'
  datamapResponses?: Maybe<Array<IDatamapResponse>>
  fileInfos?: Maybe<Array<BinaryFileInfo>>
  query: Query
  responses: Array<SurveyResponse>
}

export type SurveyResponseDeleteInput = {
  filter?: InputMaybe<SurveyResponseFilterArg>
}

export type SurveyResponseDeletePayload = {
  __typename?: 'SurveyResponseDeletePayload'
  query: Query
  responses: Array<SurveyResponse>
}

export type SurveyResponseDocumentRemoveInput = {
  fileInfoIds: Array<Scalars['ID']['input']>
  responseId: Scalars['ID']['input']
}

export type SurveyResponseDocumentRemovePayload = {
  __typename?: 'SurveyResponseDocumentRemovePayload'
  fileInfos: Array<BinaryFileInfo>
  query: Query
  response: SurveyResponse
}

export type SurveyResponseDocumentUploadInput = {
  file: Scalars['Upload']['input']
  responseId: Scalars['ID']['input']
}

export type SurveyResponseDocumentUploadPayload = {
  __typename?: 'SurveyResponseDocumentUploadPayload'
  fileInfo: BinaryFileInfo
  query: Query
  response: SurveyResponse
}

export type SurveyResponseFilterArg = {
  respondentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  responseIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveyResponseInfosItemArg = {
  answerOptionId?: InputMaybe<Scalars['ID']['input']>
  files?: InputMaybe<Array<Scalars['Upload']['input']>>
  /**
   * Shows if the user's response is sensitive
   * available only for certain Datamap questions
   */
  isSensitive?: InputMaybe<Scalars['Boolean']['input']>
  /** only for DM questions, identifier of the parent response */
  parentResponseId?: InputMaybe<Scalars['ID']['input']>
  questionId: Scalars['ID']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type SurveyResponseUpdateInput = {
  answerOptionId?: InputMaybe<Scalars['ID']['input']>
  isSensitive?: InputMaybe<Scalars['Boolean']['input']>
  responseId: Scalars['ID']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type SurveyResponseUpdatePayload = {
  __typename?: 'SurveyResponseUpdatePayload'
  datamapResponse?: Maybe<IDatamapResponse>
  query: Query
  response: SurveyResponse
}

export type SurveyResponsesCreateInput = {
  deleteExistingResponses?: Scalars['Boolean']['input']
  responseInfos: Array<SurveyResponseInfosItemArg>
  sessionId: Scalars['ID']['input']
}

export type SurveyResponsesCreatePayload = {
  __typename?: 'SurveyResponsesCreatePayload'
  datamapResponses?: Maybe<Array<IDatamapResponse>>
  fileInfos?: Maybe<Array<BinaryFileInfo>>
  query: Query
  responses: Array<SurveyResponse>
}

export type SurveyResponsesGetInput = {
  filter?: InputMaybe<SurveyResponseFilterArg>
  order?: InputMaybe<Array<SurveyResponsesGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export type SurveyResponsesGetOrderArg = {
  by: SurveyResponsesGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveyResponsesGetOrderEnum {
  AnsweredAt = 'answeredAt',
  RespondentId = 'respondentId',
  SessionId = 'sessionId',
  Value = 'value',
}

export type SurveyResponsesGetPayload = {
  __typename?: 'SurveyResponsesGetPayload'
  paginationInfo: PaginationInfo
  query: Query
  responses: Array<SurveyResponse>
}

export type SurveyResponsesImportPrepareUrlInput = {
  /** This field is ignored, we use it just to provide a link to the docs */
  _readme_csv_format?: InputMaybe<GqlDocs_CsvFileUpload>
  language: LanguageCodeEnum
  respondentId?: InputMaybe<Scalars['ID']['input']>
  sessionId: Scalars['ID']['input']
}

export type SurveyResponsesImportPrepareUrlPayload = {
  __typename?: 'SurveyResponsesImportPrepareUrlPayload'
  headers: Array<HttpHeader>
  query: Query
  url: Scalars['URL']['output']
}

export type SurveySchedule = {
  __typename?: 'SurveySchedule'
  defaultSessionDurationDays: Scalars['NonNegativeInt']['output']
  id: Scalars['ID']['output']
  periodDays?: Maybe<Scalars['NonNegativeInt']['output']>
  scheduleId: Scalars['ID']['output']
  startAt: Scalars['DateTime']['output']
  survey: Survey
}

export type SurveyScheduleCreateInput = {
  defaultSessionDurationDays: Scalars['NonNegativeInt']['input']
  /** it is `nullable` just because in future releases we could add `periodMonths` */
  periodDays?: InputMaybe<Scalars['NonNegativeInt']['input']>
  startAt: Scalars['DateTime']['input']
  surveyId: Scalars['ID']['input']
}

export type SurveyScheduleCreatePayload = {
  __typename?: 'SurveyScheduleCreatePayload'
  query: Query
  schedule: SurveySchedule
}

export type SurveyScheduleDeleteInput = {
  scheduleId: Scalars['ID']['input']
}

export type SurveyScheduleDeletePayload = {
  __typename?: 'SurveyScheduleDeletePayload'
  query: Query
  /** Deleted schedule. Nested fields **ARE NOT AVAILABLE** */
  schedule: SurveySchedule
}

export type SurveyScheduleUpdateInput = {
  defaultSessionDurationDays?: InputMaybe<Scalars['NonNegativeInt']['input']>
  periodDays?: InputMaybe<Scalars['NonNegativeInt']['input']>
  scheduleId: Scalars['ID']['input']
  startAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type SurveyScheduleUpdatePayload = {
  __typename?: 'SurveyScheduleUpdatePayload'
  query: Query
  schedule: SurveySchedule
}

export type SurveySession = {
  __typename?: 'SurveySession'
  datamaps: Array<Datamap>
  deadlineAt?: Maybe<Scalars['DateTime']['output']>
  finishedAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  invitations: Array<Invitation>
  invitedUsers: Array<User>
  isComplete?: Maybe<Scalars['Boolean']['output']>
  isCompleted: Scalars['Boolean']['output']
  maturityScoreAveragesForSession?: Maybe<StatisticsMaturityScoreAveragesItem>
  responses: Array<SurveyResponse>
  sessionId: Scalars['ID']['output']
  startedAt: Scalars['DateTime']['output']
  /** SessionStatus for the current user */
  statusForUser?: Maybe<SurveySessionPerUserStatusEnum>
  survey: Survey
  surveyId: Scalars['ID']['output']
}

export type SurveySessionDatamapsArgs = {
  input?: InputMaybe<SurveySessionDatamapsInput>
}

export type SurveySessionCreateInput = {
  createInvitationsForAudiences?: InputMaybe<Scalars['Boolean']['input']>
  deadlineAt?: InputMaybe<Scalars['DateTime']['input']>
  finishedAt: Scalars['DateTime']['input']
  startedAt: Scalars['DateTime']['input']
  surveyId: Scalars['ID']['input']
}

export type SurveySessionCreatePayload = {
  __typename?: 'SurveySessionCreatePayload'
  invitations?: Maybe<Array<Invitation>>
  query: Query
  session: SurveySession
  survey: Survey
}

export type SurveySessionDatamapsFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SurveySessionDatamapsInput = {
  filter?: InputMaybe<SurveySessionDatamapsFilterArg>
}

export type SurveySessionDeleteInput = {
  sessionId: Scalars['ID']['input']
}

export type SurveySessionDeletePayload = {
  __typename?: 'SurveySessionDeletePayload'
  query: Query
  session: SurveySession
  survey: Survey
}

export enum SurveySessionPerUserStatusEnum {
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type SurveySessionUpdateInput = {
  deadlineAt?: InputMaybe<Scalars['DateTime']['input']>
  finishedAt?: InputMaybe<Scalars['DateTime']['input']>
  sessionId: Scalars['ID']['input']
  startedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type SurveySessionUpdatePayload = {
  __typename?: 'SurveySessionUpdatePayload'
  query: Query
  session: SurveySession
  survey: Survey
}

export type SurveySessionsGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  createdAt?: InputMaybe<DateTimeFilterArg>
  deadlineAt?: InputMaybe<DateTimeFilterArg>
  finishedAt?: InputMaybe<DateTimeFilterArg>
  invitationUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  onlyLastSessionPerSurvey?: Scalars['Boolean']['input']
  respondentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  statusesForCurrentUser?: InputMaybe<Array<SurveySessionPerUserStatusEnum>>
  surveyAudienceUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  surveyIsDraft?: InputMaybe<Scalars['Boolean']['input']>
  surveyIsTemplate?: InputMaybe<Scalars['Boolean']['input']>
}

export type SurveySessionsGetInput = {
  filter?: InputMaybe<SurveySessionsGetFilterArg>
  order?: InputMaybe<Array<SurveySessionsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<SurveySessionsGetSearchArg>
}

export type SurveySessionsGetOrderArg = {
  by: SurveySessionsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum SurveySessionsGetOrderEnum {
  CreatedAt = 'createdAt',
  DeadlineAt = 'deadlineAt',
  FinishedAt = 'finishedAt',
  SessionId = 'sessionId',
  StartedAt = 'startedAt',
  SurveyCreatedAt = 'surveyCreatedAt',
  SurveyIsDraft = 'surveyIsDraft',
  SurveyIsTemplate = 'surveyIsTemplate',
  SurveyName = 'surveyName',
  SurveyQuestionsCount = 'surveyQuestionsCount',
}

export type SurveySessionsGetPayload = {
  __typename?: 'SurveySessionsGetPayload'
  paginationInfo: PaginationInfo
  query: Query
  sessions: Array<SurveySession>
}

export type SurveySessionsGetSearchArg = {
  /**
   * Searches in:
   *   - session.survey.name
   */
  pattern: Scalars['String']['input']
}

export enum SurveyStatusEnum {
  Complete = 'COMPLETE',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
}

export type SurveySubcategory = {
  __typename?: 'SurveySubcategory'
  description?: Maybe<Scalars['String']['output']>
  /**
   * Guided Steps
   *
   * `null` - when the Subcategory is not 'guided' this filed is null
   * `[ guidedSteps ... ]` - when the Subcategory is `guided`
   */
  guidedSteps?: Maybe<Array<SurveyGuidedStep>>
  header: Scalars['String']['output']
  id: Scalars['ID']['output']
  /**
   * Indicates whether the user with this `userId` (by default - currentUser)
   * answered all questions of this `Subcategory`
   *
   *  - `true` - yes
   *  - `false` - no
   *  - `undefined` - there are 0 questions in this `Subcategory`
   * @deprecated use `SurveySession.isCompleted` or `Invitation.isCompleted`
   */
  isCompletedByUser?: Maybe<Scalars['Boolean']['output']>
  isGuided: Scalars['Boolean']['output']
  questions: Array<SurveyQuestion>
  questionsCount: Scalars['NonNegativeInt']['output']
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  subcategoryId: Scalars['ID']['output']
  subcategoryTemplate?: Maybe<SurveySubcategoryTemplate>
  survey: Survey
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveySubcategoryIsCompletedByUserArgs = {
  input?: InputMaybe<SurveyIsCompletedByUserInput>
}

export type SurveySubcategoryCreateFromTemplateInput = {
  subcategoryTemplateIds: Array<Scalars['ID']['input']>
  surveyId: Scalars['ID']['input']
}

export type SurveySubcategoryCreateFromTemplatePayload = {
  __typename?: 'SurveySubcategoryCreateFromTemplatePayload'
  query: Query
  subcategories: Array<SurveySubcategory>
  survey: Survey
}

export type SurveySubcategoryCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  header: Scalars['String']['input']
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  surveyId: Scalars['ID']['input']
}

export type SurveySubcategoryCreatePayload = {
  __typename?: 'SurveySubcategoryCreatePayload'
  query: Query
  subcategory: SurveySubcategory
  survey: Survey
}

export type SurveySubcategoryDeleteInput = {
  subcategoryId: Scalars['ID']['input']
}

export type SurveySubcategoryDeletePayload = {
  __typename?: 'SurveySubcategoryDeletePayload'
  query: Query
  subcategory: SurveySubcategory
  survey: Survey
}

export type SurveySubcategoryTemplate = {
  __typename?: 'SurveySubcategoryTemplate'
  category: SurveyCategory
  description?: Maybe<Scalars['String']['output']>
  header: Scalars['String']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  isConfirmed?: Maybe<Scalars['Boolean']['output']>
  isGuided: Scalars['Boolean']['output']
  isSelected?: Maybe<Scalars['Boolean']['output']>
  questionTemplates: Array<SurveyQuestionTemplate>
  questionTemplatesCount: Scalars['NonNegativeInt']['output']
  sortOrder: Scalars['NonNegativeInt']['output']
  subcategoryTemplateId: Scalars['ID']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type SurveySubcategoryTemplateUpdateInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  subcategoryTemplateId: Scalars['ID']['input']
}

export type SurveySubcategoryTemplateUpdatePayload = {
  __typename?: 'SurveySubcategoryTemplateUpdatePayload'
  subcategoryTemplate: SurveySubcategoryTemplate
}

export type SurveySubcategoryTemplatesFilterArg = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  subcategoryTemplateIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type SurveySubcategoryTemplatesGetInput = {
  filter?: InputMaybe<SurveySubcategoryTemplatesFilterArg>
}

export type SurveySubcategoryTemplatesGetPayload = {
  __typename?: 'SurveySubcategoryTemplatesGetPayload'
  query: Query
  subcategoryTemplates: Array<SurveySubcategoryTemplate>
}

export type SurveySubcategoryUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  header?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  subcategoryId: Scalars['ID']['input']
}

export type SurveySubcategoryUpdatePayload = {
  __typename?: 'SurveySubcategoryUpdatePayload'
  query: Query
  subcategory: SurveySubcategory
}

export type SurveySurveyAudiencesSetInput = {
  audienceIds: Array<Scalars['ID']['input']>
  surveyId: Scalars['ID']['input']
}

export type SurveySurveyAudiencesSetPayload = {
  __typename?: 'SurveySurveyAudiencesSetPayload'
  query: Query
  survey: Survey
}

export type SurveySurveyConvertIntoTemplateInput = {
  categoryId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  surveyId: Scalars['ID']['input']
}

export type SurveySurveyConvertIntoTemplatePayload = {
  __typename?: 'SurveySurveyConvertIntoTemplatePayload'
  query?: Maybe<Query>
  subcategoryTemplate?: Maybe<Array<Maybe<SurveySubcategoryTemplate>>>
}

export enum SurveyType {
  Custom = 'CUSTOM',
  Regulatory = 'REGULATORY',
  Templated = 'TEMPLATED',
}

export type SurveyUpdateInput = {
  isDraft?: InputMaybe<Scalars['Boolean']['input']>
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  surveyId: Scalars['ID']['input']
}

export type SurveyUpdatePayload = {
  __typename?: 'SurveyUpdatePayload'
  query: Query
  survey: Survey
}

export type SurveyVirtualAnswerOption = ISurveyAnswerOption & {
  __typename?: 'SurveyVirtualAnswerOption'
  id?: Maybe<Scalars['ID']['output']>
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type Task = {
  __typename?: 'Task'
  assigneeAudiences: Array<Audience>
  assigneeUsers: Array<User>
  attachments: Array<TaskAttachment>
  attachmentsCount: Scalars['NonNegativeInt']['output']
  categories: Array<TaskCategory>
  comments: Array<TaskComment>
  commentsCount: Scalars['NonNegativeInt']['output']
  company: Company
  companyId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  deadlineAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isArchived: Scalars['Boolean']['output']
  linkedDocboxDocuments: Array<DocboxDocument>
  linkedDsarRequests: Array<DsarRequest>
  linkedSurveySessions: Array<SurveySession>
  linkedUrls: Array<TaskLinkedUrl>
  priority: TaskPriority
  priorityId: Scalars['ID']['output']
  sortOrder: Scalars['NonNegativeInt']['output']
  startAt?: Maybe<Scalars['DateTime']['output']>
  status?: Maybe<TaskStatus>
  statusId?: Maybe<Scalars['ID']['output']>
  taskId: Scalars['ID']['output']
  title: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  user: User
  userId: Scalars['ID']['output']
}

export type TaskAttachment = {
  __typename?: 'TaskAttachment'
  fileInfo: IBinaryFileInfo
  fileInfoId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  isEmbed: Scalars['Boolean']['output']
  task: Task
  taskAttachmentId: Scalars['ID']['output']
  taskId: Scalars['ID']['output']
}

export type TaskAttachmentRemoveInput = {
  fileInfoIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskAttachmentRemovePayload = {
  __typename?: 'TaskAttachmentRemovePayload'
  query: Query
  task: Task
}

export type TaskAttachmentUploadInput = {
  files: Array<Scalars['Upload']['input']>
  taskId?: InputMaybe<Scalars['ID']['input']>
}

export type TaskAttachmentUploadPayload = {
  __typename?: 'TaskAttachmentUploadPayload'
  /**
   * These created if the `taskId` was provided
   *
   * That is why the array is NULLABLE: if there is a task - every uploaded
   * file will be attached to a `Task` and then the array will contain
   * `TaskAttachment`
   */
  attachments?: Maybe<Array<TaskAttachment>>
  fileInfos: Array<IBinaryFileInfo>
  query: Query
  task?: Maybe<Task>
}

export type TaskCategoriesGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskCategoriesGetInput = {
  filter?: InputMaybe<TaskCategoriesGetFilterArg>
}

export type TaskCategoriesGetPayload = {
  __typename?: 'TaskCategoriesGetPayload'
  categories: Array<TaskCategory>
  query: Query
}

export type TaskCategory = {
  __typename?: 'TaskCategory'
  categoryId: Scalars['ID']['output']
  color: Scalars['HexColorCode']['output']
  company: Company
  companyId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type TaskCategoryCreateInput = {
  color: Scalars['HexColorCode']['input']
  companyId: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type TaskCategoryCreatePayload = {
  __typename?: 'TaskCategoryCreatePayload'
  category: TaskCategory
  query: Query
}

export type TaskCategoryDeleteFilterArg = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskCategoryDeleteInput = {
  filter?: InputMaybe<TaskCategoryDeleteFilterArg>
}

export type TaskCategoryDeletePayload = {
  __typename?: 'TaskCategoryDeletePayload'
  categories: Array<TaskCategory>
  query: Query
}

export type TaskCategoryLinkInput = {
  categoryIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskCategoryLinkPayload = {
  __typename?: 'TaskCategoryLinkPayload'
  query: Query
  task: Task
}

export type TaskCategoryUnlinkInput = {
  categoryIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskCategoryUnlinkPayload = {
  __typename?: 'TaskCategoryUnlinkPayload'
  query: Query
  task: Task
}

export type TaskCategoryUpdateInput = {
  categoryId: Scalars['ID']['input']
  color?: InputMaybe<Scalars['HexColorCode']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type TaskCategoryUpdatePayload = {
  __typename?: 'TaskCategoryUpdatePayload'
  category: TaskCategory
  query: Query
}

export type TaskComment = {
  __typename?: 'TaskComment'
  attachments: Array<TaskCommentAttachment>
  commentId: Scalars['ID']['output']
  content: Scalars['String']['output']
  id: Scalars['ID']['output']
  task: Task
  taskId: Scalars['ID']['output']
  userId: Scalars['ID']['output']
}

export type TaskCommentAttachment = {
  __typename?: 'TaskCommentAttachment'
  comment: TaskComment
  commentAttachmentId: Scalars['ID']['output']
  commentId: Scalars['ID']['output']
  fileInfo: IBinaryFileInfo
  fileInfoId: Scalars['ID']['output']
  id: Scalars['ID']['output']
}

export type TaskCommentCreateInput = {
  content: Scalars['String']['input']
  fileInfoEmbedIds?: InputMaybe<Array<Scalars['ID']['input']>>
  mentionedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  taskId: Scalars['ID']['input']
}

export type TaskCommentCreatePayload = {
  __typename?: 'TaskCommentCreatePayload'
  comment: TaskComment
  query: Query
}

export type TaskCopyInput = {
  taskId: Scalars['ID']['input']
}

export type TaskCopyPayload = {
  __typename?: 'TaskCopyPayload'
  query: Query
  task: Task
}

export type TaskCreateInput = {
  assigneeAudienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  assigneeUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyId: Scalars['ID']['input']
  deadlineAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** For creating "embed" attachments */
  fileInfoEmbedIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /** For creating "pure" attachments (not "embed") */
  fileInfoIds?: InputMaybe<Array<Scalars['ID']['input']>>
  linkedDocboxDocumentIds?: InputMaybe<Array<Scalars['ID']['input']>>
  linkedDsarRequestIds?: InputMaybe<Array<Scalars['ID']['input']>>
  linkedSurveySessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  linkedUrls?: InputMaybe<Array<Scalars['URL']['input']>>
  mentionedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * Priority for the new task.
   * By default "Priority:NONE" will be used
   */
  priorityId?: InputMaybe<Scalars['ID']['input']>
  sortOrder?: Scalars['NonNegativeInt']['input']
  /** When the task should start. */
  startAt?: InputMaybe<Scalars['DateTime']['input']>
  /**
   * Status for the new task.
   * By default NEW status for the company with provided `companyId` will be used.
   */
  statusId?: InputMaybe<Scalars['ID']['input']>
  title: Scalars['String']['input']
}

export type TaskCreatePayload = {
  __typename?: 'TaskCreatePayload'
  query: Query
  task: Task
}

export type TaskDeleteFilterArg = {
  taskIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskDeleteInput = {
  filter?: InputMaybe<TaskDeleteFilterArg>
}

export type TaskDeletePayload = {
  __typename?: 'TaskDeletePayload'
  query: Query
  tasks: Array<Task>
}

export type TaskDocboxDocumentLinkInput = {
  documentIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskDocboxDocumentLinkPayload = {
  __typename?: 'TaskDocboxDocumentLinkPayload'
  query: Query
  task: Task
}

export type TaskDocboxDocumentUnlinkInput = {
  documentIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskDocboxDocumentUnlinkPayload = {
  __typename?: 'TaskDocboxDocumentUnlinkPayload'
  query: Query
  task: Task
}

export type TaskDsarRequestLinkInput = {
  requestIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskDsarRequestLinkPayload = {
  __typename?: 'TaskDsarRequestLinkPayload'
  query: Query
  task: Task
}

export type TaskDsarRequestUnlinkInput = {
  requestIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskDsarRequestUnlinkPayload = {
  __typename?: 'TaskDsarRequestUnlinkPayload'
  query: Query
  task: Task
}

export type TaskGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  isArchived?: InputMaybe<Scalars['Boolean']['input']>
  statusIds?: InputMaybe<Array<Scalars['ID']['input']>>
  taskIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskGetInput = {
  filter?: InputMaybe<TaskGetFilterArg>
  order?: InputMaybe<Array<TasksGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<TaskGetSearchArg>
}

export enum TaskGetOrderEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  IsArchived = 'isArchived',
  SortOrder = 'sortOrder',
  StartAt = 'startAt',
  StatusName = 'statusName',
  StatusSortOrder = 'statusSortOrder',
  StatusType = 'statusType',
  Title = 'title',
  UpdatedAt = 'updatedAt',
}

export type TaskGetPayload = {
  __typename?: 'TaskGetPayload'
  paginationInfo: PaginationInfo
  query: Query
  tasks: Array<Task>
}

export type TaskGetSearchArg = {
  pattern: Scalars['String']['input']
}

export enum TaskHistoryEventCodeEnum {
  TaskAssigneeAudienceLinked = 'TASK_ASSIGNEE_AUDIENCE__LINKED',
  TaskAssigneeAudienceUnlinked = 'TASK_ASSIGNEE_AUDIENCE__UNLINKED',
  TaskAssigneeUserLinked = 'TASK_ASSIGNEE_USER__LINKED',
  TaskAssigneeUserUnlinked = 'TASK_ASSIGNEE_USER__UNLINKED',
  TaskAttachmentCreated = 'TASK_ATTACHMENT__CREATED',
  TaskAttachmentDeleted = 'TASK_ATTACHMENT__DELETED',
  TaskCategoryLinked = 'TASK_CATEGORY__LINKED',
  TaskCategoryUnlinked = 'TASK_CATEGORY__UNLINKED',
  TaskCommentCreated = 'TASK_COMMENT__CREATED',
  TaskDeadlineatCreated = 'TASK_DEADLINEAT__CREATED',
  TaskDeadlineatDeleted = 'TASK_DEADLINEAT__DELETED',
  TaskDeadlineatUpdated = 'TASK_DEADLINEAT__UPDATED',
  TaskDescriptionCreated = 'TASK_DESCRIPTION__CREATED',
  TaskDescriptionDeleted = 'TASK_DESCRIPTION__DELETED',
  TaskDescriptionUpdated = 'TASK_DESCRIPTION__UPDATED',
  TaskLinkedUrlCreated = 'TASK_LINKED_URL__CREATED',
  TaskLinkedUrlDeleted = 'TASK_LINKED_URL__DELETED',
  TaskLinkDocboxDocumentLinked = 'TASK_LINK_DOCBOX_DOCUMENT__LINKED',
  TaskLinkDocboxDocumentUnlinked = 'TASK_LINK_DOCBOX_DOCUMENT__UNLINKED',
  TaskLinkDsarRequestLinked = 'TASK_LINK_DSAR_REQUEST__LINKED',
  TaskLinkDsarRequestUnlinked = 'TASK_LINK_DSAR_REQUEST__UNLINKED',
  TaskLinkSurveySessionLinked = 'TASK_LINK_SURVEY_SESSION__LINKED',
  TaskLinkSurveySessionUnlinked = 'TASK_LINK_SURVEY_SESSION__UNLINKED',
  TaskPriorityUpdated = 'TASK_PRIORITY__UPDATED',
  TaskStartatCreated = 'TASK_STARTAT__CREATED',
  TaskStartatDeleted = 'TASK_STARTAT__DELETED',
  TaskStartatUpdated = 'TASK_STARTAT__UPDATED',
  TaskStatusUpdated = 'TASK_STATUS__UPDATED',
  TaskTitleUpdated = 'TASK_TITLE__UPDATED',
  TaskArchived = 'TASK__ARCHIVED',
  TaskCreated = 'TASK__CREATED',
}

export type TaskHistoryItem = {
  __typename?: 'TaskHistoryItem'
  createdAt: Scalars['DateTime']['output']
  eventCode: TaskHistoryEventCodeEnum
  historyItemId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  newInfo?: Maybe<Scalars['JSONObject']['output']>
  oldInfo?: Maybe<Scalars['JSONObject']['output']>
  task: Task
  taskId: Scalars['ID']['output']
  user: User
  userId: Scalars['ID']['output']
}

export type TaskHistoryItemsGetFilterArg = {
  eventCodes?: InputMaybe<Array<TaskHistoryEventCodeEnum>>
  taskIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskHistoryItemsGetInput = {
  filter?: InputMaybe<TaskHistoryItemsGetFilterArg>
  order?: InputMaybe<Array<TasksHistoryItemsGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
}

export enum TaskHistoryItemsGetOrderEnum {
  CreatedAt = 'createdAt',
  EventCode = 'eventCode',
}

export type TaskHistoryItemsGetPayload = {
  __typename?: 'TaskHistoryItemsGetPayload'
  historyItems: Array<TaskHistoryItem>
  paginationInfo: PaginationInfo
  query: Query
}

export type TaskLinkedUrl = {
  __typename?: 'TaskLinkedUrl'
  id: Scalars['ID']['output']
  taskId: Scalars['ID']['output']
  url: Scalars['URL']['output']
}

export type TaskLinkedUrlCreateInput = {
  taskId: Scalars['ID']['input']
  urls: Array<Scalars['URL']['input']>
}

export type TaskLinkedUrlCreatePayload = {
  __typename?: 'TaskLinkedUrlCreatePayload'
  query: Query
  task: Task
}

export type TaskLinkedUrlDeleteInput = {
  linkedUrlIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskLinkedUrlDeletePayload = {
  __typename?: 'TaskLinkedUrlDeletePayload'
  query: Query
  task: Task
}

export type TaskPrioritiesGetPayload = {
  __typename?: 'TaskPrioritiesGetPayload'
  priorities: Array<TaskPriority>
  query: Query
}

export type TaskPriority = {
  __typename?: 'TaskPriority'
  code: Scalars['String']['output']
  color: Scalars['HexColorCode']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  priorityId: Scalars['ID']['output']
  sortOrder?: Maybe<Scalars['NonNegativeInt']['output']>
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type TaskStatus = {
  __typename?: 'TaskStatus'
  color: Scalars['HexColorCode']['output']
  company: Company
  companyId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  sortOrder: Scalars['NonNegativeInt']['output']
  statusId: Scalars['ID']['output']
  type: TaskStatusTypeEnum
  uiCode?: Maybe<Scalars['UICode']['output']>
}

export type TaskStatusCreateInput = {
  color: Scalars['HexColorCode']['input']
  companyId: Scalars['ID']['input']
  name: Scalars['String']['input']
  sortOrder: Scalars['NonNegativeInt']['input']
  type?: InputMaybe<TaskStatusTypeEnum>
}

export type TaskStatusCreatePayload = {
  __typename?: 'TaskStatusCreatePayload'
  query: Query
  status: TaskStatus
}

export type TaskStatusDeleteFilterArg = {
  statusIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskStatusDeleteInput = {
  filter?: InputMaybe<TaskStatusDeleteFilterArg>
}

export type TaskStatusDeletePayload = {
  __typename?: 'TaskStatusDeletePayload'
  query: Query
  statuses: Array<TaskStatus>
}

export enum TaskStatusTypeEnum {
  /** Default PRE-defined status for CLOSED tasks */
  Closed = 'CLOSED',
  /**
   * Default PRE-defined status for NEW tasks
   *
   * There is and can be **one and only one** status of this type per company
   */
  New = 'NEW',
  /** Other intermediate statuses */
  Other = 'OTHER',
}

export type TaskStatusUpdateInput = {
  color?: InputMaybe<Scalars['HexColorCode']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  statusId: Scalars['ID']['input']
  type?: InputMaybe<TaskStatusTypeEnum>
}

export type TaskStatusUpdatePayload = {
  __typename?: 'TaskStatusUpdatePayload'
  query: Query
  status: TaskStatus
}

export type TaskStatusesGetFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TaskStatusesGetInput = {
  filter?: InputMaybe<TaskStatusesGetFilterArg>
}

export type TaskStatusesGetPaylod = {
  __typename?: 'TaskStatusesGetPaylod'
  query: Query
  statuses: Array<TaskStatus>
}

export type TaskStatusesModifyCreateItemArg = {
  color: Scalars['HexColorCode']['input']
  name: Scalars['String']['input']
  sortOrder: Scalars['NonNegativeInt']['input']
  type?: InputMaybe<TaskStatusTypeEnum>
}

export type TaskStatusesModifyInput = {
  companyId: Scalars['ID']['input']
  createItems?: InputMaybe<Array<TaskStatusesModifyCreateItemArg>>
  deleteItems?: InputMaybe<Array<Scalars['ID']['input']>>
  updateItems?: InputMaybe<Array<TaskStatusesModifyUpdateItemArg>>
}

export type TaskStatusesModifyPayload = {
  __typename?: 'TaskStatusesModifyPayload'
  query: Query
  statuses: Array<TaskStatus>
}

export type TaskStatusesModifyUpdateItemArg = {
  color?: InputMaybe<Scalars['HexColorCode']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  statusId: Scalars['ID']['input']
  type?: InputMaybe<TaskStatusTypeEnum>
}

export type TaskSurveySessionLinkInput = {
  sessionIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskSurveySessionLinkPayload = {
  __typename?: 'TaskSurveySessionLinkPayload'
  query: Query
  task: Task
}

export type TaskSurveySessionUnlinkInput = {
  sessionIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskSurveySessionUnlinkPayload = {
  __typename?: 'TaskSurveySessionUnlinkPayload'
  query: Query
  task: Task
}

export type TaskTaskArchivedSetInput = {
  isArchived: Scalars['Boolean']['input']
  taskIds: Array<Scalars['ID']['input']>
}

export type TaskTaskArchivedSetPayload = {
  __typename?: 'TaskTaskArchivedSetPayload'
  query: Query
  tasks: Array<Task>
}

export type TaskTaskAssigneeAudienceLinkInput = {
  audienceIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskTaskAssigneeAudienceLinkPayload = {
  __typename?: 'TaskTaskAssigneeAudienceLinkPayload'
  query: Query
  task: Task
}

export type TaskTaskAssigneeAudienceUnlinkInput = {
  audienceIds: Array<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
}

export type TaskTaskAssigneeAudienceUnlinkPayload = {
  __typename?: 'TaskTaskAssigneeAudienceUnlinkPayload'
  query: Query
  task: Task
}

export type TaskTaskAssigneeUserLinkInput = {
  taskId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']>
}

export type TaskTaskAssigneeUserLinkPayload = {
  __typename?: 'TaskTaskAssigneeUserLinkPayload'
  query: Query
  task: Task
}

export type TaskTaskAssigneeUserUnlinkInput = {
  taskId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']>
}

export type TaskTaskAssigneeUserUnlinkPayload = {
  __typename?: 'TaskTaskAssigneeUserUnlinkPayload'
  query: Query
  task: Task
}

export type TaskUpdateInput = {
  deadlineAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /**
   * Will be used to SET the `isEmbed` attachments for the task (attachments
   * that are used in the description/title of the task).
   *
   * This argument will be used to **override** the existing `isEmbed`
   * attachments: the provided list will be compared with the list of existing
   * attachments, and then - the new ones will be `related`/`linked` with
   * the task, not mentioned - removed.
   */
  fileInfoEmbedIds?: InputMaybe<Array<Scalars['ID']['input']>>
  mentionedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>
  priorityId?: InputMaybe<Scalars['ID']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  /** When the task should start. */
  startAt?: InputMaybe<Scalars['DateTime']['input']>
  statusId?: InputMaybe<Scalars['ID']['input']>
  taskId: Scalars['ID']['input']
  title?: InputMaybe<Scalars['String']['input']>
}

export type TaskUpdatePayload = {
  __typename?: 'TaskUpdatePayload'
  query: Query
  task: Task
}

export type TasksGetOrderArg = {
  by: TaskGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export type TasksHistoryItemsGetOrderArg = {
  by: TaskHistoryItemsGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export type Tenant = {
  __typename?: 'Tenant'
  hasPermanentAccess: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  tenantId: Scalars['ID']['output']
  trialExpiredAt?: Maybe<Scalars['DateTime']['output']>
}

export enum UnitOfMeasurementEnum {
  Gigabyte = 'GIGABYTE',
  Item = 'ITEM',
  Megabyte = 'MEGABYTE',
  Person = 'PERSON',
}

export type User = IUserWithEmail & {
  __typename?: 'User'
  /** the list of audiences the user belongs to */
  audiences: Array<Audience>
  /** companies, related to user (with same `tenantId`) */
  companies: Array<Company>
  defaultCompany?: Maybe<Company>
  /** default company for the user (only for UI) */
  defaultCompanyId?: Maybe<Scalars['ID']['output']>
  email: Scalars['EmailAddress']['output']
  /** can use this to determine if user has ever logged in before */
  firstLogin?: Maybe<Scalars['DateTime']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isAdmin?: Maybe<Scalars['Boolean']['output']>
  /**
   * Shows if MFA is enabled for the user. It can be null for SSO-signed up users
   * because we don't know if the user has MFA enabled in that Identity Provider
   */
  isMfaEnabled?: Maybe<Scalars['Boolean']['output']>
  isPalqeeManager?: Maybe<Scalars['Boolean']['output']>
  isRegistrationComplete?: Maybe<Scalars['Boolean']['output']>
  isSelected?: Maybe<Scalars['Boolean']['output']>
  language?: Maybe<LanguageCodeEnum>
  lastLogin?: Maybe<Scalars['DateTime']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  passwordLastChangeAt?: Maybe<Scalars['DateTime']['output']>
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>
  positions: Array<UserPosition>
  roles: Array<UserRole>
  status: UserStatusEnum
  tenant: Tenant
  tenantId: Scalars['ID']['output']
  title?: Maybe<Scalars['String']['output']>
  userId: Scalars['ID']['output']
  vendorInfo?: Maybe<VendorInfo>
}

export type UserAudiencesArgs = {
  input?: InputMaybe<UserAudiencesInput>
}

export type UserPositionsArgs = {
  input?: InputMaybe<UserPositionsInput>
}

export type UserRolesArgs = {
  input?: InputMaybe<UserRolesInput>
}

export type UserAudiencesFilterArg = {
  audiencesIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserAudiencesInput = {
  filter?: InputMaybe<UserAudiencesFilterArg>
}

export type UserCreateInput = {
  companyId: Scalars['ID']['input']
  defaultCompanyId?: InputMaybe<Scalars['ID']['input']>
  email: Scalars['EmailAddress']['input']
  firstName: Scalars['String']['input']
  language?: InputMaybe<LanguageCodeEnum>
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  /** in order this field to work you have to provide `companyId` parameter */
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  sendRegistrationEmail?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UserCreatePayload = {
  __typename?: 'UserCreatePayload'
  codeDeliveryInfo?: Maybe<CodeDeliveryInfo>
  query: Query
  user: User
}

export type UserDeleteFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  emails?: InputMaybe<Array<Scalars['EmailAddress']['input']>>
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  statuses?: InputMaybe<Array<UserStatusEnum>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserDeleteInput = {
  allowDeleteCurrentUser?: Scalars['Boolean']['input']
  filter: UserDeleteFilterArg
  /** Transwer files/folders ownership to this user (by default - current user) */
  transferOwnershipToUserId?: InputMaybe<Scalars['ID']['input']>
}

export type UserDeletePayload = {
  __typename?: 'UserDeletePayload'
  /** Deleted companies - nested fields are not availabe!!! */
  companies: Array<Company>
  query: Query
  /** Deleted users - nested fields are not availabe!!! */
  users: Array<User>
}

export type UserGetExcludeArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserGetFilterArg = {
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  emails?: InputMaybe<Array<Scalars['EmailAddress']['input']>>
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  statuses?: InputMaybe<Array<UserStatusEnum>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserGetInput = {
  exclude?: InputMaybe<UserGetExcludeArg>
  filter?: InputMaybe<UserGetFilterArg>
  order?: InputMaybe<Array<UserGetOrderArg>>
  pagination?: InputMaybe<PaginationArg>
  search?: InputMaybe<UserGetSearchArg>
}

export type UserGetOrderArg = {
  by: UserGetOrderEnum
  desc?: Scalars['Boolean']['input']
}

export enum UserGetOrderEnum {
  CreatedAt = 'createdAt',
  DepartmentName = 'departmentName',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  PositionName = 'positionName',
  Status = 'status',
}

export type UserGetPayload = {
  __typename?: 'UserGetPayload'
  paginationInfo: PaginationInfo
  query: Query
  users: Array<User>
}

export type UserGetSearchArg = {
  /** see `pattern` docs */
  byDepartmentName?: Scalars['Boolean']['input']
  /** see `pattern` docs */
  byEmail?: Scalars['Boolean']['input']
  /** see `pattern` docs */
  byFirstName?: Scalars['Boolean']['input']
  /** see `pattern` docs */
  byLastName?: Scalars['Boolean']['input']
  /** see `pattern` docs */
  byPositionName?: Scalars['Boolean']['input']
  /**
   * **AND-search**
   *
   * **ALL** of these conditions are applied to the user entry, so the user must
   * match patterns from **ALL** specified `conditions`
   */
  conditions?: InputMaybe<Array<UserGetSearchConditionItem>>
  /**
   * **OR-search**
   *
   * A user matches this `pattern` if **ANY** of the fields selected by flags:
   *  - `byEmail`
   *  - `byFirstName`
   *  - `byLastName`
   *  - `byDepartmentName`
   *  - `byPositionName`
   *
   * matches the `pattern`
   */
  pattern?: InputMaybe<Scalars['String']['input']>
}

export type UserGetSearchConditionItem = {
  field: UserGetSearchFieldEnum
  pattern: Scalars['String']['input']
}

export enum UserGetSearchFieldEnum {
  DepartmentName = 'departmentName',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  PositionName = 'positionName',
  VendorName = 'vendorName',
}

export type UserPosition = {
  __typename?: 'UserPosition'
  department: Department
  departmentId: Scalars['ID']['output']
  position: Position
  positionId: Scalars['ID']['output']
}

export type UserPositionFilterArg = {
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserPositionSetInput = {
  departmentId: Scalars['ID']['input']
  positionId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type UserPositionSetPayload = {
  __typename?: 'UserPositionSetPayload'
  query: Query
  user: User
}

export type UserPositionsInput = {
  filter?: InputMaybe<UserPositionFilterArg>
}

/**
 * Security role attached to the user
 * Most fields contains COPIES of this.role
 */
export type UserRole = {
  __typename?: 'UserRole'
  code: AuthRoleCodeEnum
  company?: Maybe<Company>
  companyId?: Maybe<Scalars['ID']['output']>
  name: Scalars['String']['output']
  role?: Maybe<AuthRole>
  roleId: Scalars['ID']['output']
  uiCode: Scalars['UICode']['output']
  userId: Scalars['ID']['output']
}

export type UserRolesFilterArg = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type UserRolesInput = {
  filter?: InputMaybe<UserRolesFilterArg>
}

export type UserRolesLinkInput = {
  /** The target company you want to link the roles to. */
  companyId: Scalars['ID']['input']
  /**
   * When any user is already linked to the role you are trying to link.
   *
   * Pick the API behavior: THROW or IGNORE.
   * Default behavior is to IGNORE.
   */
  onRoleAlreadyLinkedBehavior?: InputMaybe<OnErrorBehaviorEnum>
  roleCodes: Array<AuthRoleCodeEnum>
  users: UserRolesLinkUsersArg
}

export type UserRolesLinkPayload = {
  __typename?: 'UserRolesLinkPayload'
  query: Query
  users?: Maybe<Array<User>>
}

export type UserRolesLinkUsersArg = {
  exclude?: InputMaybe<UserRolesLinkUsersExcludeArg>
  filter: UserRolesLinkUsersFilterArg
}

export type UserRolesLinkUsersExcludeArg = {
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserRolesLinkUsersFilterArg = {
  /**
   * Used to 'get all users from company'.
   *
   * This only **FILTER users**.
   *
   * One can provide any company here, but roles still are going to be linked to **{input.companyId}**.
   * That means if you provide company A here and company B in {input.companyId}, no roles will be linked.
   */
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserRolesSetInput = {
  companyId: Scalars['ID']['input']
  roleCodes: Array<AuthRoleCodeEnum>
  userId: Scalars['ID']['input']
}

export type UserRolesSetPayload = {
  __typename?: 'UserRolesSetPayload'
  query: Query
  user: User
}

export type UserRolesUnlinkInput = {
  /**
   * Whether the API should unlink or not roles from the current user.
   *
   * **Warning**: one is able to unlink roles from ALL users within company.
   * If this flag is true, that includes the current user.
   *
   * Default value is false.
   */
  allowRolesUnlinkCurrentUser?: Scalars['Boolean']['input']
  /** The target company you want to link the roles to. */
  companyId: Scalars['ID']['input']
  /**
   * When any provided user is not linked to the role you want to unlink.
   * Example: Users are only linked to EMPLOYEE role and you're trying to unlink VENDOR.
   *
   * Pick the API behavior: THROW or IGNORE.
   *
   * Default behavior is to IGNORE.
   */
  onRoleNotLinkedBehavior?: InputMaybe<OnErrorBehaviorEnum>
  roleCodes: Array<AuthRoleCodeEnum>
  users: UserRolesUnlinkUsersArg
}

export type UserRolesUnlinkPayload = {
  __typename?: 'UserRolesUnlinkPayload'
  query: Query
  users?: Maybe<Array<User>>
}

export type UserRolesUnlinkUsersArg = {
  exclude?: InputMaybe<UserRolesUnlinkUsersExcludeArg>
  filter: UserRolesUnlinkUsersFilterArg
}

export type UserRolesUnlinkUsersExcludeArg = {
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UserRolesUnlinkUsersFilterArg = {
  /**
   * Used to 'get all users from company'.
   *
   * This only **FILTER users**.
   *
   * One can provide any company here, but roles still are going to be unlinked from **{input.companyId}**.
   * That means if you provide company A here and company B in {input.companyId}, no roles will be unlinked.
   */
  companyIds?: InputMaybe<Array<Scalars['ID']['input']>>
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum UserStatusEnum {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Pending = 'PENDING',
  Removing = 'REMOVING',
}

/** @todo validate defaults (because it will prevent users from being created later) */
export type UserUpsertBulkPrepareUrlDefaultsArg = {
  defaultCompanyId?: InputMaybe<Scalars['ID']['input']>
  departmentName?: InputMaybe<Scalars['String']['input']>
  /** default language for the new users */
  language?: InputMaybe<LanguageCodeEnum>
  positionName?: InputMaybe<Scalars['String']['input']>
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  sendRegistrationEmail?: InputMaybe<Scalars['Boolean']['input']>
}

export type UserUpsertBulkPrepareUrlInput = {
  /** This field is ignored, we use it just to provide a link to the docs */
  _readme_csv_format?: InputMaybe<GqlDocs_CsvFileUpload>
  audienceIds?: InputMaybe<Array<Scalars['ID']['input']>>
  companyId: Scalars['ID']['input']
  defaults?: InputMaybe<UserUpsertBulkPrepareUrlDefaultsArg>
  /** Whether the bulk should UPSERT or only INSERT users. */
  disableUserUpdate?: InputMaybe<Scalars['Boolean']['input']>
}

export type UserUpsertBulkPrepareUrlPayload = {
  __typename?: 'UserUpsertBulkPrepareUrlPayload'
  headers: Array<HttpHeader>
  query: Query
  url: Scalars['URL']['output']
}

export type UserUpsertInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  defaultCompanyId?: InputMaybe<Scalars['ID']['input']>
  email?: InputMaybe<Scalars['EmailAddress']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  /**
   * in order this field to work (set roles for user) you **MUST**
   * provide the `companyId` parameter
   */
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum>>
  sendRegistrationEmail?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type UserUpsertPayload = {
  __typename?: 'UserUpsertPayload'
  codeDeliveryInfo?: Maybe<CodeDeliveryInfo>
  query: Query
  user: User
}

export type UserVendorInfoSetInput = {
  name: Scalars['String']['input']
  userId: Scalars['ID']['input']
}

export type UserVendorInfoSetPayload = {
  __typename?: 'UserVendorInfoSetPayload'
  query: Query
  user: User
  vendorInfo: VendorInfo
}

export type VendorInfo = {
  __typename?: 'VendorInfo'
  name?: Maybe<Scalars['String']['output']>
}

export type CompanyBasicWithSlugFragment = {
  __typename?: 'Company'
  id: string
  slug: string
  usersPrimaryEmailDomain?: string | null
}

export type IdentityApplicationsGetMainFragment = {
  __typename?: 'AuthIdentityApplicationsGetPayload'
  companies: Array<{
    __typename?: 'Company'
    id: string
    slug: string
    usersPrimaryEmailDomain?: string | null
  }>
  identityApplications: Array<{
    __typename?: 'AuthIdentityApplication'
    id: string
    identityProviderCode: AuthIdentityProviderCodeEnum
    name: string
    uriInit?: any | null
    uriLogout?: any | null
    uriProviderRedirect?: any | null
    specificConfig?: any | null
  }>
}

export type LoginIdentityApplicationsGetFragment = {
  __typename?: 'AuthIdentityApplicationsGetPayload'
  companies: Array<{
    __typename?: 'Company'
    name?: string | null
    logo?: {
      __typename?: 'BinaryFileInfoPublic'
      publicUrl?: string | null
    } | null
  }>
  identityApplications: Array<{
    __typename?: 'AuthIdentityApplication'
    identityProviderCode: AuthIdentityProviderCodeEnum
    uriInit?: any | null
  }>
}

export type AuthLoginConfirmMutationVariables = Exact<{
  input: AuthLoginConfirmInput
}>

export type AuthLoginConfirmMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    loginConfirm:
      | {
          __typename: 'AuthLoginNexChallengePayload'
          mfaChallengeKey: string
          mfaType: AuthMfaTypeEnum
        }
      | {
          __typename: 'AuthLoginSuccessPayload'
          accessToken: string
          refreshToken: string
          expiresAfterSeconds: any
          user: {
            __typename?: 'User'
            id: string
            firstName?: string | null
            lastName?: string | null
            email: any
            phoneNumber?: any | null
            lastLogin?: any | null
            isMfaEnabled?: boolean | null
            language?: LanguageCodeEnum | null
            passwordLastChangeAt?: any | null
            defaultCompanyId?: string | null
            tenantId: string
            roles: Array<{
              __typename?: 'UserRole'
              userId: string
              companyId?: string | null
              roleId: string
              code: AuthRoleCodeEnum
            }>
          }
        }
  }
}

export type AuthLoginMutationVariables = Exact<{
  username: Scalars['EmailAddress']['input']
  password: Scalars['String']['input']
}>

export type AuthLoginMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    login:
      | {
          __typename: 'AuthLoginNexChallengePayload'
          mfaChallengeKey: string
          mfaType: AuthMfaTypeEnum
        }
      | {
          __typename: 'AuthLoginSuccessPayload'
          accessToken: string
          refreshToken: string
          expiresAfterSeconds: any
          user: {
            __typename?: 'User'
            id: string
            firstName?: string | null
            lastName?: string | null
            email: any
            phoneNumber?: any | null
            lastLogin?: any | null
            isMfaEnabled?: boolean | null
            passwordLastChangeAt?: any | null
            language?: LanguageCodeEnum | null
            defaultCompany?: { __typename?: 'Company'; id: string } | null
            tenant: { __typename?: 'Tenant'; id: string }
            companies: Array<{
              __typename?: 'Company'
              id: string
              regions: Array<{ __typename?: 'Region'; id: string }>
            }>
            roles: Array<{
              __typename?: 'UserRole'
              userId: string
              name: string
              roleId: string
              code: AuthRoleCodeEnum
              companyId?: string | null
            }>
          }
        }
  }
}

export type AuthLogoutMutationVariables = Exact<{ [key: string]: never }>

export type AuthLogoutMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    logout: { __typename?: 'AuthLogoutPayload'; nothing?: any | null }
  }
}

export type AuthMfaConfigureConfirmMutationVariables = Exact<{
  mfaType: AuthMfaTypeEnum
  confirmationCode: Scalars['String']['input']
}>

export type AuthMfaConfigureConfirmMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    mfaConfigureConfirm: {
      __typename?: 'AuthMfaConfigureConfirmPayload'
      nothing?: any | null
      user: { __typename?: 'User'; id: string; email: any }
    }
  }
}

export type AuthMfaConfigureMutationVariables = Exact<{
  mfaType: AuthMfaTypeEnum
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
}>

export type AuthMfaConfigureMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    mfaConfigure: {
      __typename?: 'AuthMfaConfigurePayload'
      secretKey?: string | null
      user: { __typename?: 'User'; id: string; email: any }
    }
  }
}

export type AuthMfaDisableMutationVariables = Exact<{ [key: string]: never }>

export type AuthMfaDisableMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    mfaDisable: { __typename?: 'AuthMfaDisablePayload'; nothing?: any | null }
  }
}

export type AuthPasswordChangeMutationVariables = Exact<{
  oldPassword: Scalars['String']['input']
  newPassword: Scalars['String']['input']
  companyId: Scalars['ID']['input']
}>

export type AuthPasswordChangeMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    passwordChange: {
      __typename?: 'AuthPasswordChangePayload'
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        phoneNumber?: any | null
        passwordLastChangeAt?: any | null
        isMfaEnabled?: boolean | null
        email: any
        isAdmin?: boolean | null
        isPalqeeManager?: boolean | null
        isRegistrationComplete?: boolean | null
        status: UserStatusEnum
        language?: LanguageCodeEnum | null
        defaultCompanyId?: string | null
        positions: Array<{
          __typename?: 'UserPosition'
          departmentId: string
          positionId: string
          position: {
            __typename?: 'Position'
            id: string
            name: string
            companyId: string
          }
          department: {
            __typename?: 'Department'
            id: string
            name: string
            companyId: string
          }
        }>
        defaultCompany?: {
          __typename?: 'Company'
          id: string
          name?: string | null
          numberOfEmployees: any
          industries: Array<{
            __typename?: 'Industry'
            id: string
            name: string
          }>
          address?: {
            __typename?: 'Address'
            id: string
            country: { __typename?: 'Country'; id: string; name: string }
          } | null
        } | null
        companies: Array<{
          __typename?: 'Company'
          id: string
          name?: string | null
          logo?: {
            __typename?: 'BinaryFileInfoPublic'
            publicUrl?: string | null
            id: string
            isPublic: boolean
            originalFilename?: string | null
            mimetype: string
            url: string
          } | null
          currentSubscriptionTier?: {
            __typename?: 'SubscriptionTier'
            id: string
            name: string
            features?: Array<
              | {
                  __typename?: 'SubscriptionFeatureBoolean'
                  available: boolean
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
              | {
                  __typename?: 'SubscriptionFeatureIntRange'
                  minValue?: number | null
                  maxValue?: number | null
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
            > | null
          } | null
        }>
        roles: Array<{
          __typename?: 'UserRole'
          userId: string
          roleId: string
          name: string
          uiCode: any
          companyId?: string | null
          code: AuthRoleCodeEnum
        }>
        tenant: {
          __typename?: 'Tenant'
          hasPermanentAccess: boolean
          trialExpiredAt?: any | null
        }
      }
    }
  }
}

export type AuthPasswordRecoverConfirmMutationVariables = Exact<{
  newPassword: Scalars['String']['input']
  confirmationCode: Scalars['String']['input']
  confirmationToken: Scalars['String']['input']
}>

export type AuthPasswordRecoverConfirmMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    passwordRestoreConfirm: {
      __typename?: 'AuthPasswordRestoreConfirmPayload'
      nothing?: any | null
    }
  }
}

export type AuthPasswordRecoverInitMutationVariables = Exact<{
  username: Scalars['EmailAddress']['input']
}>

export type AuthPasswordRecoverInitMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    passwordRestoreInit: { __typename: 'AuthPasswordRestoreInitPayload' }
  }
}

export type AuthSignUpConfirmMutationVariables = Exact<{
  confirmationCode: Scalars['String']['input']
  confirmationToken: Scalars['String']['input']
}>

export type AuthSignUpConfirmMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    signUpConfirm: {
      __typename?: 'AuthSignUpConfirmPayload'
      user: { __typename?: 'User'; id: string }
    }
  }
}

export type AuthSignUpResendCodeMutationVariables = Exact<{
  usernames:
    | Array<Scalars['EmailAddress']['input']>
    | Scalars['EmailAddress']['input']
}>

export type AuthSignUpResendCodeMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    signUpResendCode: {
      __typename?: 'AuthSignUpResendCodePayload'
      nothing?: any | null
    }
  }
}

export type AuthSignUpResendCodeToPendingUsersMutationVariables = Exact<{
  companyIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type AuthSignUpResendCodeToPendingUsersMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    signUpResendCodeToPendingUsers: {
      __typename?: 'AuthSignUpResendCodeToPendingUsersPayload'
      nothing?: any | null
    }
  }
}

export type AuthSignUpMutationVariables = Exact<{
  username: Scalars['EmailAddress']['input']
  password: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  positionName?: InputMaybe<Scalars['String']['input']>
  departmentName?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<LanguageCodeEnum>
}>

export type AuthSignUpMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    signUp: {
      __typename?: 'AuthSignUpPayload'
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        email: any
        phoneNumber?: any | null
        language?: LanguageCodeEnum | null
        lastLogin?: any | null
        positions: Array<{
          __typename?: 'UserPosition'
          position: {
            __typename?: 'Position'
            id: string
            name: string
            description?: string | null
          }
        }>
        roles: Array<{
          __typename?: 'UserRole'
          userId: string
          code: AuthRoleCodeEnum
          companyId?: string | null
          roleId: string
          uiCode: any
        }>
      }
    }
  }
}

export type AuthTokenRefreshMutationVariables = Exact<{
  refreshToken: Scalars['String']['input']
}>

export type AuthTokenRefreshMutation = {
  __typename?: 'Mutation'
  auth: {
    __typename?: 'NamespaceAuthMutation'
    tokenRefresh: {
      __typename: 'AuthTokenRefreshPayload'
      accessToken: string
      refreshToken: string
      expiresAfterSeconds: any
    }
  }
}

export type CompanySelectedRegulationFragment = {
  __typename?: 'Regulation'
  id: string
  regulationId: string
  name: string
  uiCode?: any | null
  logoPath?: string | null
  description?: string | null
}

export type DepartmentFragment = {
  __typename?: 'Department'
  id: string
  name: string
  description?: string | null
}

type CompanyBinaryFileInfo_BinaryFileInfo_Fragment = {
  __typename?: 'BinaryFileInfo'
  id: string
  isPublic: boolean
  originalFilename?: string | null
  mimetype: string
  url: string
}

type CompanyBinaryFileInfo_BinaryFileInfoPublic_Fragment = {
  __typename?: 'BinaryFileInfoPublic'
  publicUrl?: string | null
  id: string
  isPublic: boolean
  originalFilename?: string | null
  mimetype: string
  url: string
}

export type CompanyBinaryFileInfoFragment =
  | CompanyBinaryFileInfo_BinaryFileInfo_Fragment
  | CompanyBinaryFileInfo_BinaryFileInfoPublic_Fragment

export type CompanyMainFragment = {
  __typename?: 'Company'
  id: string
  name?: string | null
  logo?: {
    __typename?: 'BinaryFileInfoPublic'
    publicUrl?: string | null
    id: string
    isPublic: boolean
    originalFilename?: string | null
    mimetype: string
    url: string
  } | null
}

export type CompanyFullDetailsFragment = {
  __typename?: 'Company'
  numberOfEmployees: any
  id: string
  name?: string | null
  structure: {
    __typename?: 'CompanyStructure'
    id: string
    name: string
    uiCode?: any | null
  }
  address?: {
    __typename?: 'Address'
    id: string
    streetAddress?: string | null
    streetAddress2?: string | null
    locality?: string | null
    subAdministrativeArea?: string | null
    administrativeArea?: string | null
    postalCode?: any | null
    country: { __typename?: 'Country'; id: string; name: string }
  } | null
  industries: Array<{ __typename?: 'Industry'; id: string; name: string }>
  regions: Array<{ __typename?: 'Region'; id: string; name: string }>
  logo?: {
    __typename?: 'BinaryFileInfoPublic'
    publicUrl?: string | null
    id: string
    isPublic: boolean
    originalFilename?: string | null
    mimetype: string
    url: string
  } | null
  currentSubscriptionTier?: {
    __typename?: 'SubscriptionTier'
    id: string
    name: string
    features?: Array<
      | {
          __typename?: 'SubscriptionFeatureBoolean'
          available: boolean
          id: string
          name: string
          code: string
          description?: string | null
        }
      | {
          __typename?: 'SubscriptionFeatureIntRange'
          minValue?: number | null
          maxValue?: number | null
          id: string
          name: string
          code: string
          description?: string | null
        }
    > | null
  } | null
}

export type CompanyBasicFragment = {
  __typename?: 'Company'
  id: string
  name?: string | null
  numberOfEmployees: any
  industries: Array<{ __typename?: 'Industry'; id: string; name: string }>
  address?: {
    __typename?: 'Address'
    id: string
    streetAddress?: string | null
    streetAddress2?: string | null
    locality?: string | null
    subAdministrativeArea?: string | null
    administrativeArea?: string | null
    postalCode?: any | null
    country: { __typename?: 'Country'; id: string; name: string }
  } | null
  regions: Array<{ __typename?: 'Region'; id: string; name: string }>
  logo?: {
    __typename?: 'BinaryFileInfoPublic'
    publicUrl?: string | null
    id: string
    isPublic: boolean
    originalFilename?: string | null
    mimetype: string
    url: string
  } | null
  currentSubscriptionTier?: {
    __typename?: 'SubscriptionTier'
    id: string
    name: string
    features?: Array<
      | {
          __typename?: 'SubscriptionFeatureBoolean'
          available: boolean
          id: string
          name: string
          code: string
          description?: string | null
        }
      | {
          __typename?: 'SubscriptionFeatureIntRange'
          minValue?: number | null
          maxValue?: number | null
          id: string
          name: string
          code: string
          description?: string | null
        }
    > | null
  } | null
}

export type CompanyAssetConnectionCreateMutationVariables = Exact<{
  input: CompanyAssetConnectionCreateInput
}>

export type CompanyAssetConnectionCreateMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    assetConnectionCreate: {
      __typename?: 'CompanyAssetConnectionCreatePayload'
      assetConnection: {
        __typename?: 'AssetConnection'
        id: string
        asset?: { __typename?: 'Asset'; id: string; name: string } | null
      }
    }
  }
}

export type CompanyAudienceCreateMutationVariables = Exact<{
  input: CompanyAudienceCreateInput
}>

export type CompanyAudienceCreateMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    audienceCreate: {
      __typename?: 'CompanyAudienceCreatePayload'
      audience: {
        __typename?: 'Audience'
        id: string
        name: string
        createdAt: any
        updatedAt?: any | null
      }
    }
  }
}

export type CompanyAudienceDeleteMutationVariables = Exact<{
  filter?: InputMaybe<CompanyAudienceDeleteFilterArg>
}>

export type CompanyAudienceDeleteMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    audienceDelete: {
      __typename?: 'CompanyAudienceDeletePayload'
      audiences: Array<{
        __typename?: 'Audience'
        id: string
        audienceId: string
        name: string
        description?: string | null
        users: Array<{ __typename?: 'User'; id: string; email: any }>
        company: { __typename?: 'Company'; id: string }
      }>
    }
  }
}

export type CompanyAudienceUpdateMutationVariables = Exact<{
  audienceId: Scalars['ID']['input']
  name: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
}>

export type CompanyAudienceUpdateMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    audienceUpdate: {
      __typename?: 'CompanyAudienceUpdatePayload'
      audience: {
        __typename?: 'Audience'
        audienceId: string
        name: string
        description?: string | null
        users: Array<{ __typename?: 'User'; id: string; email: any }>
        company: { __typename?: 'Company'; id: string }
      }
    }
  }
}

export type CompanyAudienceUsersLinkMutationVariables = Exact<{
  audienceId: Scalars['ID']['input']
  users: CompanyAudienceUsersLinkUsersArg
}>

export type CompanyAudienceUsersLinkMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    audienceUsersLink: {
      __typename?: 'CompanyAudienceUsersLinkPayload'
      audience: {
        __typename?: 'Audience'
        id: string
        audienceId: string
        name: string
        description?: string | null
        users: Array<{ __typename?: 'User'; id: string; email: any }>
        company: { __typename?: 'Company'; id: string }
      }
    }
  }
}

export type CompanyAudienceUsersUnlinkMutationVariables = Exact<{
  audienceId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type CompanyAudienceUsersUnlinkMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    audienceUsersUnlink: {
      __typename?: 'CompanyAudienceUsersUnlinkPayload'
      audience: {
        __typename?: 'Audience'
        audienceId: string
        name: string
        description?: string | null
        users: Array<{ __typename?: 'User'; id: string; email: any }>
        company: { __typename?: 'Company'; id: string }
      }
    }
  }
}

export type CompanyCreateMutationVariables = Exact<{
  name: Scalars['String']['input']
  typeId: Scalars['ID']['input']
  structureId: Scalars['ID']['input']
  numberOfEmployees: Scalars['NonNegativeInt']['input']
  annualSalesRevenueId: Scalars['ID']['input']
  currencyId: Scalars['ID']['input']
  isSubscriptionTrialEnabled: Scalars['Boolean']['input']
}>

export type CompanyCreateMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    create: {
      __typename?: 'CompanyCreatePayload'
      company: {
        __typename?: 'Company'
        id: string
        name?: string | null
        numberOfEmployees: any
      }
    }
  }
}

export type CompanyDeleteMutationVariables = Exact<{
  filter: CompanyDeleteFilterArgument
}>

export type CompanyDeleteMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    delete: {
      __typename?: 'CompanyDeletePayload'
      companies: Array<{
        __typename?: 'Company'
        id: string
        name?: string | null
      }>
    }
  }
}

export type CompanyIndustriesSetMutationVariables = Exact<{
  companyId: Scalars['ID']['input']
  industryIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type CompanyIndustriesSetMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    industriesSet: {
      __typename?: 'CompanyIndustriesSetPayload'
      company: { __typename?: 'Company'; id: string }
    }
  }
}

export type CompanyLogoSetMutationVariables = Exact<{
  file: Scalars['Upload']['input']
  companyId: Scalars['ID']['input']
}>

export type CompanyLogoSetMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    logoSet: {
      __typename?: 'CompanyLogoSetPayload'
      logo:
        | {
            __typename?: 'BinaryFileInfo'
            id: string
            isPublic: boolean
            originalFilename?: string | null
            mimetype: string
            url: string
          }
        | {
            __typename?: 'BinaryFileInfoPublic'
            publicUrl?: string | null
            id: string
            isPublic: boolean
            originalFilename?: string | null
            mimetype: string
            url: string
          }
    }
  }
}

export type CompanyOnboardingStepsSaveMutationVariables = Exact<{
  companyId: Scalars['ID']['input']
  stepCode: OnboardingProgressStepCodeEnum
  isCompleted: Scalars['Boolean']['input']
}>

export type CompanyOnboardingStepsSaveMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    onboardingStepsSave: {
      __typename?: 'CompanyOnboardingStepsSavePayload'
      stepCodes: Array<OnboardingProgressStepCodeEnum>
    }
  }
}

export type CompanyProcessCreateMutationVariables = Exact<{
  name: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  companyId: Scalars['ID']['input']
  processTemplateId?: InputMaybe<Scalars['ID']['input']>
}>

export type CompanyProcessCreateMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    processCreate: {
      __typename?: 'CompanyProcessCreatePayload'
      process: {
        __typename?: 'Process'
        id: string
        processTemplate?: {
          __typename?: 'ProcessTemplate'
          id: string
          name: string
        } | null
      }
    }
  }
}

export type CompanyRegulationLinkMutationVariables = Exact<{
  companyId: Scalars['ID']['input']
  regulationIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
}>

export type CompanyRegulationLinkMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    regulationLink: {
      __typename?: 'CompanyRegulationLinkPayload'
      company: {
        __typename?: 'Company'
        id: string
        selectedRegulations: Array<{
          __typename?: 'Regulation'
          id: string
          regulationId: string
          name: string
          uiCode?: any | null
          logoPath?: string | null
          description?: string | null
        }>
        currentSubscriptionTier?: {
          __typename?: 'SubscriptionTier'
          id: string
          name: string
          features?: Array<
            | {
                __typename?: 'SubscriptionFeatureBoolean'
                available: boolean
                id: string
                name: string
                code: string
                description?: string | null
              }
            | {
                __typename?: 'SubscriptionFeatureIntRange'
                minValue?: number | null
                maxValue?: number | null
                id: string
                name: string
                code: string
                description?: string | null
              }
          > | null
        } | null
      }
    }
  }
}

export type RepresentativeAddressSetMutationVariables = Exact<{
  representativeId: Scalars['ID']['input']
  address?: InputMaybe<AddressArg>
}>

export type RepresentativeAddressSetMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    representativeAddressSet: {
      __typename?: 'CompanyRepresentativeAddressSetPayload'
      representative: { __typename?: 'Representative'; id: string }
    }
  }
}

export type RepresentativeCreateMutationVariables = Exact<{
  companyId: Scalars['ID']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['EmailAddress']['input']>
  phoneNumber?: InputMaybe<Scalars['SloppyPhoneNumber']['input']>
  representativeGroup: RepresentativeGroupEnum
}>

export type RepresentativeCreateMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    representativeCreate: {
      __typename?: 'CompanyRepresentativeCreatePayload'
      representative: { __typename?: 'Representative'; id: string }
    }
  }
}

export type RepresentativeDeleteMutationVariables = Exact<{
  representativeIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
  companyIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
  representativeGroups?: InputMaybe<
    Array<RepresentativeGroupEnum> | RepresentativeGroupEnum
  >
}>

export type RepresentativeDeleteMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    representativeDelete: {
      __typename?: 'CompanyRepresentativeDeletePayload'
      representatives: Array<{ __typename?: 'Representative'; id: string }>
    }
  }
}

export type RepresentativeUpdateMutationVariables = Exact<{
  representativeId: Scalars['ID']['input']
  companyId: Scalars['ID']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['EmailAddress']['input']>
  phoneNumber?: InputMaybe<Scalars['SloppyPhoneNumber']['input']>
  representativeGroup: RepresentativeGroupEnum
}>

export type RepresentativeUpdateMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    representativeUpdate: {
      __typename?: 'CompanyRepresentativeUpdatePayload'
      representative: { __typename?: 'Representative'; id: string }
    }
  }
}

export type CompanyUpdateMutationVariables = Exact<{
  companyId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  typeId?: InputMaybe<Scalars['ID']['input']>
  structureId?: InputMaybe<Scalars['ID']['input']>
  numberOfEmployees?: InputMaybe<Scalars['NonNegativeInt']['input']>
  annualSalesRevenueId?: InputMaybe<Scalars['ID']['input']>
  currencyId?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}>

export type CompanyUpdateMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    update: {
      __typename?: 'CompanyUpdatePayload'
      company: {
        __typename?: 'Company'
        id: string
        name?: string | null
        slug: string
      }
    }
  }
}

export type CompanyAudiencesGetQueryVariables = Exact<{
  input: CompanyAudiencesGetInput
  companyId: Scalars['ID']['input']
}>

export type CompanyAudiencesGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    audiencesGet: {
      __typename?: 'CompanyAudiencesGetPayload'
      audiences: Array<{
        __typename?: 'Audience'
        id: string
        name: string
        description?: string | null
        isSingleUser: boolean
        updatedAt?: any | null
        createdAt: any
        computingMethod: CompanyAudienceComputingMethodEnum
        users: Array<{
          __typename?: 'User'
          id: string
          userId: string
          firstName?: string | null
          lastName?: string | null
          email: any
          status: UserStatusEnum
          roles: Array<{
            __typename?: 'UserRole'
            userId: string
            roleId: string
            name: string
            companyId?: string | null
            code: AuthRoleCodeEnum
          }>
          positions: Array<{
            __typename?: 'UserPosition'
            position: {
              __typename?: 'Position'
              id: string
              name: string
              companyId: string
            }
            department: {
              __typename?: 'Department'
              id: string
              name: string
              companyId: string
            }
          }>
        }>
      }>
      paginationInfo: {
        __typename?: 'PaginationInfo'
        totalItems: any
        totalPages: any
      }
    }
  }
}

export type CompanyCompanyGetQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type CompanyCompanyGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    get: {
      __typename?: 'CompanyGetPayload'
      company: {
        __typename?: 'Company'
        id: string
        name?: string | null
        numberOfEmployees: any
        currentSubscriptionTier?: {
          __typename?: 'SubscriptionTier'
          id: string
          name: string
          features?: Array<
            | {
                __typename?: 'SubscriptionFeatureBoolean'
                available: boolean
                id: string
                name: string
                code: string
                description?: string | null
              }
            | {
                __typename?: 'SubscriptionFeatureIntRange'
                minValue?: number | null
                maxValue?: number | null
                id: string
                name: string
                code: string
                description?: string | null
              }
          > | null
        } | null
        representatives: Array<{
          __typename?: 'Representative'
          id: string
          firstName?: string | null
          lastName?: string | null
          email?: any | null
          phoneNumber?: any | null
          representativeGroup: RepresentativeGroupEnum
          address?: {
            __typename?: 'Address'
            id: string
            streetAddress?: string | null
            locality?: string | null
            administrativeArea?: string | null
            postalCode?: any | null
            country: { __typename?: 'Country'; id: string; name: string }
          } | null
        }>
        audiences: Array<{
          __typename?: 'Audience'
          id: string
          name: string
          description?: string | null
          isSingleUser: boolean
          updatedAt?: any | null
          users: Array<{
            __typename?: 'User'
            id: string
            firstName?: string | null
            lastName?: string | null
            status: UserStatusEnum
            email: any
            roles: Array<{
              __typename?: 'UserRole'
              userId: string
              companyId?: string | null
              roleId: string
              name: string
              code: AuthRoleCodeEnum
            }>
            positions: Array<{
              __typename?: 'UserPosition'
              position: {
                __typename?: 'Position'
                id: string
                name: string
                companyId: string
              }
              department: {
                __typename?: 'Department'
                id: string
                name: string
                companyId: string
              }
            }>
          }>
        }>
        type: { __typename?: 'CompanyType'; id: string; name?: string | null }
        logo?: {
          __typename?: 'BinaryFileInfoPublic'
          publicUrl?: string | null
          id: string
          isPublic: boolean
          originalFilename?: string | null
          mimetype: string
          url: string
        } | null
        structure: { __typename?: 'CompanyStructure'; id: string; name: string }
        industries: Array<{ __typename?: 'Industry'; id: string; name: string }>
        regions: Array<{ __typename?: 'Region'; id: string; name: string }>
        annualSalesRevenue: {
          __typename?: 'AnnualSalesRevenue'
          id: string
          minValue?: number | null
          maxValue?: number | null
        }
        currency?: {
          __typename?: 'Currency'
          id: string
          isoCode: string
        } | null
        address?: {
          __typename?: 'Address'
          id: string
          streetAddress?: string | null
          streetAddress2?: string | null
          locality?: string | null
          subAdministrativeArea?: string | null
          administrativeArea?: string | null
          postalCode?: any | null
          country: { __typename?: 'Country'; id: string; name: string }
        } | null
        tenant: { __typename?: 'Tenant'; id: string }
      }
    }
  }
}

export type CompanyDepartmentsGetQueryVariables = Exact<{
  filter?: InputMaybe<DepartmentFilterArg>
}>

export type CompanyDepartmentsGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    departmentsGet: {
      __typename?: 'CompanyDepartmentsGetPayload'
      departments: Array<{
        __typename?: 'Department'
        id: string
        name: string
        description?: string | null
      }>
    }
  }
}

export type CompanyFeaturesGetQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type CompanyFeaturesGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    companyFeaturesGet: {
      __typename?: 'CompanyFeaturesGetPayload'
      features: Array<
        | {
            __typename?: 'SubscriptionFeatureBoolean'
            available: boolean
            id: string
            subscriptionFeatureId: string
            name: string
            description?: string | null
            uiCode?: any | null
            code: string
          }
        | {
            __typename?: 'SubscriptionFeatureIntRange'
            minValue?: number | null
            maxValue?: number | null
            id: string
            subscriptionFeatureId: string
            name: string
            description?: string | null
            uiCode?: any | null
            code: string
          }
      >
    }
  }
}

export type CompanyOnboardingStepsGetQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type CompanyOnboardingStepsGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    onboardingStepsGet: {
      __typename?: 'CompanyOnboardingStepsGetPayload'
      stepCodes: Array<OnboardingProgressStepCodeEnum>
    }
  }
}

export type CompanyPositionsGetQueryVariables = Exact<{
  filter?: InputMaybe<PositionFilterArg>
}>

export type CompanyPositionsGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    positionsGet: {
      __typename?: 'CompanyPositionsGetPayload'
      positions: Array<{
        __typename?: 'Position'
        id: string
        name: string
        description?: string | null
      }>
    }
  }
}

export type CompanyProcessesGetQueryVariables = Exact<{
  filter?: InputMaybe<ProcessesGetFilterArg>
}>

export type CompanyProcessesGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    processesGet: {
      __typename?: 'CompanyProcessesGetPayload'
      processes: Array<{ __typename?: 'Process'; id: string; name: string }>
    }
  }
}

export type CompanyRepresentativesGetQueryVariables = Exact<{
  filter?: InputMaybe<RepresentativeFilterArg>
}>

export type CompanyRepresentativesGetQuery = {
  __typename?: 'Query'
  company: {
    __typename?: 'NamespaceCompanyQuery'
    representativesGet: {
      __typename?: 'CompanyRepresentativesGetPayload'
      representatives: Array<{
        __typename?: 'Representative'
        id: string
        firstName?: string | null
        lastName?: string | null
        email?: any | null
        phoneNumber?: any | null
        address?: {
          __typename?: 'Address'
          id: string
          streetAddress?: string | null
          locality?: string | null
          administrativeArea?: string | null
          postalCode?: any | null
          country: { __typename?: 'Country'; id: string; name: string }
        } | null
      }>
    }
  }
}

export type DocumentFieldsFragment = {
  __typename?: 'DocboxDocument'
  id: string
  documentId: string
  folderId: string
  name: string
  description?: string | null
  ownerId: string
  createdAt: any
  createdByUserId: string
  validUntil?: any | null
  createdByUser: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    roles: Array<{
      __typename?: 'UserRole'
      userId: string
      roleId: string
      name: string
      uiCode: any
      companyId?: string | null
      code: AuthRoleCodeEnum
    }>
  }
  fileInfo?: {
    __typename?: 'BinaryFileInfo'
    id: string
    binaryFileInfoId: string
    originalFilename?: string | null
    mimetype: string
    contentLength?: any | null
    url: string
  } | null
}

export type FileInfoFragment = {
  __typename?: 'BinaryFileInfo'
  id: string
  binaryFileInfoId: string
  originalFilename?: string | null
  mimetype: string
  contentLength?: any | null
  url: string
}

export type DocumentCreateMutationVariables = Exact<{
  name: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  folderId?: InputMaybe<Scalars['ID']['input']>
  companyId?: InputMaybe<Scalars['ID']['input']>
  ownerId?: InputMaybe<Scalars['ID']['input']>
  file: Scalars['Upload']['input']
  fileUrlInput?: InputMaybe<BinaryFileInfoUrlInput>
  validUntil?: InputMaybe<Scalars['DateTime']['input']>
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
}>

export type DocumentCreateMutation = {
  __typename?: 'Mutation'
  document: {
    __typename?: 'NamespaceDocumentMutation'
    create: {
      __typename?: 'DocboxDocumentCreatePayload'
      document: {
        __typename?: 'DocboxDocument'
        id: string
        documentId: string
        folderId: string
        name: string
        description?: string | null
        ownerId: string
        createdAt: any
        createdByUserId: string
        validUntil?: any | null
        createdByUser: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
          roles: Array<{
            __typename?: 'UserRole'
            userId: string
            roleId: string
            name: string
            uiCode: any
            companyId?: string | null
            code: AuthRoleCodeEnum
          }>
        }
        fileInfo?: {
          __typename?: 'BinaryFileInfo'
          id: string
          binaryFileInfoId: string
          originalFilename?: string | null
          mimetype: string
          contentLength?: any | null
          url: string
        } | null
      }
    }
  }
}

export type DocumentDeleteMutationVariables = Exact<{
  documentIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
}>

export type DocumentDeleteMutation = {
  __typename?: 'Mutation'
  document: {
    __typename?: 'NamespaceDocumentMutation'
    delete: {
      __typename?: 'DocboxDocumentDeletePayload'
      documents: Array<{ __typename?: 'DocboxDocument'; id: string }>
    }
  }
}

export type DocumentUpdateMutationVariables = Exact<{
  documentId: Scalars['ID']['input']
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  folderId?: InputMaybe<Scalars['ID']['input']>
  ownerId?: InputMaybe<Scalars['ID']['input']>
  validUntil?: InputMaybe<Scalars['DateTime']['input']>
  fileUrlInput?: InputMaybe<BinaryFileInfoUrlInput>
}>

export type DocumentUpdateMutation = {
  __typename?: 'Mutation'
  document: {
    __typename?: 'NamespaceDocumentMutation'
    update: {
      __typename?: 'DocboxDocumentUpdatePayload'
      document: {
        __typename?: 'DocboxDocument'
        id: string
        documentId: string
        folderId: string
        name: string
        description?: string | null
        ownerId: string
        createdAt: any
        createdByUserId: string
        validUntil?: any | null
        createdByUser: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
          roles: Array<{
            __typename?: 'UserRole'
            userId: string
            roleId: string
            name: string
            uiCode: any
            companyId?: string | null
            code: AuthRoleCodeEnum
          }>
        }
        fileInfo?: {
          __typename?: 'BinaryFileInfo'
          id: string
          binaryFileInfoId: string
          originalFilename?: string | null
          mimetype: string
          contentLength?: any | null
          url: string
        } | null
      }
    }
  }
}

export type DocumentFolderCreateMutationVariables = Exact<{
  name: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  parentFolderId?: InputMaybe<Scalars['ID']['input']>
  companyId: Scalars['ID']['input']
  ownerId?: InputMaybe<Scalars['ID']['input']>
}>

export type DocumentFolderCreateMutation = {
  __typename?: 'Mutation'
  document: {
    __typename?: 'NamespaceDocumentMutation'
    folderCreate: {
      __typename?: 'DocboxFolderCreatePayload'
      folder: {
        __typename?: 'DocboxFolder'
        id: string
        folderId: string
        name: string
        description?: string | null
        isRoot: boolean
        ownerId: string
        createdByUserId: string
        parentFolderId?: string | null
        companyId: string
      }
    }
  }
}

export type DocumentFolderDeleteMutationVariables = Exact<{
  filter?: InputMaybe<DocboxFolderDeleteFilterArg>
  deleteRecursive?: InputMaybe<Scalars['Boolean']['input']>
}>

export type DocumentFolderDeleteMutation = {
  __typename?: 'Mutation'
  document: {
    __typename?: 'NamespaceDocumentMutation'
    folderDelete: {
      __typename?: 'DocboxFolderDeletePayload'
      folders: Array<{
        __typename?: 'DocboxFolder'
        id: string
        folderId: string
        name: string
        description?: string | null
        isRoot: boolean
        ownerId: string
        createdByUserId: string
        parentFolderId?: string | null
        companyId: string
      }>
    }
  }
}

export type DocumentFolderUpdateMutationVariables = Exact<{
  folderId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  parentFolderId?: InputMaybe<Scalars['ID']['input']>
  companyId: Scalars['ID']['input']
  ownerId?: InputMaybe<Scalars['ID']['input']>
}>

export type DocumentFolderUpdateMutation = {
  __typename?: 'Mutation'
  document: {
    __typename?: 'NamespaceDocumentMutation'
    folderUpdate: {
      __typename?: 'DocboxFolderUpdatePayload'
      folder: {
        __typename?: 'DocboxFolder'
        id: string
        folderId: string
        name: string
        description?: string | null
        isRoot: boolean
        ownerId: string
        createdByUserId: string
        parentFolderId?: string | null
        companyId: string
      }
    }
  }
}

export type DocumentGetQueryVariables = Exact<{
  filter?: InputMaybe<DocboxGetFilterArg>
  fileUrlInput?: InputMaybe<BinaryFileInfoUrlInput>
  pagination?: InputMaybe<PaginationArg>
  order?: InputMaybe<Array<DocboxGetOrderArg> | DocboxGetOrderArg>
  search?: InputMaybe<DocboxGetSearchArg>
}>

export type DocumentGetQuery = {
  __typename?: 'Query'
  document: {
    __typename?: 'NamespaceDocumentQuery'
    get: {
      __typename?: 'DocboxGetPayload'
      documents?: Array<{
        __typename?: 'DocboxDocument'
        id: string
        documentId: string
        folderId: string
        name: string
        description?: string | null
        ownerId: string
        createdAt: any
        createdByUserId: string
        validUntil?: any | null
        createdByUser: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
          roles: Array<{
            __typename?: 'UserRole'
            userId: string
            roleId: string
            name: string
            uiCode: any
            companyId?: string | null
            code: AuthRoleCodeEnum
          }>
        }
        fileInfo?: {
          __typename?: 'BinaryFileInfo'
          id: string
          binaryFileInfoId: string
          originalFilename?: string | null
          mimetype: string
          contentLength?: any | null
          url: string
        } | null
      }> | null
      paginationInfo: {
        __typename?: 'PaginationInfo'
        offset?: any | null
        limit?: any | null
        totalItems: any
        totalPages: any
      }
    }
  }
}

export type CompanyAddressUpsertMutationVariables = Exact<{
  input: CompanyAddressSetInput
}>

export type CompanyAddressUpsertMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    addressSet: {
      __typename?: 'CompanyAddressSetPayload'
      company: {
        __typename?: 'Company'
        id: string
        name?: string | null
        address?: {
          __typename?: 'Address'
          id: string
          streetAddress?: string | null
          locality?: string | null
          postalCode?: any | null
          administrativeArea?: string | null
          country: { __typename?: 'Country'; id: string; name: string }
        } | null
      }
    }
  }
}

export type GeneralCompanyRegionLinkMutationVariables = Exact<{
  companyId: Scalars['ID']['input']
  regionIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type GeneralCompanyRegionLinkMutation = {
  __typename?: 'Mutation'
  general: {
    __typename?: 'NamespaceGeneralMutation'
    companyRegionLink: {
      __typename?: 'GeneralCompanyRegionLinkPayload'
      company: {
        __typename?: 'Company'
        id: string
        selectedRegulations: Array<{
          __typename?: 'Regulation'
          id: string
          regulationId: string
          name: string
          uiCode?: any | null
          logoPath?: string | null
          description?: string | null
        }>
      }
    }
  }
}

export type GeneralCompanyRegionUnlinkMutationVariables = Exact<{
  companyId: Scalars['ID']['input']
  regionIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type GeneralCompanyRegionUnlinkMutation = {
  __typename?: 'Mutation'
  general: {
    __typename?: 'NamespaceGeneralMutation'
    companyRegionUnlink: {
      __typename?: 'GeneralCompanyRegionUnlinkPayload'
      company: { __typename?: 'Company'; id: string }
    }
  }
}

export type CompanyDepartmentCreateMutationVariables = Exact<{
  input: CompanyDepartmentCreateInput
}>

export type CompanyDepartmentCreateMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    departmentCreate: {
      __typename?: 'CompanyDepartmentCreatePayload'
      department: {
        __typename?: 'Department'
        id: string
        name: string
        description?: string | null
      }
    }
  }
}

export type GeneralSendEmailMutationVariables = Exact<{
  to?: InputMaybe<
    Array<Scalars['EmailAddress']['input']> | Scalars['EmailAddress']['input']
  >
  userIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  audienceIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
  subject: Scalars['String']['input']
  body: Scalars['String']['input']
}>

export type GeneralSendEmailMutation = {
  __typename?: 'Mutation'
  general: {
    __typename?: 'NamespaceGeneralMutation'
    emailSend: {
      __typename?: 'GeneralEmailSendPayload'
      messagesSentCount: any
    }
  }
}

export type CompanyPositionCreateMutationVariables = Exact<{
  input: CompanyPositionCreateInput
}>

export type CompanyPositionCreateMutation = {
  __typename?: 'Mutation'
  company: {
    __typename?: 'NamespaceCompanyMutation'
    positionCreate: {
      __typename?: 'CompanyPositionCreatePayload'
      position: {
        __typename?: 'Position'
        id: string
        name: string
        description?: string | null
      }
    }
  }
}

export type GeneralAssetListQueryVariables = Exact<{ [key: string]: never }>

export type GeneralAssetListQuery = {
  __typename?: 'Query'
  general: {
    __typename?: 'NamespaceGeneralQuery'
    assetList: {
      __typename?: 'GeneralAssetListPayload'
      assets: Array<{
        __typename?: 'Asset'
        id: string
        assetId: string
        uiCode?: any | null
        name: string
        description?: string | null
        isActive: boolean
      }>
    }
  }
}

export type GeneralDepartmentTemplatesGetQueryVariables = Exact<{
  [key: string]: never
}>

export type GeneralDepartmentTemplatesGetQuery = {
  __typename?: 'Query'
  general: {
    __typename?: 'NamespaceGeneralQuery'
    departmentTemplatesGet: {
      __typename?: 'GeneralDepartmentTemplatesGetPayload'
      departmentTemplates: Array<{
        __typename?: 'DepartmentTemplate'
        id: string
        name: string
      }>
    }
  }
}

export type GeneralPositionTemplatesGetQueryVariables = Exact<{
  [key: string]: never
}>

export type GeneralPositionTemplatesGetQuery = {
  __typename?: 'Query'
  general: {
    __typename?: 'NamespaceGeneralQuery'
    positionTemplatesGet: {
      __typename?: 'GeneralPositionTemplatesGetPayload'
      positionTemplates: Array<{
        __typename?: 'PositionTemplate'
        name: string
      }>
    }
  }
}

export type GeneralProcessTemplateListQueryVariables = Exact<{
  [key: string]: never
}>

export type GeneralProcessTemplateListQuery = {
  __typename?: 'Query'
  general: {
    __typename?: 'NamespaceGeneralQuery'
    processTemplateList: {
      __typename?: 'GeneralProcessTemplateListPayload'
      processTemplates: Array<{
        __typename?: 'ProcessTemplate'
        id: string
        uiCode?: any | null
        name: string
        description?: string | null
        isActive: boolean
      }>
    }
  }
}

export type GeneralRegionGroupListQueryVariables = Exact<{
  [key: string]: never
}>

export type GeneralRegionGroupListQuery = {
  __typename?: 'Query'
  general: {
    __typename?: 'NamespaceGeneralQuery'
    regionGroupList: {
      __typename?: 'GeneralRegionGroupListPayload'
      regionGroups: Array<{
        __typename?: 'RegionGroup'
        id: string
        name: string
        uiCode?: any | null
        regions: Array<{
          __typename?: 'Region'
          id: string
          name: string
          uiCode?: any | null
        }>
      }>
    }
  }
}

export type GeneralRegionListQueryVariables = Exact<{ [key: string]: never }>

export type GeneralRegionListQuery = {
  __typename?: 'Query'
  general: {
    __typename?: 'NamespaceGeneralQuery'
    regionList: {
      __typename?: 'GeneralRegionListPayload'
      regions: Array<{
        __typename?: 'Region'
        id: string
        name: string
        description?: string | null
        uiCode?: any | null
      }>
    }
  }
}

export type StatisticsCompletedSurveysGetQueryVariables = Exact<{
  filter?: InputMaybe<StatisticsCompletedSurveysFilterArg>
  groupBy?: InputMaybe<
    | Array<StatisticsCompletedSurveysGroupByEnum>
    | StatisticsCompletedSurveysGroupByEnum
  >
}>

export type StatisticsCompletedSurveysGetQuery = {
  __typename?: 'Query'
  statistics: {
    __typename?: 'NamespaceStatisticsQuery'
    completedSurveys: {
      __typename?: 'StatisticsCompletedSurveysPayload'
      items: Array<{
        __typename?: 'StatisticsCompletedSurveysItem'
        averageResponseTime?: any | null
        session?: { __typename?: 'SurveySession'; id: string } | null
      } | null>
    }
  }
}

export type StatisticsRespondedQuestionsGetQueryVariables = Exact<{
  filter?: InputMaybe<StatisticsRespondedQuestionsFilterArg>
  groupBy?: InputMaybe<
    | Array<StatisticsRespondedQuestionsGroupByEnum>
    | StatisticsRespondedQuestionsGroupByEnum
  >
}>

export type StatisticsRespondedQuestionsGetQuery = {
  __typename?: 'Query'
  statistics: {
    __typename?: 'NamespaceStatisticsQuery'
    respondedQuestions: {
      __typename?: 'StatisticsRespondedQuestionsPayload'
      items: Array<{
        __typename?: 'StatisticsRespondedQuestionsItem'
        session?: { __typename?: 'SurveySession'; id: string } | null
        respondedQuestionsRate: {
          __typename?: 'Fraction'
          A?: number | null
          B?: number | null
          decimal?: number | null
        }
      } | null>
    }
  }
}

export type SurveyManagerStatisticsGetQueryVariables = Exact<{
  filter?: InputMaybe<StatisticsCompletedSurveysFilterArg>
  groupBy?: InputMaybe<
    | Array<StatisticsCompletedSurveysGroupByEnum>
    | StatisticsCompletedSurveysGroupByEnum
  >
}>

export type SurveyManagerStatisticsGetQuery = {
  __typename?: 'Query'
  statistics: {
    __typename?: 'NamespaceStatisticsQuery'
    completedSurveys: {
      __typename?: 'StatisticsCompletedSurveysPayload'
      items: Array<{
        __typename?: 'StatisticsCompletedSurveysItem'
        averageResponseTime?: any | null
        employeeEngagementRate: {
          __typename?: 'Fraction'
          A?: number | null
          B?: number | null
          decimal?: number | null
        }
        survey?: {
          __typename?: 'Survey'
          id: string
          name: string
          type: SurveyType
          questionsCount: any
        } | null
        session?: {
          __typename?: 'SurveySession'
          id: string
          startedAt: any
          deadlineAt?: any | null
        } | null
        department?: {
          __typename?: 'Department'
          id: string
          name: string
        } | null
        company?: {
          __typename?: 'Company'
          id: string
          name?: string | null
          logo?: {
            __typename?: 'BinaryFileInfoPublic'
            publicUrl?: string | null
            id: string
            isPublic: boolean
            originalFilename?: string | null
            mimetype: string
            url: string
          } | null
          address?: {
            __typename?: 'Address'
            id: string
            streetAddress?: string | null
            locality?: string | null
            administrativeArea?: string | null
            postalCode?: any | null
            country: { __typename?: 'Country'; name: string }
          } | null
          representatives: Array<{
            __typename?: 'Representative'
            id: string
            firstName?: string | null
            lastName?: string | null
            email?: any | null
            phoneNumber?: any | null
            representativeGroup: RepresentativeGroupEnum
            address?: {
              __typename?: 'Address'
              id: string
              streetAddress?: string | null
              locality?: string | null
              administrativeArea?: string | null
              postalCode?: any | null
              country: { __typename?: 'Country'; name: string }
            } | null
          }>
        } | null
      } | null>
    }
  }
}

export type SurveyManagerViewAnswersStatisticsGetQueryVariables = Exact<{
  workforcePercentageStatisticsInput?: InputMaybe<StatisticsWorkforcePercentageInput>
  maturityScoreStatisticsInput?: InputMaybe<StatisticsMaturityScoreAveragesInput>
  sessionResponseRateStatisticsInput?: InputMaybe<StatisticsCompletedSurveysInput>
}>

export type SurveyManagerViewAnswersStatisticsGetQuery = {
  __typename?: 'Query'
  statistics: {
    __typename?: 'NamespaceStatisticsQuery'
    workforcePercentage: {
      __typename?: 'StatisticsWorkforcePercentagePayload'
      items: Array<{
        __typename?: 'StatisticsWorkforcePercentageItem'
        percentage: {
          __typename?: 'Fraction'
          A?: number | null
          B?: number | null
          decimal?: number | null
        }
      }>
    }
    sessionResponseRate: {
      __typename?: 'StatisticsCompletedSurveysPayload'
      items: Array<{
        __typename?: 'StatisticsCompletedSurveysItem'
        employeeEngagementRate: {
          __typename?: 'Fraction'
          A?: number | null
          B?: number | null
          decimal?: number | null
        }
      } | null>
    }
    maturityScoreAverages: {
      __typename?: 'StatisticsMaturityScoreAveragesPayload'
      items: Array<{
        __typename?: 'StatisticsMaturityScoreAveragesItem'
        sessionId?: string | null
        maturityScoreAverage?: number | null
        maturityScoreAverageSum?: number | null
        maturityScoreSum?: any | null
        surveysCount: any
        questionsCount: any
      }>
    }
  }
}

export type StatisticsWorkforcePercentageGetQueryVariables = Exact<{
  filter?: InputMaybe<StatisticsWorkforcePercentageFilterArg>
}>

export type StatisticsWorkforcePercentageGetQuery = {
  __typename?: 'Query'
  statistics: {
    __typename?: 'NamespaceStatisticsQuery'
    workforcePercentage: {
      __typename?: 'StatisticsWorkforcePercentagePayload'
      items: Array<{
        __typename?: 'StatisticsWorkforcePercentageItem'
        percentage: {
          __typename?: 'Fraction'
          A?: number | null
          B?: number | null
          decimal?: number | null
        }
      }>
    }
  }
}

export type SurveyDetailsFragment = {
  __typename?: 'Survey'
  id: string
  surveyId: string
  name: string
  questionsCount: any
  isDraft: boolean
  isTemplate: boolean
  type: SurveyType
  createdAt: any
  lastUsedAt?: any | null
  sessions: Array<{
    __typename?: 'SurveySession'
    id: string
    finishedAt: any
    deadlineAt?: any | null
  }>
  subcategories: Array<{
    __typename?: 'SurveySubcategory'
    id: string
    header: string
    uiCode?: any | null
    description?: string | null
    isGuided: boolean
    sortOrder?: any | null
    questionsCount: any
    questions: Array<{
      __typename?: 'SurveyQuestion'
      id: string
      uiCode?: any | null
      modifiers?: any | null
      label: string
      description?: string | null
      subcategoryId: string
      sortOrder?: any | null
      datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
      datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
      guidedStepId?: string | null
      questionTemplateId?: string | null
      isTemplateQuestion?: boolean | null
      answerOptions: Array<
        | {
            __typename?: 'DatamapAnswerOption'
            id: string
            isSensitive?: boolean | null
            isCustom: boolean
            value?: string | null
            datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
            sortOrder?: any | null
            entity?:
              | {
                  __typename?: 'Asset'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataElement'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataLifecycle'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataOwnership'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSharing'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSource'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSubject'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Department'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'LegalBasis'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Process'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'ProcessingType'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Region'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  name?: string | null
                  uiCode?: any | null
                }
              | null
          }
        | {
            __typename?: 'DatamapCustomAnswerOption'
            uiCode?: any | null
            value?: string | null
            sortOrder?: any | null
            isSensitive?: boolean | null
            isCustom: boolean
            datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
          }
        | {
            __typename?: 'SurveyAnswerOption'
            id: string
            value?: string | null
            uiCode?: any | null
            sortOrder?: any | null
          }
        | { __typename?: 'SurveyVirtualAnswerOption' }
      >
      type: {
        __typename?: 'QuestionType'
        id: string
        name: string
        code: string
        availableModifiers?: any | null
        isActive: boolean
        uiCode: any
      }
    }>
    guidedSteps?: Array<{
      __typename?: 'SurveyGuidedStep'
      id: string
      name: string
      description?: string | null
      sortOrder?: any | null
    }> | null
    subcategoryTemplate?: {
      __typename?: 'SurveySubcategoryTemplate'
      id: string
      header: string
      isSelected?: boolean | null
      isConfirmed?: boolean | null
      description?: string | null
      sortOrder: any
      questionTemplatesCount: any
      category: {
        __typename?: 'SurveyCategory'
        id: string
        regulation?: {
          __typename?: 'Regulation'
          id: string
          name: string
          uiCode?: any | null
        } | null
      }
    } | null
  }>
}

export type SurveySubcategoryTemplateDetailsFragment = {
  __typename?: 'SurveySubcategoryTemplate'
  id: string
  uiCode?: any | null
  isSelected?: boolean | null
  isConfirmed?: boolean | null
  isActive: boolean
  isGuided: boolean
  header: string
  description?: string | null
  sortOrder: any
  questionTemplatesCount: any
  questionTemplates: Array<{
    __typename?: 'SurveyQuestionTemplate'
    id: string
    uiCode?: any | null
    modifiers?: any | null
    label: string
    description?: string | null
    sortOrder: any
    answerOptionTemplates?: Array<{
      __typename?: 'SurveyAnswerOptionTemplate'
      id: string
      value?: string | null
    }> | null
    type: {
      __typename?: 'QuestionType'
      id: string
      name: string
      code: string
      availableModifiers?: any | null
      isActive: boolean
      uiCode: any
    }
  }>
  category: {
    __typename?: 'SurveyCategory'
    id: string
    regulation?: {
      __typename?: 'Regulation'
      id: string
      name: string
      uiCode?: any | null
    } | null
  }
}

export type GuidedStepsDetailsFragment = {
  __typename?: 'SurveyGuidedStep'
  id: string
  name: string
  description?: string | null
  sortOrder?: any | null
}

export type SurveySubcategoryDetailsFragment = {
  __typename?: 'SurveySubcategory'
  id: string
  header: string
  uiCode?: any | null
  description?: string | null
  isGuided: boolean
  sortOrder?: any | null
  questionsCount: any
  questions: Array<{
    __typename?: 'SurveyQuestion'
    id: string
    uiCode?: any | null
    modifiers?: any | null
    label: string
    description?: string | null
    subcategoryId: string
    sortOrder?: any | null
    datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
    datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
    guidedStepId?: string | null
    questionTemplateId?: string | null
    isTemplateQuestion?: boolean | null
    answerOptions: Array<
      | {
          __typename?: 'DatamapAnswerOption'
          id: string
          isSensitive?: boolean | null
          isCustom: boolean
          value?: string | null
          datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
          sortOrder?: any | null
          entity?:
            | {
                __typename?: 'Asset'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename?: 'DataElement'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename?: 'DataLifecycle'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename?: 'DataOwnership'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename?: 'DataSharing'
                id: string
                needsValue: boolean
                name: string
                uiCode?: any | null
              }
            | {
                __typename?: 'DataSource'
                id: string
                needsValue: boolean
                name: string
                uiCode?: any | null
              }
            | {
                __typename?: 'DataSubject'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename?: 'Department'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename?: 'LegalBasis'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename?: 'Process'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename?: 'ProcessingType'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename?: 'Region'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename?: 'SurveyAnswerOption'
                id: string
                name?: string | null
                uiCode?: any | null
              }
            | null
        }
      | {
          __typename?: 'DatamapCustomAnswerOption'
          uiCode?: any | null
          value?: string | null
          sortOrder?: any | null
          isSensitive?: boolean | null
          isCustom: boolean
          datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
        }
      | {
          __typename?: 'SurveyAnswerOption'
          id: string
          value?: string | null
          uiCode?: any | null
          sortOrder?: any | null
        }
      | { __typename?: 'SurveyVirtualAnswerOption' }
    >
    type: {
      __typename?: 'QuestionType'
      id: string
      name: string
      code: string
      availableModifiers?: any | null
      isActive: boolean
      uiCode: any
    }
  }>
  guidedSteps?: Array<{
    __typename?: 'SurveyGuidedStep'
    id: string
    name: string
    description?: string | null
    sortOrder?: any | null
  }> | null
  subcategoryTemplate?: {
    __typename?: 'SurveySubcategoryTemplate'
    id: string
    header: string
    isSelected?: boolean | null
    isConfirmed?: boolean | null
    description?: string | null
    sortOrder: any
    questionTemplatesCount: any
    category: {
      __typename?: 'SurveyCategory'
      id: string
      regulation?: {
        __typename?: 'Regulation'
        id: string
        name: string
        uiCode?: any | null
      } | null
    }
  } | null
}

type ISurveyAnswerOptionDetails_DatamapAnswerOption_Fragment = {
  __typename?: 'DatamapAnswerOption'
  id: string
  isSensitive?: boolean | null
  isCustom: boolean
  value?: string | null
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  sortOrder?: any | null
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type ISurveyAnswerOptionDetails_DatamapCustomAnswerOption_Fragment = {
  __typename?: 'DatamapCustomAnswerOption'
  uiCode?: any | null
  value?: string | null
  sortOrder?: any | null
  isSensitive?: boolean | null
  isCustom: boolean
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
}

type ISurveyAnswerOptionDetails_SurveyAnswerOption_Fragment = {
  __typename?: 'SurveyAnswerOption'
  id: string
  value?: string | null
  uiCode?: any | null
  sortOrder?: any | null
}

type ISurveyAnswerOptionDetails_SurveyVirtualAnswerOption_Fragment = {
  __typename?: 'SurveyVirtualAnswerOption'
}

export type ISurveyAnswerOptionDetailsFragment =
  | ISurveyAnswerOptionDetails_DatamapAnswerOption_Fragment
  | ISurveyAnswerOptionDetails_DatamapCustomAnswerOption_Fragment
  | ISurveyAnswerOptionDetails_SurveyAnswerOption_Fragment
  | ISurveyAnswerOptionDetails_SurveyVirtualAnswerOption_Fragment

type DatamapEntityDetails_Asset_Fragment = {
  __typename?: 'Asset'
  id: string
  name: string
  uiCode?: any | null
}

type DatamapEntityDetails_DataElement_Fragment = {
  __typename?: 'DataElement'
  id: string
  name: string
  uiCode?: any | null
}

type DatamapEntityDetails_DataLifecycle_Fragment = {
  __typename?: 'DataLifecycle'
  id: string
  name: string
  uiCode?: any | null
}

type DatamapEntityDetails_DataOwnership_Fragment = {
  __typename?: 'DataOwnership'
  id: string
  name: string
  uiCode?: any | null
}

type DatamapEntityDetails_DataSharing_Fragment = {
  __typename?: 'DataSharing'
  id: string
  needsValue: boolean
  name: string
  uiCode?: any | null
}

type DatamapEntityDetails_DataSource_Fragment = {
  __typename?: 'DataSource'
  id: string
  needsValue: boolean
  name: string
  uiCode?: any | null
}

type DatamapEntityDetails_DataSubject_Fragment = {
  __typename?: 'DataSubject'
  id: string
  name: string
  uiCode?: any | null
}

type DatamapEntityDetails_Department_Fragment = {
  __typename?: 'Department'
  id: string
  name: string
  uiCode?: any | null
}

type DatamapEntityDetails_LegalBasis_Fragment = {
  __typename?: 'LegalBasis'
  id: string
  name: string
  uiCode?: any | null
}

type DatamapEntityDetails_Process_Fragment = {
  __typename?: 'Process'
  id: string
  name: string
  uiCode?: any | null
}

type DatamapEntityDetails_ProcessingType_Fragment = {
  __typename?: 'ProcessingType'
  id: string
  name: string
  uiCode?: any | null
}

type DatamapEntityDetails_Region_Fragment = {
  __typename?: 'Region'
  id: string
  name: string
  uiCode?: any | null
}

type DatamapEntityDetails_SurveyAnswerOption_Fragment = {
  __typename?: 'SurveyAnswerOption'
  id: string
  name?: string | null
  uiCode?: any | null
}

export type DatamapEntityDetailsFragment =
  | DatamapEntityDetails_Asset_Fragment
  | DatamapEntityDetails_DataElement_Fragment
  | DatamapEntityDetails_DataLifecycle_Fragment
  | DatamapEntityDetails_DataOwnership_Fragment
  | DatamapEntityDetails_DataSharing_Fragment
  | DatamapEntityDetails_DataSource_Fragment
  | DatamapEntityDetails_DataSubject_Fragment
  | DatamapEntityDetails_Department_Fragment
  | DatamapEntityDetails_LegalBasis_Fragment
  | DatamapEntityDetails_Process_Fragment
  | DatamapEntityDetails_ProcessingType_Fragment
  | DatamapEntityDetails_Region_Fragment
  | DatamapEntityDetails_SurveyAnswerOption_Fragment

export type DatamapAnswerOptionDetailsFragment = {
  __typename?: 'DatamapAnswerOption'
  id: string
  isSensitive?: boolean | null
  isCustom: boolean
  value?: string | null
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  sortOrder?: any | null
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapCustomAnswerOptionDetailsFragment = {
  __typename?: 'DatamapCustomAnswerOption'
  uiCode?: any | null
  value?: string | null
  sortOrder?: any | null
  isSensitive?: boolean | null
  isCustom: boolean
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
}

export type SurveyAnswerOptionDetailsFragment = {
  __typename?: 'SurveyAnswerOption'
  id: string
  value?: string | null
  uiCode?: any | null
  sortOrder?: any | null
}

export type SurveyQuestionDetailsFragment = {
  __typename?: 'SurveyQuestion'
  id: string
  uiCode?: any | null
  modifiers?: any | null
  label: string
  description?: string | null
  subcategoryId: string
  sortOrder?: any | null
  datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  guidedStepId?: string | null
  questionTemplateId?: string | null
  isTemplateQuestion?: boolean | null
  answerOptions: Array<
    | {
        __typename?: 'DatamapAnswerOption'
        id: string
        isSensitive?: boolean | null
        isCustom: boolean
        value?: string | null
        datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
        sortOrder?: any | null
        entity?:
          | {
              __typename?: 'Asset'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename?: 'DataElement'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename?: 'DataLifecycle'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename?: 'DataOwnership'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename?: 'DataSharing'
              id: string
              needsValue: boolean
              name: string
              uiCode?: any | null
            }
          | {
              __typename?: 'DataSource'
              id: string
              needsValue: boolean
              name: string
              uiCode?: any | null
            }
          | {
              __typename?: 'DataSubject'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename?: 'Department'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename?: 'LegalBasis'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename?: 'Process'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename?: 'ProcessingType'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename?: 'Region'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename?: 'SurveyAnswerOption'
              id: string
              name?: string | null
              uiCode?: any | null
            }
          | null
      }
    | {
        __typename?: 'DatamapCustomAnswerOption'
        uiCode?: any | null
        value?: string | null
        sortOrder?: any | null
        isSensitive?: boolean | null
        isCustom: boolean
        datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
      }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        value?: string | null
        uiCode?: any | null
        sortOrder?: any | null
      }
    | { __typename?: 'SurveyVirtualAnswerOption' }
  >
  type: {
    __typename?: 'QuestionType'
    id: string
    name: string
    code: string
    availableModifiers?: any | null
    isActive: boolean
    uiCode: any
  }
}

export type QuestionTemplateDetailsFragment = {
  __typename?: 'SurveyQuestionTemplate'
  id: string
  uiCode?: any | null
  modifiers?: any | null
  label: string
  description?: string | null
  sortOrder: any
  answerOptionTemplates?: Array<{
    __typename?: 'SurveyAnswerOptionTemplate'
    id: string
    value?: string | null
  }> | null
  type: {
    __typename?: 'QuestionType'
    id: string
    name: string
    code: string
    availableModifiers?: any | null
    isActive: boolean
    uiCode: any
  }
}

export type AudienceDetailsFragment = {
  __typename?: 'Audience'
  id: string
  name: string
  description?: string | null
  isSingleUser: boolean
  updatedAt?: any | null
  createdAt: any
  computingMethod: CompanyAudienceComputingMethodEnum
  users: Array<{
    __typename?: 'User'
    id: string
    userId: string
    firstName?: string | null
    lastName?: string | null
    email: any
    status: UserStatusEnum
    roles: Array<{
      __typename?: 'UserRole'
      userId: string
      roleId: string
      name: string
      companyId?: string | null
      code: AuthRoleCodeEnum
    }>
    positions: Array<{
      __typename?: 'UserPosition'
      position: {
        __typename?: 'Position'
        id: string
        name: string
        companyId: string
      }
      department: {
        __typename?: 'Department'
        id: string
        name: string
        companyId: string
      }
    }>
  }>
}

export type AudienceUserDetailsFragment = {
  __typename?: 'User'
  id: string
  userId: string
  firstName?: string | null
  lastName?: string | null
  email: any
  status: UserStatusEnum
  roles: Array<{
    __typename?: 'UserRole'
    userId: string
    roleId: string
    name: string
    companyId?: string | null
    code: AuthRoleCodeEnum
  }>
  positions: Array<{
    __typename?: 'UserPosition'
    position: {
      __typename?: 'Position'
      id: string
      name: string
      companyId: string
    }
    department: {
      __typename?: 'Department'
      id: string
      name: string
      companyId: string
    }
  }>
}

export type ResponseDetailsFragment = {
  __typename?: 'SurveyResponse'
  id: string
  value?: string | null
  answeredAt: any
  respondentId: string
  files: Array<{
    __typename?: 'BinaryFileInfo'
    id: string
    originalFilename?: string | null
  }>
  answerOption?:
    | { __typename?: 'DatamapAnswerOption'; id: string }
    | { __typename?: 'DatamapCustomAnswerOption'; id?: string | null }
    | { __typename?: 'SurveyAnswerOption'; id: string }
    | { __typename?: 'SurveyVirtualAnswerOption'; id?: string | null }
    | null
}

export type DatamapResponseResponseCommonFragment = {
  __typename?: 'SurveyResponse'
  id: string
  value?: string | null
  respondentId: string
  questionId: string
  question: {
    __typename?: 'SurveyQuestion'
    id: string
    sortOrder?: any | null
  }
}

export type DatamapResponseResponseDetailsFragment = {
  __typename?: 'SurveyResponse'
  id: string
  value?: string | null
  respondentId: string
  questionId: string
  answerOption?:
    | { __typename?: 'DatamapAnswerOption'; id: string }
    | { __typename?: 'DatamapCustomAnswerOption'; id?: string | null }
    | { __typename?: 'SurveyAnswerOption'; id: string }
    | { __typename?: 'SurveyVirtualAnswerOption'; id?: string | null }
    | null
  question: {
    __typename?: 'SurveyQuestion'
    id: string
    modifiers?: any | null
    datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
    sortOrder?: any | null
    type: {
      __typename?: 'QuestionType'
      id: string
      name: string
      code: string
      availableModifiers?: any | null
      isActive: boolean
      uiCode: any
    }
    guidedStep?: {
      __typename?: 'SurveyGuidedStep'
      id: string
      sortOrder?: any | null
    } | null
  }
}

type SurveyManagerDatamapResponseGet_DatamapDataElementResponse_Fragment = {
  __typename?: 'DatamapDataElementResponse'
  id: string
  isCustom: boolean
  isSensitive?: boolean | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  response: {
    __typename?: 'SurveyResponse'
    id: string
    value?: string | null
    questionId: string
    respondentId: string
    question: {
      __typename?: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type SurveyManagerDatamapResponseGet_DatamapDataSharingResponse_Fragment = {
  __typename?: 'DatamapDataSharingResponse'
  id: string
  sharedWith3rdParty?: string | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  response: {
    __typename?: 'SurveyResponse'
    id: string
    value?: string | null
    questionId: string
    respondentId: string
    question: {
      __typename?: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  sharedWithDepartment?: {
    __typename?: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type SurveyManagerDatamapResponseGet_DatamapDataSourceResponse_Fragment = {
  __typename?: 'DatamapDataSourceResponse'
  id: string
  providedBy3rdParty?: string | null
  isCustom: boolean
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  response: {
    __typename?: 'SurveyResponse'
    id: string
    value?: string | null
    questionId: string
    respondentId: string
    question: {
      __typename?: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  providedByDepartment?: {
    __typename?: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type SurveyManagerDatamapResponseGet_DatamapResponse_Fragment = {
  __typename?: 'DatamapResponse'
  id: string
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  response: {
    __typename?: 'SurveyResponse'
    id: string
    value?: string | null
    questionId: string
    respondentId: string
    question: {
      __typename?: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type SurveyManagerDatamapResponseGetFragment =
  | SurveyManagerDatamapResponseGet_DatamapDataElementResponse_Fragment
  | SurveyManagerDatamapResponseGet_DatamapDataSharingResponse_Fragment
  | SurveyManagerDatamapResponseGet_DatamapDataSourceResponse_Fragment
  | SurveyManagerDatamapResponseGet_DatamapResponse_Fragment

type DatamapResponseGet_DatamapDataElementResponse_Fragment = {
  __typename?: 'DatamapDataElementResponse'
  id: string
  isCustom: boolean
  isSensitive?: boolean | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  response: {
    __typename?: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    question: {
      __typename?: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type DatamapResponseGet_DatamapDataSharingResponse_Fragment = {
  __typename?: 'DatamapDataSharingResponse'
  id: string
  sharedWith3rdParty?: string | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  response: {
    __typename?: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    question: {
      __typename?: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  sharedWithDepartment?: {
    __typename?: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type DatamapResponseGet_DatamapDataSourceResponse_Fragment = {
  __typename?: 'DatamapDataSourceResponse'
  id: string
  providedBy3rdParty?: string | null
  isCustom: boolean
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  response: {
    __typename?: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    question: {
      __typename?: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  providedByDepartment?: {
    __typename?: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type DatamapResponseGet_DatamapResponse_Fragment = {
  __typename?: 'DatamapResponse'
  id: string
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  response: {
    __typename?: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    question: {
      __typename?: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseGetFragment =
  | DatamapResponseGet_DatamapDataElementResponse_Fragment
  | DatamapResponseGet_DatamapDataSharingResponse_Fragment
  | DatamapResponseGet_DatamapDataSourceResponse_Fragment
  | DatamapResponseGet_DatamapResponse_Fragment

type DatamapResponseCommon_DatamapDataElementResponse_Fragment = {
  __typename?: 'DatamapDataElementResponse'
  id: string
  isCustom: boolean
  isSensitive?: boolean | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type DatamapResponseCommon_DatamapDataSharingResponse_Fragment = {
  __typename?: 'DatamapDataSharingResponse'
  id: string
  sharedWith3rdParty?: string | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  sharedWithDepartment?: {
    __typename?: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type DatamapResponseCommon_DatamapDataSourceResponse_Fragment = {
  __typename?: 'DatamapDataSourceResponse'
  id: string
  providedBy3rdParty?: string | null
  isCustom: boolean
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  providedByDepartment?: {
    __typename?: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type DatamapResponseCommon_DatamapResponse_Fragment = {
  __typename?: 'DatamapResponse'
  id: string
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseCommonFragment =
  | DatamapResponseCommon_DatamapDataElementResponse_Fragment
  | DatamapResponseCommon_DatamapDataSharingResponse_Fragment
  | DatamapResponseCommon_DatamapDataSourceResponse_Fragment
  | DatamapResponseCommon_DatamapResponse_Fragment

type DatamapResponseDetails_DatamapDataElementResponse_Fragment = {
  __typename?: 'DatamapDataElementResponse'
  id: string
  isCustom: boolean
  isSensitive?: boolean | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  response: {
    __typename?: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    answerOption?:
      | { __typename?: 'DatamapAnswerOption'; id: string }
      | { __typename?: 'DatamapCustomAnswerOption'; id?: string | null }
      | { __typename?: 'SurveyAnswerOption'; id: string }
      | { __typename?: 'SurveyVirtualAnswerOption'; id?: string | null }
      | null
    question: {
      __typename?: 'SurveyQuestion'
      id: string
      modifiers?: any | null
      datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
      sortOrder?: any | null
      type: {
        __typename?: 'QuestionType'
        id: string
        name: string
        code: string
        availableModifiers?: any | null
        isActive: boolean
        uiCode: any
      }
      guidedStep?: {
        __typename?: 'SurveyGuidedStep'
        id: string
        sortOrder?: any | null
      } | null
    }
  }
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type DatamapResponseDetails_DatamapDataSharingResponse_Fragment = {
  __typename?: 'DatamapDataSharingResponse'
  id: string
  sharedWith3rdParty?: string | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  response: {
    __typename?: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    answerOption?:
      | { __typename?: 'DatamapAnswerOption'; id: string }
      | { __typename?: 'DatamapCustomAnswerOption'; id?: string | null }
      | { __typename?: 'SurveyAnswerOption'; id: string }
      | { __typename?: 'SurveyVirtualAnswerOption'; id?: string | null }
      | null
    question: {
      __typename?: 'SurveyQuestion'
      id: string
      modifiers?: any | null
      datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
      sortOrder?: any | null
      type: {
        __typename?: 'QuestionType'
        id: string
        name: string
        code: string
        availableModifiers?: any | null
        isActive: boolean
        uiCode: any
      }
      guidedStep?: {
        __typename?: 'SurveyGuidedStep'
        id: string
        sortOrder?: any | null
      } | null
    }
  }
  sharedWithDepartment?: {
    __typename?: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type DatamapResponseDetails_DatamapDataSourceResponse_Fragment = {
  __typename?: 'DatamapDataSourceResponse'
  id: string
  providedBy3rdParty?: string | null
  isCustom: boolean
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  response: {
    __typename?: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    answerOption?:
      | { __typename?: 'DatamapAnswerOption'; id: string }
      | { __typename?: 'DatamapCustomAnswerOption'; id?: string | null }
      | { __typename?: 'SurveyAnswerOption'; id: string }
      | { __typename?: 'SurveyVirtualAnswerOption'; id?: string | null }
      | null
    question: {
      __typename?: 'SurveyQuestion'
      id: string
      modifiers?: any | null
      datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
      sortOrder?: any | null
      type: {
        __typename?: 'QuestionType'
        id: string
        name: string
        code: string
        availableModifiers?: any | null
        isActive: boolean
        uiCode: any
      }
      guidedStep?: {
        __typename?: 'SurveyGuidedStep'
        id: string
        sortOrder?: any | null
      } | null
    }
  }
  providedByDepartment?: {
    __typename?: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

type DatamapResponseDetails_DatamapResponse_Fragment = {
  __typename?: 'DatamapResponse'
  id: string
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
  response: {
    __typename?: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    answerOption?:
      | { __typename?: 'DatamapAnswerOption'; id: string }
      | { __typename?: 'DatamapCustomAnswerOption'; id?: string | null }
      | { __typename?: 'SurveyAnswerOption'; id: string }
      | { __typename?: 'SurveyVirtualAnswerOption'; id?: string | null }
      | null
    question: {
      __typename?: 'SurveyQuestion'
      id: string
      modifiers?: any | null
      datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
      sortOrder?: any | null
      type: {
        __typename?: 'QuestionType'
        id: string
        name: string
        code: string
        availableModifiers?: any | null
        isActive: boolean
        uiCode: any
      }
      guidedStep?: {
        __typename?: 'SurveyGuidedStep'
        id: string
        sortOrder?: any | null
      } | null
    }
  }
  entity?:
    | { __typename?: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename?: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename?: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename?: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseDetailsFragment =
  | DatamapResponseDetails_DatamapDataElementResponse_Fragment
  | DatamapResponseDetails_DatamapDataSharingResponse_Fragment
  | DatamapResponseDetails_DatamapDataSourceResponse_Fragment
  | DatamapResponseDetails_DatamapResponse_Fragment

export type SurveyGetBuilderFragmentFragment = {
  __typename?: 'Survey'
  id: string
  surveyId: string
  name: string
  questionsCount: any
  isDraft: boolean
  isTemplate: boolean
  type: SurveyType
  createdAt: any
  lastUsedAt?: any | null
  sessions: Array<{
    __typename?: 'SurveySession'
    id: string
    finishedAt: any
    deadlineAt?: any | null
  }>
}

export type SurveyManagerViewAnswersSessionFragment = {
  __typename?: 'SurveySession'
  id: string
  startedAt: any
  finishedAt: any
  deadlineAt?: any | null
  isCompleted: boolean
  maturityScoreAveragesForSession?: {
    __typename?: 'StatisticsMaturityScoreAveragesItem'
    sessionId?: string | null
    maturityScoreSum?: any | null
    maturityScoreAverage?: number | null
    maturityScoreAverageSum?: number | null
    surveysCount: any
    questionsCount: any
  } | null
  invitedUsers: Array<{ __typename?: 'User'; id: string }>
  survey: {
    __typename?: 'Survey'
    id: string
    name: string
    type: SurveyType
    questionsCount: any
    audiences: Array<{ __typename?: 'Audience'; id: string }>
    company: { __typename?: 'Company'; id: string }
    categories: Array<{
      __typename?: 'SurveyCategory'
      id: string
      name?: string | null
      regulation?: {
        __typename?: 'Regulation'
        id: string
        name: string
        uiCode?: any | null
      } | null
    }>
    subcategories: Array<{
      __typename?: 'SurveySubcategory'
      id: string
      header: string
      description?: string | null
      sortOrder?: any | null
      isGuided: boolean
      subcategoryTemplate?: {
        __typename?: 'SurveySubcategoryTemplate'
        id: string
        category: {
          __typename?: 'SurveyCategory'
          id: string
          name?: string | null
          regulation?: {
            __typename?: 'Regulation'
            id: string
            name: string
            uiCode?: any | null
          } | null
        }
      } | null
      guidedSteps?: Array<{
        __typename?: 'SurveyGuidedStep'
        id: string
        name: string
        description?: string | null
        sortOrder?: any | null
        questions: Array<{
          __typename?: 'SurveyQuestion'
          maturityScore?: any | null
          id: string
          uiCode?: any | null
          modifiers?: any | null
          label: string
          description?: string | null
          subcategoryId: string
          sortOrder?: any | null
          datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
          datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
          guidedStepId?: string | null
          questionTemplateId?: string | null
          isTemplateQuestion?: boolean | null
          answerOptions: Array<
            | {
                __typename?: 'DatamapAnswerOption'
                id: string
                isSensitive?: boolean | null
                isCustom: boolean
                value?: string | null
                datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
                sortOrder?: any | null
                entity?:
                  | {
                      __typename?: 'Asset'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename?: 'DataElement'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename?: 'DataLifecycle'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename?: 'DataOwnership'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename?: 'DataSharing'
                      id: string
                      needsValue: boolean
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename?: 'DataSource'
                      id: string
                      needsValue: boolean
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename?: 'DataSubject'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename?: 'Department'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename?: 'LegalBasis'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename?: 'Process'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename?: 'ProcessingType'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename?: 'Region'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename?: 'SurveyAnswerOption'
                      id: string
                      name?: string | null
                      uiCode?: any | null
                    }
                  | null
              }
            | {
                __typename?: 'DatamapCustomAnswerOption'
                uiCode?: any | null
                value?: string | null
                sortOrder?: any | null
                isSensitive?: boolean | null
                isCustom: boolean
                datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
              }
            | {
                __typename?: 'SurveyAnswerOption'
                id: string
                value?: string | null
                uiCode?: any | null
                sortOrder?: any | null
              }
            | { __typename?: 'SurveyVirtualAnswerOption' }
          >
          type: {
            __typename?: 'QuestionType'
            id: string
            name: string
            code: string
            availableModifiers?: any | null
            isActive: boolean
            uiCode: any
          }
        }>
      }> | null
      questions: Array<{
        __typename?: 'SurveyQuestion'
        id: string
        maturityScore?: any | null
        uiCode?: any | null
        modifiers?: any | null
        label: string
        description?: string | null
        subcategoryId: string
        sortOrder?: any | null
        datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
        datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
        guidedStepId?: string | null
        questionTemplateId?: string | null
        isTemplateQuestion?: boolean | null
        answerOptions: Array<
          | {
              __typename?: 'DatamapAnswerOption'
              id: string
              isSensitive?: boolean | null
              isCustom: boolean
              value?: string | null
              datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
              sortOrder?: any | null
              entity?:
                | {
                    __typename?: 'Asset'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataElement'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataLifecycle'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataOwnership'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataSharing'
                    id: string
                    needsValue: boolean
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataSource'
                    id: string
                    needsValue: boolean
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataSubject'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'Department'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'LegalBasis'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'Process'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'ProcessingType'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'Region'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'SurveyAnswerOption'
                    id: string
                    name?: string | null
                    uiCode?: any | null
                  }
                | null
            }
          | {
              __typename?: 'DatamapCustomAnswerOption'
              uiCode?: any | null
              value?: string | null
              sortOrder?: any | null
              isSensitive?: boolean | null
              isCustom: boolean
              datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
            }
          | {
              __typename?: 'SurveyAnswerOption'
              id: string
              value?: string | null
              uiCode?: any | null
              sortOrder?: any | null
            }
          | { __typename?: 'SurveyVirtualAnswerOption' }
        >
        type: {
          __typename?: 'QuestionType'
          id: string
          name: string
          code: string
          availableModifiers?: any | null
          isActive: boolean
          uiCode: any
        }
      }>
    }>
  }
}

export type SurveyManagerPageSessionFragment = {
  __typename?: 'SurveySession'
  id: string
  startedAt: any
  finishedAt: any
  deadlineAt?: any | null
  isCompleted: boolean
}

export type SurveyAnswerOptionCreateMutationVariables = Exact<{
  questionId: Scalars['ID']['input']
  value?: InputMaybe<Scalars['String']['input']>
  answerOptionTemplateId?: InputMaybe<Scalars['ID']['input']>
  sortOrder?: InputMaybe<Scalars['Int']['input']>
}>

export type SurveyAnswerOptionCreateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    answerOptionCreate: {
      __typename?: 'SurveyAnswerOptionCreatePayload'
      question: {
        __typename?: 'SurveyQuestion'
        id: string
        uiCode?: any | null
        modifiers?: any | null
        label: string
        description?: string | null
        subcategoryId: string
        sortOrder?: any | null
        datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
        datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
        guidedStepId?: string | null
        questionTemplateId?: string | null
        isTemplateQuestion?: boolean | null
        answerOptions: Array<
          | {
              __typename?: 'DatamapAnswerOption'
              id: string
              isSensitive?: boolean | null
              isCustom: boolean
              value?: string | null
              datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
              sortOrder?: any | null
              entity?:
                | {
                    __typename?: 'Asset'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataElement'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataLifecycle'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataOwnership'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataSharing'
                    id: string
                    needsValue: boolean
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataSource'
                    id: string
                    needsValue: boolean
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataSubject'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'Department'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'LegalBasis'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'Process'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'ProcessingType'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'Region'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'SurveyAnswerOption'
                    id: string
                    name?: string | null
                    uiCode?: any | null
                  }
                | null
            }
          | {
              __typename?: 'DatamapCustomAnswerOption'
              uiCode?: any | null
              value?: string | null
              sortOrder?: any | null
              isSensitive?: boolean | null
              isCustom: boolean
              datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
            }
          | {
              __typename?: 'SurveyAnswerOption'
              id: string
              value?: string | null
              uiCode?: any | null
              sortOrder?: any | null
            }
          | { __typename?: 'SurveyVirtualAnswerOption' }
        >
        type: {
          __typename?: 'QuestionType'
          id: string
          name: string
          code: string
          availableModifiers?: any | null
          isActive: boolean
          uiCode: any
        }
      }
      answerOption: {
        __typename?: 'SurveyAnswerOption'
        id: string
        value?: string | null
        uiCode?: any | null
        sortOrder?: any | null
      }
    }
  }
}

export type SurveyAnswerOptionDeleteMutationVariables = Exact<{
  answerOptionId: Scalars['ID']['input']
}>

export type SurveyAnswerOptionDeleteMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    answerOptionDelete: {
      __typename?: 'SurveyAnswerOptionDeletePayload'
      answerOption: {
        __typename?: 'SurveyAnswerOption'
        id: string
        value?: string | null
        uiCode?: any | null
        sortOrder?: any | null
      }
    }
  }
}

export type SurveyAnswerOptionUpdateMutationVariables = Exact<{
  answerOptionId: Scalars['ID']['input']
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  value?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['Int']['input']>
}>

export type SurveyAnswerOptionUpdateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    answerOptionUpdate: {
      __typename?: 'SurveyAnswerOptionUpdatePayload'
      answerOption: {
        __typename?: 'SurveyAnswerOption'
        id: string
        value?: string | null
        uiCode?: any | null
        sortOrder?: any | null
      }
    }
  }
}

export type SurveyAudiencesSetMutationVariables = Exact<{
  surveyId: Scalars['ID']['input']
  audienceIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type SurveyAudiencesSetMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    surveyAudiencesSet: {
      __typename?: 'SurveySurveyAudiencesSetPayload'
      survey: {
        __typename?: 'Survey'
        audiences: Array<{ __typename?: 'Audience'; id: string; name: string }>
        schedules: Array<{
          __typename?: 'SurveySchedule'
          id: string
          startAt: any
          defaultSessionDurationDays: any
          periodDays?: any | null
        }>
        sessions: Array<{ __typename?: 'SurveySession'; id: string }>
      }
    }
  }
}

export type SurveyCopyMutationVariables = Exact<{
  surveyId: Scalars['ID']['input']
  companyId: Scalars['ID']['input']
  name: Scalars['String']['input']
  isDraft: Scalars['Boolean']['input']
  isTemplate: Scalars['Boolean']['input']
}>

export type SurveyCopyMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    copy: {
      __typename?: 'SurveyCopyPayload'
      survey: { __typename?: 'Survey'; id: string }
    }
  }
}

export type SurveyCreateMutationVariables = Exact<{
  companyId: Scalars['ID']['input']
  name: Scalars['String']['input']
  isDraft: Scalars['Boolean']['input']
  isTemplate: Scalars['Boolean']['input']
  categoryIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
}>

export type SurveyCreateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    create: {
      __typename?: 'SurveyCreatePayload'
      survey: {
        __typename?: 'Survey'
        id: string
        surveyId: string
        name: string
        isDraft: boolean
        isTemplate: boolean
        company: { __typename?: 'Company'; id: string; companyId: string }
      }
    }
  }
}

export type SurveyDeleteMutationVariables = Exact<{
  surveyId: Scalars['ID']['input']
}>

export type SurveyDeleteMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    delete: {
      __typename?: 'SurveyDeletePayload'
      survey: {
        __typename?: 'Survey'
        id: string
        surveyId: string
        isDraft: boolean
        isTemplate: boolean
        name: string
        company: { __typename?: 'Company'; id: string; companyId: string }
      }
    }
  }
}

export type SurveyGuidedStepCreateMutationVariables = Exact<{
  surveyId: Scalars['ID']['input']
  name: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
}>

export type SurveyGuidedStepCreateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    guidedStepCreate: {
      __typename?: 'SurveyGuidedStepCreatePayload'
      guidedStep: {
        __typename?: 'SurveyGuidedStep'
        id: string
        uiCode?: any | null
        name: string
        description?: string | null
        sortOrder?: any | null
      }
    }
  }
}

export type SurveyGuidedStepDeleteMutationVariables = Exact<{
  guidedStepId: Scalars['ID']['input']
}>

export type SurveyGuidedStepDeleteMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    guidedStepDelete: {
      __typename?: 'SurveyGuidedStepDeletePayload'
      guidedStep: {
        __typename?: 'SurveyGuidedStep'
        id: string
        uiCode?: any | null
        name: string
        description?: string | null
        sortOrder?: any | null
      }
    }
  }
}

export type SurveyGuidedStepUpdateMutationVariables = Exact<{
  guidedStepId: Scalars['ID']['input']
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
}>

export type SurveyGuidedStepUpdateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    guidedStepUpdate: {
      __typename?: 'SurveyGuidedStepUpdatePayload'
      guidedStep: {
        __typename?: 'SurveyGuidedStep'
        id: string
        name: string
        description?: string | null
        sortOrder?: any | null
      }
    }
  }
}

export type SurveyInvitationDeleteMutationVariables = Exact<{
  filter?: InputMaybe<SurveyInvitationDeleteFilterArg>
}>

export type SurveyInvitationDeleteMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    invitationDelete: {
      __typename?: 'SurveyInvitationDeletePayload'
      invitations: Array<{
        __typename?: 'Invitation'
        invitationId: string
        user: { __typename?: 'User'; id: string }
      }>
    }
  }
}

export type SurveyInviteAudiencesMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  audienceIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
  companyId: Scalars['ID']['input']
}>

export type SurveyInviteAudiencesMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    inviteAudiences: {
      __typename?: 'SurveyInviteAudiencesPayload'
      session: { __typename?: 'SurveySession'; id: string }
      invitations: Array<{
        __typename?: 'Invitation'
        invitationId: string
        user: {
          __typename?: 'User'
          id: string
          userId: string
          firstName?: string | null
          lastName?: string | null
          email: any
          status: UserStatusEnum
          roles: Array<{
            __typename?: 'UserRole'
            userId: string
            roleId: string
            name: string
            companyId?: string | null
            code: AuthRoleCodeEnum
          }>
          positions: Array<{
            __typename?: 'UserPosition'
            position: {
              __typename?: 'Position'
              id: string
              name: string
              companyId: string
            }
            department: {
              __typename?: 'Department'
              id: string
              name: string
              companyId: string
            }
          }>
        }
      }>
    }
  }
}

export type SurveyInviteUsersMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  userIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type SurveyInviteUsersMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    inviteUsers: {
      __typename?: 'SurveyInviteUsersPayload'
      session: { __typename?: 'SurveySession'; id: string }
      invitations: Array<{
        __typename?: 'Invitation'
        invitationId: string
        user: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
          email: any
          positions: Array<{
            __typename?: 'UserPosition'
            positionId: string
            departmentId: string
            position: { __typename?: 'Position'; id: string; name: string }
            department: { __typename?: 'Department'; id: string; name: string }
          }>
        }
      }>
    }
  }
}

export type MaturityScoreAveragesFragment = {
  __typename?: 'StatisticsMaturityScoreAveragesItem'
  sessionId?: string | null
  maturityScoreSum?: any | null
  maturityScoreAverage?: number | null
  maturityScoreAverageSum?: number | null
  surveysCount: any
  questionsCount: any
}

export type SurveyMaturityScoreSetMutationVariables = Exact<{
  input: SurveyMaturityScoreSetInput
  filter?: InputMaybe<StatisticsMaturityScoreAveragesFilterArg>
  groupBy?: InputMaybe<
    | Array<StatisticsMaturityScoreAveragesGroupByEnum>
    | StatisticsMaturityScoreAveragesGroupByEnum
  >
}>

export type SurveyMaturityScoreSetMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    maturityScoreSet: {
      __typename?: 'SurveyMaturityScoreSetPayload'
      query: {
        __typename?: 'Query'
        statistics: {
          __typename?: 'NamespaceStatisticsQuery'
          maturityScoreAverages: {
            __typename?: 'StatisticsMaturityScoreAveragesPayload'
            items: Array<{
              __typename?: 'StatisticsMaturityScoreAveragesItem'
              sessionId?: string | null
              maturityScoreSum?: any | null
              maturityScoreAverage?: number | null
              maturityScoreAverageSum?: number | null
              surveysCount: any
              questionsCount: any
            }>
          }
        }
      }
      maturityScore?: {
        __typename?: 'MaturityScore'
        questionId: string
        sessionId: string
        maturityScore: any
        session: {
          __typename?: 'SurveySession'
          id: string
          maturityScoreAveragesForSession?: {
            __typename?: 'StatisticsMaturityScoreAveragesItem'
            sessionId?: string | null
            maturityScoreSum?: any | null
            maturityScoreAverage?: number | null
            maturityScoreAverageSum?: number | null
            surveysCount: any
            questionsCount: any
          } | null
          survey: {
            __typename?: 'Survey'
            id: string
            name: string
            questionsCount: any
          }
        }
      } | null
    }
  }
}

export type SurveyQuestionCreateMutationVariables = Exact<{
  subcategoryId: Scalars['ID']['input']
  guidedStepId?: InputMaybe<Scalars['ID']['input']>
  label: Scalars['String']['input']
  description: Scalars['String']['input']
  modifiers?: InputMaybe<Scalars['JSONObject']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  questionTypeId: Scalars['ID']['input']
}>

export type SurveyQuestionCreateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    questionCreate: {
      __typename?: 'SurveyQuestionCreatePayload'
      question: {
        __typename?: 'SurveyQuestion'
        id: string
        uiCode?: any | null
        label: string
        description?: string | null
        modifiers?: any | null
        sortOrder?: any | null
      }
    }
  }
}

export type SurveyQuestionDeleteMutationVariables = Exact<{
  questionId: Scalars['ID']['input']
}>

export type SurveyQuestionDeleteMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    questionDelete: {
      __typename?: 'SurveyQuestionDeletePayload'
      question: {
        __typename?: 'SurveyQuestion'
        id: string
        modifiers?: any | null
        subcategoryId: string
        guidedStepId?: string | null
      }
    }
  }
}

export type SurveyQuestionUpdateMutationVariables = Exact<{
  questionId: Scalars['ID']['input']
  eraseUiCode?: InputMaybe<Scalars['Boolean']['input']>
  subcategoryId?: InputMaybe<Scalars['ID']['input']>
  guidedStepId?: InputMaybe<Scalars['ID']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  modifiers?: InputMaybe<Scalars['JSONObject']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
  questionTypeId?: InputMaybe<Scalars['ID']['input']>
}>

export type SurveyQuestionUpdateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    questionUpdate: {
      __typename?: 'SurveyQuestionUpdatePayload'
      question: {
        __typename?: 'SurveyQuestion'
        id: string
        uiCode?: any | null
        modifiers?: any | null
        label: string
        description?: string | null
        subcategoryId: string
        sortOrder?: any | null
        datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
        datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
        guidedStepId?: string | null
        questionTemplateId?: string | null
        isTemplateQuestion?: boolean | null
        answerOptions: Array<
          | {
              __typename?: 'DatamapAnswerOption'
              id: string
              isSensitive?: boolean | null
              isCustom: boolean
              value?: string | null
              datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
              sortOrder?: any | null
              entity?:
                | {
                    __typename?: 'Asset'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataElement'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataLifecycle'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataOwnership'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataSharing'
                    id: string
                    needsValue: boolean
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataSource'
                    id: string
                    needsValue: boolean
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'DataSubject'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'Department'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'LegalBasis'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'Process'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'ProcessingType'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'Region'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename?: 'SurveyAnswerOption'
                    id: string
                    name?: string | null
                    uiCode?: any | null
                  }
                | null
            }
          | {
              __typename?: 'DatamapCustomAnswerOption'
              uiCode?: any | null
              value?: string | null
              sortOrder?: any | null
              isSensitive?: boolean | null
              isCustom: boolean
              datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
            }
          | {
              __typename?: 'SurveyAnswerOption'
              id: string
              value?: string | null
              uiCode?: any | null
              sortOrder?: any | null
            }
          | { __typename?: 'SurveyVirtualAnswerOption' }
        >
        type: {
          __typename?: 'QuestionType'
          id: string
          name: string
          code: string
          availableModifiers?: any | null
          isActive: boolean
          uiCode: any
        }
      }
    }
  }
}

export type SurveyResponseCreateMutationVariables = Exact<{
  input: SurveyResponsesCreateInput
}>

export type SurveyResponseCreateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    responsesCreate: {
      __typename?: 'SurveyResponsesCreatePayload'
      responses: Array<{
        __typename?: 'SurveyResponse'
        id: string
        value?: string | null
        answeredAt: any
        respondentId: string
        files: Array<{
          __typename?: 'BinaryFileInfo'
          id: string
          originalFilename?: string | null
        }>
        answerOption?:
          | { __typename?: 'DatamapAnswerOption'; id: string }
          | { __typename?: 'DatamapCustomAnswerOption'; id?: string | null }
          | { __typename?: 'SurveyAnswerOption'; id: string }
          | { __typename?: 'SurveyVirtualAnswerOption'; id?: string | null }
          | null
      }>
      datamapResponses?: Array<
        | {
            __typename?: 'DatamapDataElementResponse'
            id: string
            isCustom: boolean
            isSensitive?: boolean | null
            responseId: string
            parentResponseId: string
            datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
            datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            response: {
              __typename?: 'SurveyResponse'
              id: string
              value?: string | null
              respondentId: string
              questionId: string
              question: {
                __typename?: 'SurveyQuestion'
                id: string
                sortOrder?: any | null
              }
            }
            entity?:
              | {
                  __typename?: 'Asset'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataElement'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataLifecycle'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataOwnership'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSharing'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSource'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSubject'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Department'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'LegalBasis'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Process'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'ProcessingType'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Region'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  name?: string | null
                  uiCode?: any | null
                }
              | null
          }
        | {
            __typename?: 'DatamapDataSharingResponse'
            id: string
            sharedWith3rdParty?: string | null
            responseId: string
            parentResponseId: string
            datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
            datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            response: {
              __typename?: 'SurveyResponse'
              id: string
              value?: string | null
              respondentId: string
              questionId: string
              question: {
                __typename?: 'SurveyQuestion'
                id: string
                sortOrder?: any | null
              }
            }
            sharedWithDepartment?: {
              __typename?: 'Department'
              id: string
              name: string
              uiCode?: any | null
            } | null
            entity?:
              | {
                  __typename?: 'Asset'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataElement'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataLifecycle'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataOwnership'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSharing'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSource'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSubject'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Department'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'LegalBasis'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Process'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'ProcessingType'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Region'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  name?: string | null
                  uiCode?: any | null
                }
              | null
          }
        | {
            __typename?: 'DatamapDataSourceResponse'
            id: string
            providedBy3rdParty?: string | null
            isCustom: boolean
            responseId: string
            parentResponseId: string
            datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
            datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            response: {
              __typename?: 'SurveyResponse'
              id: string
              value?: string | null
              respondentId: string
              questionId: string
              question: {
                __typename?: 'SurveyQuestion'
                id: string
                sortOrder?: any | null
              }
            }
            providedByDepartment?: {
              __typename?: 'Department'
              id: string
              name: string
              uiCode?: any | null
            } | null
            entity?:
              | {
                  __typename?: 'Asset'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataElement'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataLifecycle'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataOwnership'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSharing'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSource'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSubject'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Department'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'LegalBasis'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Process'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'ProcessingType'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Region'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  name?: string | null
                  uiCode?: any | null
                }
              | null
          }
        | {
            __typename?: 'DatamapResponse'
            id: string
            responseId: string
            parentResponseId: string
            datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
            datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            response: {
              __typename?: 'SurveyResponse'
              id: string
              value?: string | null
              respondentId: string
              questionId: string
              question: {
                __typename?: 'SurveyQuestion'
                id: string
                sortOrder?: any | null
              }
            }
            entity?:
              | {
                  __typename?: 'Asset'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataElement'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataLifecycle'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataOwnership'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSharing'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSource'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSubject'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Department'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'LegalBasis'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Process'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'ProcessingType'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Region'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  name?: string | null
                  uiCode?: any | null
                }
              | null
          }
      > | null
    }
  }
}

export type SurveyResponseDeleteMutationVariables = Exact<{
  filter?: InputMaybe<SurveyResponseFilterArg>
}>

export type SurveyResponseDeleteMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    responseDelete: {
      __typename?: 'SurveyResponseDeletePayload'
      responses: Array<{ __typename?: 'SurveyResponse'; id: string }>
    }
  }
}

export type SurveyResponseDocumentUploadMutationVariables = Exact<{
  responseId: Scalars['ID']['input']
  file: Scalars['Upload']['input']
}>

export type SurveyResponseDocumentUploadMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    responseDocumentUpload: {
      __typename?: 'SurveyResponseDocumentUploadPayload'
      response: { __typename?: 'SurveyResponse'; id: string }
      fileInfo: { __typename?: 'BinaryFileInfo'; id: string; url: string }
    }
  }
}

export type SurveyResponseUpdateMutationVariables = Exact<{
  responseId: Scalars['ID']['input']
  answerOptionId?: InputMaybe<Scalars['ID']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}>

export type SurveyResponseUpdateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    responseUpdate: {
      __typename?: 'SurveyResponseUpdatePayload'
      response: {
        __typename?: 'SurveyResponse'
        id: string
        value?: string | null
        answeredAt: any
        respondentId: string
        files: Array<{
          __typename?: 'BinaryFileInfo'
          id: string
          originalFilename?: string | null
        }>
        answerOption?:
          | { __typename?: 'DatamapAnswerOption'; id: string }
          | { __typename?: 'DatamapCustomAnswerOption'; id?: string | null }
          | { __typename?: 'SurveyAnswerOption'; id: string }
          | { __typename?: 'SurveyVirtualAnswerOption'; id?: string | null }
          | null
      }
    }
  }
}

export type SurveyScheduleCreateMutationVariables = Exact<{
  surveyId: Scalars['ID']['input']
  startAt: Scalars['DateTime']['input']
  defaultSessionDurationDays: Scalars['NonNegativeInt']['input']
  periodDays?: InputMaybe<Scalars['NonNegativeInt']['input']>
}>

export type SurveyScheduleCreateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    scheduleCreate: {
      __typename?: 'SurveyScheduleCreatePayload'
      schedule: {
        __typename?: 'SurveySchedule'
        id: string
        survey: { __typename?: 'Survey'; id: string }
      }
    }
  }
}

export type SurveyScheduleDeleteMutationVariables = Exact<{
  scheduleId: Scalars['ID']['input']
}>

export type SurveyScheduleDeleteMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    scheduleDelete: {
      __typename?: 'SurveyScheduleDeletePayload'
      schedule: {
        __typename?: 'SurveySchedule'
        id: string
        survey: { __typename?: 'Survey'; id: string }
      }
    }
  }
}

export type SurveyScheduleUpdateMutationVariables = Exact<{
  scheduleId: Scalars['ID']['input']
  startAt?: InputMaybe<Scalars['DateTime']['input']>
  defaultSessionDurationDays?: InputMaybe<Scalars['NonNegativeInt']['input']>
  periodDays?: InputMaybe<Scalars['NonNegativeInt']['input']>
}>

export type SurveyScheduleUpdateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    scheduleUpdate: {
      __typename?: 'SurveyScheduleUpdatePayload'
      schedule: {
        __typename?: 'SurveySchedule'
        id: string
        survey: { __typename?: 'Survey'; id: string }
      }
    }
  }
}

export type SurveySessionCreateMutationVariables = Exact<{
  surveyId: Scalars['ID']['input']
  startedAt: Scalars['DateTime']['input']
  finishedAt: Scalars['DateTime']['input']
  deadlineAt: Scalars['DateTime']['input']
}>

export type SurveySessionCreateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    sessionCreate: {
      __typename?: 'SurveySessionCreatePayload'
      survey: { __typename?: 'Survey'; id: string }
      session: {
        __typename?: 'SurveySession'
        id: string
        startedAt: any
        deadlineAt?: any | null
        finishedAt: any
      }
    }
  }
}

export type SurveySessionDeleteMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
}>

export type SurveySessionDeleteMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    sessionDelete: {
      __typename?: 'SurveySessionDeletePayload'
      survey: { __typename?: 'Survey'; id: string }
      session: { __typename?: 'SurveySession'; id: string }
    }
  }
}

export type SurveySessionUpdateMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  startedAt?: InputMaybe<Scalars['DateTime']['input']>
  finishedAt: Scalars['DateTime']['input']
  deadlineAt: Scalars['DateTime']['input']
}>

export type SurveySessionUpdateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    sessionUpdate: {
      __typename?: 'SurveySessionUpdatePayload'
      survey: { __typename?: 'Survey'; id: string }
      session: {
        __typename?: 'SurveySession'
        id: string
        startedAt: any
        deadlineAt?: any | null
        finishedAt: any
      }
    }
  }
}

export type SurveySubcategoryCreateFromTemplateMutationVariables = Exact<{
  surveyId: Scalars['ID']['input']
  subcategoryTemplateIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type SurveySubcategoryCreateFromTemplateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    subcategoryCreateFromTemplate: {
      __typename?: 'SurveySubcategoryCreateFromTemplatePayload'
      survey: {
        __typename?: 'Survey'
        id: string
        surveyId: string
        name: string
        questionsCount: any
        isDraft: boolean
        isTemplate: boolean
        type: SurveyType
        createdAt: any
        lastUsedAt?: any | null
        sessions: Array<{
          __typename?: 'SurveySession'
          id: string
          finishedAt: any
          deadlineAt?: any | null
        }>
        subcategories: Array<{
          __typename?: 'SurveySubcategory'
          id: string
          header: string
          uiCode?: any | null
          description?: string | null
          isGuided: boolean
          sortOrder?: any | null
          questionsCount: any
          questions: Array<{
            __typename?: 'SurveyQuestion'
            id: string
            uiCode?: any | null
            modifiers?: any | null
            label: string
            description?: string | null
            subcategoryId: string
            sortOrder?: any | null
            datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            guidedStepId?: string | null
            questionTemplateId?: string | null
            isTemplateQuestion?: boolean | null
            answerOptions: Array<
              | {
                  __typename?: 'DatamapAnswerOption'
                  id: string
                  isSensitive?: boolean | null
                  isCustom: boolean
                  value?: string | null
                  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
                  sortOrder?: any | null
                  entity?:
                    | {
                        __typename?: 'Asset'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataElement'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataLifecycle'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataOwnership'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataSharing'
                        id: string
                        needsValue: boolean
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataSource'
                        id: string
                        needsValue: boolean
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataSubject'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'Department'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'LegalBasis'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'Process'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'ProcessingType'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'Region'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'SurveyAnswerOption'
                        id: string
                        name?: string | null
                        uiCode?: any | null
                      }
                    | null
                }
              | {
                  __typename?: 'DatamapCustomAnswerOption'
                  uiCode?: any | null
                  value?: string | null
                  sortOrder?: any | null
                  isSensitive?: boolean | null
                  isCustom: boolean
                  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  value?: string | null
                  uiCode?: any | null
                  sortOrder?: any | null
                }
              | { __typename?: 'SurveyVirtualAnswerOption' }
            >
            type: {
              __typename?: 'QuestionType'
              id: string
              name: string
              code: string
              availableModifiers?: any | null
              isActive: boolean
              uiCode: any
            }
          }>
          guidedSteps?: Array<{
            __typename?: 'SurveyGuidedStep'
            id: string
            name: string
            description?: string | null
            sortOrder?: any | null
          }> | null
          subcategoryTemplate?: {
            __typename?: 'SurveySubcategoryTemplate'
            id: string
            header: string
            isSelected?: boolean | null
            isConfirmed?: boolean | null
            description?: string | null
            sortOrder: any
            questionTemplatesCount: any
            category: {
              __typename?: 'SurveyCategory'
              id: string
              regulation?: {
                __typename?: 'Regulation'
                id: string
                name: string
                uiCode?: any | null
              } | null
            }
          } | null
        }>
      }
    }
  }
}

export type SurveySubcategoryCreateMutationVariables = Exact<{
  surveyId: Scalars['ID']['input']
  header: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  sortOrder?: InputMaybe<Scalars['NonNegativeInt']['input']>
}>

export type SurveySubcategoryCreateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    subcategoryCreate: {
      __typename?: 'SurveySubcategoryCreatePayload'
      survey: {
        __typename?: 'Survey'
        id: string
        surveyId: string
        name: string
        questionsCount: any
        isDraft: boolean
        isTemplate: boolean
        type: SurveyType
        createdAt: any
        lastUsedAt?: any | null
        sessions: Array<{
          __typename?: 'SurveySession'
          id: string
          finishedAt: any
          deadlineAt?: any | null
        }>
        subcategories: Array<{
          __typename?: 'SurveySubcategory'
          id: string
          header: string
          uiCode?: any | null
          description?: string | null
          isGuided: boolean
          sortOrder?: any | null
          questionsCount: any
          questions: Array<{
            __typename?: 'SurveyQuestion'
            id: string
            uiCode?: any | null
            modifiers?: any | null
            label: string
            description?: string | null
            subcategoryId: string
            sortOrder?: any | null
            datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            guidedStepId?: string | null
            questionTemplateId?: string | null
            isTemplateQuestion?: boolean | null
            answerOptions: Array<
              | {
                  __typename?: 'DatamapAnswerOption'
                  id: string
                  isSensitive?: boolean | null
                  isCustom: boolean
                  value?: string | null
                  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
                  sortOrder?: any | null
                  entity?:
                    | {
                        __typename?: 'Asset'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataElement'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataLifecycle'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataOwnership'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataSharing'
                        id: string
                        needsValue: boolean
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataSource'
                        id: string
                        needsValue: boolean
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataSubject'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'Department'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'LegalBasis'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'Process'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'ProcessingType'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'Region'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'SurveyAnswerOption'
                        id: string
                        name?: string | null
                        uiCode?: any | null
                      }
                    | null
                }
              | {
                  __typename?: 'DatamapCustomAnswerOption'
                  uiCode?: any | null
                  value?: string | null
                  sortOrder?: any | null
                  isSensitive?: boolean | null
                  isCustom: boolean
                  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  value?: string | null
                  uiCode?: any | null
                  sortOrder?: any | null
                }
              | { __typename?: 'SurveyVirtualAnswerOption' }
            >
            type: {
              __typename?: 'QuestionType'
              id: string
              name: string
              code: string
              availableModifiers?: any | null
              isActive: boolean
              uiCode: any
            }
          }>
          guidedSteps?: Array<{
            __typename?: 'SurveyGuidedStep'
            id: string
            name: string
            description?: string | null
            sortOrder?: any | null
          }> | null
          subcategoryTemplate?: {
            __typename?: 'SurveySubcategoryTemplate'
            id: string
            header: string
            isSelected?: boolean | null
            isConfirmed?: boolean | null
            description?: string | null
            sortOrder: any
            questionTemplatesCount: any
            category: {
              __typename?: 'SurveyCategory'
              id: string
              regulation?: {
                __typename?: 'Regulation'
                id: string
                name: string
                uiCode?: any | null
              } | null
            }
          } | null
        }>
      }
    }
  }
}

export type SurveySubcategoryDeleteMutationVariables = Exact<{
  subcategoryId: Scalars['ID']['input']
}>

export type SurveySubcategoryDeleteMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    subcategoryDelete: {
      __typename?: 'SurveySubcategoryDeletePayload'
      survey: {
        __typename?: 'Survey'
        id: string
        surveyId: string
        name: string
        questionsCount: any
        isDraft: boolean
        isTemplate: boolean
        type: SurveyType
        createdAt: any
        lastUsedAt?: any | null
        sessions: Array<{
          __typename?: 'SurveySession'
          id: string
          finishedAt: any
          deadlineAt?: any | null
        }>
        subcategories: Array<{
          __typename?: 'SurveySubcategory'
          id: string
          header: string
          uiCode?: any | null
          description?: string | null
          isGuided: boolean
          sortOrder?: any | null
          questionsCount: any
          questions: Array<{
            __typename?: 'SurveyQuestion'
            id: string
            uiCode?: any | null
            modifiers?: any | null
            label: string
            description?: string | null
            subcategoryId: string
            sortOrder?: any | null
            datamapEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            guidedStepId?: string | null
            questionTemplateId?: string | null
            isTemplateQuestion?: boolean | null
            answerOptions: Array<
              | {
                  __typename?: 'DatamapAnswerOption'
                  id: string
                  isSensitive?: boolean | null
                  isCustom: boolean
                  value?: string | null
                  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
                  sortOrder?: any | null
                  entity?:
                    | {
                        __typename?: 'Asset'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataElement'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataLifecycle'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataOwnership'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataSharing'
                        id: string
                        needsValue: boolean
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataSource'
                        id: string
                        needsValue: boolean
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'DataSubject'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'Department'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'LegalBasis'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'Process'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'ProcessingType'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'Region'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename?: 'SurveyAnswerOption'
                        id: string
                        name?: string | null
                        uiCode?: any | null
                      }
                    | null
                }
              | {
                  __typename?: 'DatamapCustomAnswerOption'
                  uiCode?: any | null
                  value?: string | null
                  sortOrder?: any | null
                  isSensitive?: boolean | null
                  isCustom: boolean
                  datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  value?: string | null
                  uiCode?: any | null
                  sortOrder?: any | null
                }
              | { __typename?: 'SurveyVirtualAnswerOption' }
            >
            type: {
              __typename?: 'QuestionType'
              id: string
              name: string
              code: string
              availableModifiers?: any | null
              isActive: boolean
              uiCode: any
            }
          }>
          guidedSteps?: Array<{
            __typename?: 'SurveyGuidedStep'
            id: string
            name: string
            description?: string | null
            sortOrder?: any | null
          }> | null
          subcategoryTemplate?: {
            __typename?: 'SurveySubcategoryTemplate'
            id: string
            header: string
            isSelected?: boolean | null
            isConfirmed?: boolean | null
            description?: string | null
            sortOrder: any
            questionTemplatesCount: any
            category: {
              __typename?: 'SurveyCategory'
              id: string
              regulation?: {
                __typename?: 'Regulation'
                id: string
                name: string
                uiCode?: any | null
              } | null
            }
          } | null
        }>
      }
    }
  }
}

export type SurveyConvertIntoTemplateMutationVariables = Exact<{
  input: SurveySurveyConvertIntoTemplateInput
}>

export type SurveyConvertIntoTemplateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    surveyConvertIntoTemplate: {
      __typename?: 'SurveySurveyConvertIntoTemplatePayload'
      subcategoryTemplate?: Array<{
        __typename?: 'SurveySubcategoryTemplate'
        id: string
        isActive: boolean
        header: string
        description?: string | null
        sortOrder: any
      } | null> | null
    }
  }
}

export type SurveyUpdateMutationVariables = Exact<{
  surveyId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  isDraft?: InputMaybe<Scalars['Boolean']['input']>
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>
}>

export type SurveyUpdateMutation = {
  __typename?: 'Mutation'
  survey: {
    __typename?: 'NamespaceSurveyMutation'
    update: {
      __typename?: 'SurveyUpdatePayload'
      survey: { __typename?: 'Survey'; id: string }
    }
  }
}

export type SurveyCategoriesGetQueryVariables = Exact<{
  filter?: InputMaybe<SurveyCategoryFilterArg>
}>

export type SurveyCategoriesGetQuery = {
  __typename?: 'Query'
  survey: {
    __typename?: 'NamespaceSurveyQuery'
    categoriesGet: {
      __typename?: 'SurveyCategoriesGetPayload'
      categories: Array<{
        __typename?: 'SurveyCategory'
        id: string
        categoryId: string
        name?: string | null
        isActive: boolean
        regulation?: {
          __typename?: 'Regulation'
          id: string
          name: string
          logoPath?: string | null
          uiCode?: any | null
          description?: string | null
          isActive: boolean
        } | null
        subcategoryTemplates: Array<{
          __typename?: 'SurveySubcategoryTemplate'
          id: string
          subcategoryTemplateId: string
          description?: string | null
          sortOrder: any
          header: string
          isActive: boolean
        }>
      }>
    }
  }
}

export type SurveyGetQueryVariables = Exact<{
  filter?: InputMaybe<SurveyFilterArg>
  pagination?: InputMaybe<PaginationArg>
  sessionId: Scalars['ID']['input']
  order?: InputMaybe<Array<SurveyGetOrderArg> | SurveyGetOrderArg>
}>

export type SurveyGetQuery = {
  __typename?: 'Query'
  survey: {
    __typename?: 'NamespaceSurveyQuery'
    get: {
      __typename?: 'SurveyGetPayload'
      surveys: Array<{
        __typename?: 'Survey'
        id: string
        surveyId: string
        name: string
        type: SurveyType
        categories: Array<{
          __typename?: 'SurveyCategory'
          id: string
          name?: string | null
          regulation?: {
            __typename?: 'Regulation'
            id: string
            uiCode?: any | null
            name: string
          } | null
        }>
        audiences: Array<{
          __typename?: 'Audience'
          id: string
          name: string
          users: Array<{
            __typename?: 'User'
            id: string
            firstName?: string | null
            lastName?: string | null
          }>
        }>
        company: {
          __typename?: 'Company'
          id: string
          companyId: string
          selectedRegulations: Array<{
            __typename?: 'Regulation'
            id: string
            regulationId: string
            name: string
            uiCode?: any | null
            logoPath?: string | null
            description?: string | null
          }>
        }
        subcategories: Array<{
          __typename?: 'SurveySubcategory'
          id: string
          description?: string | null
          sortOrder?: any | null
          isGuided: boolean
          questions: Array<{
            __typename?: 'SurveyQuestion'
            id: string
            label: string
            maturityScore?: any | null
            description?: string | null
            dependsOn: Array<{
              __typename?: 'SurveyDependency'
              question: { __typename?: 'SurveyQuestion'; id: string }
              answerOption: { __typename?: 'SurveyAnswerOption'; id: string }
            }>
            type: {
              __typename?: 'QuestionType'
              id: string
              uiCode: any
              name: string
            }
          }>
        }>
        schedules: Array<{
          __typename?: 'SurveySchedule'
          id: string
          startAt: any
          periodDays?: any | null
          defaultSessionDurationDays: any
        }>
        sessions: Array<{
          __typename?: 'SurveySession'
          id: string
          startedAt: any
          finishedAt: any
        }>
      }>
      paginationInfo: {
        __typename?: 'PaginationInfo'
        totalItems: any
        totalPages: any
      }
    }
  }
}

export type SurveyQuestionTypesGetQueryVariables = Exact<{
  filter?: InputMaybe<QuestionTypeFilterArg>
}>

export type SurveyQuestionTypesGetQuery = {
  __typename?: 'Query'
  survey: {
    __typename?: 'NamespaceSurveyQuery'
    questionTypesGet: {
      __typename?: 'SurveyQuestionTypesGetPayload'
      questionTypes: Array<{
        __typename?: 'QuestionType'
        id: string
        name: string
        code: string
        uiCode: any
      } | null>
    }
  }
}

export type SurveyDatamapResponsesGetQueryVariables = Exact<{
  input?: InputMaybe<SurveyDatamapResponsesGetInput>
}>

export type SurveyDatamapResponsesGetQuery = {
  __typename?: 'Query'
  survey: {
    __typename?: 'NamespaceSurveyQuery'
    datamapResponsesGet: {
      __typename?: 'SurveyDatamapResponsesGetPayload'
      datamaps?: Array<{ __typename?: 'Datamap'; id: string } | null> | null
      datamapResponses?: Array<
        | {
            __typename?: 'DatamapDataElementResponse'
            id: string
            isCustom: boolean
            isSensitive?: boolean | null
            responseId: string
            parentResponseId: string
            datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
            datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            response: {
              __typename?: 'SurveyResponse'
              id: string
              value?: string | null
              respondentId: string
              questionId: string
              question: {
                __typename?: 'SurveyQuestion'
                id: string
                sortOrder?: any | null
              }
            }
            entity?:
              | {
                  __typename?: 'Asset'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataElement'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataLifecycle'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataOwnership'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSharing'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSource'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSubject'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Department'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'LegalBasis'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Process'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'ProcessingType'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Region'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  name?: string | null
                  uiCode?: any | null
                }
              | null
          }
        | {
            __typename?: 'DatamapDataSharingResponse'
            id: string
            sharedWith3rdParty?: string | null
            responseId: string
            parentResponseId: string
            datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
            datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            response: {
              __typename?: 'SurveyResponse'
              id: string
              value?: string | null
              respondentId: string
              questionId: string
              question: {
                __typename?: 'SurveyQuestion'
                id: string
                sortOrder?: any | null
              }
            }
            sharedWithDepartment?: {
              __typename?: 'Department'
              id: string
              name: string
              uiCode?: any | null
            } | null
            entity?:
              | {
                  __typename?: 'Asset'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataElement'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataLifecycle'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataOwnership'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSharing'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSource'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSubject'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Department'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'LegalBasis'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Process'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'ProcessingType'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Region'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  name?: string | null
                  uiCode?: any | null
                }
              | null
          }
        | {
            __typename?: 'DatamapDataSourceResponse'
            id: string
            providedBy3rdParty?: string | null
            isCustom: boolean
            responseId: string
            parentResponseId: string
            datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
            datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            response: {
              __typename?: 'SurveyResponse'
              id: string
              value?: string | null
              respondentId: string
              questionId: string
              question: {
                __typename?: 'SurveyQuestion'
                id: string
                sortOrder?: any | null
              }
            }
            providedByDepartment?: {
              __typename?: 'Department'
              id: string
              name: string
              uiCode?: any | null
            } | null
            entity?:
              | {
                  __typename?: 'Asset'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataElement'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataLifecycle'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataOwnership'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSharing'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSource'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSubject'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Department'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'LegalBasis'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Process'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'ProcessingType'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Region'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  name?: string | null
                  uiCode?: any | null
                }
              | null
          }
        | {
            __typename?: 'DatamapResponse'
            id: string
            responseId: string
            parentResponseId: string
            datamapEntityTypeCode: SurveyDatamapEntityTypeEnum
            datamapParentEntityTypeCode?: SurveyDatamapEntityTypeEnum | null
            response: {
              __typename?: 'SurveyResponse'
              id: string
              value?: string | null
              respondentId: string
              questionId: string
              question: {
                __typename?: 'SurveyQuestion'
                id: string
                sortOrder?: any | null
              }
            }
            entity?:
              | {
                  __typename?: 'Asset'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataElement'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataLifecycle'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataOwnership'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSharing'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSource'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'DataSubject'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Department'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'LegalBasis'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Process'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'ProcessingType'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'Region'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename?: 'SurveyAnswerOption'
                  id: string
                  name?: string | null
                  uiCode?: any | null
                }
              | null
          }
      > | null
    }
  }
}

export type SurveyResponsesGetQueryVariables = Exact<{
  filter?: InputMaybe<SurveyResponseFilterArg>
}>

export type SurveyResponsesGetQuery = {
  __typename?: 'Query'
  survey: {
    __typename?: 'NamespaceSurveyQuery'
    responsesGet: {
      __typename?: 'SurveyResponsesGetPayload'
      responses: Array<{
        __typename?: 'SurveyResponse'
        id: string
        value?: string | null
        answeredAt: any
        respondentId: string
        questionId: string
        answerOption?:
          | { __typename?: 'DatamapAnswerOption'; id: string }
          | { __typename?: 'DatamapCustomAnswerOption'; id?: string | null }
          | { __typename?: 'SurveyAnswerOption'; id: string }
          | { __typename?: 'SurveyVirtualAnswerOption'; id?: string | null }
          | null
        respondent: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        }
        question: {
          __typename?: 'SurveyQuestion'
          id: string
          subcategoryId: string
        }
      }>
    }
  }
}

export type SurveySubcategoryTemplatesGetQueryVariables = Exact<{
  filter?: InputMaybe<SurveySubcategoryTemplatesFilterArg>
}>

export type SurveySubcategoryTemplatesGetQuery = {
  __typename?: 'Query'
  survey: {
    __typename?: 'NamespaceSurveyQuery'
    subcategoryTemplatesGet: {
      __typename?: 'SurveySubcategoryTemplatesGetPayload'
      subcategoryTemplates: Array<{
        __typename?: 'SurveySubcategoryTemplate'
        id: string
        uiCode?: any | null
        isSelected?: boolean | null
        isConfirmed?: boolean | null
        isActive: boolean
        isGuided: boolean
        header: string
        description?: string | null
        sortOrder: any
        questionTemplatesCount: any
        questionTemplates: Array<{
          __typename?: 'SurveyQuestionTemplate'
          id: string
          uiCode?: any | null
          modifiers?: any | null
          label: string
          description?: string | null
          sortOrder: any
          answerOptionTemplates?: Array<{
            __typename?: 'SurveyAnswerOptionTemplate'
            id: string
            value?: string | null
          }> | null
          type: {
            __typename?: 'QuestionType'
            id: string
            name: string
            code: string
            availableModifiers?: any | null
            isActive: boolean
            uiCode: any
          }
        }>
        category: {
          __typename?: 'SurveyCategory'
          id: string
          regulation?: {
            __typename?: 'Regulation'
            id: string
            name: string
            uiCode?: any | null
          } | null
        }
      }>
    }
  }
}

export type AuthenticatedUserFragment = {
  __typename?: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
  phoneNumber?: any | null
  passwordLastChangeAt?: any | null
  isMfaEnabled?: boolean | null
  email: any
  isAdmin?: boolean | null
  isPalqeeManager?: boolean | null
  isRegistrationComplete?: boolean | null
  status: UserStatusEnum
  language?: LanguageCodeEnum | null
  defaultCompanyId?: string | null
  defaultCompany?: {
    __typename?: 'Company'
    id: string
    name?: string | null
    numberOfEmployees: any
    industries: Array<{ __typename?: 'Industry'; id: string; name: string }>
    address?: {
      __typename?: 'Address'
      id: string
      country: { __typename?: 'Country'; id: string; name: string }
    } | null
  } | null
  companies: Array<{
    __typename?: 'Company'
    id: string
    name?: string | null
    logo?: {
      __typename?: 'BinaryFileInfoPublic'
      publicUrl?: string | null
      id: string
      isPublic: boolean
      originalFilename?: string | null
      mimetype: string
      url: string
    } | null
    currentSubscriptionTier?: {
      __typename?: 'SubscriptionTier'
      id: string
      name: string
      features?: Array<
        | {
            __typename?: 'SubscriptionFeatureBoolean'
            available: boolean
            id: string
            name: string
            code: string
            description?: string | null
          }
        | {
            __typename?: 'SubscriptionFeatureIntRange'
            minValue?: number | null
            maxValue?: number | null
            id: string
            name: string
            code: string
            description?: string | null
          }
      > | null
    } | null
  }>
  roles: Array<{
    __typename?: 'UserRole'
    userId: string
    roleId: string
    name: string
    uiCode: any
    companyId?: string | null
    code: AuthRoleCodeEnum
  }>
  tenant: {
    __typename?: 'Tenant'
    hasPermanentAccess: boolean
    trialExpiredAt?: any | null
  }
}

export type AuthenticatedUserWithPositionsFragment = {
  __typename?: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
  phoneNumber?: any | null
  passwordLastChangeAt?: any | null
  isMfaEnabled?: boolean | null
  email: any
  isAdmin?: boolean | null
  isPalqeeManager?: boolean | null
  isRegistrationComplete?: boolean | null
  status: UserStatusEnum
  language?: LanguageCodeEnum | null
  defaultCompanyId?: string | null
  positions: Array<{
    __typename?: 'UserPosition'
    departmentId: string
    positionId: string
    position: {
      __typename?: 'Position'
      id: string
      name: string
      companyId: string
    }
    department: {
      __typename?: 'Department'
      id: string
      name: string
      companyId: string
    }
  }>
  defaultCompany?: {
    __typename?: 'Company'
    id: string
    name?: string | null
    numberOfEmployees: any
    industries: Array<{ __typename?: 'Industry'; id: string; name: string }>
    address?: {
      __typename?: 'Address'
      id: string
      country: { __typename?: 'Country'; id: string; name: string }
    } | null
  } | null
  companies: Array<{
    __typename?: 'Company'
    id: string
    name?: string | null
    logo?: {
      __typename?: 'BinaryFileInfoPublic'
      publicUrl?: string | null
      id: string
      isPublic: boolean
      originalFilename?: string | null
      mimetype: string
      url: string
    } | null
    currentSubscriptionTier?: {
      __typename?: 'SubscriptionTier'
      id: string
      name: string
      features?: Array<
        | {
            __typename?: 'SubscriptionFeatureBoolean'
            available: boolean
            id: string
            name: string
            code: string
            description?: string | null
          }
        | {
            __typename?: 'SubscriptionFeatureIntRange'
            minValue?: number | null
            maxValue?: number | null
            id: string
            name: string
            code: string
            description?: string | null
          }
      > | null
    } | null
  }>
  roles: Array<{
    __typename?: 'UserRole'
    userId: string
    roleId: string
    name: string
    uiCode: any
    companyId?: string | null
    code: AuthRoleCodeEnum
  }>
  tenant: {
    __typename?: 'Tenant'
    hasPermanentAccess: boolean
    trialExpiredAt?: any | null
  }
}

export type UserCreateMutationVariables = Exact<{
  email: Scalars['EmailAddress']['input']
  companyId: Scalars['ID']['input']
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  title?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<LanguageCodeEnum>
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum> | AuthRoleCodeEnum>
  sendRegistrationEmail?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UserCreateMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'NamespaceUserMutation'
    create: {
      __typename?: 'UserCreatePayload'
      user: {
        __typename?: 'User'
        id: string
        userId: string
        firstName?: string | null
        lastName?: string | null
        email: any
        status: UserStatusEnum
        roles: Array<{
          __typename?: 'UserRole'
          userId: string
          roleId: string
          name: string
          companyId?: string | null
          code: AuthRoleCodeEnum
        }>
        positions: Array<{
          __typename?: 'UserPosition'
          position: {
            __typename?: 'Position'
            id: string
            name: string
            companyId: string
          }
          department: {
            __typename?: 'Department'
            id: string
            name: string
            companyId: string
          }
        }>
      }
    }
  }
}

export type UserDeleteMutationVariables = Exact<{
  input: UserDeleteInput
}>

export type UserDeleteMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'NamespaceUserMutation'
    delete: {
      __typename?: 'UserDeletePayload'
      users: Array<{
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        email: any
      }>
    }
  }
}

export type UserPositionSetMutationVariables = Exact<{
  userId: Scalars['ID']['input']
  departmentId: Scalars['ID']['input']
  positionId: Scalars['ID']['input']
  companyId: Scalars['ID']['input']
}>

export type UserPositionSetMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'NamespaceUserMutation'
    positionSet: {
      __typename?: 'UserPositionSetPayload'
      user: {
        __typename?: 'User'
        id: string
        userId: string
        firstName?: string | null
        lastName?: string | null
        email: any
        status: UserStatusEnum
        roles: Array<{
          __typename?: 'UserRole'
          userId: string
          roleId: string
          name: string
          companyId?: string | null
          code: AuthRoleCodeEnum
        }>
        positions: Array<{
          __typename?: 'UserPosition'
          position: {
            __typename?: 'Position'
            id: string
            name: string
            companyId: string
          }
          department: {
            __typename?: 'Department'
            id: string
            name: string
            companyId: string
          }
        }>
      }
    }
  }
}

export type UserRolesSetMutationVariables = Exact<{
  userId: Scalars['ID']['input']
  companyId: Scalars['ID']['input']
  roleCodes: Array<AuthRoleCodeEnum> | AuthRoleCodeEnum
}>

export type UserRolesSetMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'NamespaceUserMutation'
    rolesSet: {
      __typename?: 'UserRolesSetPayload'
      user: {
        __typename?: 'User'
        id: string
        roles: Array<{
          __typename?: 'UserRole'
          userId: string
          roleId: string
          name: string
          code: AuthRoleCodeEnum
          companyId?: string | null
        }>
      }
    }
  }
}

export type UserUpsertMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']['input']>
  email?: InputMaybe<Scalars['EmailAddress']['input']>
  companyId?: InputMaybe<Scalars['ID']['input']>
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  roleCodes?: InputMaybe<Array<AuthRoleCodeEnum> | AuthRoleCodeEnum>
  sendRegistrationEmail?: InputMaybe<Scalars['Boolean']['input']>
  defaultCompanyId?: InputMaybe<Scalars['ID']['input']>
  language?: InputMaybe<LanguageCodeEnum>
}>

export type UserUpsertMutation = {
  __typename?: 'Mutation'
  user: {
    __typename?: 'NamespaceUserMutation'
    upsert: {
      __typename?: 'UserUpsertPayload'
      user: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        phoneNumber?: any | null
        passwordLastChangeAt?: any | null
        isMfaEnabled?: boolean | null
        email: any
        isAdmin?: boolean | null
        isPalqeeManager?: boolean | null
        isRegistrationComplete?: boolean | null
        status: UserStatusEnum
        language?: LanguageCodeEnum | null
        defaultCompanyId?: string | null
        defaultCompany?: {
          __typename?: 'Company'
          id: string
          name?: string | null
          numberOfEmployees: any
          industries: Array<{
            __typename?: 'Industry'
            id: string
            name: string
          }>
          address?: {
            __typename?: 'Address'
            id: string
            country: { __typename?: 'Country'; id: string; name: string }
          } | null
        } | null
        companies: Array<{
          __typename?: 'Company'
          id: string
          name?: string | null
          logo?: {
            __typename?: 'BinaryFileInfoPublic'
            publicUrl?: string | null
            id: string
            isPublic: boolean
            originalFilename?: string | null
            mimetype: string
            url: string
          } | null
          currentSubscriptionTier?: {
            __typename?: 'SubscriptionTier'
            id: string
            name: string
            features?: Array<
              | {
                  __typename?: 'SubscriptionFeatureBoolean'
                  available: boolean
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
              | {
                  __typename?: 'SubscriptionFeatureIntRange'
                  minValue?: number | null
                  maxValue?: number | null
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
            > | null
          } | null
        }>
        roles: Array<{
          __typename?: 'UserRole'
          userId: string
          roleId: string
          name: string
          uiCode: any
          companyId?: string | null
          code: AuthRoleCodeEnum
        }>
        tenant: {
          __typename?: 'Tenant'
          hasPermanentAccess: boolean
          trialExpiredAt?: any | null
        }
      }
    }
  }
}

export type UserGetQueryVariables = Exact<{
  input: UserGetInput
}>

export type UserGetQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'NamespaceUserQuery'
    get: {
      __typename?: 'UserGetPayload'
      users: Array<{
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        email: any
        status: UserStatusEnum
        phoneNumber?: any | null
        isMfaEnabled?: boolean | null
        roles: Array<{
          __typename?: 'UserRole'
          userId: string
          companyId?: string | null
          roleId: string
          name: string
          code: AuthRoleCodeEnum
        }>
        positions: Array<{
          __typename?: 'UserPosition'
          position: {
            __typename?: 'Position'
            id: string
            name: string
            companyId: string
          }
          department: {
            __typename?: 'Department'
            id: string
            name: string
            companyId: string
          }
        }>
      }>
      paginationInfo: {
        __typename?: 'PaginationInfo'
        offset?: any | null
        limit?: any | null
        totalItems: any
        totalPages: any
      }
    }
  }
}

export type AuthCurrentUserLanguageQueryVariables = Exact<{
  [key: string]: never
}>

export type AuthCurrentUserLanguageQuery = {
  __typename?: 'Query'
  auth: {
    __typename?: 'NamespaceAuthQuery'
    userCurrent: {
      __typename?: 'AuthUserCurrentPayload'
      user?: {
        __typename?: 'User'
        id: string
        language?: LanguageCodeEnum | null
      } | null
    }
  }
}

export const CompanyBasicWithSlugFragmentDoc = gql`
  fragment CompanyBasicWithSlug on Company {
    id
    slug
    usersPrimaryEmailDomain
  }
`
export const IdentityApplicationsGetMainFragmentDoc = gql`
  fragment IdentityApplicationsGetMain on AuthIdentityApplicationsGetPayload {
    companies {
      ...CompanyBasicWithSlug
    }
    identityApplications {
      id
      identityProviderCode
      name
      uriInit
      uriLogout
      uriProviderRedirect
      specificConfig
    }
  }
  ${CompanyBasicWithSlugFragmentDoc}
`
export const LoginIdentityApplicationsGetFragmentDoc = gql`
  fragment LoginIdentityApplicationsGet on AuthIdentityApplicationsGetPayload {
    companies {
      name
      logo {
        publicUrl
      }
    }
    identityApplications {
      identityProviderCode
      uriInit
    }
  }
`
export const CompanySelectedRegulationFragmentDoc = gql`
  fragment CompanySelectedRegulation on Regulation {
    id
    regulationId
    name
    uiCode
    name
    logoPath
    description
  }
`
export const DepartmentFragmentDoc = gql`
  fragment Department on Department {
    id
    name
    description
  }
`
export const CompanyBinaryFileInfoFragmentDoc = gql`
  fragment CompanyBinaryFileInfo on IBinaryFileInfo {
    id
    isPublic
    originalFilename
    mimetype
    url
    ... on BinaryFileInfoPublic {
      publicUrl
    }
  }
`
export const CompanyMainFragmentDoc = gql`
  fragment CompanyMain on Company {
    id
    name
    logo {
      ...CompanyBinaryFileInfo
    }
  }
  ${CompanyBinaryFileInfoFragmentDoc}
`
export const CompanyBasicFragmentDoc = gql`
  fragment CompanyBasic on Company {
    id
    name
    numberOfEmployees
    industries {
      id
      name
    }
    address {
      id
      streetAddress
      streetAddress2
      locality
      subAdministrativeArea
      administrativeArea
      postalCode
      country {
        id
        name
      }
    }
    regions {
      id
      name
    }
    logo {
      ...CompanyBinaryFileInfo
    }
    currentSubscriptionTier {
      id
      name
      features {
        id
        name
        code
        description
        ... on SubscriptionFeatureIntRange {
          minValue
          maxValue
        }
        ... on SubscriptionFeatureBoolean {
          available
        }
      }
    }
  }
  ${CompanyBinaryFileInfoFragmentDoc}
`
export const CompanyFullDetailsFragmentDoc = gql`
  fragment CompanyFullDetails on Company {
    ...CompanyBasic
    numberOfEmployees
    structure {
      id
      name
      uiCode
    }
    address {
      id
      country {
        id
        name
      }
    }
  }
  ${CompanyBasicFragmentDoc}
`
export const FileInfoFragmentDoc = gql`
  fragment FileInfo on BinaryFileInfo {
    id
    binaryFileInfoId
    originalFilename
    mimetype
    contentLength
    url(input: $fileUrlInput)
  }
`
export const DocumentFieldsFragmentDoc = gql`
  fragment DocumentFields on DocboxDocument {
    id
    documentId
    folderId
    name
    description
    ownerId
    createdAt
    createdByUserId
    validUntil
    createdByUser {
      id
      firstName
      lastName
      roles {
        userId
        roleId
        name
        uiCode
        companyId
        code
      }
    }
    fileInfo {
      ...FileInfo
    }
  }
  ${FileInfoFragmentDoc}
`
export const SurveyAnswerOptionDetailsFragmentDoc = gql`
  fragment SurveyAnswerOptionDetails on SurveyAnswerOption {
    id
    value
    uiCode
    sortOrder
  }
`
export const DatamapEntityDetailsFragmentDoc = gql`
  fragment DatamapEntityDetails on IDatamapEntity {
    id
    name
    uiCode
    ... on DataSharing {
      id
      needsValue
    }
    ... on DataSource {
      id
      needsValue
    }
  }
`
export const DatamapAnswerOptionDetailsFragmentDoc = gql`
  fragment DatamapAnswerOptionDetails on DatamapAnswerOption {
    id
    isSensitive
    isCustom
    value
    datamapEntityTypeCode
    entity {
      ...DatamapEntityDetails
    }
    sortOrder
  }
  ${DatamapEntityDetailsFragmentDoc}
`
export const DatamapCustomAnswerOptionDetailsFragmentDoc = gql`
  fragment DatamapCustomAnswerOptionDetails on DatamapCustomAnswerOption {
    uiCode
    value
    sortOrder
    isSensitive
    isCustom
    datamapEntityTypeCode
  }
`
export const ISurveyAnswerOptionDetailsFragmentDoc = gql`
  fragment ISurveyAnswerOptionDetails on ISurveyAnswerOption {
    ...SurveyAnswerOptionDetails
    ...DatamapAnswerOptionDetails
    ...DatamapCustomAnswerOptionDetails
  }
  ${SurveyAnswerOptionDetailsFragmentDoc}
  ${DatamapAnswerOptionDetailsFragmentDoc}
  ${DatamapCustomAnswerOptionDetailsFragmentDoc}
`
export const SurveyQuestionDetailsFragmentDoc = gql`
  fragment SurveyQuestionDetails on SurveyQuestion {
    id
    uiCode
    modifiers
    label
    description
    subcategoryId
    sortOrder
    datamapEntityTypeCode
    datamapParentEntityTypeCode
    guidedStepId
    answerOptions {
      ...ISurveyAnswerOptionDetails
    }
    questionTemplateId
    isTemplateQuestion @client
    type {
      id
      name
      code
      availableModifiers
      isActive
      uiCode
    }
  }
  ${ISurveyAnswerOptionDetailsFragmentDoc}
`
export const GuidedStepsDetailsFragmentDoc = gql`
  fragment GuidedStepsDetails on SurveyGuidedStep {
    id
    name
    description
    sortOrder
  }
`
export const SurveySubcategoryDetailsFragmentDoc = gql`
  fragment SurveySubcategoryDetails on SurveySubcategory {
    id
    header
    uiCode
    description
    isGuided
    sortOrder
    questionsCount
    questions {
      ...SurveyQuestionDetails
    }
    guidedSteps {
      ...GuidedStepsDetails
    }
    subcategoryTemplate {
      id
      header
      isSelected @client
      isConfirmed @client
      description
      sortOrder
      questionTemplatesCount
      category {
        id
        regulation {
          id
          name
          uiCode
        }
      }
    }
  }
  ${SurveyQuestionDetailsFragmentDoc}
  ${GuidedStepsDetailsFragmentDoc}
`
export const SurveyDetailsFragmentDoc = gql`
  fragment SurveyDetails on Survey {
    id
    surveyId
    name
    questionsCount
    isDraft
    isTemplate
    type
    createdAt
    lastUsedAt
    sessions {
      id
      finishedAt
      deadlineAt
    }
    subcategories {
      ...SurveySubcategoryDetails
    }
  }
  ${SurveySubcategoryDetailsFragmentDoc}
`
export const QuestionTemplateDetailsFragmentDoc = gql`
  fragment QuestionTemplateDetails on SurveyQuestionTemplate {
    id
    uiCode
    modifiers
    label
    description
    sortOrder
    answerOptionTemplates {
      id
      value
    }
    type {
      id
      name
      code
      availableModifiers
      isActive
      uiCode
    }
  }
`
export const SurveySubcategoryTemplateDetailsFragmentDoc = gql`
  fragment SurveySubcategoryTemplateDetails on SurveySubcategoryTemplate {
    id
    uiCode
    isSelected @client
    isConfirmed @client
    isActive
    isGuided
    header
    description
    sortOrder
    questionTemplates {
      ...QuestionTemplateDetails
    }
    questionTemplatesCount
    category {
      id
      regulation {
        id
        name
        uiCode
      }
    }
  }
  ${QuestionTemplateDetailsFragmentDoc}
`
export const AudienceUserDetailsFragmentDoc = gql`
  fragment AudienceUserDetails on User {
    id
    userId
    firstName
    lastName
    email
    status
    roles(input: { filter: { companyIds: [$companyId] } }) {
      userId
      roleId
      name
      companyId
      code
    }
    positions(input: { filter: { companyIds: [$companyId] } }) {
      position {
        id
        name
        companyId
      }
      department {
        id
        name
        companyId
      }
    }
  }
`
export const AudienceDetailsFragmentDoc = gql`
  fragment AudienceDetails on Audience {
    id
    name
    description
    isSingleUser
    updatedAt
    createdAt
    computingMethod
    users {
      ...AudienceUserDetails
    }
  }
  ${AudienceUserDetailsFragmentDoc}
`
export const ResponseDetailsFragmentDoc = gql`
  fragment ResponseDetails on SurveyResponse {
    id
    value
    answeredAt
    respondentId
    files {
      id
      originalFilename
    }
    answerOption {
      id
    }
  }
`
export const DatamapResponseCommonFragmentDoc = gql`
  fragment DatamapResponseCommon on IDatamapResponse {
    id
    responseId
    parentResponseId
    datamapEntityTypeCode
    datamapParentEntityTypeCode
    entity {
      ...DatamapEntityDetails
    }
    ... on DatamapDataSharingResponse {
      id
      sharedWithDepartment {
        id
        name
        uiCode
      }
      sharedWith3rdParty
    }
    ... on DatamapDataSourceResponse {
      id
      providedByDepartment {
        id
        name
        uiCode
      }
      providedBy3rdParty
      isCustom
    }
    ... on DatamapDataElementResponse {
      id
      isCustom
      isSensitive
    }
  }
  ${DatamapEntityDetailsFragmentDoc}
`
export const SurveyManagerDatamapResponseGetFragmentDoc = gql`
  fragment SurveyManagerDatamapResponseGet on IDatamapResponse {
    ...DatamapResponseCommon
    response {
      id
      value
      questionId
      respondentId
      question {
        id
        sortOrder
      }
    }
  }
  ${DatamapResponseCommonFragmentDoc}
`
export const DatamapResponseResponseCommonFragmentDoc = gql`
  fragment DatamapResponseResponseCommon on SurveyResponse {
    id
    value
    respondentId
    questionId
    question {
      id
      sortOrder
    }
  }
`
export const DatamapResponseGetFragmentDoc = gql`
  fragment DatamapResponseGet on IDatamapResponse {
    ...DatamapResponseCommon
    response {
      ...DatamapResponseResponseCommon
    }
  }
  ${DatamapResponseCommonFragmentDoc}
  ${DatamapResponseResponseCommonFragmentDoc}
`
export const DatamapResponseResponseDetailsFragmentDoc = gql`
  fragment DatamapResponseResponseDetails on SurveyResponse {
    ...DatamapResponseResponseCommon
    answerOption {
      id
    }
    question {
      id
      modifiers
      type {
        id
        name
        code
        availableModifiers
        isActive
        uiCode
      }
      datamapEntityTypeCode
      guidedStep {
        id
        sortOrder
      }
    }
  }
  ${DatamapResponseResponseCommonFragmentDoc}
`
export const DatamapResponseDetailsFragmentDoc = gql`
  fragment DatamapResponseDetails on IDatamapResponse {
    ...DatamapResponseCommon
    response {
      ...DatamapResponseResponseDetails
    }
  }
  ${DatamapResponseCommonFragmentDoc}
  ${DatamapResponseResponseDetailsFragmentDoc}
`
export const SurveyGetBuilderFragmentFragmentDoc = gql`
  fragment SurveyGetBuilderFragment on Survey {
    id
    surveyId
    name
    questionsCount
    isDraft
    isTemplate
    type
    createdAt
    lastUsedAt
    sessions {
      id
      finishedAt
      deadlineAt
    }
  }
`
export const SurveyManagerPageSessionFragmentDoc = gql`
  fragment SurveyManagerPageSession on SurveySession {
    id
    startedAt
    finishedAt
    deadlineAt
    isCompleted
  }
`
export const MaturityScoreAveragesFragmentDoc = gql`
  fragment MaturityScoreAverages on StatisticsMaturityScoreAveragesItem {
    sessionId
    maturityScoreSum
    maturityScoreAverage
    maturityScoreAverageSum
    surveysCount
    questionsCount
  }
`
export const SurveyManagerViewAnswersSessionFragmentDoc = gql`
  fragment SurveyManagerViewAnswersSession on SurveySession {
    ...SurveyManagerPageSession
    maturityScoreAveragesForSession {
      ...MaturityScoreAverages
    }
    invitedUsers {
      id
    }
    survey {
      id
      name
      type
      questionsCount
      audiences {
        id
      }
      company {
        id
      }
      categories {
        id
        name
        regulation {
          id
          name
          uiCode
        }
      }
      subcategories {
        id
        header
        description
        sortOrder
        isGuided
        subcategoryTemplate {
          id
          category {
            id
            name
            regulation {
              id
              name
              uiCode
            }
          }
        }
        guidedSteps {
          id
          name
          description
          sortOrder
          questions {
            ...SurveyQuestionDetails
            maturityScore(sessionId: $sessionId)
          }
        }
        questions {
          id
          ...SurveyQuestionDetails
          maturityScore(sessionId: $sessionId)
        }
      }
    }
  }
  ${SurveyManagerPageSessionFragmentDoc}
  ${MaturityScoreAveragesFragmentDoc}
  ${SurveyQuestionDetailsFragmentDoc}
`
export const AuthenticatedUserFragmentDoc = gql`
  fragment AuthenticatedUser on User {
    id
    firstName
    lastName
    fullName @client
    phoneNumber
    passwordLastChangeAt
    isMfaEnabled
    email
    isAdmin @client
    isPalqeeManager @client
    isRegistrationComplete @client
    status
    language
    defaultCompanyId
    defaultCompany {
      id
      name
      numberOfEmployees
      industries {
        id
        name
      }
      address {
        id
        country {
          id
          name
        }
      }
    }
    companies {
      id
      name
      logo {
        ...CompanyBinaryFileInfo
      }
      currentSubscriptionTier {
        id
        name
        features {
          id
          name
          code
          description
          ... on SubscriptionFeatureIntRange {
            minValue
            maxValue
          }
          ... on SubscriptionFeatureBoolean {
            available
          }
        }
      }
    }
    roles {
      userId
      roleId
      name
      uiCode
      companyId
      code
    }
    tenant {
      hasPermanentAccess
      trialExpiredAt
    }
  }
  ${CompanyBinaryFileInfoFragmentDoc}
`
export const AuthenticatedUserWithPositionsFragmentDoc = gql`
  fragment AuthenticatedUserWithPositions on User {
    ...AuthenticatedUser
    positions(input: { filter: { companyIds: [$companyId] } }) {
      departmentId
      positionId
      position {
        id
        name
        companyId
      }
      department {
        id
        name
        companyId
      }
    }
  }
  ${AuthenticatedUserFragmentDoc}
`
export const AuthLoginConfirmDocument = gql`
  mutation AuthLoginConfirm($input: AuthLoginConfirmInput!) {
    auth {
      loginConfirm(input: $input) {
        __typename
        ... on AuthLoginSuccessPayload {
          accessToken
          refreshToken
          expiresAfterSeconds
          user {
            id
            firstName
            lastName
            email
            phoneNumber
            lastLogin
            isMfaEnabled
            language
            passwordLastChangeAt
            defaultCompanyId
            tenantId
            roles {
              userId
              companyId
              roleId
              code
            }
          }
        }
        ... on AuthLoginNexChallengePayload {
          mfaChallengeKey
          mfaType
        }
      }
    }
  }
`
export type AuthLoginConfirmMutationFn = Apollo.MutationFunction<
  AuthLoginConfirmMutation,
  AuthLoginConfirmMutationVariables
>

/**
 * __useAuthLoginConfirmMutation__
 *
 * To run a mutation, you first call `useAuthLoginConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthLoginConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authLoginConfirmMutation, { data, loading, error }] = useAuthLoginConfirmMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthLoginConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthLoginConfirmMutation,
    AuthLoginConfirmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthLoginConfirmMutation,
    AuthLoginConfirmMutationVariables
  >(AuthLoginConfirmDocument, options)
}
export type AuthLoginConfirmMutationHookResult = ReturnType<
  typeof useAuthLoginConfirmMutation
>
export type AuthLoginConfirmMutationResult =
  Apollo.MutationResult<AuthLoginConfirmMutation>
export type AuthLoginConfirmMutationOptions = Apollo.BaseMutationOptions<
  AuthLoginConfirmMutation,
  AuthLoginConfirmMutationVariables
>
export const AuthLoginDocument = gql`
  mutation AuthLogin($username: EmailAddress!, $password: String!) {
    auth {
      login(input: { username: $username, password: $password }) {
        __typename
        ... on AuthLoginSuccessPayload {
          accessToken
          refreshToken
          expiresAfterSeconds
          user {
            id
            firstName
            lastName
            email
            phoneNumber
            lastLogin
            isMfaEnabled
            passwordLastChangeAt
            language
            defaultCompany {
              id
            }
            tenant {
              id
            }
            companies {
              id
              regions {
                id
              }
            }
            roles {
              userId
              name
              roleId
              code
              companyId
            }
          }
        }
        ... on AuthLoginNexChallengePayload {
          mfaChallengeKey
          mfaType
        }
      }
    }
  }
`
export type AuthLoginMutationFn = Apollo.MutationFunction<
  AuthLoginMutation,
  AuthLoginMutationVariables
>

/**
 * __useAuthLoginMutation__
 *
 * To run a mutation, you first call `useAuthLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authLoginMutation, { data, loading, error }] = useAuthLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthLoginMutation,
    AuthLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AuthLoginMutation, AuthLoginMutationVariables>(
    AuthLoginDocument,
    options,
  )
}
export type AuthLoginMutationHookResult = ReturnType<
  typeof useAuthLoginMutation
>
export type AuthLoginMutationResult = Apollo.MutationResult<AuthLoginMutation>
export type AuthLoginMutationOptions = Apollo.BaseMutationOptions<
  AuthLoginMutation,
  AuthLoginMutationVariables
>
export const AuthLogoutDocument = gql`
  mutation AuthLogout {
    auth {
      logout {
        nothing
      }
    }
  }
`
export type AuthLogoutMutationFn = Apollo.MutationFunction<
  AuthLogoutMutation,
  AuthLogoutMutationVariables
>

/**
 * __useAuthLogoutMutation__
 *
 * To run a mutation, you first call `useAuthLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authLogoutMutation, { data, loading, error }] = useAuthLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthLogoutMutation,
    AuthLogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AuthLogoutMutation, AuthLogoutMutationVariables>(
    AuthLogoutDocument,
    options,
  )
}
export type AuthLogoutMutationHookResult = ReturnType<
  typeof useAuthLogoutMutation
>
export type AuthLogoutMutationResult = Apollo.MutationResult<AuthLogoutMutation>
export type AuthLogoutMutationOptions = Apollo.BaseMutationOptions<
  AuthLogoutMutation,
  AuthLogoutMutationVariables
>
export const AuthMfaConfigureConfirmDocument = gql`
  mutation AuthMfaConfigureConfirm(
    $mfaType: AuthMfaTypeEnum!
    $confirmationCode: String!
  ) {
    auth {
      mfaConfigureConfirm(
        input: { mfaType: $mfaType, confirmationCode: $confirmationCode }
      ) {
        nothing
        user {
          id
          email
        }
      }
    }
  }
`
export type AuthMfaConfigureConfirmMutationFn = Apollo.MutationFunction<
  AuthMfaConfigureConfirmMutation,
  AuthMfaConfigureConfirmMutationVariables
>

/**
 * __useAuthMfaConfigureConfirmMutation__
 *
 * To run a mutation, you first call `useAuthMfaConfigureConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthMfaConfigureConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authMfaConfigureConfirmMutation, { data, loading, error }] = useAuthMfaConfigureConfirmMutation({
 *   variables: {
 *      mfaType: // value for 'mfaType'
 *      confirmationCode: // value for 'confirmationCode'
 *   },
 * });
 */
export function useAuthMfaConfigureConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthMfaConfigureConfirmMutation,
    AuthMfaConfigureConfirmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthMfaConfigureConfirmMutation,
    AuthMfaConfigureConfirmMutationVariables
  >(AuthMfaConfigureConfirmDocument, options)
}
export type AuthMfaConfigureConfirmMutationHookResult = ReturnType<
  typeof useAuthMfaConfigureConfirmMutation
>
export type AuthMfaConfigureConfirmMutationResult =
  Apollo.MutationResult<AuthMfaConfigureConfirmMutation>
export type AuthMfaConfigureConfirmMutationOptions = Apollo.BaseMutationOptions<
  AuthMfaConfigureConfirmMutation,
  AuthMfaConfigureConfirmMutationVariables
>
export const AuthMfaConfigureDocument = gql`
  mutation AuthMfaConfigure(
    $mfaType: AuthMfaTypeEnum!
    $phoneNumber: PhoneNumber
  ) {
    auth {
      mfaConfigure(input: { mfaType: $mfaType, phoneNumber: $phoneNumber }) {
        secretKey
        user {
          id
          email
        }
      }
    }
  }
`
export type AuthMfaConfigureMutationFn = Apollo.MutationFunction<
  AuthMfaConfigureMutation,
  AuthMfaConfigureMutationVariables
>

/**
 * __useAuthMfaConfigureMutation__
 *
 * To run a mutation, you first call `useAuthMfaConfigureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthMfaConfigureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authMfaConfigureMutation, { data, loading, error }] = useAuthMfaConfigureMutation({
 *   variables: {
 *      mfaType: // value for 'mfaType'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useAuthMfaConfigureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthMfaConfigureMutation,
    AuthMfaConfigureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthMfaConfigureMutation,
    AuthMfaConfigureMutationVariables
  >(AuthMfaConfigureDocument, options)
}
export type AuthMfaConfigureMutationHookResult = ReturnType<
  typeof useAuthMfaConfigureMutation
>
export type AuthMfaConfigureMutationResult =
  Apollo.MutationResult<AuthMfaConfigureMutation>
export type AuthMfaConfigureMutationOptions = Apollo.BaseMutationOptions<
  AuthMfaConfigureMutation,
  AuthMfaConfigureMutationVariables
>
export const AuthMfaDisableDocument = gql`
  mutation AuthMfaDisable {
    auth {
      mfaDisable {
        nothing
      }
    }
  }
`
export type AuthMfaDisableMutationFn = Apollo.MutationFunction<
  AuthMfaDisableMutation,
  AuthMfaDisableMutationVariables
>

/**
 * __useAuthMfaDisableMutation__
 *
 * To run a mutation, you first call `useAuthMfaDisableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthMfaDisableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authMfaDisableMutation, { data, loading, error }] = useAuthMfaDisableMutation({
 *   variables: {
 *   },
 * });
 */
export function useAuthMfaDisableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthMfaDisableMutation,
    AuthMfaDisableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthMfaDisableMutation,
    AuthMfaDisableMutationVariables
  >(AuthMfaDisableDocument, options)
}
export type AuthMfaDisableMutationHookResult = ReturnType<
  typeof useAuthMfaDisableMutation
>
export type AuthMfaDisableMutationResult =
  Apollo.MutationResult<AuthMfaDisableMutation>
export type AuthMfaDisableMutationOptions = Apollo.BaseMutationOptions<
  AuthMfaDisableMutation,
  AuthMfaDisableMutationVariables
>
export const AuthPasswordChangeDocument = gql`
  mutation AuthPasswordChange(
    $oldPassword: String!
    $newPassword: String!
    $companyId: ID!
  ) {
    auth {
      passwordChange(
        input: { oldPassword: $oldPassword, newPassword: $newPassword }
      ) {
        user {
          ...AuthenticatedUserWithPositions
        }
      }
    }
  }
  ${AuthenticatedUserWithPositionsFragmentDoc}
`
export type AuthPasswordChangeMutationFn = Apollo.MutationFunction<
  AuthPasswordChangeMutation,
  AuthPasswordChangeMutationVariables
>

/**
 * __useAuthPasswordChangeMutation__
 *
 * To run a mutation, you first call `useAuthPasswordChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthPasswordChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authPasswordChangeMutation, { data, loading, error }] = useAuthPasswordChangeMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAuthPasswordChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthPasswordChangeMutation,
    AuthPasswordChangeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthPasswordChangeMutation,
    AuthPasswordChangeMutationVariables
  >(AuthPasswordChangeDocument, options)
}
export type AuthPasswordChangeMutationHookResult = ReturnType<
  typeof useAuthPasswordChangeMutation
>
export type AuthPasswordChangeMutationResult =
  Apollo.MutationResult<AuthPasswordChangeMutation>
export type AuthPasswordChangeMutationOptions = Apollo.BaseMutationOptions<
  AuthPasswordChangeMutation,
  AuthPasswordChangeMutationVariables
>
export const AuthPasswordRecoverConfirmDocument = gql`
  mutation AuthPasswordRecoverConfirm(
    $newPassword: String!
    $confirmationCode: String!
    $confirmationToken: String!
  ) {
    auth {
      passwordRestoreConfirm(
        input: {
          newPassword: $newPassword
          confirmationCode: $confirmationCode
          confirmationToken: $confirmationToken
        }
      ) {
        nothing
      }
    }
  }
`
export type AuthPasswordRecoverConfirmMutationFn = Apollo.MutationFunction<
  AuthPasswordRecoverConfirmMutation,
  AuthPasswordRecoverConfirmMutationVariables
>

/**
 * __useAuthPasswordRecoverConfirmMutation__
 *
 * To run a mutation, you first call `useAuthPasswordRecoverConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthPasswordRecoverConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authPasswordRecoverConfirmMutation, { data, loading, error }] = useAuthPasswordRecoverConfirmMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      confirmationCode: // value for 'confirmationCode'
 *      confirmationToken: // value for 'confirmationToken'
 *   },
 * });
 */
export function useAuthPasswordRecoverConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthPasswordRecoverConfirmMutation,
    AuthPasswordRecoverConfirmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthPasswordRecoverConfirmMutation,
    AuthPasswordRecoverConfirmMutationVariables
  >(AuthPasswordRecoverConfirmDocument, options)
}
export type AuthPasswordRecoverConfirmMutationHookResult = ReturnType<
  typeof useAuthPasswordRecoverConfirmMutation
>
export type AuthPasswordRecoverConfirmMutationResult =
  Apollo.MutationResult<AuthPasswordRecoverConfirmMutation>
export type AuthPasswordRecoverConfirmMutationOptions =
  Apollo.BaseMutationOptions<
    AuthPasswordRecoverConfirmMutation,
    AuthPasswordRecoverConfirmMutationVariables
  >
export const AuthPasswordRecoverInitDocument = gql`
  mutation AuthPasswordRecoverInit($username: EmailAddress!) {
    auth {
      passwordRestoreInit(input: { username: $username }) {
        __typename
      }
    }
  }
`
export type AuthPasswordRecoverInitMutationFn = Apollo.MutationFunction<
  AuthPasswordRecoverInitMutation,
  AuthPasswordRecoverInitMutationVariables
>

/**
 * __useAuthPasswordRecoverInitMutation__
 *
 * To run a mutation, you first call `useAuthPasswordRecoverInitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthPasswordRecoverInitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authPasswordRecoverInitMutation, { data, loading, error }] = useAuthPasswordRecoverInitMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useAuthPasswordRecoverInitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthPasswordRecoverInitMutation,
    AuthPasswordRecoverInitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthPasswordRecoverInitMutation,
    AuthPasswordRecoverInitMutationVariables
  >(AuthPasswordRecoverInitDocument, options)
}
export type AuthPasswordRecoverInitMutationHookResult = ReturnType<
  typeof useAuthPasswordRecoverInitMutation
>
export type AuthPasswordRecoverInitMutationResult =
  Apollo.MutationResult<AuthPasswordRecoverInitMutation>
export type AuthPasswordRecoverInitMutationOptions = Apollo.BaseMutationOptions<
  AuthPasswordRecoverInitMutation,
  AuthPasswordRecoverInitMutationVariables
>
export const AuthSignUpConfirmDocument = gql`
  mutation AuthSignUpConfirm(
    $confirmationCode: String!
    $confirmationToken: String!
  ) {
    auth {
      signUpConfirm(
        input: {
          confirmationCode: $confirmationCode
          confirmationToken: $confirmationToken
        }
      ) {
        user {
          id
        }
      }
    }
  }
`
export type AuthSignUpConfirmMutationFn = Apollo.MutationFunction<
  AuthSignUpConfirmMutation,
  AuthSignUpConfirmMutationVariables
>

/**
 * __useAuthSignUpConfirmMutation__
 *
 * To run a mutation, you first call `useAuthSignUpConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignUpConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignUpConfirmMutation, { data, loading, error }] = useAuthSignUpConfirmMutation({
 *   variables: {
 *      confirmationCode: // value for 'confirmationCode'
 *      confirmationToken: // value for 'confirmationToken'
 *   },
 * });
 */
export function useAuthSignUpConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthSignUpConfirmMutation,
    AuthSignUpConfirmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthSignUpConfirmMutation,
    AuthSignUpConfirmMutationVariables
  >(AuthSignUpConfirmDocument, options)
}
export type AuthSignUpConfirmMutationHookResult = ReturnType<
  typeof useAuthSignUpConfirmMutation
>
export type AuthSignUpConfirmMutationResult =
  Apollo.MutationResult<AuthSignUpConfirmMutation>
export type AuthSignUpConfirmMutationOptions = Apollo.BaseMutationOptions<
  AuthSignUpConfirmMutation,
  AuthSignUpConfirmMutationVariables
>
export const AuthSignUpResendCodeDocument = gql`
  mutation AuthSignUpResendCode($usernames: [EmailAddress!]!) {
    auth {
      signUpResendCode(input: { usernames: $usernames }) {
        nothing
      }
    }
  }
`
export type AuthSignUpResendCodeMutationFn = Apollo.MutationFunction<
  AuthSignUpResendCodeMutation,
  AuthSignUpResendCodeMutationVariables
>

/**
 * __useAuthSignUpResendCodeMutation__
 *
 * To run a mutation, you first call `useAuthSignUpResendCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignUpResendCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignUpResendCodeMutation, { data, loading, error }] = useAuthSignUpResendCodeMutation({
 *   variables: {
 *      usernames: // value for 'usernames'
 *   },
 * });
 */
export function useAuthSignUpResendCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthSignUpResendCodeMutation,
    AuthSignUpResendCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthSignUpResendCodeMutation,
    AuthSignUpResendCodeMutationVariables
  >(AuthSignUpResendCodeDocument, options)
}
export type AuthSignUpResendCodeMutationHookResult = ReturnType<
  typeof useAuthSignUpResendCodeMutation
>
export type AuthSignUpResendCodeMutationResult =
  Apollo.MutationResult<AuthSignUpResendCodeMutation>
export type AuthSignUpResendCodeMutationOptions = Apollo.BaseMutationOptions<
  AuthSignUpResendCodeMutation,
  AuthSignUpResendCodeMutationVariables
>
export const AuthSignUpResendCodeToPendingUsersDocument = gql`
  mutation AuthSignUpResendCodeToPendingUsers($companyIds: [ID!]!) {
    auth {
      signUpResendCodeToPendingUsers(input: { companyIds: $companyIds }) {
        nothing
      }
    }
  }
`
export type AuthSignUpResendCodeToPendingUsersMutationFn =
  Apollo.MutationFunction<
    AuthSignUpResendCodeToPendingUsersMutation,
    AuthSignUpResendCodeToPendingUsersMutationVariables
  >

/**
 * __useAuthSignUpResendCodeToPendingUsersMutation__
 *
 * To run a mutation, you first call `useAuthSignUpResendCodeToPendingUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignUpResendCodeToPendingUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignUpResendCodeToPendingUsersMutation, { data, loading, error }] = useAuthSignUpResendCodeToPendingUsersMutation({
 *   variables: {
 *      companyIds: // value for 'companyIds'
 *   },
 * });
 */
export function useAuthSignUpResendCodeToPendingUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthSignUpResendCodeToPendingUsersMutation,
    AuthSignUpResendCodeToPendingUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthSignUpResendCodeToPendingUsersMutation,
    AuthSignUpResendCodeToPendingUsersMutationVariables
  >(AuthSignUpResendCodeToPendingUsersDocument, options)
}
export type AuthSignUpResendCodeToPendingUsersMutationHookResult = ReturnType<
  typeof useAuthSignUpResendCodeToPendingUsersMutation
>
export type AuthSignUpResendCodeToPendingUsersMutationResult =
  Apollo.MutationResult<AuthSignUpResendCodeToPendingUsersMutation>
export type AuthSignUpResendCodeToPendingUsersMutationOptions =
  Apollo.BaseMutationOptions<
    AuthSignUpResendCodeToPendingUsersMutation,
    AuthSignUpResendCodeToPendingUsersMutationVariables
  >
export const AuthSignUpDocument = gql`
  mutation AuthSignUp(
    $username: EmailAddress!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: PhoneNumber
    $positionName: String
    $departmentName: String
    $language: LanguageCodeEnum
  ) {
    auth {
      signUp(
        input: {
          username: $username
          password: $password
          firstName: $firstName
          lastName: $lastName
          phoneNumber: $phoneNumber
          positionName: $positionName
          departmentName: $departmentName
          language: $language
        }
      ) {
        user {
          id
          firstName
          lastName
          email
          phoneNumber
          language
          positions {
            position {
              id
              name
              description
            }
          }
          roles {
            userId
            code
            companyId
            roleId
            uiCode
          }
          lastLogin
        }
      }
    }
  }
`
export type AuthSignUpMutationFn = Apollo.MutationFunction<
  AuthSignUpMutation,
  AuthSignUpMutationVariables
>

/**
 * __useAuthSignUpMutation__
 *
 * To run a mutation, you first call `useAuthSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignUpMutation, { data, loading, error }] = useAuthSignUpMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      positionName: // value for 'positionName'
 *      departmentName: // value for 'departmentName'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useAuthSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthSignUpMutation,
    AuthSignUpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AuthSignUpMutation, AuthSignUpMutationVariables>(
    AuthSignUpDocument,
    options,
  )
}
export type AuthSignUpMutationHookResult = ReturnType<
  typeof useAuthSignUpMutation
>
export type AuthSignUpMutationResult = Apollo.MutationResult<AuthSignUpMutation>
export type AuthSignUpMutationOptions = Apollo.BaseMutationOptions<
  AuthSignUpMutation,
  AuthSignUpMutationVariables
>
export const AuthTokenRefreshDocument = gql`
  mutation AuthTokenRefresh($refreshToken: String!) {
    auth {
      tokenRefresh(input: { refreshToken: $refreshToken }) {
        __typename
        accessToken
        refreshToken
        expiresAfterSeconds
      }
    }
  }
`
export type AuthTokenRefreshMutationFn = Apollo.MutationFunction<
  AuthTokenRefreshMutation,
  AuthTokenRefreshMutationVariables
>

/**
 * __useAuthTokenRefreshMutation__
 *
 * To run a mutation, you first call `useAuthTokenRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthTokenRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authTokenRefreshMutation, { data, loading, error }] = useAuthTokenRefreshMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useAuthTokenRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthTokenRefreshMutation,
    AuthTokenRefreshMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthTokenRefreshMutation,
    AuthTokenRefreshMutationVariables
  >(AuthTokenRefreshDocument, options)
}
export type AuthTokenRefreshMutationHookResult = ReturnType<
  typeof useAuthTokenRefreshMutation
>
export type AuthTokenRefreshMutationResult =
  Apollo.MutationResult<AuthTokenRefreshMutation>
export type AuthTokenRefreshMutationOptions = Apollo.BaseMutationOptions<
  AuthTokenRefreshMutation,
  AuthTokenRefreshMutationVariables
>
export const CompanyAssetConnectionCreateDocument = gql`
  mutation CompanyAssetConnectionCreate(
    $input: CompanyAssetConnectionCreateInput!
  ) {
    company {
      assetConnectionCreate(input: $input) {
        assetConnection {
          id
          asset {
            id
            name
          }
        }
      }
    }
  }
`
export type CompanyAssetConnectionCreateMutationFn = Apollo.MutationFunction<
  CompanyAssetConnectionCreateMutation,
  CompanyAssetConnectionCreateMutationVariables
>

/**
 * __useCompanyAssetConnectionCreateMutation__
 *
 * To run a mutation, you first call `useCompanyAssetConnectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAssetConnectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAssetConnectionCreateMutation, { data, loading, error }] = useCompanyAssetConnectionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyAssetConnectionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyAssetConnectionCreateMutation,
    CompanyAssetConnectionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyAssetConnectionCreateMutation,
    CompanyAssetConnectionCreateMutationVariables
  >(CompanyAssetConnectionCreateDocument, options)
}
export type CompanyAssetConnectionCreateMutationHookResult = ReturnType<
  typeof useCompanyAssetConnectionCreateMutation
>
export type CompanyAssetConnectionCreateMutationResult =
  Apollo.MutationResult<CompanyAssetConnectionCreateMutation>
export type CompanyAssetConnectionCreateMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyAssetConnectionCreateMutation,
    CompanyAssetConnectionCreateMutationVariables
  >
export const CompanyAudienceCreateDocument = gql`
  mutation CompanyAudienceCreate($input: CompanyAudienceCreateInput!) {
    company {
      audienceCreate(input: $input) {
        audience {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`
export type CompanyAudienceCreateMutationFn = Apollo.MutationFunction<
  CompanyAudienceCreateMutation,
  CompanyAudienceCreateMutationVariables
>

/**
 * __useCompanyAudienceCreateMutation__
 *
 * To run a mutation, you first call `useCompanyAudienceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAudienceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAudienceCreateMutation, { data, loading, error }] = useCompanyAudienceCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyAudienceCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyAudienceCreateMutation,
    CompanyAudienceCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyAudienceCreateMutation,
    CompanyAudienceCreateMutationVariables
  >(CompanyAudienceCreateDocument, options)
}
export type CompanyAudienceCreateMutationHookResult = ReturnType<
  typeof useCompanyAudienceCreateMutation
>
export type CompanyAudienceCreateMutationResult =
  Apollo.MutationResult<CompanyAudienceCreateMutation>
export type CompanyAudienceCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyAudienceCreateMutation,
  CompanyAudienceCreateMutationVariables
>
export const CompanyAudienceDeleteDocument = gql`
  mutation CompanyAudienceDelete($filter: CompanyAudienceDeleteFilterArg) {
    company {
      audienceDelete(input: { filter: $filter }) {
        audiences {
          id
          audienceId
          name
          description
          users {
            id
            email
          }
          company {
            id
          }
        }
      }
    }
  }
`
export type CompanyAudienceDeleteMutationFn = Apollo.MutationFunction<
  CompanyAudienceDeleteMutation,
  CompanyAudienceDeleteMutationVariables
>

/**
 * __useCompanyAudienceDeleteMutation__
 *
 * To run a mutation, you first call `useCompanyAudienceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAudienceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAudienceDeleteMutation, { data, loading, error }] = useCompanyAudienceDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyAudienceDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyAudienceDeleteMutation,
    CompanyAudienceDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyAudienceDeleteMutation,
    CompanyAudienceDeleteMutationVariables
  >(CompanyAudienceDeleteDocument, options)
}
export type CompanyAudienceDeleteMutationHookResult = ReturnType<
  typeof useCompanyAudienceDeleteMutation
>
export type CompanyAudienceDeleteMutationResult =
  Apollo.MutationResult<CompanyAudienceDeleteMutation>
export type CompanyAudienceDeleteMutationOptions = Apollo.BaseMutationOptions<
  CompanyAudienceDeleteMutation,
  CompanyAudienceDeleteMutationVariables
>
export const CompanyAudienceUpdateDocument = gql`
  mutation CompanyAudienceUpdate(
    $audienceId: ID!
    $name: String!
    $description: String
  ) {
    company {
      audienceUpdate(
        input: {
          audienceId: $audienceId
          name: $name
          description: $description
        }
      ) {
        audience {
          audienceId
          name
          description
          users {
            id
            email
          }
          company {
            id
          }
        }
      }
    }
  }
`
export type CompanyAudienceUpdateMutationFn = Apollo.MutationFunction<
  CompanyAudienceUpdateMutation,
  CompanyAudienceUpdateMutationVariables
>

/**
 * __useCompanyAudienceUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyAudienceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAudienceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAudienceUpdateMutation, { data, loading, error }] = useCompanyAudienceUpdateMutation({
 *   variables: {
 *      audienceId: // value for 'audienceId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCompanyAudienceUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyAudienceUpdateMutation,
    CompanyAudienceUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyAudienceUpdateMutation,
    CompanyAudienceUpdateMutationVariables
  >(CompanyAudienceUpdateDocument, options)
}
export type CompanyAudienceUpdateMutationHookResult = ReturnType<
  typeof useCompanyAudienceUpdateMutation
>
export type CompanyAudienceUpdateMutationResult =
  Apollo.MutationResult<CompanyAudienceUpdateMutation>
export type CompanyAudienceUpdateMutationOptions = Apollo.BaseMutationOptions<
  CompanyAudienceUpdateMutation,
  CompanyAudienceUpdateMutationVariables
>
export const CompanyAudienceUsersLinkDocument = gql`
  mutation CompanyAudienceUsersLink(
    $audienceId: ID!
    $users: CompanyAudienceUsersLinkUsersArg!
  ) {
    company {
      audienceUsersLink(input: { audienceId: $audienceId, users: $users }) {
        audience {
          id
          audienceId
          name
          description
          users {
            id
            email
          }
          company {
            id
          }
        }
      }
    }
  }
`
export type CompanyAudienceUsersLinkMutationFn = Apollo.MutationFunction<
  CompanyAudienceUsersLinkMutation,
  CompanyAudienceUsersLinkMutationVariables
>

/**
 * __useCompanyAudienceUsersLinkMutation__
 *
 * To run a mutation, you first call `useCompanyAudienceUsersLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAudienceUsersLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAudienceUsersLinkMutation, { data, loading, error }] = useCompanyAudienceUsersLinkMutation({
 *   variables: {
 *      audienceId: // value for 'audienceId'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useCompanyAudienceUsersLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyAudienceUsersLinkMutation,
    CompanyAudienceUsersLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyAudienceUsersLinkMutation,
    CompanyAudienceUsersLinkMutationVariables
  >(CompanyAudienceUsersLinkDocument, options)
}
export type CompanyAudienceUsersLinkMutationHookResult = ReturnType<
  typeof useCompanyAudienceUsersLinkMutation
>
export type CompanyAudienceUsersLinkMutationResult =
  Apollo.MutationResult<CompanyAudienceUsersLinkMutation>
export type CompanyAudienceUsersLinkMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyAudienceUsersLinkMutation,
    CompanyAudienceUsersLinkMutationVariables
  >
export const CompanyAudienceUsersUnlinkDocument = gql`
  mutation CompanyAudienceUsersUnlink($audienceId: ID!, $userIds: [ID!]!) {
    company {
      audienceUsersUnlink(
        input: { audienceId: $audienceId, userIds: $userIds }
      ) {
        audience {
          audienceId
          name
          description
          users {
            id
            email
          }
          company {
            id
          }
        }
      }
    }
  }
`
export type CompanyAudienceUsersUnlinkMutationFn = Apollo.MutationFunction<
  CompanyAudienceUsersUnlinkMutation,
  CompanyAudienceUsersUnlinkMutationVariables
>

/**
 * __useCompanyAudienceUsersUnlinkMutation__
 *
 * To run a mutation, you first call `useCompanyAudienceUsersUnlinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAudienceUsersUnlinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAudienceUsersUnlinkMutation, { data, loading, error }] = useCompanyAudienceUsersUnlinkMutation({
 *   variables: {
 *      audienceId: // value for 'audienceId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useCompanyAudienceUsersUnlinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyAudienceUsersUnlinkMutation,
    CompanyAudienceUsersUnlinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyAudienceUsersUnlinkMutation,
    CompanyAudienceUsersUnlinkMutationVariables
  >(CompanyAudienceUsersUnlinkDocument, options)
}
export type CompanyAudienceUsersUnlinkMutationHookResult = ReturnType<
  typeof useCompanyAudienceUsersUnlinkMutation
>
export type CompanyAudienceUsersUnlinkMutationResult =
  Apollo.MutationResult<CompanyAudienceUsersUnlinkMutation>
export type CompanyAudienceUsersUnlinkMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyAudienceUsersUnlinkMutation,
    CompanyAudienceUsersUnlinkMutationVariables
  >
export const CompanyCreateDocument = gql`
  mutation CompanyCreate(
    $name: String!
    $typeId: ID!
    $structureId: ID!
    $numberOfEmployees: NonNegativeInt!
    $annualSalesRevenueId: ID!
    $currencyId: ID!
    $isSubscriptionTrialEnabled: Boolean!
  ) {
    company {
      create(
        input: {
          name: $name
          typeId: $typeId
          structureId: $structureId
          numberOfEmployees: $numberOfEmployees
          annualSalesRevenueId: $annualSalesRevenueId
          currencyId: $currencyId
          isSubscriptionTrialEnabled: $isSubscriptionTrialEnabled
        }
      ) {
        company {
          id
          name
          numberOfEmployees
        }
      }
    }
  }
`
export type CompanyCreateMutationFn = Apollo.MutationFunction<
  CompanyCreateMutation,
  CompanyCreateMutationVariables
>

/**
 * __useCompanyCreateMutation__
 *
 * To run a mutation, you first call `useCompanyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCreateMutation, { data, loading, error }] = useCompanyCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      typeId: // value for 'typeId'
 *      structureId: // value for 'structureId'
 *      numberOfEmployees: // value for 'numberOfEmployees'
 *      annualSalesRevenueId: // value for 'annualSalesRevenueId'
 *      currencyId: // value for 'currencyId'
 *      isSubscriptionTrialEnabled: // value for 'isSubscriptionTrialEnabled'
 *   },
 * });
 */
export function useCompanyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >(CompanyCreateDocument, options)
}
export type CompanyCreateMutationHookResult = ReturnType<
  typeof useCompanyCreateMutation
>
export type CompanyCreateMutationResult =
  Apollo.MutationResult<CompanyCreateMutation>
export type CompanyCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyCreateMutation,
  CompanyCreateMutationVariables
>
export const CompanyDeleteDocument = gql`
  mutation CompanyDelete($filter: CompanyDeleteFilterArgument!) {
    company {
      delete(input: { filter: $filter }) {
        companies {
          id
          name
        }
      }
    }
  }
`
export type CompanyDeleteMutationFn = Apollo.MutationFunction<
  CompanyDeleteMutation,
  CompanyDeleteMutationVariables
>

/**
 * __useCompanyDeleteMutation__
 *
 * To run a mutation, you first call `useCompanyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyDeleteMutation, { data, loading, error }] = useCompanyDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyDeleteMutation,
    CompanyDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyDeleteMutation,
    CompanyDeleteMutationVariables
  >(CompanyDeleteDocument, options)
}
export type CompanyDeleteMutationHookResult = ReturnType<
  typeof useCompanyDeleteMutation
>
export type CompanyDeleteMutationResult =
  Apollo.MutationResult<CompanyDeleteMutation>
export type CompanyDeleteMutationOptions = Apollo.BaseMutationOptions<
  CompanyDeleteMutation,
  CompanyDeleteMutationVariables
>
export const CompanyIndustriesSetDocument = gql`
  mutation CompanyIndustriesSet($companyId: ID!, $industryIds: [ID!]!) {
    company {
      industriesSet(
        input: { companyId: $companyId, industryIds: $industryIds }
      ) {
        company {
          id
        }
      }
    }
  }
`
export type CompanyIndustriesSetMutationFn = Apollo.MutationFunction<
  CompanyIndustriesSetMutation,
  CompanyIndustriesSetMutationVariables
>

/**
 * __useCompanyIndustriesSetMutation__
 *
 * To run a mutation, you first call `useCompanyIndustriesSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyIndustriesSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyIndustriesSetMutation, { data, loading, error }] = useCompanyIndustriesSetMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      industryIds: // value for 'industryIds'
 *   },
 * });
 */
export function useCompanyIndustriesSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyIndustriesSetMutation,
    CompanyIndustriesSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyIndustriesSetMutation,
    CompanyIndustriesSetMutationVariables
  >(CompanyIndustriesSetDocument, options)
}
export type CompanyIndustriesSetMutationHookResult = ReturnType<
  typeof useCompanyIndustriesSetMutation
>
export type CompanyIndustriesSetMutationResult =
  Apollo.MutationResult<CompanyIndustriesSetMutation>
export type CompanyIndustriesSetMutationOptions = Apollo.BaseMutationOptions<
  CompanyIndustriesSetMutation,
  CompanyIndustriesSetMutationVariables
>
export const CompanyLogoSetDocument = gql`
  mutation CompanyLogoSet($file: Upload!, $companyId: ID!) {
    company {
      logoSet(input: { file: $file, companyId: $companyId }) {
        logo {
          ...CompanyBinaryFileInfo
        }
      }
    }
  }
  ${CompanyBinaryFileInfoFragmentDoc}
`
export type CompanyLogoSetMutationFn = Apollo.MutationFunction<
  CompanyLogoSetMutation,
  CompanyLogoSetMutationVariables
>

/**
 * __useCompanyLogoSetMutation__
 *
 * To run a mutation, you first call `useCompanyLogoSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyLogoSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyLogoSetMutation, { data, loading, error }] = useCompanyLogoSetMutation({
 *   variables: {
 *      file: // value for 'file'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyLogoSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyLogoSetMutation,
    CompanyLogoSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyLogoSetMutation,
    CompanyLogoSetMutationVariables
  >(CompanyLogoSetDocument, options)
}
export type CompanyLogoSetMutationHookResult = ReturnType<
  typeof useCompanyLogoSetMutation
>
export type CompanyLogoSetMutationResult =
  Apollo.MutationResult<CompanyLogoSetMutation>
export type CompanyLogoSetMutationOptions = Apollo.BaseMutationOptions<
  CompanyLogoSetMutation,
  CompanyLogoSetMutationVariables
>
export const CompanyOnboardingStepsSaveDocument = gql`
  mutation CompanyOnboardingStepsSave(
    $companyId: ID!
    $stepCode: OnboardingProgressStepCodeEnum!
    $isCompleted: Boolean!
  ) {
    company {
      onboardingStepsSave(
        input: {
          companyId: $companyId
          stepCode: $stepCode
          isCompleted: $isCompleted
        }
      ) {
        stepCodes
      }
    }
  }
`
export type CompanyOnboardingStepsSaveMutationFn = Apollo.MutationFunction<
  CompanyOnboardingStepsSaveMutation,
  CompanyOnboardingStepsSaveMutationVariables
>

/**
 * __useCompanyOnboardingStepsSaveMutation__
 *
 * To run a mutation, you first call `useCompanyOnboardingStepsSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyOnboardingStepsSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyOnboardingStepsSaveMutation, { data, loading, error }] = useCompanyOnboardingStepsSaveMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      stepCode: // value for 'stepCode'
 *      isCompleted: // value for 'isCompleted'
 *   },
 * });
 */
export function useCompanyOnboardingStepsSaveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyOnboardingStepsSaveMutation,
    CompanyOnboardingStepsSaveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyOnboardingStepsSaveMutation,
    CompanyOnboardingStepsSaveMutationVariables
  >(CompanyOnboardingStepsSaveDocument, options)
}
export type CompanyOnboardingStepsSaveMutationHookResult = ReturnType<
  typeof useCompanyOnboardingStepsSaveMutation
>
export type CompanyOnboardingStepsSaveMutationResult =
  Apollo.MutationResult<CompanyOnboardingStepsSaveMutation>
export type CompanyOnboardingStepsSaveMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyOnboardingStepsSaveMutation,
    CompanyOnboardingStepsSaveMutationVariables
  >
export const CompanyProcessCreateDocument = gql`
  mutation CompanyProcessCreate(
    $name: String!
    $description: String
    $companyId: ID!
    $processTemplateId: ID
  ) {
    company {
      processCreate(
        input: {
          name: $name
          description: $description
          companyId: $companyId
          processTemplateId: $processTemplateId
        }
      ) {
        process {
          id
          processTemplate {
            id
            name
          }
        }
      }
    }
  }
`
export type CompanyProcessCreateMutationFn = Apollo.MutationFunction<
  CompanyProcessCreateMutation,
  CompanyProcessCreateMutationVariables
>

/**
 * __useCompanyProcessCreateMutation__
 *
 * To run a mutation, you first call `useCompanyProcessCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyProcessCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyProcessCreateMutation, { data, loading, error }] = useCompanyProcessCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      companyId: // value for 'companyId'
 *      processTemplateId: // value for 'processTemplateId'
 *   },
 * });
 */
export function useCompanyProcessCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyProcessCreateMutation,
    CompanyProcessCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyProcessCreateMutation,
    CompanyProcessCreateMutationVariables
  >(CompanyProcessCreateDocument, options)
}
export type CompanyProcessCreateMutationHookResult = ReturnType<
  typeof useCompanyProcessCreateMutation
>
export type CompanyProcessCreateMutationResult =
  Apollo.MutationResult<CompanyProcessCreateMutation>
export type CompanyProcessCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyProcessCreateMutation,
  CompanyProcessCreateMutationVariables
>
export const CompanyRegulationLinkDocument = gql`
  mutation CompanyRegulationLink($companyId: ID!, $regulationIds: [ID!]) {
    company {
      regulationLink(
        input: { companyId: $companyId, regulationIds: $regulationIds }
      ) {
        company {
          id
          selectedRegulations {
            ...CompanySelectedRegulation
          }
          currentSubscriptionTier {
            id
            name
            features {
              id
              name
              code
              description
              ... on SubscriptionFeatureIntRange {
                minValue
                maxValue
              }
              ... on SubscriptionFeatureBoolean {
                available
              }
            }
          }
        }
      }
    }
  }
  ${CompanySelectedRegulationFragmentDoc}
`
export type CompanyRegulationLinkMutationFn = Apollo.MutationFunction<
  CompanyRegulationLinkMutation,
  CompanyRegulationLinkMutationVariables
>

/**
 * __useCompanyRegulationLinkMutation__
 *
 * To run a mutation, you first call `useCompanyRegulationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyRegulationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyRegulationLinkMutation, { data, loading, error }] = useCompanyRegulationLinkMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      regulationIds: // value for 'regulationIds'
 *   },
 * });
 */
export function useCompanyRegulationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyRegulationLinkMutation,
    CompanyRegulationLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyRegulationLinkMutation,
    CompanyRegulationLinkMutationVariables
  >(CompanyRegulationLinkDocument, options)
}
export type CompanyRegulationLinkMutationHookResult = ReturnType<
  typeof useCompanyRegulationLinkMutation
>
export type CompanyRegulationLinkMutationResult =
  Apollo.MutationResult<CompanyRegulationLinkMutation>
export type CompanyRegulationLinkMutationOptions = Apollo.BaseMutationOptions<
  CompanyRegulationLinkMutation,
  CompanyRegulationLinkMutationVariables
>
export const RepresentativeAddressSetDocument = gql`
  mutation RepresentativeAddressSet(
    $representativeId: ID!
    $address: AddressArg
  ) {
    company {
      representativeAddressSet(
        input: { representativeId: $representativeId, address: $address }
      ) {
        representative {
          id
        }
      }
    }
  }
`
export type RepresentativeAddressSetMutationFn = Apollo.MutationFunction<
  RepresentativeAddressSetMutation,
  RepresentativeAddressSetMutationVariables
>

/**
 * __useRepresentativeAddressSetMutation__
 *
 * To run a mutation, you first call `useRepresentativeAddressSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepresentativeAddressSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [representativeAddressSetMutation, { data, loading, error }] = useRepresentativeAddressSetMutation({
 *   variables: {
 *      representativeId: // value for 'representativeId'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useRepresentativeAddressSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RepresentativeAddressSetMutation,
    RepresentativeAddressSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RepresentativeAddressSetMutation,
    RepresentativeAddressSetMutationVariables
  >(RepresentativeAddressSetDocument, options)
}
export type RepresentativeAddressSetMutationHookResult = ReturnType<
  typeof useRepresentativeAddressSetMutation
>
export type RepresentativeAddressSetMutationResult =
  Apollo.MutationResult<RepresentativeAddressSetMutation>
export type RepresentativeAddressSetMutationOptions =
  Apollo.BaseMutationOptions<
    RepresentativeAddressSetMutation,
    RepresentativeAddressSetMutationVariables
  >
export const RepresentativeCreateDocument = gql`
  mutation RepresentativeCreate(
    $companyId: ID!
    $firstName: String
    $lastName: String
    $email: EmailAddress
    $phoneNumber: SloppyPhoneNumber
    $representativeGroup: RepresentativeGroupEnum!
  ) {
    company {
      representativeCreate(
        input: {
          companyId: $companyId
          firstName: $firstName
          lastName: $lastName
          email: $email
          phoneNumber: $phoneNumber
          representativeGroup: $representativeGroup
        }
      ) {
        representative {
          id
        }
      }
    }
  }
`
export type RepresentativeCreateMutationFn = Apollo.MutationFunction<
  RepresentativeCreateMutation,
  RepresentativeCreateMutationVariables
>

/**
 * __useRepresentativeCreateMutation__
 *
 * To run a mutation, you first call `useRepresentativeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepresentativeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [representativeCreateMutation, { data, loading, error }] = useRepresentativeCreateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      representativeGroup: // value for 'representativeGroup'
 *   },
 * });
 */
export function useRepresentativeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RepresentativeCreateMutation,
    RepresentativeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RepresentativeCreateMutation,
    RepresentativeCreateMutationVariables
  >(RepresentativeCreateDocument, options)
}
export type RepresentativeCreateMutationHookResult = ReturnType<
  typeof useRepresentativeCreateMutation
>
export type RepresentativeCreateMutationResult =
  Apollo.MutationResult<RepresentativeCreateMutation>
export type RepresentativeCreateMutationOptions = Apollo.BaseMutationOptions<
  RepresentativeCreateMutation,
  RepresentativeCreateMutationVariables
>
export const RepresentativeDeleteDocument = gql`
  mutation RepresentativeDelete(
    $representativeIds: [ID!]
    $companyIds: [ID!]
    $representativeGroups: [RepresentativeGroupEnum!]
  ) {
    company {
      representativeDelete(
        input: {
          filter: {
            representativeIds: $representativeIds
            companyIds: $companyIds
            representativeGroups: $representativeGroups
          }
        }
      ) {
        representatives {
          id
        }
      }
    }
  }
`
export type RepresentativeDeleteMutationFn = Apollo.MutationFunction<
  RepresentativeDeleteMutation,
  RepresentativeDeleteMutationVariables
>

/**
 * __useRepresentativeDeleteMutation__
 *
 * To run a mutation, you first call `useRepresentativeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepresentativeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [representativeDeleteMutation, { data, loading, error }] = useRepresentativeDeleteMutation({
 *   variables: {
 *      representativeIds: // value for 'representativeIds'
 *      companyIds: // value for 'companyIds'
 *      representativeGroups: // value for 'representativeGroups'
 *   },
 * });
 */
export function useRepresentativeDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RepresentativeDeleteMutation,
    RepresentativeDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RepresentativeDeleteMutation,
    RepresentativeDeleteMutationVariables
  >(RepresentativeDeleteDocument, options)
}
export type RepresentativeDeleteMutationHookResult = ReturnType<
  typeof useRepresentativeDeleteMutation
>
export type RepresentativeDeleteMutationResult =
  Apollo.MutationResult<RepresentativeDeleteMutation>
export type RepresentativeDeleteMutationOptions = Apollo.BaseMutationOptions<
  RepresentativeDeleteMutation,
  RepresentativeDeleteMutationVariables
>
export const RepresentativeUpdateDocument = gql`
  mutation RepresentativeUpdate(
    $representativeId: ID!
    $companyId: ID!
    $firstName: String
    $lastName: String
    $email: EmailAddress
    $phoneNumber: SloppyPhoneNumber
    $representativeGroup: RepresentativeGroupEnum!
  ) {
    company {
      representativeUpdate(
        input: {
          representativeId: $representativeId
          companyId: $companyId
          firstName: $firstName
          lastName: $lastName
          email: $email
          phoneNumber: $phoneNumber
          representativeGroup: $representativeGroup
        }
      ) {
        representative {
          id
        }
      }
    }
  }
`
export type RepresentativeUpdateMutationFn = Apollo.MutationFunction<
  RepresentativeUpdateMutation,
  RepresentativeUpdateMutationVariables
>

/**
 * __useRepresentativeUpdateMutation__
 *
 * To run a mutation, you first call `useRepresentativeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepresentativeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [representativeUpdateMutation, { data, loading, error }] = useRepresentativeUpdateMutation({
 *   variables: {
 *      representativeId: // value for 'representativeId'
 *      companyId: // value for 'companyId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      representativeGroup: // value for 'representativeGroup'
 *   },
 * });
 */
export function useRepresentativeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RepresentativeUpdateMutation,
    RepresentativeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RepresentativeUpdateMutation,
    RepresentativeUpdateMutationVariables
  >(RepresentativeUpdateDocument, options)
}
export type RepresentativeUpdateMutationHookResult = ReturnType<
  typeof useRepresentativeUpdateMutation
>
export type RepresentativeUpdateMutationResult =
  Apollo.MutationResult<RepresentativeUpdateMutation>
export type RepresentativeUpdateMutationOptions = Apollo.BaseMutationOptions<
  RepresentativeUpdateMutation,
  RepresentativeUpdateMutationVariables
>
export const CompanyUpdateDocument = gql`
  mutation CompanyUpdate(
    $companyId: ID!
    $name: String
    $typeId: ID
    $structureId: ID
    $numberOfEmployees: NonNegativeInt
    $annualSalesRevenueId: ID
    $currencyId: ID
    $slug: String
  ) {
    company {
      update(
        input: {
          companyId: $companyId
          name: $name
          typeId: $typeId
          structureId: $structureId
          numberOfEmployees: $numberOfEmployees
          annualSalesRevenueId: $annualSalesRevenueId
          currencyId: $currencyId
          slug: $slug
        }
      ) {
        company {
          id
          name
          slug
        }
      }
    }
  }
`
export type CompanyUpdateMutationFn = Apollo.MutationFunction<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>

/**
 * __useCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateMutation, { data, loading, error }] = useCompanyUpdateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      typeId: // value for 'typeId'
 *      structureId: // value for 'structureId'
 *      numberOfEmployees: // value for 'numberOfEmployees'
 *      annualSalesRevenueId: // value for 'annualSalesRevenueId'
 *      currencyId: // value for 'currencyId'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCompanyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >(CompanyUpdateDocument, options)
}
export type CompanyUpdateMutationHookResult = ReturnType<
  typeof useCompanyUpdateMutation
>
export type CompanyUpdateMutationResult =
  Apollo.MutationResult<CompanyUpdateMutation>
export type CompanyUpdateMutationOptions = Apollo.BaseMutationOptions<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>
export const CompanyAudiencesGetDocument = gql`
  query CompanyAudiencesGet(
    $input: CompanyAudiencesGetInput!
    $companyId: ID!
  ) {
    company {
      audiencesGet(input: $input) {
        audiences {
          ...AudienceDetails
        }
        paginationInfo {
          totalItems
          totalPages
        }
      }
    }
  }
  ${AudienceDetailsFragmentDoc}
`

/**
 * __useCompanyAudiencesGetQuery__
 *
 * To run a query within a React component, call `useCompanyAudiencesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAudiencesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAudiencesGetQuery({
 *   variables: {
 *      input: // value for 'input'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyAudiencesGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyAudiencesGetQuery,
    CompanyAudiencesGetQueryVariables
  > &
    (
      | { variables: CompanyAudiencesGetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyAudiencesGetQuery,
    CompanyAudiencesGetQueryVariables
  >(CompanyAudiencesGetDocument, options)
}
export function useCompanyAudiencesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyAudiencesGetQuery,
    CompanyAudiencesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyAudiencesGetQuery,
    CompanyAudiencesGetQueryVariables
  >(CompanyAudiencesGetDocument, options)
}
export function useCompanyAudiencesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompanyAudiencesGetQuery,
    CompanyAudiencesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyAudiencesGetQuery,
    CompanyAudiencesGetQueryVariables
  >(CompanyAudiencesGetDocument, options)
}
export type CompanyAudiencesGetQueryHookResult = ReturnType<
  typeof useCompanyAudiencesGetQuery
>
export type CompanyAudiencesGetLazyQueryHookResult = ReturnType<
  typeof useCompanyAudiencesGetLazyQuery
>
export type CompanyAudiencesGetSuspenseQueryHookResult = ReturnType<
  typeof useCompanyAudiencesGetSuspenseQuery
>
export type CompanyAudiencesGetQueryResult = Apollo.QueryResult<
  CompanyAudiencesGetQuery,
  CompanyAudiencesGetQueryVariables
>
export const CompanyCompanyGetDocument = gql`
  query CompanyCompanyGet($companyId: ID!) {
    company {
      get(input: { companyId: $companyId }) {
        company {
          id
          name
          currentSubscriptionTier {
            id
            name
            features {
              id
              name
              code
              description
              ... on SubscriptionFeatureIntRange {
                minValue
                maxValue
              }
              ... on SubscriptionFeatureBoolean {
                available
              }
            }
          }
          representatives {
            id
            firstName
            lastName
            email
            phoneNumber
            representativeGroup
            address {
              id
              streetAddress
              locality
              administrativeArea
              postalCode
              country {
                id
                name
              }
            }
          }
          audiences {
            id
            name
            description
            isSingleUser
            updatedAt
            users {
              id
              firstName
              lastName
              status
              email
              roles(input: { filter: { companyIds: [$companyId] } }) {
                userId
                companyId
                roleId
                name
                code
              }
              positions(input: { filter: { companyIds: [$companyId] } }) {
                position {
                  id
                  name
                  companyId
                }
                department {
                  id
                  name
                  companyId
                }
              }
            }
          }
          type {
            id
            name
          }
          logo {
            ...CompanyBinaryFileInfo
          }
          structure {
            id
            name
          }
          industries {
            id
            name
          }
          regions {
            id
            name
          }
          numberOfEmployees
          annualSalesRevenue {
            id
            minValue
            maxValue
          }
          currency {
            id
            isoCode
          }
          address {
            id
            streetAddress
            streetAddress2
            locality
            subAdministrativeArea
            administrativeArea
            postalCode
            country {
              id
              name
            }
            administrativeArea
            subAdministrativeArea
          }
          tenant {
            id
          }
        }
      }
    }
  }
  ${CompanyBinaryFileInfoFragmentDoc}
`

/**
 * __useCompanyCompanyGetQuery__
 *
 * To run a query within a React component, call `useCompanyCompanyGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCompanyGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCompanyGetQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyCompanyGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyCompanyGetQuery,
    CompanyCompanyGetQueryVariables
  > &
    (
      | { variables: CompanyCompanyGetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyCompanyGetQuery,
    CompanyCompanyGetQueryVariables
  >(CompanyCompanyGetDocument, options)
}
export function useCompanyCompanyGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyCompanyGetQuery,
    CompanyCompanyGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyCompanyGetQuery,
    CompanyCompanyGetQueryVariables
  >(CompanyCompanyGetDocument, options)
}
export function useCompanyCompanyGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompanyCompanyGetQuery,
    CompanyCompanyGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyCompanyGetQuery,
    CompanyCompanyGetQueryVariables
  >(CompanyCompanyGetDocument, options)
}
export type CompanyCompanyGetQueryHookResult = ReturnType<
  typeof useCompanyCompanyGetQuery
>
export type CompanyCompanyGetLazyQueryHookResult = ReturnType<
  typeof useCompanyCompanyGetLazyQuery
>
export type CompanyCompanyGetSuspenseQueryHookResult = ReturnType<
  typeof useCompanyCompanyGetSuspenseQuery
>
export type CompanyCompanyGetQueryResult = Apollo.QueryResult<
  CompanyCompanyGetQuery,
  CompanyCompanyGetQueryVariables
>
export const CompanyDepartmentsGetDocument = gql`
  query CompanyDepartmentsGet($filter: DepartmentFilterArg) {
    company {
      departmentsGet(input: { filter: $filter }) {
        departments {
          ...Department
        }
      }
    }
  }
  ${DepartmentFragmentDoc}
`

/**
 * __useCompanyDepartmentsGetQuery__
 *
 * To run a query within a React component, call `useCompanyDepartmentsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDepartmentsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDepartmentsGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyDepartmentsGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyDepartmentsGetQuery,
    CompanyDepartmentsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyDepartmentsGetQuery,
    CompanyDepartmentsGetQueryVariables
  >(CompanyDepartmentsGetDocument, options)
}
export function useCompanyDepartmentsGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyDepartmentsGetQuery,
    CompanyDepartmentsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyDepartmentsGetQuery,
    CompanyDepartmentsGetQueryVariables
  >(CompanyDepartmentsGetDocument, options)
}
export function useCompanyDepartmentsGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompanyDepartmentsGetQuery,
    CompanyDepartmentsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyDepartmentsGetQuery,
    CompanyDepartmentsGetQueryVariables
  >(CompanyDepartmentsGetDocument, options)
}
export type CompanyDepartmentsGetQueryHookResult = ReturnType<
  typeof useCompanyDepartmentsGetQuery
>
export type CompanyDepartmentsGetLazyQueryHookResult = ReturnType<
  typeof useCompanyDepartmentsGetLazyQuery
>
export type CompanyDepartmentsGetSuspenseQueryHookResult = ReturnType<
  typeof useCompanyDepartmentsGetSuspenseQuery
>
export type CompanyDepartmentsGetQueryResult = Apollo.QueryResult<
  CompanyDepartmentsGetQuery,
  CompanyDepartmentsGetQueryVariables
>
export const CompanyFeaturesGetDocument = gql`
  query CompanyFeaturesGet($companyId: ID!) {
    company {
      companyFeaturesGet(input: { companyId: $companyId }) {
        features {
          id
          subscriptionFeatureId
          name
          description
          uiCode
          code
          ... on SubscriptionFeatureIntRange {
            minValue
            maxValue
          }
          ... on SubscriptionFeatureBoolean {
            available
          }
        }
      }
    }
  }
`

/**
 * __useCompanyFeaturesGetQuery__
 *
 * To run a query within a React component, call `useCompanyFeaturesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFeaturesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFeaturesGetQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyFeaturesGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyFeaturesGetQuery,
    CompanyFeaturesGetQueryVariables
  > &
    (
      | { variables: CompanyFeaturesGetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyFeaturesGetQuery,
    CompanyFeaturesGetQueryVariables
  >(CompanyFeaturesGetDocument, options)
}
export function useCompanyFeaturesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyFeaturesGetQuery,
    CompanyFeaturesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyFeaturesGetQuery,
    CompanyFeaturesGetQueryVariables
  >(CompanyFeaturesGetDocument, options)
}
export function useCompanyFeaturesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompanyFeaturesGetQuery,
    CompanyFeaturesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyFeaturesGetQuery,
    CompanyFeaturesGetQueryVariables
  >(CompanyFeaturesGetDocument, options)
}
export type CompanyFeaturesGetQueryHookResult = ReturnType<
  typeof useCompanyFeaturesGetQuery
>
export type CompanyFeaturesGetLazyQueryHookResult = ReturnType<
  typeof useCompanyFeaturesGetLazyQuery
>
export type CompanyFeaturesGetSuspenseQueryHookResult = ReturnType<
  typeof useCompanyFeaturesGetSuspenseQuery
>
export type CompanyFeaturesGetQueryResult = Apollo.QueryResult<
  CompanyFeaturesGetQuery,
  CompanyFeaturesGetQueryVariables
>
export const CompanyOnboardingStepsGetDocument = gql`
  query CompanyOnboardingStepsGet($companyId: ID!) {
    company {
      onboardingStepsGet(input: { companyId: $companyId }) {
        stepCodes
      }
    }
  }
`

/**
 * __useCompanyOnboardingStepsGetQuery__
 *
 * To run a query within a React component, call `useCompanyOnboardingStepsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOnboardingStepsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOnboardingStepsGetQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyOnboardingStepsGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyOnboardingStepsGetQuery,
    CompanyOnboardingStepsGetQueryVariables
  > &
    (
      | { variables: CompanyOnboardingStepsGetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyOnboardingStepsGetQuery,
    CompanyOnboardingStepsGetQueryVariables
  >(CompanyOnboardingStepsGetDocument, options)
}
export function useCompanyOnboardingStepsGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyOnboardingStepsGetQuery,
    CompanyOnboardingStepsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyOnboardingStepsGetQuery,
    CompanyOnboardingStepsGetQueryVariables
  >(CompanyOnboardingStepsGetDocument, options)
}
export function useCompanyOnboardingStepsGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompanyOnboardingStepsGetQuery,
    CompanyOnboardingStepsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyOnboardingStepsGetQuery,
    CompanyOnboardingStepsGetQueryVariables
  >(CompanyOnboardingStepsGetDocument, options)
}
export type CompanyOnboardingStepsGetQueryHookResult = ReturnType<
  typeof useCompanyOnboardingStepsGetQuery
>
export type CompanyOnboardingStepsGetLazyQueryHookResult = ReturnType<
  typeof useCompanyOnboardingStepsGetLazyQuery
>
export type CompanyOnboardingStepsGetSuspenseQueryHookResult = ReturnType<
  typeof useCompanyOnboardingStepsGetSuspenseQuery
>
export type CompanyOnboardingStepsGetQueryResult = Apollo.QueryResult<
  CompanyOnboardingStepsGetQuery,
  CompanyOnboardingStepsGetQueryVariables
>
export const CompanyPositionsGetDocument = gql`
  query CompanyPositionsGet($filter: PositionFilterArg) {
    company {
      positionsGet(input: { filter: $filter }) {
        positions {
          id
          name
          description
        }
      }
    }
  }
`

/**
 * __useCompanyPositionsGetQuery__
 *
 * To run a query within a React component, call `useCompanyPositionsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPositionsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPositionsGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyPositionsGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyPositionsGetQuery,
    CompanyPositionsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyPositionsGetQuery,
    CompanyPositionsGetQueryVariables
  >(CompanyPositionsGetDocument, options)
}
export function useCompanyPositionsGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyPositionsGetQuery,
    CompanyPositionsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyPositionsGetQuery,
    CompanyPositionsGetQueryVariables
  >(CompanyPositionsGetDocument, options)
}
export function useCompanyPositionsGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompanyPositionsGetQuery,
    CompanyPositionsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyPositionsGetQuery,
    CompanyPositionsGetQueryVariables
  >(CompanyPositionsGetDocument, options)
}
export type CompanyPositionsGetQueryHookResult = ReturnType<
  typeof useCompanyPositionsGetQuery
>
export type CompanyPositionsGetLazyQueryHookResult = ReturnType<
  typeof useCompanyPositionsGetLazyQuery
>
export type CompanyPositionsGetSuspenseQueryHookResult = ReturnType<
  typeof useCompanyPositionsGetSuspenseQuery
>
export type CompanyPositionsGetQueryResult = Apollo.QueryResult<
  CompanyPositionsGetQuery,
  CompanyPositionsGetQueryVariables
>
export const CompanyProcessesGetDocument = gql`
  query CompanyProcessesGet($filter: ProcessesGetFilterArg) {
    company {
      processesGet(input: { filter: $filter }) {
        processes {
          id
          name
        }
      }
    }
  }
`

/**
 * __useCompanyProcessesGetQuery__
 *
 * To run a query within a React component, call `useCompanyProcessesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProcessesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProcessesGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyProcessesGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyProcessesGetQuery,
    CompanyProcessesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyProcessesGetQuery,
    CompanyProcessesGetQueryVariables
  >(CompanyProcessesGetDocument, options)
}
export function useCompanyProcessesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyProcessesGetQuery,
    CompanyProcessesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyProcessesGetQuery,
    CompanyProcessesGetQueryVariables
  >(CompanyProcessesGetDocument, options)
}
export function useCompanyProcessesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompanyProcessesGetQuery,
    CompanyProcessesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyProcessesGetQuery,
    CompanyProcessesGetQueryVariables
  >(CompanyProcessesGetDocument, options)
}
export type CompanyProcessesGetQueryHookResult = ReturnType<
  typeof useCompanyProcessesGetQuery
>
export type CompanyProcessesGetLazyQueryHookResult = ReturnType<
  typeof useCompanyProcessesGetLazyQuery
>
export type CompanyProcessesGetSuspenseQueryHookResult = ReturnType<
  typeof useCompanyProcessesGetSuspenseQuery
>
export type CompanyProcessesGetQueryResult = Apollo.QueryResult<
  CompanyProcessesGetQuery,
  CompanyProcessesGetQueryVariables
>
export const CompanyRepresentativesGetDocument = gql`
  query CompanyRepresentativesGet($filter: RepresentativeFilterArg) {
    company {
      representativesGet(input: { filter: $filter }) {
        representatives {
          id
          firstName
          lastName
          email
          phoneNumber
          address {
            id
            streetAddress
            locality
            administrativeArea
            postalCode
            country {
              id
              name
            }
          }
        }
      }
    }
  }
`

/**
 * __useCompanyRepresentativesGetQuery__
 *
 * To run a query within a React component, call `useCompanyRepresentativesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRepresentativesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRepresentativesGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyRepresentativesGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyRepresentativesGetQuery,
    CompanyRepresentativesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyRepresentativesGetQuery,
    CompanyRepresentativesGetQueryVariables
  >(CompanyRepresentativesGetDocument, options)
}
export function useCompanyRepresentativesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyRepresentativesGetQuery,
    CompanyRepresentativesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyRepresentativesGetQuery,
    CompanyRepresentativesGetQueryVariables
  >(CompanyRepresentativesGetDocument, options)
}
export function useCompanyRepresentativesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CompanyRepresentativesGetQuery,
    CompanyRepresentativesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyRepresentativesGetQuery,
    CompanyRepresentativesGetQueryVariables
  >(CompanyRepresentativesGetDocument, options)
}
export type CompanyRepresentativesGetQueryHookResult = ReturnType<
  typeof useCompanyRepresentativesGetQuery
>
export type CompanyRepresentativesGetLazyQueryHookResult = ReturnType<
  typeof useCompanyRepresentativesGetLazyQuery
>
export type CompanyRepresentativesGetSuspenseQueryHookResult = ReturnType<
  typeof useCompanyRepresentativesGetSuspenseQuery
>
export type CompanyRepresentativesGetQueryResult = Apollo.QueryResult<
  CompanyRepresentativesGetQuery,
  CompanyRepresentativesGetQueryVariables
>
export const DocumentCreateDocument = gql`
  mutation DocumentCreate(
    $name: String!
    $description: String
    $folderId: ID
    $companyId: ID
    $ownerId: ID
    $file: Upload!
    $fileUrlInput: BinaryFileInfoUrlInput
    $validUntil: DateTime
    $isPrivate: Boolean
  ) {
    document {
      create(
        input: {
          name: $name
          description: $description
          folderId: $folderId
          companyId: $companyId
          ownerId: $ownerId
          file: $file
          validUntil: $validUntil
          isPrivate: $isPrivate
        }
      ) {
        document {
          ...DocumentFields
        }
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`
export type DocumentCreateMutationFn = Apollo.MutationFunction<
  DocumentCreateMutation,
  DocumentCreateMutationVariables
>

/**
 * __useDocumentCreateMutation__
 *
 * To run a mutation, you first call `useDocumentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentCreateMutation, { data, loading, error }] = useDocumentCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      folderId: // value for 'folderId'
 *      companyId: // value for 'companyId'
 *      ownerId: // value for 'ownerId'
 *      file: // value for 'file'
 *      fileUrlInput: // value for 'fileUrlInput'
 *      validUntil: // value for 'validUntil'
 *      isPrivate: // value for 'isPrivate'
 *   },
 * });
 */
export function useDocumentCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DocumentCreateMutation,
    DocumentCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DocumentCreateMutation,
    DocumentCreateMutationVariables
  >(DocumentCreateDocument, options)
}
export type DocumentCreateMutationHookResult = ReturnType<
  typeof useDocumentCreateMutation
>
export type DocumentCreateMutationResult =
  Apollo.MutationResult<DocumentCreateMutation>
export type DocumentCreateMutationOptions = Apollo.BaseMutationOptions<
  DocumentCreateMutation,
  DocumentCreateMutationVariables
>
export const DocumentDeleteDocument = gql`
  mutation DocumentDelete($documentIds: [ID!]) {
    document {
      delete(input: { filter: { documentIds: $documentIds } }) {
        documents {
          id
        }
      }
    }
  }
`
export type DocumentDeleteMutationFn = Apollo.MutationFunction<
  DocumentDeleteMutation,
  DocumentDeleteMutationVariables
>

/**
 * __useDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentDeleteMutation, { data, loading, error }] = useDocumentDeleteMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useDocumentDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DocumentDeleteMutation,
    DocumentDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DocumentDeleteMutation,
    DocumentDeleteMutationVariables
  >(DocumentDeleteDocument, options)
}
export type DocumentDeleteMutationHookResult = ReturnType<
  typeof useDocumentDeleteMutation
>
export type DocumentDeleteMutationResult =
  Apollo.MutationResult<DocumentDeleteMutation>
export type DocumentDeleteMutationOptions = Apollo.BaseMutationOptions<
  DocumentDeleteMutation,
  DocumentDeleteMutationVariables
>
export const DocumentUpdateDocument = gql`
  mutation DocumentUpdate(
    $documentId: ID!
    $isPrivate: Boolean
    $name: String!
    $description: String
    $folderId: ID
    $ownerId: ID
    $validUntil: DateTime
    $fileUrlInput: BinaryFileInfoUrlInput
  ) {
    document {
      update(
        input: {
          documentId: $documentId
          isPrivate: $isPrivate
          name: $name
          description: $description
          folderId: $folderId
          ownerId: $ownerId
          validUntil: $validUntil
        }
      ) {
        document {
          ...DocumentFields
        }
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`
export type DocumentUpdateMutationFn = Apollo.MutationFunction<
  DocumentUpdateMutation,
  DocumentUpdateMutationVariables
>

/**
 * __useDocumentUpdateMutation__
 *
 * To run a mutation, you first call `useDocumentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentUpdateMutation, { data, loading, error }] = useDocumentUpdateMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      isPrivate: // value for 'isPrivate'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      folderId: // value for 'folderId'
 *      ownerId: // value for 'ownerId'
 *      validUntil: // value for 'validUntil'
 *      fileUrlInput: // value for 'fileUrlInput'
 *   },
 * });
 */
export function useDocumentUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DocumentUpdateMutation,
    DocumentUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DocumentUpdateMutation,
    DocumentUpdateMutationVariables
  >(DocumentUpdateDocument, options)
}
export type DocumentUpdateMutationHookResult = ReturnType<
  typeof useDocumentUpdateMutation
>
export type DocumentUpdateMutationResult =
  Apollo.MutationResult<DocumentUpdateMutation>
export type DocumentUpdateMutationOptions = Apollo.BaseMutationOptions<
  DocumentUpdateMutation,
  DocumentUpdateMutationVariables
>
export const DocumentFolderCreateDocument = gql`
  mutation DocumentFolderCreate(
    $name: String!
    $description: String
    $parentFolderId: ID
    $companyId: ID!
    $ownerId: ID
  ) {
    document {
      folderCreate(
        input: {
          name: $name
          description: $description
          parentFolderId: $parentFolderId
          companyId: $companyId
          ownerId: $ownerId
        }
      ) {
        folder {
          id
          folderId
          name
          description
          isRoot
          ownerId
          createdByUserId
          parentFolderId
          companyId
        }
      }
    }
  }
`
export type DocumentFolderCreateMutationFn = Apollo.MutationFunction<
  DocumentFolderCreateMutation,
  DocumentFolderCreateMutationVariables
>

/**
 * __useDocumentFolderCreateMutation__
 *
 * To run a mutation, you first call `useDocumentFolderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentFolderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentFolderCreateMutation, { data, loading, error }] = useDocumentFolderCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      parentFolderId: // value for 'parentFolderId'
 *      companyId: // value for 'companyId'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useDocumentFolderCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DocumentFolderCreateMutation,
    DocumentFolderCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DocumentFolderCreateMutation,
    DocumentFolderCreateMutationVariables
  >(DocumentFolderCreateDocument, options)
}
export type DocumentFolderCreateMutationHookResult = ReturnType<
  typeof useDocumentFolderCreateMutation
>
export type DocumentFolderCreateMutationResult =
  Apollo.MutationResult<DocumentFolderCreateMutation>
export type DocumentFolderCreateMutationOptions = Apollo.BaseMutationOptions<
  DocumentFolderCreateMutation,
  DocumentFolderCreateMutationVariables
>
export const DocumentFolderDeleteDocument = gql`
  mutation DocumentFolderDelete(
    $filter: DocboxFolderDeleteFilterArg
    $deleteRecursive: Boolean
  ) {
    document {
      folderDelete(
        input: { filter: $filter, deleteRecursive: $deleteRecursive }
      ) {
        folders {
          id
          folderId
          name
          description
          isRoot
          ownerId
          createdByUserId
          parentFolderId
          companyId
        }
      }
    }
  }
`
export type DocumentFolderDeleteMutationFn = Apollo.MutationFunction<
  DocumentFolderDeleteMutation,
  DocumentFolderDeleteMutationVariables
>

/**
 * __useDocumentFolderDeleteMutation__
 *
 * To run a mutation, you first call `useDocumentFolderDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentFolderDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentFolderDeleteMutation, { data, loading, error }] = useDocumentFolderDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      deleteRecursive: // value for 'deleteRecursive'
 *   },
 * });
 */
export function useDocumentFolderDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DocumentFolderDeleteMutation,
    DocumentFolderDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DocumentFolderDeleteMutation,
    DocumentFolderDeleteMutationVariables
  >(DocumentFolderDeleteDocument, options)
}
export type DocumentFolderDeleteMutationHookResult = ReturnType<
  typeof useDocumentFolderDeleteMutation
>
export type DocumentFolderDeleteMutationResult =
  Apollo.MutationResult<DocumentFolderDeleteMutation>
export type DocumentFolderDeleteMutationOptions = Apollo.BaseMutationOptions<
  DocumentFolderDeleteMutation,
  DocumentFolderDeleteMutationVariables
>
export const DocumentFolderUpdateDocument = gql`
  mutation DocumentFolderUpdate(
    $folderId: ID!
    $name: String
    $description: String
    $parentFolderId: ID
    $companyId: ID!
    $ownerId: ID
  ) {
    document {
      folderUpdate(
        input: {
          folderId: $folderId
          name: $name
          description: $description
          parentFolderId: $parentFolderId
          companyId: $companyId
          ownerId: $ownerId
        }
      ) {
        folder {
          id
          folderId
          name
          description
          isRoot
          ownerId
          createdByUserId
          parentFolderId
          companyId
        }
      }
    }
  }
`
export type DocumentFolderUpdateMutationFn = Apollo.MutationFunction<
  DocumentFolderUpdateMutation,
  DocumentFolderUpdateMutationVariables
>

/**
 * __useDocumentFolderUpdateMutation__
 *
 * To run a mutation, you first call `useDocumentFolderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentFolderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentFolderUpdateMutation, { data, loading, error }] = useDocumentFolderUpdateMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      parentFolderId: // value for 'parentFolderId'
 *      companyId: // value for 'companyId'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useDocumentFolderUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DocumentFolderUpdateMutation,
    DocumentFolderUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DocumentFolderUpdateMutation,
    DocumentFolderUpdateMutationVariables
  >(DocumentFolderUpdateDocument, options)
}
export type DocumentFolderUpdateMutationHookResult = ReturnType<
  typeof useDocumentFolderUpdateMutation
>
export type DocumentFolderUpdateMutationResult =
  Apollo.MutationResult<DocumentFolderUpdateMutation>
export type DocumentFolderUpdateMutationOptions = Apollo.BaseMutationOptions<
  DocumentFolderUpdateMutation,
  DocumentFolderUpdateMutationVariables
>
export const DocumentGetDocument = gql`
  query DocumentGet(
    $filter: DocboxGetFilterArg
    $fileUrlInput: BinaryFileInfoUrlInput
    $pagination: PaginationArg
    $order: [DocboxGetOrderArg!]
    $search: DocboxGetSearchArg
  ) {
    document {
      get(
        input: {
          filter: $filter
          pagination: $pagination
          order: $order
          search: $search
        }
      ) {
        documents {
          ...DocumentFields
        }
        paginationInfo {
          offset
          limit
          totalItems
          totalPages
        }
      }
    }
  }
  ${DocumentFieldsFragmentDoc}
`

/**
 * __useDocumentGetQuery__
 *
 * To run a query within a React component, call `useDocumentGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      fileUrlInput: // value for 'fileUrlInput'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDocumentGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DocumentGetQuery,
    DocumentGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DocumentGetQuery, DocumentGetQueryVariables>(
    DocumentGetDocument,
    options,
  )
}
export function useDocumentGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DocumentGetQuery,
    DocumentGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DocumentGetQuery, DocumentGetQueryVariables>(
    DocumentGetDocument,
    options,
  )
}
export function useDocumentGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DocumentGetQuery,
    DocumentGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DocumentGetQuery, DocumentGetQueryVariables>(
    DocumentGetDocument,
    options,
  )
}
export type DocumentGetQueryHookResult = ReturnType<typeof useDocumentGetQuery>
export type DocumentGetLazyQueryHookResult = ReturnType<
  typeof useDocumentGetLazyQuery
>
export type DocumentGetSuspenseQueryHookResult = ReturnType<
  typeof useDocumentGetSuspenseQuery
>
export type DocumentGetQueryResult = Apollo.QueryResult<
  DocumentGetQuery,
  DocumentGetQueryVariables
>
export const CompanyAddressUpsertDocument = gql`
  mutation CompanyAddressUpsert($input: CompanyAddressSetInput!) {
    company {
      addressSet(input: $input) {
        company {
          id
          name
          address {
            id
            streetAddress
            locality
            postalCode
            administrativeArea
            country {
              id
              name
            }
          }
        }
      }
    }
  }
`
export type CompanyAddressUpsertMutationFn = Apollo.MutationFunction<
  CompanyAddressUpsertMutation,
  CompanyAddressUpsertMutationVariables
>

/**
 * __useCompanyAddressUpsertMutation__
 *
 * To run a mutation, you first call `useCompanyAddressUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAddressUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAddressUpsertMutation, { data, loading, error }] = useCompanyAddressUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyAddressUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyAddressUpsertMutation,
    CompanyAddressUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyAddressUpsertMutation,
    CompanyAddressUpsertMutationVariables
  >(CompanyAddressUpsertDocument, options)
}
export type CompanyAddressUpsertMutationHookResult = ReturnType<
  typeof useCompanyAddressUpsertMutation
>
export type CompanyAddressUpsertMutationResult =
  Apollo.MutationResult<CompanyAddressUpsertMutation>
export type CompanyAddressUpsertMutationOptions = Apollo.BaseMutationOptions<
  CompanyAddressUpsertMutation,
  CompanyAddressUpsertMutationVariables
>
export const GeneralCompanyRegionLinkDocument = gql`
  mutation GeneralCompanyRegionLink($companyId: ID!, $regionIds: [ID!]!) {
    general {
      companyRegionLink(
        input: { companyId: $companyId, regionIds: $regionIds }
      ) {
        company {
          id
          selectedRegulations {
            ...CompanySelectedRegulation
          }
        }
      }
    }
  }
  ${CompanySelectedRegulationFragmentDoc}
`
export type GeneralCompanyRegionLinkMutationFn = Apollo.MutationFunction<
  GeneralCompanyRegionLinkMutation,
  GeneralCompanyRegionLinkMutationVariables
>

/**
 * __useGeneralCompanyRegionLinkMutation__
 *
 * To run a mutation, you first call `useGeneralCompanyRegionLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneralCompanyRegionLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generalCompanyRegionLinkMutation, { data, loading, error }] = useGeneralCompanyRegionLinkMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      regionIds: // value for 'regionIds'
 *   },
 * });
 */
export function useGeneralCompanyRegionLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneralCompanyRegionLinkMutation,
    GeneralCompanyRegionLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GeneralCompanyRegionLinkMutation,
    GeneralCompanyRegionLinkMutationVariables
  >(GeneralCompanyRegionLinkDocument, options)
}
export type GeneralCompanyRegionLinkMutationHookResult = ReturnType<
  typeof useGeneralCompanyRegionLinkMutation
>
export type GeneralCompanyRegionLinkMutationResult =
  Apollo.MutationResult<GeneralCompanyRegionLinkMutation>
export type GeneralCompanyRegionLinkMutationOptions =
  Apollo.BaseMutationOptions<
    GeneralCompanyRegionLinkMutation,
    GeneralCompanyRegionLinkMutationVariables
  >
export const GeneralCompanyRegionUnlinkDocument = gql`
  mutation GeneralCompanyRegionUnlink($companyId: ID!, $regionIds: [ID!]!) {
    general {
      companyRegionUnlink(
        input: { companyId: $companyId, regionIds: $regionIds }
      ) {
        company {
          id
        }
      }
    }
  }
`
export type GeneralCompanyRegionUnlinkMutationFn = Apollo.MutationFunction<
  GeneralCompanyRegionUnlinkMutation,
  GeneralCompanyRegionUnlinkMutationVariables
>

/**
 * __useGeneralCompanyRegionUnlinkMutation__
 *
 * To run a mutation, you first call `useGeneralCompanyRegionUnlinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneralCompanyRegionUnlinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generalCompanyRegionUnlinkMutation, { data, loading, error }] = useGeneralCompanyRegionUnlinkMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      regionIds: // value for 'regionIds'
 *   },
 * });
 */
export function useGeneralCompanyRegionUnlinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneralCompanyRegionUnlinkMutation,
    GeneralCompanyRegionUnlinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GeneralCompanyRegionUnlinkMutation,
    GeneralCompanyRegionUnlinkMutationVariables
  >(GeneralCompanyRegionUnlinkDocument, options)
}
export type GeneralCompanyRegionUnlinkMutationHookResult = ReturnType<
  typeof useGeneralCompanyRegionUnlinkMutation
>
export type GeneralCompanyRegionUnlinkMutationResult =
  Apollo.MutationResult<GeneralCompanyRegionUnlinkMutation>
export type GeneralCompanyRegionUnlinkMutationOptions =
  Apollo.BaseMutationOptions<
    GeneralCompanyRegionUnlinkMutation,
    GeneralCompanyRegionUnlinkMutationVariables
  >
export const CompanyDepartmentCreateDocument = gql`
  mutation CompanyDepartmentCreate($input: CompanyDepartmentCreateInput!) {
    company {
      departmentCreate(input: $input) {
        department {
          ...Department
        }
      }
    }
  }
  ${DepartmentFragmentDoc}
`
export type CompanyDepartmentCreateMutationFn = Apollo.MutationFunction<
  CompanyDepartmentCreateMutation,
  CompanyDepartmentCreateMutationVariables
>

/**
 * __useCompanyDepartmentCreateMutation__
 *
 * To run a mutation, you first call `useCompanyDepartmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyDepartmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyDepartmentCreateMutation, { data, loading, error }] = useCompanyDepartmentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyDepartmentCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyDepartmentCreateMutation,
    CompanyDepartmentCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyDepartmentCreateMutation,
    CompanyDepartmentCreateMutationVariables
  >(CompanyDepartmentCreateDocument, options)
}
export type CompanyDepartmentCreateMutationHookResult = ReturnType<
  typeof useCompanyDepartmentCreateMutation
>
export type CompanyDepartmentCreateMutationResult =
  Apollo.MutationResult<CompanyDepartmentCreateMutation>
export type CompanyDepartmentCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyDepartmentCreateMutation,
  CompanyDepartmentCreateMutationVariables
>
export const GeneralSendEmailDocument = gql`
  mutation GeneralSendEmail(
    $to: [EmailAddress!]
    $userIds: [ID!]
    $audienceIds: [ID!]
    $subject: String!
    $body: String!
  ) {
    general {
      emailSend(
        input: {
          to: $to
          userIds: $userIds
          audienceIds: $audienceIds
          subject: $subject
          body: $body
        }
      ) {
        messagesSentCount
      }
    }
  }
`
export type GeneralSendEmailMutationFn = Apollo.MutationFunction<
  GeneralSendEmailMutation,
  GeneralSendEmailMutationVariables
>

/**
 * __useGeneralSendEmailMutation__
 *
 * To run a mutation, you first call `useGeneralSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneralSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generalSendEmailMutation, { data, loading, error }] = useGeneralSendEmailMutation({
 *   variables: {
 *      to: // value for 'to'
 *      userIds: // value for 'userIds'
 *      audienceIds: // value for 'audienceIds'
 *      subject: // value for 'subject'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useGeneralSendEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GeneralSendEmailMutation,
    GeneralSendEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GeneralSendEmailMutation,
    GeneralSendEmailMutationVariables
  >(GeneralSendEmailDocument, options)
}
export type GeneralSendEmailMutationHookResult = ReturnType<
  typeof useGeneralSendEmailMutation
>
export type GeneralSendEmailMutationResult =
  Apollo.MutationResult<GeneralSendEmailMutation>
export type GeneralSendEmailMutationOptions = Apollo.BaseMutationOptions<
  GeneralSendEmailMutation,
  GeneralSendEmailMutationVariables
>
export const CompanyPositionCreateDocument = gql`
  mutation CompanyPositionCreate($input: CompanyPositionCreateInput!) {
    company {
      positionCreate(input: $input) {
        position {
          id
          name
          description
        }
      }
    }
  }
`
export type CompanyPositionCreateMutationFn = Apollo.MutationFunction<
  CompanyPositionCreateMutation,
  CompanyPositionCreateMutationVariables
>

/**
 * __useCompanyPositionCreateMutation__
 *
 * To run a mutation, you first call `useCompanyPositionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyPositionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyPositionCreateMutation, { data, loading, error }] = useCompanyPositionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyPositionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyPositionCreateMutation,
    CompanyPositionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompanyPositionCreateMutation,
    CompanyPositionCreateMutationVariables
  >(CompanyPositionCreateDocument, options)
}
export type CompanyPositionCreateMutationHookResult = ReturnType<
  typeof useCompanyPositionCreateMutation
>
export type CompanyPositionCreateMutationResult =
  Apollo.MutationResult<CompanyPositionCreateMutation>
export type CompanyPositionCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyPositionCreateMutation,
  CompanyPositionCreateMutationVariables
>
export const GeneralAssetListDocument = gql`
  query GeneralAssetList {
    general {
      assetList {
        assets {
          id
          assetId
          uiCode
          name
          description
          isActive
        }
      }
    }
  }
`

/**
 * __useGeneralAssetListQuery__
 *
 * To run a query within a React component, call `useGeneralAssetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneralAssetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneralAssetListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeneralAssetListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneralAssetListQuery,
    GeneralAssetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GeneralAssetListQuery, GeneralAssetListQueryVariables>(
    GeneralAssetListDocument,
    options,
  )
}
export function useGeneralAssetListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneralAssetListQuery,
    GeneralAssetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GeneralAssetListQuery,
    GeneralAssetListQueryVariables
  >(GeneralAssetListDocument, options)
}
export function useGeneralAssetListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GeneralAssetListQuery,
    GeneralAssetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GeneralAssetListQuery,
    GeneralAssetListQueryVariables
  >(GeneralAssetListDocument, options)
}
export type GeneralAssetListQueryHookResult = ReturnType<
  typeof useGeneralAssetListQuery
>
export type GeneralAssetListLazyQueryHookResult = ReturnType<
  typeof useGeneralAssetListLazyQuery
>
export type GeneralAssetListSuspenseQueryHookResult = ReturnType<
  typeof useGeneralAssetListSuspenseQuery
>
export type GeneralAssetListQueryResult = Apollo.QueryResult<
  GeneralAssetListQuery,
  GeneralAssetListQueryVariables
>
export const GeneralDepartmentTemplatesGetDocument = gql`
  query GeneralDepartmentTemplatesGet {
    general {
      departmentTemplatesGet {
        departmentTemplates {
          id
          name
        }
      }
    }
  }
`

/**
 * __useGeneralDepartmentTemplatesGetQuery__
 *
 * To run a query within a React component, call `useGeneralDepartmentTemplatesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneralDepartmentTemplatesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneralDepartmentTemplatesGetQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeneralDepartmentTemplatesGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneralDepartmentTemplatesGetQuery,
    GeneralDepartmentTemplatesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GeneralDepartmentTemplatesGetQuery,
    GeneralDepartmentTemplatesGetQueryVariables
  >(GeneralDepartmentTemplatesGetDocument, options)
}
export function useGeneralDepartmentTemplatesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneralDepartmentTemplatesGetQuery,
    GeneralDepartmentTemplatesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GeneralDepartmentTemplatesGetQuery,
    GeneralDepartmentTemplatesGetQueryVariables
  >(GeneralDepartmentTemplatesGetDocument, options)
}
export function useGeneralDepartmentTemplatesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GeneralDepartmentTemplatesGetQuery,
    GeneralDepartmentTemplatesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GeneralDepartmentTemplatesGetQuery,
    GeneralDepartmentTemplatesGetQueryVariables
  >(GeneralDepartmentTemplatesGetDocument, options)
}
export type GeneralDepartmentTemplatesGetQueryHookResult = ReturnType<
  typeof useGeneralDepartmentTemplatesGetQuery
>
export type GeneralDepartmentTemplatesGetLazyQueryHookResult = ReturnType<
  typeof useGeneralDepartmentTemplatesGetLazyQuery
>
export type GeneralDepartmentTemplatesGetSuspenseQueryHookResult = ReturnType<
  typeof useGeneralDepartmentTemplatesGetSuspenseQuery
>
export type GeneralDepartmentTemplatesGetQueryResult = Apollo.QueryResult<
  GeneralDepartmentTemplatesGetQuery,
  GeneralDepartmentTemplatesGetQueryVariables
>
export const GeneralPositionTemplatesGetDocument = gql`
  query GeneralPositionTemplatesGet {
    general {
      positionTemplatesGet {
        positionTemplates {
          name
        }
      }
    }
  }
`

/**
 * __useGeneralPositionTemplatesGetQuery__
 *
 * To run a query within a React component, call `useGeneralPositionTemplatesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneralPositionTemplatesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneralPositionTemplatesGetQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeneralPositionTemplatesGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneralPositionTemplatesGetQuery,
    GeneralPositionTemplatesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GeneralPositionTemplatesGetQuery,
    GeneralPositionTemplatesGetQueryVariables
  >(GeneralPositionTemplatesGetDocument, options)
}
export function useGeneralPositionTemplatesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneralPositionTemplatesGetQuery,
    GeneralPositionTemplatesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GeneralPositionTemplatesGetQuery,
    GeneralPositionTemplatesGetQueryVariables
  >(GeneralPositionTemplatesGetDocument, options)
}
export function useGeneralPositionTemplatesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GeneralPositionTemplatesGetQuery,
    GeneralPositionTemplatesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GeneralPositionTemplatesGetQuery,
    GeneralPositionTemplatesGetQueryVariables
  >(GeneralPositionTemplatesGetDocument, options)
}
export type GeneralPositionTemplatesGetQueryHookResult = ReturnType<
  typeof useGeneralPositionTemplatesGetQuery
>
export type GeneralPositionTemplatesGetLazyQueryHookResult = ReturnType<
  typeof useGeneralPositionTemplatesGetLazyQuery
>
export type GeneralPositionTemplatesGetSuspenseQueryHookResult = ReturnType<
  typeof useGeneralPositionTemplatesGetSuspenseQuery
>
export type GeneralPositionTemplatesGetQueryResult = Apollo.QueryResult<
  GeneralPositionTemplatesGetQuery,
  GeneralPositionTemplatesGetQueryVariables
>
export const GeneralProcessTemplateListDocument = gql`
  query GeneralProcessTemplateList {
    general {
      processTemplateList {
        processTemplates {
          id
          uiCode
          name
          description
          isActive
        }
      }
    }
  }
`

/**
 * __useGeneralProcessTemplateListQuery__
 *
 * To run a query within a React component, call `useGeneralProcessTemplateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneralProcessTemplateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneralProcessTemplateListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeneralProcessTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneralProcessTemplateListQuery,
    GeneralProcessTemplateListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GeneralProcessTemplateListQuery,
    GeneralProcessTemplateListQueryVariables
  >(GeneralProcessTemplateListDocument, options)
}
export function useGeneralProcessTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneralProcessTemplateListQuery,
    GeneralProcessTemplateListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GeneralProcessTemplateListQuery,
    GeneralProcessTemplateListQueryVariables
  >(GeneralProcessTemplateListDocument, options)
}
export function useGeneralProcessTemplateListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GeneralProcessTemplateListQuery,
    GeneralProcessTemplateListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GeneralProcessTemplateListQuery,
    GeneralProcessTemplateListQueryVariables
  >(GeneralProcessTemplateListDocument, options)
}
export type GeneralProcessTemplateListQueryHookResult = ReturnType<
  typeof useGeneralProcessTemplateListQuery
>
export type GeneralProcessTemplateListLazyQueryHookResult = ReturnType<
  typeof useGeneralProcessTemplateListLazyQuery
>
export type GeneralProcessTemplateListSuspenseQueryHookResult = ReturnType<
  typeof useGeneralProcessTemplateListSuspenseQuery
>
export type GeneralProcessTemplateListQueryResult = Apollo.QueryResult<
  GeneralProcessTemplateListQuery,
  GeneralProcessTemplateListQueryVariables
>
export const GeneralRegionGroupListDocument = gql`
  query GeneralRegionGroupList {
    general {
      regionGroupList {
        regionGroups {
          id
          name
          uiCode
          regions {
            id
            name
            uiCode
          }
        }
      }
    }
  }
`

/**
 * __useGeneralRegionGroupListQuery__
 *
 * To run a query within a React component, call `useGeneralRegionGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneralRegionGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneralRegionGroupListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeneralRegionGroupListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneralRegionGroupListQuery,
    GeneralRegionGroupListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GeneralRegionGroupListQuery,
    GeneralRegionGroupListQueryVariables
  >(GeneralRegionGroupListDocument, options)
}
export function useGeneralRegionGroupListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneralRegionGroupListQuery,
    GeneralRegionGroupListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GeneralRegionGroupListQuery,
    GeneralRegionGroupListQueryVariables
  >(GeneralRegionGroupListDocument, options)
}
export function useGeneralRegionGroupListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GeneralRegionGroupListQuery,
    GeneralRegionGroupListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GeneralRegionGroupListQuery,
    GeneralRegionGroupListQueryVariables
  >(GeneralRegionGroupListDocument, options)
}
export type GeneralRegionGroupListQueryHookResult = ReturnType<
  typeof useGeneralRegionGroupListQuery
>
export type GeneralRegionGroupListLazyQueryHookResult = ReturnType<
  typeof useGeneralRegionGroupListLazyQuery
>
export type GeneralRegionGroupListSuspenseQueryHookResult = ReturnType<
  typeof useGeneralRegionGroupListSuspenseQuery
>
export type GeneralRegionGroupListQueryResult = Apollo.QueryResult<
  GeneralRegionGroupListQuery,
  GeneralRegionGroupListQueryVariables
>
export const GeneralRegionListDocument = gql`
  query GeneralRegionList {
    general {
      regionList {
        regions {
          id
          name
          description
          uiCode
        }
      }
    }
  }
`

/**
 * __useGeneralRegionListQuery__
 *
 * To run a query within a React component, call `useGeneralRegionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneralRegionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneralRegionListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeneralRegionListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeneralRegionListQuery,
    GeneralRegionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GeneralRegionListQuery,
    GeneralRegionListQueryVariables
  >(GeneralRegionListDocument, options)
}
export function useGeneralRegionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeneralRegionListQuery,
    GeneralRegionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GeneralRegionListQuery,
    GeneralRegionListQueryVariables
  >(GeneralRegionListDocument, options)
}
export function useGeneralRegionListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GeneralRegionListQuery,
    GeneralRegionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GeneralRegionListQuery,
    GeneralRegionListQueryVariables
  >(GeneralRegionListDocument, options)
}
export type GeneralRegionListQueryHookResult = ReturnType<
  typeof useGeneralRegionListQuery
>
export type GeneralRegionListLazyQueryHookResult = ReturnType<
  typeof useGeneralRegionListLazyQuery
>
export type GeneralRegionListSuspenseQueryHookResult = ReturnType<
  typeof useGeneralRegionListSuspenseQuery
>
export type GeneralRegionListQueryResult = Apollo.QueryResult<
  GeneralRegionListQuery,
  GeneralRegionListQueryVariables
>
export const StatisticsCompletedSurveysGetDocument = gql`
  query StatisticsCompletedSurveysGet(
    $filter: StatisticsCompletedSurveysFilterArg
    $groupBy: [StatisticsCompletedSurveysGroupByEnum!]
  ) {
    statistics {
      completedSurveys(input: { filter: $filter, groupBy: $groupBy }) {
        items {
          averageResponseTime
          session {
            id
          }
        }
      }
    }
  }
`

/**
 * __useStatisticsCompletedSurveysGetQuery__
 *
 * To run a query within a React component, call `useStatisticsCompletedSurveysGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsCompletedSurveysGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsCompletedSurveysGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useStatisticsCompletedSurveysGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StatisticsCompletedSurveysGetQuery,
    StatisticsCompletedSurveysGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    StatisticsCompletedSurveysGetQuery,
    StatisticsCompletedSurveysGetQueryVariables
  >(StatisticsCompletedSurveysGetDocument, options)
}
export function useStatisticsCompletedSurveysGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StatisticsCompletedSurveysGetQuery,
    StatisticsCompletedSurveysGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    StatisticsCompletedSurveysGetQuery,
    StatisticsCompletedSurveysGetQueryVariables
  >(StatisticsCompletedSurveysGetDocument, options)
}
export function useStatisticsCompletedSurveysGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StatisticsCompletedSurveysGetQuery,
    StatisticsCompletedSurveysGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    StatisticsCompletedSurveysGetQuery,
    StatisticsCompletedSurveysGetQueryVariables
  >(StatisticsCompletedSurveysGetDocument, options)
}
export type StatisticsCompletedSurveysGetQueryHookResult = ReturnType<
  typeof useStatisticsCompletedSurveysGetQuery
>
export type StatisticsCompletedSurveysGetLazyQueryHookResult = ReturnType<
  typeof useStatisticsCompletedSurveysGetLazyQuery
>
export type StatisticsCompletedSurveysGetSuspenseQueryHookResult = ReturnType<
  typeof useStatisticsCompletedSurveysGetSuspenseQuery
>
export type StatisticsCompletedSurveysGetQueryResult = Apollo.QueryResult<
  StatisticsCompletedSurveysGetQuery,
  StatisticsCompletedSurveysGetQueryVariables
>
export const StatisticsRespondedQuestionsGetDocument = gql`
  query StatisticsRespondedQuestionsGet(
    $filter: StatisticsRespondedQuestionsFilterArg
    $groupBy: [StatisticsRespondedQuestionsGroupByEnum!]
  ) {
    statistics {
      respondedQuestions(input: { filter: $filter, groupBy: $groupBy }) {
        items {
          session {
            id
          }
          respondedQuestionsRate {
            A
            B
            decimal
          }
        }
      }
    }
  }
`

/**
 * __useStatisticsRespondedQuestionsGetQuery__
 *
 * To run a query within a React component, call `useStatisticsRespondedQuestionsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsRespondedQuestionsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsRespondedQuestionsGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useStatisticsRespondedQuestionsGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StatisticsRespondedQuestionsGetQuery,
    StatisticsRespondedQuestionsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    StatisticsRespondedQuestionsGetQuery,
    StatisticsRespondedQuestionsGetQueryVariables
  >(StatisticsRespondedQuestionsGetDocument, options)
}
export function useStatisticsRespondedQuestionsGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StatisticsRespondedQuestionsGetQuery,
    StatisticsRespondedQuestionsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    StatisticsRespondedQuestionsGetQuery,
    StatisticsRespondedQuestionsGetQueryVariables
  >(StatisticsRespondedQuestionsGetDocument, options)
}
export function useStatisticsRespondedQuestionsGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StatisticsRespondedQuestionsGetQuery,
    StatisticsRespondedQuestionsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    StatisticsRespondedQuestionsGetQuery,
    StatisticsRespondedQuestionsGetQueryVariables
  >(StatisticsRespondedQuestionsGetDocument, options)
}
export type StatisticsRespondedQuestionsGetQueryHookResult = ReturnType<
  typeof useStatisticsRespondedQuestionsGetQuery
>
export type StatisticsRespondedQuestionsGetLazyQueryHookResult = ReturnType<
  typeof useStatisticsRespondedQuestionsGetLazyQuery
>
export type StatisticsRespondedQuestionsGetSuspenseQueryHookResult = ReturnType<
  typeof useStatisticsRespondedQuestionsGetSuspenseQuery
>
export type StatisticsRespondedQuestionsGetQueryResult = Apollo.QueryResult<
  StatisticsRespondedQuestionsGetQuery,
  StatisticsRespondedQuestionsGetQueryVariables
>
export const SurveyManagerStatisticsGetDocument = gql`
  query SurveyManagerStatisticsGet(
    $filter: StatisticsCompletedSurveysFilterArg
    $groupBy: [StatisticsCompletedSurveysGroupByEnum!]
  ) {
    statistics {
      completedSurveys(input: { filter: $filter, groupBy: $groupBy }) {
        items {
          averageResponseTime
          employeeEngagementRate {
            A
            B
            decimal
          }
          survey {
            id
            name
            type
            questionsCount
          }
          session {
            id
            startedAt
            deadlineAt
          }
          department {
            id
            name
          }
          company {
            id
            name
            logo {
              ...CompanyBinaryFileInfo
            }
            address {
              id
              streetAddress
              locality
              administrativeArea
              postalCode
              country {
                name
              }
            }
            representatives {
              id
              firstName
              lastName
              email
              phoneNumber
              representativeGroup
              address {
                id
                streetAddress
                locality
                administrativeArea
                postalCode
                country {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  ${CompanyBinaryFileInfoFragmentDoc}
`

/**
 * __useSurveyManagerStatisticsGetQuery__
 *
 * To run a query within a React component, call `useSurveyManagerStatisticsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyManagerStatisticsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyManagerStatisticsGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useSurveyManagerStatisticsGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SurveyManagerStatisticsGetQuery,
    SurveyManagerStatisticsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SurveyManagerStatisticsGetQuery,
    SurveyManagerStatisticsGetQueryVariables
  >(SurveyManagerStatisticsGetDocument, options)
}
export function useSurveyManagerStatisticsGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyManagerStatisticsGetQuery,
    SurveyManagerStatisticsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveyManagerStatisticsGetQuery,
    SurveyManagerStatisticsGetQueryVariables
  >(SurveyManagerStatisticsGetDocument, options)
}
export function useSurveyManagerStatisticsGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveyManagerStatisticsGetQuery,
    SurveyManagerStatisticsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveyManagerStatisticsGetQuery,
    SurveyManagerStatisticsGetQueryVariables
  >(SurveyManagerStatisticsGetDocument, options)
}
export type SurveyManagerStatisticsGetQueryHookResult = ReturnType<
  typeof useSurveyManagerStatisticsGetQuery
>
export type SurveyManagerStatisticsGetLazyQueryHookResult = ReturnType<
  typeof useSurveyManagerStatisticsGetLazyQuery
>
export type SurveyManagerStatisticsGetSuspenseQueryHookResult = ReturnType<
  typeof useSurveyManagerStatisticsGetSuspenseQuery
>
export type SurveyManagerStatisticsGetQueryResult = Apollo.QueryResult<
  SurveyManagerStatisticsGetQuery,
  SurveyManagerStatisticsGetQueryVariables
>
export const SurveyManagerViewAnswersStatisticsGetDocument = gql`
  query SurveyManagerViewAnswersStatisticsGet(
    $workforcePercentageStatisticsInput: StatisticsWorkforcePercentageInput
    $maturityScoreStatisticsInput: StatisticsMaturityScoreAveragesInput
    $sessionResponseRateStatisticsInput: StatisticsCompletedSurveysInput
  ) {
    statistics {
      workforcePercentage(input: $workforcePercentageStatisticsInput) {
        items {
          percentage {
            A
            B
            decimal
          }
        }
      }
      sessionResponseRate: completedSurveys(
        input: $sessionResponseRateStatisticsInput
      ) {
        items {
          employeeEngagementRate {
            A
            B
            decimal
          }
        }
      }
      maturityScoreAverages(input: $maturityScoreStatisticsInput) {
        items {
          sessionId
          maturityScoreAverage
          maturityScoreAverageSum
          maturityScoreSum
          surveysCount
          questionsCount
        }
      }
    }
  }
`

/**
 * __useSurveyManagerViewAnswersStatisticsGetQuery__
 *
 * To run a query within a React component, call `useSurveyManagerViewAnswersStatisticsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyManagerViewAnswersStatisticsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyManagerViewAnswersStatisticsGetQuery({
 *   variables: {
 *      workforcePercentageStatisticsInput: // value for 'workforcePercentageStatisticsInput'
 *      maturityScoreStatisticsInput: // value for 'maturityScoreStatisticsInput'
 *      sessionResponseRateStatisticsInput: // value for 'sessionResponseRateStatisticsInput'
 *   },
 * });
 */
export function useSurveyManagerViewAnswersStatisticsGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SurveyManagerViewAnswersStatisticsGetQuery,
    SurveyManagerViewAnswersStatisticsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SurveyManagerViewAnswersStatisticsGetQuery,
    SurveyManagerViewAnswersStatisticsGetQueryVariables
  >(SurveyManagerViewAnswersStatisticsGetDocument, options)
}
export function useSurveyManagerViewAnswersStatisticsGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyManagerViewAnswersStatisticsGetQuery,
    SurveyManagerViewAnswersStatisticsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveyManagerViewAnswersStatisticsGetQuery,
    SurveyManagerViewAnswersStatisticsGetQueryVariables
  >(SurveyManagerViewAnswersStatisticsGetDocument, options)
}
export function useSurveyManagerViewAnswersStatisticsGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveyManagerViewAnswersStatisticsGetQuery,
    SurveyManagerViewAnswersStatisticsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveyManagerViewAnswersStatisticsGetQuery,
    SurveyManagerViewAnswersStatisticsGetQueryVariables
  >(SurveyManagerViewAnswersStatisticsGetDocument, options)
}
export type SurveyManagerViewAnswersStatisticsGetQueryHookResult = ReturnType<
  typeof useSurveyManagerViewAnswersStatisticsGetQuery
>
export type SurveyManagerViewAnswersStatisticsGetLazyQueryHookResult =
  ReturnType<typeof useSurveyManagerViewAnswersStatisticsGetLazyQuery>
export type SurveyManagerViewAnswersStatisticsGetSuspenseQueryHookResult =
  ReturnType<typeof useSurveyManagerViewAnswersStatisticsGetSuspenseQuery>
export type SurveyManagerViewAnswersStatisticsGetQueryResult =
  Apollo.QueryResult<
    SurveyManagerViewAnswersStatisticsGetQuery,
    SurveyManagerViewAnswersStatisticsGetQueryVariables
  >
export const StatisticsWorkforcePercentageGetDocument = gql`
  query StatisticsWorkforcePercentageGet(
    $filter: StatisticsWorkforcePercentageFilterArg
  ) {
    statistics {
      workforcePercentage(input: { filter: $filter }) {
        items {
          percentage {
            A
            B
            decimal
          }
        }
      }
    }
  }
`

/**
 * __useStatisticsWorkforcePercentageGetQuery__
 *
 * To run a query within a React component, call `useStatisticsWorkforcePercentageGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsWorkforcePercentageGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsWorkforcePercentageGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStatisticsWorkforcePercentageGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StatisticsWorkforcePercentageGetQuery,
    StatisticsWorkforcePercentageGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    StatisticsWorkforcePercentageGetQuery,
    StatisticsWorkforcePercentageGetQueryVariables
  >(StatisticsWorkforcePercentageGetDocument, options)
}
export function useStatisticsWorkforcePercentageGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StatisticsWorkforcePercentageGetQuery,
    StatisticsWorkforcePercentageGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    StatisticsWorkforcePercentageGetQuery,
    StatisticsWorkforcePercentageGetQueryVariables
  >(StatisticsWorkforcePercentageGetDocument, options)
}
export function useStatisticsWorkforcePercentageGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StatisticsWorkforcePercentageGetQuery,
    StatisticsWorkforcePercentageGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    StatisticsWorkforcePercentageGetQuery,
    StatisticsWorkforcePercentageGetQueryVariables
  >(StatisticsWorkforcePercentageGetDocument, options)
}
export type StatisticsWorkforcePercentageGetQueryHookResult = ReturnType<
  typeof useStatisticsWorkforcePercentageGetQuery
>
export type StatisticsWorkforcePercentageGetLazyQueryHookResult = ReturnType<
  typeof useStatisticsWorkforcePercentageGetLazyQuery
>
export type StatisticsWorkforcePercentageGetSuspenseQueryHookResult =
  ReturnType<typeof useStatisticsWorkforcePercentageGetSuspenseQuery>
export type StatisticsWorkforcePercentageGetQueryResult = Apollo.QueryResult<
  StatisticsWorkforcePercentageGetQuery,
  StatisticsWorkforcePercentageGetQueryVariables
>
export const SurveyAnswerOptionCreateDocument = gql`
  mutation SurveyAnswerOptionCreate(
    $questionId: ID!
    $value: String
    $answerOptionTemplateId: ID
    $sortOrder: Int
  ) {
    survey {
      answerOptionCreate(
        input: {
          questionId: $questionId
          value: $value
          answerOptionTemplateId: $answerOptionTemplateId
          sortOrder: $sortOrder
        }
      ) {
        question {
          ...SurveyQuestionDetails
        }
        answerOption {
          ...ISurveyAnswerOptionDetails
        }
      }
    }
  }
  ${SurveyQuestionDetailsFragmentDoc}
  ${ISurveyAnswerOptionDetailsFragmentDoc}
`
export type SurveyAnswerOptionCreateMutationFn = Apollo.MutationFunction<
  SurveyAnswerOptionCreateMutation,
  SurveyAnswerOptionCreateMutationVariables
>

/**
 * __useSurveyAnswerOptionCreateMutation__
 *
 * To run a mutation, you first call `useSurveyAnswerOptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyAnswerOptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyAnswerOptionCreateMutation, { data, loading, error }] = useSurveyAnswerOptionCreateMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      value: // value for 'value'
 *      answerOptionTemplateId: // value for 'answerOptionTemplateId'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useSurveyAnswerOptionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyAnswerOptionCreateMutation,
    SurveyAnswerOptionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyAnswerOptionCreateMutation,
    SurveyAnswerOptionCreateMutationVariables
  >(SurveyAnswerOptionCreateDocument, options)
}
export type SurveyAnswerOptionCreateMutationHookResult = ReturnType<
  typeof useSurveyAnswerOptionCreateMutation
>
export type SurveyAnswerOptionCreateMutationResult =
  Apollo.MutationResult<SurveyAnswerOptionCreateMutation>
export type SurveyAnswerOptionCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SurveyAnswerOptionCreateMutation,
    SurveyAnswerOptionCreateMutationVariables
  >
export const SurveyAnswerOptionDeleteDocument = gql`
  mutation SurveyAnswerOptionDelete($answerOptionId: ID!) {
    survey {
      answerOptionDelete(input: { answerOptionId: $answerOptionId }) {
        answerOption {
          ...ISurveyAnswerOptionDetails
        }
      }
    }
  }
  ${ISurveyAnswerOptionDetailsFragmentDoc}
`
export type SurveyAnswerOptionDeleteMutationFn = Apollo.MutationFunction<
  SurveyAnswerOptionDeleteMutation,
  SurveyAnswerOptionDeleteMutationVariables
>

/**
 * __useSurveyAnswerOptionDeleteMutation__
 *
 * To run a mutation, you first call `useSurveyAnswerOptionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyAnswerOptionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyAnswerOptionDeleteMutation, { data, loading, error }] = useSurveyAnswerOptionDeleteMutation({
 *   variables: {
 *      answerOptionId: // value for 'answerOptionId'
 *   },
 * });
 */
export function useSurveyAnswerOptionDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyAnswerOptionDeleteMutation,
    SurveyAnswerOptionDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyAnswerOptionDeleteMutation,
    SurveyAnswerOptionDeleteMutationVariables
  >(SurveyAnswerOptionDeleteDocument, options)
}
export type SurveyAnswerOptionDeleteMutationHookResult = ReturnType<
  typeof useSurveyAnswerOptionDeleteMutation
>
export type SurveyAnswerOptionDeleteMutationResult =
  Apollo.MutationResult<SurveyAnswerOptionDeleteMutation>
export type SurveyAnswerOptionDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    SurveyAnswerOptionDeleteMutation,
    SurveyAnswerOptionDeleteMutationVariables
  >
export const SurveyAnswerOptionUpdateDocument = gql`
  mutation SurveyAnswerOptionUpdate(
    $answerOptionId: ID!
    $eraseUiCode: Boolean = false
    $value: String
    $sortOrder: Int
  ) {
    survey {
      answerOptionUpdate(
        input: {
          answerOptionId: $answerOptionId
          eraseUiCode: $eraseUiCode
          value: $value
          sortOrder: $sortOrder
        }
      ) {
        answerOption {
          ...ISurveyAnswerOptionDetails
        }
      }
    }
  }
  ${ISurveyAnswerOptionDetailsFragmentDoc}
`
export type SurveyAnswerOptionUpdateMutationFn = Apollo.MutationFunction<
  SurveyAnswerOptionUpdateMutation,
  SurveyAnswerOptionUpdateMutationVariables
>

/**
 * __useSurveyAnswerOptionUpdateMutation__
 *
 * To run a mutation, you first call `useSurveyAnswerOptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyAnswerOptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyAnswerOptionUpdateMutation, { data, loading, error }] = useSurveyAnswerOptionUpdateMutation({
 *   variables: {
 *      answerOptionId: // value for 'answerOptionId'
 *      eraseUiCode: // value for 'eraseUiCode'
 *      value: // value for 'value'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useSurveyAnswerOptionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyAnswerOptionUpdateMutation,
    SurveyAnswerOptionUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyAnswerOptionUpdateMutation,
    SurveyAnswerOptionUpdateMutationVariables
  >(SurveyAnswerOptionUpdateDocument, options)
}
export type SurveyAnswerOptionUpdateMutationHookResult = ReturnType<
  typeof useSurveyAnswerOptionUpdateMutation
>
export type SurveyAnswerOptionUpdateMutationResult =
  Apollo.MutationResult<SurveyAnswerOptionUpdateMutation>
export type SurveyAnswerOptionUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    SurveyAnswerOptionUpdateMutation,
    SurveyAnswerOptionUpdateMutationVariables
  >
export const SurveyAudiencesSetDocument = gql`
  mutation SurveyAudiencesSet($surveyId: ID!, $audienceIds: [ID!]!) {
    survey {
      surveyAudiencesSet(
        input: { surveyId: $surveyId, audienceIds: $audienceIds }
      ) {
        survey {
          audiences {
            id
            name
          }
          schedules {
            id
            startAt
            defaultSessionDurationDays
            periodDays
          }
          sessions {
            id
          }
        }
      }
    }
  }
`
export type SurveyAudiencesSetMutationFn = Apollo.MutationFunction<
  SurveyAudiencesSetMutation,
  SurveyAudiencesSetMutationVariables
>

/**
 * __useSurveyAudiencesSetMutation__
 *
 * To run a mutation, you first call `useSurveyAudiencesSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyAudiencesSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyAudiencesSetMutation, { data, loading, error }] = useSurveyAudiencesSetMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      audienceIds: // value for 'audienceIds'
 *   },
 * });
 */
export function useSurveyAudiencesSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyAudiencesSetMutation,
    SurveyAudiencesSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyAudiencesSetMutation,
    SurveyAudiencesSetMutationVariables
  >(SurveyAudiencesSetDocument, options)
}
export type SurveyAudiencesSetMutationHookResult = ReturnType<
  typeof useSurveyAudiencesSetMutation
>
export type SurveyAudiencesSetMutationResult =
  Apollo.MutationResult<SurveyAudiencesSetMutation>
export type SurveyAudiencesSetMutationOptions = Apollo.BaseMutationOptions<
  SurveyAudiencesSetMutation,
  SurveyAudiencesSetMutationVariables
>
export const SurveyCopyDocument = gql`
  mutation SurveyCopy(
    $surveyId: ID!
    $companyId: ID!
    $name: String!
    $isDraft: Boolean!
    $isTemplate: Boolean!
  ) {
    survey {
      copy(
        input: {
          surveyId: $surveyId
          companyId: $companyId
          name: $name
          isDraft: $isDraft
          isTemplate: $isTemplate
        }
      ) {
        survey {
          id
        }
      }
    }
  }
`
export type SurveyCopyMutationFn = Apollo.MutationFunction<
  SurveyCopyMutation,
  SurveyCopyMutationVariables
>

/**
 * __useSurveyCopyMutation__
 *
 * To run a mutation, you first call `useSurveyCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyCopyMutation, { data, loading, error }] = useSurveyCopyMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      isDraft: // value for 'isDraft'
 *      isTemplate: // value for 'isTemplate'
 *   },
 * });
 */
export function useSurveyCopyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyCopyMutation,
    SurveyCopyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SurveyCopyMutation, SurveyCopyMutationVariables>(
    SurveyCopyDocument,
    options,
  )
}
export type SurveyCopyMutationHookResult = ReturnType<
  typeof useSurveyCopyMutation
>
export type SurveyCopyMutationResult = Apollo.MutationResult<SurveyCopyMutation>
export type SurveyCopyMutationOptions = Apollo.BaseMutationOptions<
  SurveyCopyMutation,
  SurveyCopyMutationVariables
>
export const SurveyCreateDocument = gql`
  mutation SurveyCreate(
    $companyId: ID!
    $name: String!
    $isDraft: Boolean!
    $isTemplate: Boolean!
    $categoryIds: [ID!]
  ) {
    survey {
      create(
        input: {
          companyId: $companyId
          name: $name
          isDraft: $isDraft
          isTemplate: $isTemplate
          categoryIds: $categoryIds
        }
      ) {
        survey {
          id
          surveyId
          name
          isDraft
          isTemplate
          company {
            id
            companyId
          }
        }
      }
    }
  }
`
export type SurveyCreateMutationFn = Apollo.MutationFunction<
  SurveyCreateMutation,
  SurveyCreateMutationVariables
>

/**
 * __useSurveyCreateMutation__
 *
 * To run a mutation, you first call `useSurveyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyCreateMutation, { data, loading, error }] = useSurveyCreateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      isDraft: // value for 'isDraft'
 *      isTemplate: // value for 'isTemplate'
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useSurveyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyCreateMutation,
    SurveyCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyCreateMutation,
    SurveyCreateMutationVariables
  >(SurveyCreateDocument, options)
}
export type SurveyCreateMutationHookResult = ReturnType<
  typeof useSurveyCreateMutation
>
export type SurveyCreateMutationResult =
  Apollo.MutationResult<SurveyCreateMutation>
export type SurveyCreateMutationOptions = Apollo.BaseMutationOptions<
  SurveyCreateMutation,
  SurveyCreateMutationVariables
>
export const SurveyDeleteDocument = gql`
  mutation SurveyDelete($surveyId: ID!) {
    survey {
      delete(input: { surveyId: $surveyId }) {
        survey {
          id
          surveyId
          isDraft
          isTemplate
          name
          company {
            id
            companyId
          }
        }
      }
    }
  }
`
export type SurveyDeleteMutationFn = Apollo.MutationFunction<
  SurveyDeleteMutation,
  SurveyDeleteMutationVariables
>

/**
 * __useSurveyDeleteMutation__
 *
 * To run a mutation, you first call `useSurveyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyDeleteMutation, { data, loading, error }] = useSurveyDeleteMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useSurveyDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyDeleteMutation,
    SurveyDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyDeleteMutation,
    SurveyDeleteMutationVariables
  >(SurveyDeleteDocument, options)
}
export type SurveyDeleteMutationHookResult = ReturnType<
  typeof useSurveyDeleteMutation
>
export type SurveyDeleteMutationResult =
  Apollo.MutationResult<SurveyDeleteMutation>
export type SurveyDeleteMutationOptions = Apollo.BaseMutationOptions<
  SurveyDeleteMutation,
  SurveyDeleteMutationVariables
>
export const SurveyGuidedStepCreateDocument = gql`
  mutation SurveyGuidedStepCreate(
    $surveyId: ID!
    $name: String!
    $description: String
    $sortOrder: NonNegativeInt
  ) {
    survey {
      guidedStepCreate(
        input: {
          surveyId: $surveyId
          name: $name
          description: $description
          sortOrder: $sortOrder
        }
      ) {
        guidedStep {
          id
          uiCode
          name
          description
          sortOrder
        }
      }
    }
  }
`
export type SurveyGuidedStepCreateMutationFn = Apollo.MutationFunction<
  SurveyGuidedStepCreateMutation,
  SurveyGuidedStepCreateMutationVariables
>

/**
 * __useSurveyGuidedStepCreateMutation__
 *
 * To run a mutation, you first call `useSurveyGuidedStepCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyGuidedStepCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyGuidedStepCreateMutation, { data, loading, error }] = useSurveyGuidedStepCreateMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useSurveyGuidedStepCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyGuidedStepCreateMutation,
    SurveyGuidedStepCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyGuidedStepCreateMutation,
    SurveyGuidedStepCreateMutationVariables
  >(SurveyGuidedStepCreateDocument, options)
}
export type SurveyGuidedStepCreateMutationHookResult = ReturnType<
  typeof useSurveyGuidedStepCreateMutation
>
export type SurveyGuidedStepCreateMutationResult =
  Apollo.MutationResult<SurveyGuidedStepCreateMutation>
export type SurveyGuidedStepCreateMutationOptions = Apollo.BaseMutationOptions<
  SurveyGuidedStepCreateMutation,
  SurveyGuidedStepCreateMutationVariables
>
export const SurveyGuidedStepDeleteDocument = gql`
  mutation SurveyGuidedStepDelete($guidedStepId: ID!) {
    survey {
      guidedStepDelete(input: { guidedStepId: $guidedStepId }) {
        guidedStep {
          id
          uiCode
          name
          description
          sortOrder
        }
      }
    }
  }
`
export type SurveyGuidedStepDeleteMutationFn = Apollo.MutationFunction<
  SurveyGuidedStepDeleteMutation,
  SurveyGuidedStepDeleteMutationVariables
>

/**
 * __useSurveyGuidedStepDeleteMutation__
 *
 * To run a mutation, you first call `useSurveyGuidedStepDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyGuidedStepDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyGuidedStepDeleteMutation, { data, loading, error }] = useSurveyGuidedStepDeleteMutation({
 *   variables: {
 *      guidedStepId: // value for 'guidedStepId'
 *   },
 * });
 */
export function useSurveyGuidedStepDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyGuidedStepDeleteMutation,
    SurveyGuidedStepDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyGuidedStepDeleteMutation,
    SurveyGuidedStepDeleteMutationVariables
  >(SurveyGuidedStepDeleteDocument, options)
}
export type SurveyGuidedStepDeleteMutationHookResult = ReturnType<
  typeof useSurveyGuidedStepDeleteMutation
>
export type SurveyGuidedStepDeleteMutationResult =
  Apollo.MutationResult<SurveyGuidedStepDeleteMutation>
export type SurveyGuidedStepDeleteMutationOptions = Apollo.BaseMutationOptions<
  SurveyGuidedStepDeleteMutation,
  SurveyGuidedStepDeleteMutationVariables
>
export const SurveyGuidedStepUpdateDocument = gql`
  mutation SurveyGuidedStepUpdate(
    $guidedStepId: ID!
    $eraseUiCode: Boolean
    $name: String
    $description: String
    $sortOrder: NonNegativeInt
  ) {
    survey {
      guidedStepUpdate(
        input: {
          guidedStepId: $guidedStepId
          eraseUiCode: $eraseUiCode
          name: $name
          description: $description
          sortOrder: $sortOrder
        }
      ) {
        guidedStep {
          ...GuidedStepsDetails
        }
      }
    }
  }
  ${GuidedStepsDetailsFragmentDoc}
`
export type SurveyGuidedStepUpdateMutationFn = Apollo.MutationFunction<
  SurveyGuidedStepUpdateMutation,
  SurveyGuidedStepUpdateMutationVariables
>

/**
 * __useSurveyGuidedStepUpdateMutation__
 *
 * To run a mutation, you first call `useSurveyGuidedStepUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyGuidedStepUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyGuidedStepUpdateMutation, { data, loading, error }] = useSurveyGuidedStepUpdateMutation({
 *   variables: {
 *      guidedStepId: // value for 'guidedStepId'
 *      eraseUiCode: // value for 'eraseUiCode'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useSurveyGuidedStepUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyGuidedStepUpdateMutation,
    SurveyGuidedStepUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyGuidedStepUpdateMutation,
    SurveyGuidedStepUpdateMutationVariables
  >(SurveyGuidedStepUpdateDocument, options)
}
export type SurveyGuidedStepUpdateMutationHookResult = ReturnType<
  typeof useSurveyGuidedStepUpdateMutation
>
export type SurveyGuidedStepUpdateMutationResult =
  Apollo.MutationResult<SurveyGuidedStepUpdateMutation>
export type SurveyGuidedStepUpdateMutationOptions = Apollo.BaseMutationOptions<
  SurveyGuidedStepUpdateMutation,
  SurveyGuidedStepUpdateMutationVariables
>
export const SurveyInvitationDeleteDocument = gql`
  mutation SurveyInvitationDelete($filter: SurveyInvitationDeleteFilterArg) {
    survey {
      invitationDelete(input: { filter: $filter }) {
        invitations {
          invitationId
          user {
            id
          }
        }
      }
    }
  }
`
export type SurveyInvitationDeleteMutationFn = Apollo.MutationFunction<
  SurveyInvitationDeleteMutation,
  SurveyInvitationDeleteMutationVariables
>

/**
 * __useSurveyInvitationDeleteMutation__
 *
 * To run a mutation, you first call `useSurveyInvitationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyInvitationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyInvitationDeleteMutation, { data, loading, error }] = useSurveyInvitationDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSurveyInvitationDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyInvitationDeleteMutation,
    SurveyInvitationDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyInvitationDeleteMutation,
    SurveyInvitationDeleteMutationVariables
  >(SurveyInvitationDeleteDocument, options)
}
export type SurveyInvitationDeleteMutationHookResult = ReturnType<
  typeof useSurveyInvitationDeleteMutation
>
export type SurveyInvitationDeleteMutationResult =
  Apollo.MutationResult<SurveyInvitationDeleteMutation>
export type SurveyInvitationDeleteMutationOptions = Apollo.BaseMutationOptions<
  SurveyInvitationDeleteMutation,
  SurveyInvitationDeleteMutationVariables
>
export const SurveyInviteAudiencesDocument = gql`
  mutation SurveyInviteAudiences(
    $sessionId: ID!
    $audienceIds: [ID!]!
    $companyId: ID!
  ) {
    survey {
      inviteAudiences(
        input: { sessionId: $sessionId, audienceIds: $audienceIds }
      ) {
        session {
          id
        }
        invitations {
          invitationId
          user {
            ...AudienceUserDetails
          }
        }
      }
    }
  }
  ${AudienceUserDetailsFragmentDoc}
`
export type SurveyInviteAudiencesMutationFn = Apollo.MutationFunction<
  SurveyInviteAudiencesMutation,
  SurveyInviteAudiencesMutationVariables
>

/**
 * __useSurveyInviteAudiencesMutation__
 *
 * To run a mutation, you first call `useSurveyInviteAudiencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyInviteAudiencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyInviteAudiencesMutation, { data, loading, error }] = useSurveyInviteAudiencesMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      audienceIds: // value for 'audienceIds'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSurveyInviteAudiencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyInviteAudiencesMutation,
    SurveyInviteAudiencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyInviteAudiencesMutation,
    SurveyInviteAudiencesMutationVariables
  >(SurveyInviteAudiencesDocument, options)
}
export type SurveyInviteAudiencesMutationHookResult = ReturnType<
  typeof useSurveyInviteAudiencesMutation
>
export type SurveyInviteAudiencesMutationResult =
  Apollo.MutationResult<SurveyInviteAudiencesMutation>
export type SurveyInviteAudiencesMutationOptions = Apollo.BaseMutationOptions<
  SurveyInviteAudiencesMutation,
  SurveyInviteAudiencesMutationVariables
>
export const SurveyInviteUsersDocument = gql`
  mutation SurveyInviteUsers($sessionId: ID!, $userIds: [ID!]!) {
    survey {
      inviteUsers(input: { sessionId: $sessionId, userIds: $userIds }) {
        session {
          id
        }
        invitations {
          invitationId
          user {
            id
            firstName
            lastName
            email
            positions {
              positionId
              position {
                id
                name
              }
              departmentId
              department {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
export type SurveyInviteUsersMutationFn = Apollo.MutationFunction<
  SurveyInviteUsersMutation,
  SurveyInviteUsersMutationVariables
>

/**
 * __useSurveyInviteUsersMutation__
 *
 * To run a mutation, you first call `useSurveyInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyInviteUsersMutation, { data, loading, error }] = useSurveyInviteUsersMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useSurveyInviteUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyInviteUsersMutation,
    SurveyInviteUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyInviteUsersMutation,
    SurveyInviteUsersMutationVariables
  >(SurveyInviteUsersDocument, options)
}
export type SurveyInviteUsersMutationHookResult = ReturnType<
  typeof useSurveyInviteUsersMutation
>
export type SurveyInviteUsersMutationResult =
  Apollo.MutationResult<SurveyInviteUsersMutation>
export type SurveyInviteUsersMutationOptions = Apollo.BaseMutationOptions<
  SurveyInviteUsersMutation,
  SurveyInviteUsersMutationVariables
>
export const SurveyMaturityScoreSetDocument = gql`
  mutation SurveyMaturityScoreSet(
    $input: SurveyMaturityScoreSetInput!
    $filter: StatisticsMaturityScoreAveragesFilterArg
    $groupBy: [StatisticsMaturityScoreAveragesGroupByEnum!]
  ) {
    survey {
      maturityScoreSet(input: $input) {
        query {
          statistics {
            maturityScoreAverages(
              input: { filter: $filter, groupBy: $groupBy }
            ) {
              items {
                ...MaturityScoreAverages
              }
            }
          }
        }
        maturityScore {
          questionId
          sessionId
          maturityScore
          session {
            id
            maturityScoreAveragesForSession {
              ...MaturityScoreAverages
            }
            survey {
              id
              name
              questionsCount
            }
          }
        }
      }
    }
  }
  ${MaturityScoreAveragesFragmentDoc}
`
export type SurveyMaturityScoreSetMutationFn = Apollo.MutationFunction<
  SurveyMaturityScoreSetMutation,
  SurveyMaturityScoreSetMutationVariables
>

/**
 * __useSurveyMaturityScoreSetMutation__
 *
 * To run a mutation, you first call `useSurveyMaturityScoreSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyMaturityScoreSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyMaturityScoreSetMutation, { data, loading, error }] = useSurveyMaturityScoreSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useSurveyMaturityScoreSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyMaturityScoreSetMutation,
    SurveyMaturityScoreSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyMaturityScoreSetMutation,
    SurveyMaturityScoreSetMutationVariables
  >(SurveyMaturityScoreSetDocument, options)
}
export type SurveyMaturityScoreSetMutationHookResult = ReturnType<
  typeof useSurveyMaturityScoreSetMutation
>
export type SurveyMaturityScoreSetMutationResult =
  Apollo.MutationResult<SurveyMaturityScoreSetMutation>
export type SurveyMaturityScoreSetMutationOptions = Apollo.BaseMutationOptions<
  SurveyMaturityScoreSetMutation,
  SurveyMaturityScoreSetMutationVariables
>
export const SurveyQuestionCreateDocument = gql`
  mutation SurveyQuestionCreate(
    $subcategoryId: ID!
    $guidedStepId: ID
    $label: String!
    $description: String!
    $modifiers: JSONObject
    $sortOrder: NonNegativeInt
    $questionTypeId: ID!
  ) {
    survey {
      questionCreate(
        input: {
          subcategoryId: $subcategoryId
          label: $label
          description: $description
          modifiers: $modifiers
          guidedStepId: $guidedStepId
          sortOrder: $sortOrder
          questionTypeId: $questionTypeId
        }
      ) {
        question {
          id
          uiCode
          label
          description
          modifiers
          sortOrder
        }
      }
    }
  }
`
export type SurveyQuestionCreateMutationFn = Apollo.MutationFunction<
  SurveyQuestionCreateMutation,
  SurveyQuestionCreateMutationVariables
>

/**
 * __useSurveyQuestionCreateMutation__
 *
 * To run a mutation, you first call `useSurveyQuestionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuestionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyQuestionCreateMutation, { data, loading, error }] = useSurveyQuestionCreateMutation({
 *   variables: {
 *      subcategoryId: // value for 'subcategoryId'
 *      guidedStepId: // value for 'guidedStepId'
 *      label: // value for 'label'
 *      description: // value for 'description'
 *      modifiers: // value for 'modifiers'
 *      sortOrder: // value for 'sortOrder'
 *      questionTypeId: // value for 'questionTypeId'
 *   },
 * });
 */
export function useSurveyQuestionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyQuestionCreateMutation,
    SurveyQuestionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyQuestionCreateMutation,
    SurveyQuestionCreateMutationVariables
  >(SurveyQuestionCreateDocument, options)
}
export type SurveyQuestionCreateMutationHookResult = ReturnType<
  typeof useSurveyQuestionCreateMutation
>
export type SurveyQuestionCreateMutationResult =
  Apollo.MutationResult<SurveyQuestionCreateMutation>
export type SurveyQuestionCreateMutationOptions = Apollo.BaseMutationOptions<
  SurveyQuestionCreateMutation,
  SurveyQuestionCreateMutationVariables
>
export const SurveyQuestionDeleteDocument = gql`
  mutation SurveyQuestionDelete($questionId: ID!) {
    survey {
      questionDelete(input: { questionId: $questionId }) {
        question {
          id
          modifiers
          subcategoryId
          guidedStepId
        }
      }
    }
  }
`
export type SurveyQuestionDeleteMutationFn = Apollo.MutationFunction<
  SurveyQuestionDeleteMutation,
  SurveyQuestionDeleteMutationVariables
>

/**
 * __useSurveyQuestionDeleteMutation__
 *
 * To run a mutation, you first call `useSurveyQuestionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuestionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyQuestionDeleteMutation, { data, loading, error }] = useSurveyQuestionDeleteMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useSurveyQuestionDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyQuestionDeleteMutation,
    SurveyQuestionDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyQuestionDeleteMutation,
    SurveyQuestionDeleteMutationVariables
  >(SurveyQuestionDeleteDocument, options)
}
export type SurveyQuestionDeleteMutationHookResult = ReturnType<
  typeof useSurveyQuestionDeleteMutation
>
export type SurveyQuestionDeleteMutationResult =
  Apollo.MutationResult<SurveyQuestionDeleteMutation>
export type SurveyQuestionDeleteMutationOptions = Apollo.BaseMutationOptions<
  SurveyQuestionDeleteMutation,
  SurveyQuestionDeleteMutationVariables
>
export const SurveyQuestionUpdateDocument = gql`
  mutation SurveyQuestionUpdate(
    $questionId: ID!
    $eraseUiCode: Boolean = false
    $subcategoryId: ID
    $guidedStepId: ID
    $label: String
    $description: String
    $modifiers: JSONObject
    $sortOrder: NonNegativeInt
    $questionTypeId: ID
  ) {
    survey {
      questionUpdate(
        input: {
          questionId: $questionId
          eraseUiCode: $eraseUiCode
          subcategoryId: $subcategoryId
          guidedStepId: $guidedStepId
          label: $label
          description: $description
          modifiers: $modifiers
          sortOrder: $sortOrder
          questionTypeId: $questionTypeId
        }
      ) {
        question {
          ...SurveyQuestionDetails
        }
      }
    }
  }
  ${SurveyQuestionDetailsFragmentDoc}
`
export type SurveyQuestionUpdateMutationFn = Apollo.MutationFunction<
  SurveyQuestionUpdateMutation,
  SurveyQuestionUpdateMutationVariables
>

/**
 * __useSurveyQuestionUpdateMutation__
 *
 * To run a mutation, you first call `useSurveyQuestionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuestionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyQuestionUpdateMutation, { data, loading, error }] = useSurveyQuestionUpdateMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      eraseUiCode: // value for 'eraseUiCode'
 *      subcategoryId: // value for 'subcategoryId'
 *      guidedStepId: // value for 'guidedStepId'
 *      label: // value for 'label'
 *      description: // value for 'description'
 *      modifiers: // value for 'modifiers'
 *      sortOrder: // value for 'sortOrder'
 *      questionTypeId: // value for 'questionTypeId'
 *   },
 * });
 */
export function useSurveyQuestionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyQuestionUpdateMutation,
    SurveyQuestionUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyQuestionUpdateMutation,
    SurveyQuestionUpdateMutationVariables
  >(SurveyQuestionUpdateDocument, options)
}
export type SurveyQuestionUpdateMutationHookResult = ReturnType<
  typeof useSurveyQuestionUpdateMutation
>
export type SurveyQuestionUpdateMutationResult =
  Apollo.MutationResult<SurveyQuestionUpdateMutation>
export type SurveyQuestionUpdateMutationOptions = Apollo.BaseMutationOptions<
  SurveyQuestionUpdateMutation,
  SurveyQuestionUpdateMutationVariables
>
export const SurveyResponseCreateDocument = gql`
  mutation SurveyResponseCreate($input: SurveyResponsesCreateInput!) {
    survey {
      responsesCreate(input: $input) {
        responses {
          ...ResponseDetails
        }
        datamapResponses {
          ...DatamapResponseGet
        }
      }
    }
  }
  ${ResponseDetailsFragmentDoc}
  ${DatamapResponseGetFragmentDoc}
`
export type SurveyResponseCreateMutationFn = Apollo.MutationFunction<
  SurveyResponseCreateMutation,
  SurveyResponseCreateMutationVariables
>

/**
 * __useSurveyResponseCreateMutation__
 *
 * To run a mutation, you first call `useSurveyResponseCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyResponseCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyResponseCreateMutation, { data, loading, error }] = useSurveyResponseCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSurveyResponseCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyResponseCreateMutation,
    SurveyResponseCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyResponseCreateMutation,
    SurveyResponseCreateMutationVariables
  >(SurveyResponseCreateDocument, options)
}
export type SurveyResponseCreateMutationHookResult = ReturnType<
  typeof useSurveyResponseCreateMutation
>
export type SurveyResponseCreateMutationResult =
  Apollo.MutationResult<SurveyResponseCreateMutation>
export type SurveyResponseCreateMutationOptions = Apollo.BaseMutationOptions<
  SurveyResponseCreateMutation,
  SurveyResponseCreateMutationVariables
>
export const SurveyResponseDeleteDocument = gql`
  mutation SurveyResponseDelete($filter: SurveyResponseFilterArg) {
    survey {
      responseDelete(input: { filter: $filter }) {
        responses {
          id
        }
      }
    }
  }
`
export type SurveyResponseDeleteMutationFn = Apollo.MutationFunction<
  SurveyResponseDeleteMutation,
  SurveyResponseDeleteMutationVariables
>

/**
 * __useSurveyResponseDeleteMutation__
 *
 * To run a mutation, you first call `useSurveyResponseDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyResponseDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyResponseDeleteMutation, { data, loading, error }] = useSurveyResponseDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSurveyResponseDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyResponseDeleteMutation,
    SurveyResponseDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyResponseDeleteMutation,
    SurveyResponseDeleteMutationVariables
  >(SurveyResponseDeleteDocument, options)
}
export type SurveyResponseDeleteMutationHookResult = ReturnType<
  typeof useSurveyResponseDeleteMutation
>
export type SurveyResponseDeleteMutationResult =
  Apollo.MutationResult<SurveyResponseDeleteMutation>
export type SurveyResponseDeleteMutationOptions = Apollo.BaseMutationOptions<
  SurveyResponseDeleteMutation,
  SurveyResponseDeleteMutationVariables
>
export const SurveyResponseDocumentUploadDocument = gql`
  mutation SurveyResponseDocumentUpload($responseId: ID!, $file: Upload!) {
    survey {
      responseDocumentUpload(input: { responseId: $responseId, file: $file }) {
        response {
          id
        }
        fileInfo {
          id
          url
        }
      }
    }
  }
`
export type SurveyResponseDocumentUploadMutationFn = Apollo.MutationFunction<
  SurveyResponseDocumentUploadMutation,
  SurveyResponseDocumentUploadMutationVariables
>

/**
 * __useSurveyResponseDocumentUploadMutation__
 *
 * To run a mutation, you first call `useSurveyResponseDocumentUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyResponseDocumentUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyResponseDocumentUploadMutation, { data, loading, error }] = useSurveyResponseDocumentUploadMutation({
 *   variables: {
 *      responseId: // value for 'responseId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useSurveyResponseDocumentUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyResponseDocumentUploadMutation,
    SurveyResponseDocumentUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyResponseDocumentUploadMutation,
    SurveyResponseDocumentUploadMutationVariables
  >(SurveyResponseDocumentUploadDocument, options)
}
export type SurveyResponseDocumentUploadMutationHookResult = ReturnType<
  typeof useSurveyResponseDocumentUploadMutation
>
export type SurveyResponseDocumentUploadMutationResult =
  Apollo.MutationResult<SurveyResponseDocumentUploadMutation>
export type SurveyResponseDocumentUploadMutationOptions =
  Apollo.BaseMutationOptions<
    SurveyResponseDocumentUploadMutation,
    SurveyResponseDocumentUploadMutationVariables
  >
export const SurveyResponseUpdateDocument = gql`
  mutation SurveyResponseUpdate(
    $responseId: ID!
    $answerOptionId: ID
    $value: String
  ) {
    survey {
      responseUpdate(
        input: {
          responseId: $responseId
          answerOptionId: $answerOptionId
          value: $value
        }
      ) {
        response {
          ...ResponseDetails
        }
      }
    }
  }
  ${ResponseDetailsFragmentDoc}
`
export type SurveyResponseUpdateMutationFn = Apollo.MutationFunction<
  SurveyResponseUpdateMutation,
  SurveyResponseUpdateMutationVariables
>

/**
 * __useSurveyResponseUpdateMutation__
 *
 * To run a mutation, you first call `useSurveyResponseUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyResponseUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyResponseUpdateMutation, { data, loading, error }] = useSurveyResponseUpdateMutation({
 *   variables: {
 *      responseId: // value for 'responseId'
 *      answerOptionId: // value for 'answerOptionId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSurveyResponseUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyResponseUpdateMutation,
    SurveyResponseUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyResponseUpdateMutation,
    SurveyResponseUpdateMutationVariables
  >(SurveyResponseUpdateDocument, options)
}
export type SurveyResponseUpdateMutationHookResult = ReturnType<
  typeof useSurveyResponseUpdateMutation
>
export type SurveyResponseUpdateMutationResult =
  Apollo.MutationResult<SurveyResponseUpdateMutation>
export type SurveyResponseUpdateMutationOptions = Apollo.BaseMutationOptions<
  SurveyResponseUpdateMutation,
  SurveyResponseUpdateMutationVariables
>
export const SurveyScheduleCreateDocument = gql`
  mutation SurveyScheduleCreate(
    $surveyId: ID!
    $startAt: DateTime!
    $defaultSessionDurationDays: NonNegativeInt!
    $periodDays: NonNegativeInt
  ) {
    survey {
      scheduleCreate(
        input: {
          surveyId: $surveyId
          startAt: $startAt
          defaultSessionDurationDays: $defaultSessionDurationDays
          periodDays: $periodDays
        }
      ) {
        schedule {
          id
          survey {
            id
          }
        }
      }
    }
  }
`
export type SurveyScheduleCreateMutationFn = Apollo.MutationFunction<
  SurveyScheduleCreateMutation,
  SurveyScheduleCreateMutationVariables
>

/**
 * __useSurveyScheduleCreateMutation__
 *
 * To run a mutation, you first call `useSurveyScheduleCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyScheduleCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyScheduleCreateMutation, { data, loading, error }] = useSurveyScheduleCreateMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      startAt: // value for 'startAt'
 *      defaultSessionDurationDays: // value for 'defaultSessionDurationDays'
 *      periodDays: // value for 'periodDays'
 *   },
 * });
 */
export function useSurveyScheduleCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyScheduleCreateMutation,
    SurveyScheduleCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyScheduleCreateMutation,
    SurveyScheduleCreateMutationVariables
  >(SurveyScheduleCreateDocument, options)
}
export type SurveyScheduleCreateMutationHookResult = ReturnType<
  typeof useSurveyScheduleCreateMutation
>
export type SurveyScheduleCreateMutationResult =
  Apollo.MutationResult<SurveyScheduleCreateMutation>
export type SurveyScheduleCreateMutationOptions = Apollo.BaseMutationOptions<
  SurveyScheduleCreateMutation,
  SurveyScheduleCreateMutationVariables
>
export const SurveyScheduleDeleteDocument = gql`
  mutation SurveyScheduleDelete($scheduleId: ID!) {
    survey {
      scheduleDelete(input: { scheduleId: $scheduleId }) {
        schedule {
          id
          survey {
            id
          }
        }
      }
    }
  }
`
export type SurveyScheduleDeleteMutationFn = Apollo.MutationFunction<
  SurveyScheduleDeleteMutation,
  SurveyScheduleDeleteMutationVariables
>

/**
 * __useSurveyScheduleDeleteMutation__
 *
 * To run a mutation, you first call `useSurveyScheduleDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyScheduleDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyScheduleDeleteMutation, { data, loading, error }] = useSurveyScheduleDeleteMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useSurveyScheduleDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyScheduleDeleteMutation,
    SurveyScheduleDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyScheduleDeleteMutation,
    SurveyScheduleDeleteMutationVariables
  >(SurveyScheduleDeleteDocument, options)
}
export type SurveyScheduleDeleteMutationHookResult = ReturnType<
  typeof useSurveyScheduleDeleteMutation
>
export type SurveyScheduleDeleteMutationResult =
  Apollo.MutationResult<SurveyScheduleDeleteMutation>
export type SurveyScheduleDeleteMutationOptions = Apollo.BaseMutationOptions<
  SurveyScheduleDeleteMutation,
  SurveyScheduleDeleteMutationVariables
>
export const SurveyScheduleUpdateDocument = gql`
  mutation SurveyScheduleUpdate(
    $scheduleId: ID!
    $startAt: DateTime
    $defaultSessionDurationDays: NonNegativeInt
    $periodDays: NonNegativeInt
  ) {
    survey {
      scheduleUpdate(
        input: {
          scheduleId: $scheduleId
          startAt: $startAt
          defaultSessionDurationDays: $defaultSessionDurationDays
          periodDays: $periodDays
        }
      ) {
        schedule {
          id
          survey {
            id
          }
        }
      }
    }
  }
`
export type SurveyScheduleUpdateMutationFn = Apollo.MutationFunction<
  SurveyScheduleUpdateMutation,
  SurveyScheduleUpdateMutationVariables
>

/**
 * __useSurveyScheduleUpdateMutation__
 *
 * To run a mutation, you first call `useSurveyScheduleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyScheduleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyScheduleUpdateMutation, { data, loading, error }] = useSurveyScheduleUpdateMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      startAt: // value for 'startAt'
 *      defaultSessionDurationDays: // value for 'defaultSessionDurationDays'
 *      periodDays: // value for 'periodDays'
 *   },
 * });
 */
export function useSurveyScheduleUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyScheduleUpdateMutation,
    SurveyScheduleUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyScheduleUpdateMutation,
    SurveyScheduleUpdateMutationVariables
  >(SurveyScheduleUpdateDocument, options)
}
export type SurveyScheduleUpdateMutationHookResult = ReturnType<
  typeof useSurveyScheduleUpdateMutation
>
export type SurveyScheduleUpdateMutationResult =
  Apollo.MutationResult<SurveyScheduleUpdateMutation>
export type SurveyScheduleUpdateMutationOptions = Apollo.BaseMutationOptions<
  SurveyScheduleUpdateMutation,
  SurveyScheduleUpdateMutationVariables
>
export const SurveySessionCreateDocument = gql`
  mutation SurveySessionCreate(
    $surveyId: ID!
    $startedAt: DateTime!
    $finishedAt: DateTime!
    $deadlineAt: DateTime!
  ) {
    survey {
      sessionCreate(
        input: {
          surveyId: $surveyId
          startedAt: $startedAt
          finishedAt: $finishedAt
          deadlineAt: $deadlineAt
        }
      ) {
        survey {
          id
        }
        session {
          id
          startedAt
          deadlineAt
          finishedAt
        }
      }
    }
  }
`
export type SurveySessionCreateMutationFn = Apollo.MutationFunction<
  SurveySessionCreateMutation,
  SurveySessionCreateMutationVariables
>

/**
 * __useSurveySessionCreateMutation__
 *
 * To run a mutation, you first call `useSurveySessionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveySessionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveySessionCreateMutation, { data, loading, error }] = useSurveySessionCreateMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      startedAt: // value for 'startedAt'
 *      finishedAt: // value for 'finishedAt'
 *      deadlineAt: // value for 'deadlineAt'
 *   },
 * });
 */
export function useSurveySessionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveySessionCreateMutation,
    SurveySessionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveySessionCreateMutation,
    SurveySessionCreateMutationVariables
  >(SurveySessionCreateDocument, options)
}
export type SurveySessionCreateMutationHookResult = ReturnType<
  typeof useSurveySessionCreateMutation
>
export type SurveySessionCreateMutationResult =
  Apollo.MutationResult<SurveySessionCreateMutation>
export type SurveySessionCreateMutationOptions = Apollo.BaseMutationOptions<
  SurveySessionCreateMutation,
  SurveySessionCreateMutationVariables
>
export const SurveySessionDeleteDocument = gql`
  mutation SurveySessionDelete($sessionId: ID!) {
    survey {
      sessionDelete(input: { sessionId: $sessionId }) {
        survey {
          id
        }
        session {
          id
        }
      }
    }
  }
`
export type SurveySessionDeleteMutationFn = Apollo.MutationFunction<
  SurveySessionDeleteMutation,
  SurveySessionDeleteMutationVariables
>

/**
 * __useSurveySessionDeleteMutation__
 *
 * To run a mutation, you first call `useSurveySessionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveySessionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveySessionDeleteMutation, { data, loading, error }] = useSurveySessionDeleteMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSurveySessionDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveySessionDeleteMutation,
    SurveySessionDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveySessionDeleteMutation,
    SurveySessionDeleteMutationVariables
  >(SurveySessionDeleteDocument, options)
}
export type SurveySessionDeleteMutationHookResult = ReturnType<
  typeof useSurveySessionDeleteMutation
>
export type SurveySessionDeleteMutationResult =
  Apollo.MutationResult<SurveySessionDeleteMutation>
export type SurveySessionDeleteMutationOptions = Apollo.BaseMutationOptions<
  SurveySessionDeleteMutation,
  SurveySessionDeleteMutationVariables
>
export const SurveySessionUpdateDocument = gql`
  mutation SurveySessionUpdate(
    $sessionId: ID!
    $startedAt: DateTime
    $finishedAt: DateTime!
    $deadlineAt: DateTime!
  ) {
    survey {
      sessionUpdate(
        input: {
          sessionId: $sessionId
          startedAt: $startedAt
          finishedAt: $finishedAt
          deadlineAt: $deadlineAt
        }
      ) {
        survey {
          id
        }
        session {
          id
          startedAt
          deadlineAt
          finishedAt
        }
      }
    }
  }
`
export type SurveySessionUpdateMutationFn = Apollo.MutationFunction<
  SurveySessionUpdateMutation,
  SurveySessionUpdateMutationVariables
>

/**
 * __useSurveySessionUpdateMutation__
 *
 * To run a mutation, you first call `useSurveySessionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveySessionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveySessionUpdateMutation, { data, loading, error }] = useSurveySessionUpdateMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      startedAt: // value for 'startedAt'
 *      finishedAt: // value for 'finishedAt'
 *      deadlineAt: // value for 'deadlineAt'
 *   },
 * });
 */
export function useSurveySessionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveySessionUpdateMutation,
    SurveySessionUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveySessionUpdateMutation,
    SurveySessionUpdateMutationVariables
  >(SurveySessionUpdateDocument, options)
}
export type SurveySessionUpdateMutationHookResult = ReturnType<
  typeof useSurveySessionUpdateMutation
>
export type SurveySessionUpdateMutationResult =
  Apollo.MutationResult<SurveySessionUpdateMutation>
export type SurveySessionUpdateMutationOptions = Apollo.BaseMutationOptions<
  SurveySessionUpdateMutation,
  SurveySessionUpdateMutationVariables
>
export const SurveySubcategoryCreateFromTemplateDocument = gql`
  mutation SurveySubcategoryCreateFromTemplate(
    $surveyId: ID!
    $subcategoryTemplateIds: [ID!]!
  ) {
    survey {
      subcategoryCreateFromTemplate(
        input: {
          surveyId: $surveyId
          subcategoryTemplateIds: $subcategoryTemplateIds
        }
      ) {
        survey {
          ...SurveyDetails
        }
      }
    }
  }
  ${SurveyDetailsFragmentDoc}
`
export type SurveySubcategoryCreateFromTemplateMutationFn =
  Apollo.MutationFunction<
    SurveySubcategoryCreateFromTemplateMutation,
    SurveySubcategoryCreateFromTemplateMutationVariables
  >

/**
 * __useSurveySubcategoryCreateFromTemplateMutation__
 *
 * To run a mutation, you first call `useSurveySubcategoryCreateFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveySubcategoryCreateFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveySubcategoryCreateFromTemplateMutation, { data, loading, error }] = useSurveySubcategoryCreateFromTemplateMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      subcategoryTemplateIds: // value for 'subcategoryTemplateIds'
 *   },
 * });
 */
export function useSurveySubcategoryCreateFromTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveySubcategoryCreateFromTemplateMutation,
    SurveySubcategoryCreateFromTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveySubcategoryCreateFromTemplateMutation,
    SurveySubcategoryCreateFromTemplateMutationVariables
  >(SurveySubcategoryCreateFromTemplateDocument, options)
}
export type SurveySubcategoryCreateFromTemplateMutationHookResult = ReturnType<
  typeof useSurveySubcategoryCreateFromTemplateMutation
>
export type SurveySubcategoryCreateFromTemplateMutationResult =
  Apollo.MutationResult<SurveySubcategoryCreateFromTemplateMutation>
export type SurveySubcategoryCreateFromTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    SurveySubcategoryCreateFromTemplateMutation,
    SurveySubcategoryCreateFromTemplateMutationVariables
  >
export const SurveySubcategoryCreateDocument = gql`
  mutation SurveySubcategoryCreate(
    $surveyId: ID!
    $header: String!
    $description: String
    $sortOrder: NonNegativeInt
  ) {
    survey {
      subcategoryCreate(
        input: {
          surveyId: $surveyId
          header: $header
          description: $description
          sortOrder: $sortOrder
        }
      ) {
        survey {
          ...SurveyDetails
        }
      }
    }
  }
  ${SurveyDetailsFragmentDoc}
`
export type SurveySubcategoryCreateMutationFn = Apollo.MutationFunction<
  SurveySubcategoryCreateMutation,
  SurveySubcategoryCreateMutationVariables
>

/**
 * __useSurveySubcategoryCreateMutation__
 *
 * To run a mutation, you first call `useSurveySubcategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveySubcategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveySubcategoryCreateMutation, { data, loading, error }] = useSurveySubcategoryCreateMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      header: // value for 'header'
 *      description: // value for 'description'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useSurveySubcategoryCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveySubcategoryCreateMutation,
    SurveySubcategoryCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveySubcategoryCreateMutation,
    SurveySubcategoryCreateMutationVariables
  >(SurveySubcategoryCreateDocument, options)
}
export type SurveySubcategoryCreateMutationHookResult = ReturnType<
  typeof useSurveySubcategoryCreateMutation
>
export type SurveySubcategoryCreateMutationResult =
  Apollo.MutationResult<SurveySubcategoryCreateMutation>
export type SurveySubcategoryCreateMutationOptions = Apollo.BaseMutationOptions<
  SurveySubcategoryCreateMutation,
  SurveySubcategoryCreateMutationVariables
>
export const SurveySubcategoryDeleteDocument = gql`
  mutation SurveySubcategoryDelete($subcategoryId: ID!) {
    survey {
      subcategoryDelete(input: { subcategoryId: $subcategoryId }) {
        survey {
          ...SurveyDetails
        }
      }
    }
  }
  ${SurveyDetailsFragmentDoc}
`
export type SurveySubcategoryDeleteMutationFn = Apollo.MutationFunction<
  SurveySubcategoryDeleteMutation,
  SurveySubcategoryDeleteMutationVariables
>

/**
 * __useSurveySubcategoryDeleteMutation__
 *
 * To run a mutation, you first call `useSurveySubcategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveySubcategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveySubcategoryDeleteMutation, { data, loading, error }] = useSurveySubcategoryDeleteMutation({
 *   variables: {
 *      subcategoryId: // value for 'subcategoryId'
 *   },
 * });
 */
export function useSurveySubcategoryDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveySubcategoryDeleteMutation,
    SurveySubcategoryDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveySubcategoryDeleteMutation,
    SurveySubcategoryDeleteMutationVariables
  >(SurveySubcategoryDeleteDocument, options)
}
export type SurveySubcategoryDeleteMutationHookResult = ReturnType<
  typeof useSurveySubcategoryDeleteMutation
>
export type SurveySubcategoryDeleteMutationResult =
  Apollo.MutationResult<SurveySubcategoryDeleteMutation>
export type SurveySubcategoryDeleteMutationOptions = Apollo.BaseMutationOptions<
  SurveySubcategoryDeleteMutation,
  SurveySubcategoryDeleteMutationVariables
>
export const SurveyConvertIntoTemplateDocument = gql`
  mutation SurveyConvertIntoTemplate(
    $input: SurveySurveyConvertIntoTemplateInput!
  ) {
    survey {
      surveyConvertIntoTemplate(input: $input) {
        subcategoryTemplate {
          id
          isActive
          header
          description
          sortOrder
        }
      }
    }
  }
`
export type SurveyConvertIntoTemplateMutationFn = Apollo.MutationFunction<
  SurveyConvertIntoTemplateMutation,
  SurveyConvertIntoTemplateMutationVariables
>

/**
 * __useSurveyConvertIntoTemplateMutation__
 *
 * To run a mutation, you first call `useSurveyConvertIntoTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyConvertIntoTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyConvertIntoTemplateMutation, { data, loading, error }] = useSurveyConvertIntoTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSurveyConvertIntoTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyConvertIntoTemplateMutation,
    SurveyConvertIntoTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyConvertIntoTemplateMutation,
    SurveyConvertIntoTemplateMutationVariables
  >(SurveyConvertIntoTemplateDocument, options)
}
export type SurveyConvertIntoTemplateMutationHookResult = ReturnType<
  typeof useSurveyConvertIntoTemplateMutation
>
export type SurveyConvertIntoTemplateMutationResult =
  Apollo.MutationResult<SurveyConvertIntoTemplateMutation>
export type SurveyConvertIntoTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    SurveyConvertIntoTemplateMutation,
    SurveyConvertIntoTemplateMutationVariables
  >
export const SurveyUpdateDocument = gql`
  mutation SurveyUpdate(
    $surveyId: ID!
    $name: String
    $isDraft: Boolean
    $isTemplate: Boolean
  ) {
    survey {
      update(
        input: {
          surveyId: $surveyId
          name: $name
          isDraft: $isDraft
          isTemplate: $isTemplate
        }
      ) {
        survey {
          id
        }
      }
    }
  }
`
export type SurveyUpdateMutationFn = Apollo.MutationFunction<
  SurveyUpdateMutation,
  SurveyUpdateMutationVariables
>

/**
 * __useSurveyUpdateMutation__
 *
 * To run a mutation, you first call `useSurveyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyUpdateMutation, { data, loading, error }] = useSurveyUpdateMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      name: // value for 'name'
 *      isDraft: // value for 'isDraft'
 *      isTemplate: // value for 'isTemplate'
 *   },
 * });
 */
export function useSurveyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyUpdateMutation,
    SurveyUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyUpdateMutation,
    SurveyUpdateMutationVariables
  >(SurveyUpdateDocument, options)
}
export type SurveyUpdateMutationHookResult = ReturnType<
  typeof useSurveyUpdateMutation
>
export type SurveyUpdateMutationResult =
  Apollo.MutationResult<SurveyUpdateMutation>
export type SurveyUpdateMutationOptions = Apollo.BaseMutationOptions<
  SurveyUpdateMutation,
  SurveyUpdateMutationVariables
>
export const SurveyCategoriesGetDocument = gql`
  query SurveyCategoriesGet($filter: SurveyCategoryFilterArg) {
    survey {
      categoriesGet(input: { filter: $filter }) {
        categories {
          id
          categoryId
          name
          isActive
          regulation {
            id
            name
            logoPath
            uiCode
            description
            isActive
          }
          subcategoryTemplates {
            id
            subcategoryTemplateId
            description
            sortOrder
            header
            isActive
          }
        }
      }
    }
  }
`

/**
 * __useSurveyCategoriesGetQuery__
 *
 * To run a query within a React component, call `useSurveyCategoriesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyCategoriesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyCategoriesGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSurveyCategoriesGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SurveyCategoriesGetQuery,
    SurveyCategoriesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SurveyCategoriesGetQuery,
    SurveyCategoriesGetQueryVariables
  >(SurveyCategoriesGetDocument, options)
}
export function useSurveyCategoriesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyCategoriesGetQuery,
    SurveyCategoriesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveyCategoriesGetQuery,
    SurveyCategoriesGetQueryVariables
  >(SurveyCategoriesGetDocument, options)
}
export function useSurveyCategoriesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveyCategoriesGetQuery,
    SurveyCategoriesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveyCategoriesGetQuery,
    SurveyCategoriesGetQueryVariables
  >(SurveyCategoriesGetDocument, options)
}
export type SurveyCategoriesGetQueryHookResult = ReturnType<
  typeof useSurveyCategoriesGetQuery
>
export type SurveyCategoriesGetLazyQueryHookResult = ReturnType<
  typeof useSurveyCategoriesGetLazyQuery
>
export type SurveyCategoriesGetSuspenseQueryHookResult = ReturnType<
  typeof useSurveyCategoriesGetSuspenseQuery
>
export type SurveyCategoriesGetQueryResult = Apollo.QueryResult<
  SurveyCategoriesGetQuery,
  SurveyCategoriesGetQueryVariables
>
export const SurveyGetDocument = gql`
  query SurveyGet(
    $filter: SurveyFilterArg
    $pagination: PaginationArg
    $sessionId: ID!
    $order: [SurveyGetOrderArg!]
  ) {
    survey {
      get(input: { filter: $filter, pagination: $pagination, order: $order }) {
        surveys {
          id
          surveyId
          name
          type
          categories {
            id
            regulation {
              id
              uiCode
              name
            }
            name
          }
          audiences {
            id
            name
            users {
              id
              firstName
              lastName
            }
          }
          company {
            id
            companyId
            selectedRegulations {
              id
              regulationId
              name
              uiCode
              name
              logoPath
              description
            }
          }
          subcategories {
            id
            description
            sortOrder
            isGuided
            questions {
              id
              label
              maturityScore(sessionId: $sessionId)
              description
              dependsOn {
                question {
                  id
                }
                answerOption {
                  id
                }
              }
              type {
                id
                uiCode
                name
              }
            }
          }
          schedules {
            id
            startAt
            periodDays
            defaultSessionDurationDays
          }
          sessions {
            id
            startedAt
            finishedAt
          }
        }
        paginationInfo {
          totalItems
          totalPages
        }
      }
    }
  }
`

/**
 * __useSurveyGetQuery__
 *
 * To run a query within a React component, call `useSurveyGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sessionId: // value for 'sessionId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSurveyGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    SurveyGetQuery,
    SurveyGetQueryVariables
  > &
    (
      | { variables: SurveyGetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SurveyGetQuery, SurveyGetQueryVariables>(
    SurveyGetDocument,
    options,
  )
}
export function useSurveyGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyGetQuery,
    SurveyGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SurveyGetQuery, SurveyGetQueryVariables>(
    SurveyGetDocument,
    options,
  )
}
export function useSurveyGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveyGetQuery,
    SurveyGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SurveyGetQuery, SurveyGetQueryVariables>(
    SurveyGetDocument,
    options,
  )
}
export type SurveyGetQueryHookResult = ReturnType<typeof useSurveyGetQuery>
export type SurveyGetLazyQueryHookResult = ReturnType<
  typeof useSurveyGetLazyQuery
>
export type SurveyGetSuspenseQueryHookResult = ReturnType<
  typeof useSurveyGetSuspenseQuery
>
export type SurveyGetQueryResult = Apollo.QueryResult<
  SurveyGetQuery,
  SurveyGetQueryVariables
>
export const SurveyQuestionTypesGetDocument = gql`
  query SurveyQuestionTypesGet($filter: QuestionTypeFilterArg) {
    survey {
      questionTypesGet(input: { filter: $filter }) {
        questionTypes {
          id
          name
          code
          uiCode
        }
      }
    }
  }
`

/**
 * __useSurveyQuestionTypesGetQuery__
 *
 * To run a query within a React component, call `useSurveyQuestionTypesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuestionTypesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuestionTypesGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSurveyQuestionTypesGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SurveyQuestionTypesGetQuery,
    SurveyQuestionTypesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SurveyQuestionTypesGetQuery,
    SurveyQuestionTypesGetQueryVariables
  >(SurveyQuestionTypesGetDocument, options)
}
export function useSurveyQuestionTypesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyQuestionTypesGetQuery,
    SurveyQuestionTypesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveyQuestionTypesGetQuery,
    SurveyQuestionTypesGetQueryVariables
  >(SurveyQuestionTypesGetDocument, options)
}
export function useSurveyQuestionTypesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveyQuestionTypesGetQuery,
    SurveyQuestionTypesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveyQuestionTypesGetQuery,
    SurveyQuestionTypesGetQueryVariables
  >(SurveyQuestionTypesGetDocument, options)
}
export type SurveyQuestionTypesGetQueryHookResult = ReturnType<
  typeof useSurveyQuestionTypesGetQuery
>
export type SurveyQuestionTypesGetLazyQueryHookResult = ReturnType<
  typeof useSurveyQuestionTypesGetLazyQuery
>
export type SurveyQuestionTypesGetSuspenseQueryHookResult = ReturnType<
  typeof useSurveyQuestionTypesGetSuspenseQuery
>
export type SurveyQuestionTypesGetQueryResult = Apollo.QueryResult<
  SurveyQuestionTypesGetQuery,
  SurveyQuestionTypesGetQueryVariables
>
export const SurveyDatamapResponsesGetDocument = gql`
  query SurveyDatamapResponsesGet($input: SurveyDatamapResponsesGetInput) {
    survey {
      datamapResponsesGet(input: $input) {
        datamaps {
          id
        }
        datamapResponses {
          ...DatamapResponseGet
        }
      }
    }
  }
  ${DatamapResponseGetFragmentDoc}
`

/**
 * __useSurveyDatamapResponsesGetQuery__
 *
 * To run a query within a React component, call `useSurveyDatamapResponsesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyDatamapResponsesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyDatamapResponsesGetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSurveyDatamapResponsesGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SurveyDatamapResponsesGetQuery,
    SurveyDatamapResponsesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SurveyDatamapResponsesGetQuery,
    SurveyDatamapResponsesGetQueryVariables
  >(SurveyDatamapResponsesGetDocument, options)
}
export function useSurveyDatamapResponsesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyDatamapResponsesGetQuery,
    SurveyDatamapResponsesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveyDatamapResponsesGetQuery,
    SurveyDatamapResponsesGetQueryVariables
  >(SurveyDatamapResponsesGetDocument, options)
}
export function useSurveyDatamapResponsesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveyDatamapResponsesGetQuery,
    SurveyDatamapResponsesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveyDatamapResponsesGetQuery,
    SurveyDatamapResponsesGetQueryVariables
  >(SurveyDatamapResponsesGetDocument, options)
}
export type SurveyDatamapResponsesGetQueryHookResult = ReturnType<
  typeof useSurveyDatamapResponsesGetQuery
>
export type SurveyDatamapResponsesGetLazyQueryHookResult = ReturnType<
  typeof useSurveyDatamapResponsesGetLazyQuery
>
export type SurveyDatamapResponsesGetSuspenseQueryHookResult = ReturnType<
  typeof useSurveyDatamapResponsesGetSuspenseQuery
>
export type SurveyDatamapResponsesGetQueryResult = Apollo.QueryResult<
  SurveyDatamapResponsesGetQuery,
  SurveyDatamapResponsesGetQueryVariables
>
export const SurveyResponsesGetDocument = gql`
  query SurveyResponsesGet($filter: SurveyResponseFilterArg) {
    survey {
      responsesGet(input: { filter: $filter }) {
        responses {
          id
          value
          answeredAt
          respondentId
          answerOption {
            id
          }
          respondent {
            id
            firstName
            lastName
          }
          questionId
          question {
            id
            subcategoryId
          }
        }
      }
    }
  }
`

/**
 * __useSurveyResponsesGetQuery__
 *
 * To run a query within a React component, call `useSurveyResponsesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyResponsesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyResponsesGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSurveyResponsesGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SurveyResponsesGetQuery,
    SurveyResponsesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SurveyResponsesGetQuery,
    SurveyResponsesGetQueryVariables
  >(SurveyResponsesGetDocument, options)
}
export function useSurveyResponsesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyResponsesGetQuery,
    SurveyResponsesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveyResponsesGetQuery,
    SurveyResponsesGetQueryVariables
  >(SurveyResponsesGetDocument, options)
}
export function useSurveyResponsesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveyResponsesGetQuery,
    SurveyResponsesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveyResponsesGetQuery,
    SurveyResponsesGetQueryVariables
  >(SurveyResponsesGetDocument, options)
}
export type SurveyResponsesGetQueryHookResult = ReturnType<
  typeof useSurveyResponsesGetQuery
>
export type SurveyResponsesGetLazyQueryHookResult = ReturnType<
  typeof useSurveyResponsesGetLazyQuery
>
export type SurveyResponsesGetSuspenseQueryHookResult = ReturnType<
  typeof useSurveyResponsesGetSuspenseQuery
>
export type SurveyResponsesGetQueryResult = Apollo.QueryResult<
  SurveyResponsesGetQuery,
  SurveyResponsesGetQueryVariables
>
export const SurveySubcategoryTemplatesGetDocument = gql`
  query SurveySubcategoryTemplatesGet(
    $filter: SurveySubcategoryTemplatesFilterArg
  ) {
    survey {
      subcategoryTemplatesGet(input: { filter: $filter }) {
        subcategoryTemplates {
          ...SurveySubcategoryTemplateDetails
        }
      }
    }
  }
  ${SurveySubcategoryTemplateDetailsFragmentDoc}
`

/**
 * __useSurveySubcategoryTemplatesGetQuery__
 *
 * To run a query within a React component, call `useSurveySubcategoryTemplatesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveySubcategoryTemplatesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveySubcategoryTemplatesGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSurveySubcategoryTemplatesGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SurveySubcategoryTemplatesGetQuery,
    SurveySubcategoryTemplatesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SurveySubcategoryTemplatesGetQuery,
    SurveySubcategoryTemplatesGetQueryVariables
  >(SurveySubcategoryTemplatesGetDocument, options)
}
export function useSurveySubcategoryTemplatesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveySubcategoryTemplatesGetQuery,
    SurveySubcategoryTemplatesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveySubcategoryTemplatesGetQuery,
    SurveySubcategoryTemplatesGetQueryVariables
  >(SurveySubcategoryTemplatesGetDocument, options)
}
export function useSurveySubcategoryTemplatesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveySubcategoryTemplatesGetQuery,
    SurveySubcategoryTemplatesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveySubcategoryTemplatesGetQuery,
    SurveySubcategoryTemplatesGetQueryVariables
  >(SurveySubcategoryTemplatesGetDocument, options)
}
export type SurveySubcategoryTemplatesGetQueryHookResult = ReturnType<
  typeof useSurveySubcategoryTemplatesGetQuery
>
export type SurveySubcategoryTemplatesGetLazyQueryHookResult = ReturnType<
  typeof useSurveySubcategoryTemplatesGetLazyQuery
>
export type SurveySubcategoryTemplatesGetSuspenseQueryHookResult = ReturnType<
  typeof useSurveySubcategoryTemplatesGetSuspenseQuery
>
export type SurveySubcategoryTemplatesGetQueryResult = Apollo.QueryResult<
  SurveySubcategoryTemplatesGetQuery,
  SurveySubcategoryTemplatesGetQueryVariables
>
export const UserCreateDocument = gql`
  mutation UserCreate(
    $email: EmailAddress!
    $companyId: ID!
    $phoneNumber: PhoneNumber
    $firstName: String!
    $lastName: String!
    $title: String
    $language: LanguageCodeEnum
    $roleCodes: [AuthRoleCodeEnum!]
    $sendRegistrationEmail: Boolean
  ) {
    user {
      create(
        input: {
          email: $email
          companyId: $companyId
          phoneNumber: $phoneNumber
          firstName: $firstName
          lastName: $lastName
          title: $title
          language: $language
          roleCodes: $roleCodes
          sendRegistrationEmail: $sendRegistrationEmail
        }
      ) {
        user {
          ...AudienceUserDetails
        }
      }
    }
  }
  ${AudienceUserDetailsFragmentDoc}
`
export type UserCreateMutationFn = Apollo.MutationFunction<
  UserCreateMutation,
  UserCreateMutationVariables
>

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      email: // value for 'email'
 *      companyId: // value for 'companyId'
 *      phoneNumber: // value for 'phoneNumber'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      title: // value for 'title'
 *      language: // value for 'language'
 *      roleCodes: // value for 'roleCodes'
 *      sendRegistrationEmail: // value for 'sendRegistrationEmail'
 *   },
 * });
 */
export function useUserCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserCreateMutation,
    UserCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(
    UserCreateDocument,
    options,
  )
}
export type UserCreateMutationHookResult = ReturnType<
  typeof useUserCreateMutation
>
export type UserCreateMutationResult = Apollo.MutationResult<UserCreateMutation>
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<
  UserCreateMutation,
  UserCreateMutationVariables
>
export const UserDeleteDocument = gql`
  mutation UserDelete($input: UserDeleteInput!) {
    user {
      delete(input: $input) {
        users {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`
export type UserDeleteMutationFn = Apollo.MutationFunction<
  UserDeleteMutation,
  UserDeleteMutationVariables
>

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserDeleteMutation,
    UserDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(
    UserDeleteDocument,
    options,
  )
}
export type UserDeleteMutationHookResult = ReturnType<
  typeof useUserDeleteMutation
>
export type UserDeleteMutationResult = Apollo.MutationResult<UserDeleteMutation>
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<
  UserDeleteMutation,
  UserDeleteMutationVariables
>
export const UserPositionSetDocument = gql`
  mutation UserPositionSet(
    $userId: ID!
    $departmentId: ID!
    $positionId: ID!
    $companyId: ID!
  ) {
    user {
      positionSet(
        input: {
          userId: $userId
          departmentId: $departmentId
          positionId: $positionId
        }
      ) {
        user {
          ...AudienceUserDetails
        }
      }
    }
  }
  ${AudienceUserDetailsFragmentDoc}
`
export type UserPositionSetMutationFn = Apollo.MutationFunction<
  UserPositionSetMutation,
  UserPositionSetMutationVariables
>

/**
 * __useUserPositionSetMutation__
 *
 * To run a mutation, you first call `useUserPositionSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPositionSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPositionSetMutation, { data, loading, error }] = useUserPositionSetMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      departmentId: // value for 'departmentId'
 *      positionId: // value for 'positionId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUserPositionSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserPositionSetMutation,
    UserPositionSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UserPositionSetMutation,
    UserPositionSetMutationVariables
  >(UserPositionSetDocument, options)
}
export type UserPositionSetMutationHookResult = ReturnType<
  typeof useUserPositionSetMutation
>
export type UserPositionSetMutationResult =
  Apollo.MutationResult<UserPositionSetMutation>
export type UserPositionSetMutationOptions = Apollo.BaseMutationOptions<
  UserPositionSetMutation,
  UserPositionSetMutationVariables
>
export const UserRolesSetDocument = gql`
  mutation UserRolesSet(
    $userId: ID!
    $companyId: ID!
    $roleCodes: [AuthRoleCodeEnum!]!
  ) {
    user {
      rolesSet(
        input: { userId: $userId, companyId: $companyId, roleCodes: $roleCodes }
      ) {
        user {
          id
          roles {
            userId
            roleId
            name
            code
            companyId
          }
        }
      }
    }
  }
`
export type UserRolesSetMutationFn = Apollo.MutationFunction<
  UserRolesSetMutation,
  UserRolesSetMutationVariables
>

/**
 * __useUserRolesSetMutation__
 *
 * To run a mutation, you first call `useUserRolesSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRolesSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRolesSetMutation, { data, loading, error }] = useUserRolesSetMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *      roleCodes: // value for 'roleCodes'
 *   },
 * });
 */
export function useUserRolesSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserRolesSetMutation,
    UserRolesSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UserRolesSetMutation,
    UserRolesSetMutationVariables
  >(UserRolesSetDocument, options)
}
export type UserRolesSetMutationHookResult = ReturnType<
  typeof useUserRolesSetMutation
>
export type UserRolesSetMutationResult =
  Apollo.MutationResult<UserRolesSetMutation>
export type UserRolesSetMutationOptions = Apollo.BaseMutationOptions<
  UserRolesSetMutation,
  UserRolesSetMutationVariables
>
export const UserUpsertDocument = gql`
  mutation UserUpsert(
    $userId: ID
    $email: EmailAddress
    $companyId: ID
    $phoneNumber: PhoneNumber
    $firstName: String
    $lastName: String
    $title: String
    $roleCodes: [AuthRoleCodeEnum!]
    $sendRegistrationEmail: Boolean
    $defaultCompanyId: ID
    $language: LanguageCodeEnum
  ) {
    user {
      upsert(
        input: {
          userId: $userId
          email: $email
          companyId: $companyId
          phoneNumber: $phoneNumber
          firstName: $firstName
          lastName: $lastName
          title: $title
          sendRegistrationEmail: $sendRegistrationEmail
          defaultCompanyId: $defaultCompanyId
          language: $language
          roleCodes: $roleCodes
        }
      ) {
        user {
          ...AuthenticatedUser
        }
      }
    }
  }
  ${AuthenticatedUserFragmentDoc}
`
export type UserUpsertMutationFn = Apollo.MutationFunction<
  UserUpsertMutation,
  UserUpsertMutationVariables
>

/**
 * __useUserUpsertMutation__
 *
 * To run a mutation, you first call `useUserUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpsertMutation, { data, loading, error }] = useUserUpsertMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *      companyId: // value for 'companyId'
 *      phoneNumber: // value for 'phoneNumber'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      title: // value for 'title'
 *      roleCodes: // value for 'roleCodes'
 *      sendRegistrationEmail: // value for 'sendRegistrationEmail'
 *      defaultCompanyId: // value for 'defaultCompanyId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUserUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserUpsertMutation,
    UserUpsertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UserUpsertMutation, UserUpsertMutationVariables>(
    UserUpsertDocument,
    options,
  )
}
export type UserUpsertMutationHookResult = ReturnType<
  typeof useUserUpsertMutation
>
export type UserUpsertMutationResult = Apollo.MutationResult<UserUpsertMutation>
export type UserUpsertMutationOptions = Apollo.BaseMutationOptions<
  UserUpsertMutation,
  UserUpsertMutationVariables
>
export const UserGetDocument = gql`
  query UserGet($input: UserGetInput!) {
    user {
      get(input: $input) {
        users {
          id
          firstName
          lastName
          email
          status
          phoneNumber
          roles {
            userId
            companyId
            roleId
            name
            code
          }
          isMfaEnabled
          positions {
            position {
              id
              name
              companyId
            }
            department {
              id
              name
              companyId
            }
          }
        }
        paginationInfo {
          offset
          limit
          totalItems
          totalPages
        }
      }
    }
  }
`

/**
 * __useUserGetQuery__
 *
 * To run a query within a React component, call `useUserGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserGetQuery(
  baseOptions: Apollo.QueryHookOptions<UserGetQuery, UserGetQueryVariables> &
    ({ variables: UserGetQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserGetQuery, UserGetQueryVariables>(
    UserGetDocument,
    options,
  )
}
export function useUserGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserGetQuery,
    UserGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserGetQuery, UserGetQueryVariables>(
    UserGetDocument,
    options,
  )
}
export function useUserGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserGetQuery,
    UserGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UserGetQuery, UserGetQueryVariables>(
    UserGetDocument,
    options,
  )
}
export type UserGetQueryHookResult = ReturnType<typeof useUserGetQuery>
export type UserGetLazyQueryHookResult = ReturnType<typeof useUserGetLazyQuery>
export type UserGetSuspenseQueryHookResult = ReturnType<
  typeof useUserGetSuspenseQuery
>
export type UserGetQueryResult = Apollo.QueryResult<
  UserGetQuery,
  UserGetQueryVariables
>
export const AuthCurrentUserLanguageDocument = gql`
  query AuthCurrentUserLanguage {
    auth {
      userCurrent {
        user {
          id
          language
        }
      }
    }
  }
`

/**
 * __useAuthCurrentUserLanguageQuery__
 *
 * To run a query within a React component, call `useAuthCurrentUserLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthCurrentUserLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthCurrentUserLanguageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthCurrentUserLanguageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthCurrentUserLanguageQuery,
    AuthCurrentUserLanguageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AuthCurrentUserLanguageQuery,
    AuthCurrentUserLanguageQueryVariables
  >(AuthCurrentUserLanguageDocument, options)
}
export function useAuthCurrentUserLanguageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthCurrentUserLanguageQuery,
    AuthCurrentUserLanguageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AuthCurrentUserLanguageQuery,
    AuthCurrentUserLanguageQueryVariables
  >(AuthCurrentUserLanguageDocument, options)
}
export function useAuthCurrentUserLanguageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AuthCurrentUserLanguageQuery,
    AuthCurrentUserLanguageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AuthCurrentUserLanguageQuery,
    AuthCurrentUserLanguageQueryVariables
  >(AuthCurrentUserLanguageDocument, options)
}
export type AuthCurrentUserLanguageQueryHookResult = ReturnType<
  typeof useAuthCurrentUserLanguageQuery
>
export type AuthCurrentUserLanguageLazyQueryHookResult = ReturnType<
  typeof useAuthCurrentUserLanguageLazyQuery
>
export type AuthCurrentUserLanguageSuspenseQueryHookResult = ReturnType<
  typeof useAuthCurrentUserLanguageSuspenseQuery
>
export type AuthCurrentUserLanguageQueryResult = Apollo.QueryResult<
  AuthCurrentUserLanguageQuery,
  AuthCurrentUserLanguageQueryVariables
>
export const namedOperations = {
  Query: {
    CompanyAudiencesGet: 'CompanyAudiencesGet',
    CompanyCompanyGet: 'CompanyCompanyGet',
    CompanyDepartmentsGet: 'CompanyDepartmentsGet',
    CompanyFeaturesGet: 'CompanyFeaturesGet',
    CompanyOnboardingStepsGet: 'CompanyOnboardingStepsGet',
    CompanyPositionsGet: 'CompanyPositionsGet',
    CompanyProcessesGet: 'CompanyProcessesGet',
    CompanyRepresentativesGet: 'CompanyRepresentativesGet',
    DocumentGet: 'DocumentGet',
    GeneralAssetList: 'GeneralAssetList',
    GeneralDepartmentTemplatesGet: 'GeneralDepartmentTemplatesGet',
    GeneralPositionTemplatesGet: 'GeneralPositionTemplatesGet',
    GeneralProcessTemplateList: 'GeneralProcessTemplateList',
    GeneralRegionGroupList: 'GeneralRegionGroupList',
    GeneralRegionList: 'GeneralRegionList',
    StatisticsCompletedSurveysGet: 'StatisticsCompletedSurveysGet',
    StatisticsRespondedQuestionsGet: 'StatisticsRespondedQuestionsGet',
    SurveyManagerStatisticsGet: 'SurveyManagerStatisticsGet',
    SurveyManagerViewAnswersStatisticsGet:
      'SurveyManagerViewAnswersStatisticsGet',
    StatisticsWorkforcePercentageGet: 'StatisticsWorkforcePercentageGet',
    SurveyCategoriesGet: 'SurveyCategoriesGet',
    SurveyGet: 'SurveyGet',
    SurveyQuestionTypesGet: 'SurveyQuestionTypesGet',
    SurveyDatamapResponsesGet: 'SurveyDatamapResponsesGet',
    SurveyResponsesGet: 'SurveyResponsesGet',
    SurveySubcategoryTemplatesGet: 'SurveySubcategoryTemplatesGet',
    UserGet: 'UserGet',
    AuthCurrentUserLanguage: 'AuthCurrentUserLanguage',
  },
  Mutation: {
    AuthLoginConfirm: 'AuthLoginConfirm',
    AuthLogin: 'AuthLogin',
    AuthLogout: 'AuthLogout',
    AuthMfaConfigureConfirm: 'AuthMfaConfigureConfirm',
    AuthMfaConfigure: 'AuthMfaConfigure',
    AuthMfaDisable: 'AuthMfaDisable',
    AuthPasswordChange: 'AuthPasswordChange',
    AuthPasswordRecoverConfirm: 'AuthPasswordRecoverConfirm',
    AuthPasswordRecoverInit: 'AuthPasswordRecoverInit',
    AuthSignUpConfirm: 'AuthSignUpConfirm',
    AuthSignUpResendCode: 'AuthSignUpResendCode',
    AuthSignUpResendCodeToPendingUsers: 'AuthSignUpResendCodeToPendingUsers',
    AuthSignUp: 'AuthSignUp',
    AuthTokenRefresh: 'AuthTokenRefresh',
    CompanyAssetConnectionCreate: 'CompanyAssetConnectionCreate',
    CompanyAudienceCreate: 'CompanyAudienceCreate',
    CompanyAudienceDelete: 'CompanyAudienceDelete',
    CompanyAudienceUpdate: 'CompanyAudienceUpdate',
    CompanyAudienceUsersLink: 'CompanyAudienceUsersLink',
    CompanyAudienceUsersUnlink: 'CompanyAudienceUsersUnlink',
    CompanyCreate: 'CompanyCreate',
    CompanyDelete: 'CompanyDelete',
    CompanyIndustriesSet: 'CompanyIndustriesSet',
    CompanyLogoSet: 'CompanyLogoSet',
    CompanyOnboardingStepsSave: 'CompanyOnboardingStepsSave',
    CompanyProcessCreate: 'CompanyProcessCreate',
    CompanyRegulationLink: 'CompanyRegulationLink',
    RepresentativeAddressSet: 'RepresentativeAddressSet',
    RepresentativeCreate: 'RepresentativeCreate',
    RepresentativeDelete: 'RepresentativeDelete',
    RepresentativeUpdate: 'RepresentativeUpdate',
    CompanyUpdate: 'CompanyUpdate',
    DocumentCreate: 'DocumentCreate',
    DocumentDelete: 'DocumentDelete',
    DocumentUpdate: 'DocumentUpdate',
    DocumentFolderCreate: 'DocumentFolderCreate',
    DocumentFolderDelete: 'DocumentFolderDelete',
    DocumentFolderUpdate: 'DocumentFolderUpdate',
    CompanyAddressUpsert: 'CompanyAddressUpsert',
    GeneralCompanyRegionLink: 'GeneralCompanyRegionLink',
    GeneralCompanyRegionUnlink: 'GeneralCompanyRegionUnlink',
    CompanyDepartmentCreate: 'CompanyDepartmentCreate',
    GeneralSendEmail: 'GeneralSendEmail',
    CompanyPositionCreate: 'CompanyPositionCreate',
    SurveyAnswerOptionCreate: 'SurveyAnswerOptionCreate',
    SurveyAnswerOptionDelete: 'SurveyAnswerOptionDelete',
    SurveyAnswerOptionUpdate: 'SurveyAnswerOptionUpdate',
    SurveyAudiencesSet: 'SurveyAudiencesSet',
    SurveyCopy: 'SurveyCopy',
    SurveyCreate: 'SurveyCreate',
    SurveyDelete: 'SurveyDelete',
    SurveyGuidedStepCreate: 'SurveyGuidedStepCreate',
    SurveyGuidedStepDelete: 'SurveyGuidedStepDelete',
    SurveyGuidedStepUpdate: 'SurveyGuidedStepUpdate',
    SurveyInvitationDelete: 'SurveyInvitationDelete',
    SurveyInviteAudiences: 'SurveyInviteAudiences',
    SurveyInviteUsers: 'SurveyInviteUsers',
    SurveyMaturityScoreSet: 'SurveyMaturityScoreSet',
    SurveyQuestionCreate: 'SurveyQuestionCreate',
    SurveyQuestionDelete: 'SurveyQuestionDelete',
    SurveyQuestionUpdate: 'SurveyQuestionUpdate',
    SurveyResponseCreate: 'SurveyResponseCreate',
    SurveyResponseDelete: 'SurveyResponseDelete',
    SurveyResponseDocumentUpload: 'SurveyResponseDocumentUpload',
    SurveyResponseUpdate: 'SurveyResponseUpdate',
    SurveyScheduleCreate: 'SurveyScheduleCreate',
    SurveyScheduleDelete: 'SurveyScheduleDelete',
    SurveyScheduleUpdate: 'SurveyScheduleUpdate',
    SurveySessionCreate: 'SurveySessionCreate',
    SurveySessionDelete: 'SurveySessionDelete',
    SurveySessionUpdate: 'SurveySessionUpdate',
    SurveySubcategoryCreateFromTemplate: 'SurveySubcategoryCreateFromTemplate',
    SurveySubcategoryCreate: 'SurveySubcategoryCreate',
    SurveySubcategoryDelete: 'SurveySubcategoryDelete',
    SurveyConvertIntoTemplate: 'SurveyConvertIntoTemplate',
    SurveyUpdate: 'SurveyUpdate',
    UserCreate: 'UserCreate',
    UserDelete: 'UserDelete',
    UserPositionSet: 'UserPositionSet',
    UserRolesSet: 'UserRolesSet',
    UserUpsert: 'UserUpsert',
  },
  Fragment: {
    CompanyBasicWithSlug: 'CompanyBasicWithSlug',
    IdentityApplicationsGetMain: 'IdentityApplicationsGetMain',
    LoginIdentityApplicationsGet: 'LoginIdentityApplicationsGet',
    CompanySelectedRegulation: 'CompanySelectedRegulation',
    Department: 'Department',
    CompanyBinaryFileInfo: 'CompanyBinaryFileInfo',
    CompanyMain: 'CompanyMain',
    CompanyFullDetails: 'CompanyFullDetails',
    CompanyBasic: 'CompanyBasic',
    DocumentFields: 'DocumentFields',
    FileInfo: 'FileInfo',
    SurveyDetails: 'SurveyDetails',
    SurveySubcategoryTemplateDetails: 'SurveySubcategoryTemplateDetails',
    GuidedStepsDetails: 'GuidedStepsDetails',
    SurveySubcategoryDetails: 'SurveySubcategoryDetails',
    ISurveyAnswerOptionDetails: 'ISurveyAnswerOptionDetails',
    DatamapEntityDetails: 'DatamapEntityDetails',
    DatamapAnswerOptionDetails: 'DatamapAnswerOptionDetails',
    DatamapCustomAnswerOptionDetails: 'DatamapCustomAnswerOptionDetails',
    SurveyAnswerOptionDetails: 'SurveyAnswerOptionDetails',
    SurveyQuestionDetails: 'SurveyQuestionDetails',
    QuestionTemplateDetails: 'QuestionTemplateDetails',
    AudienceDetails: 'AudienceDetails',
    AudienceUserDetails: 'AudienceUserDetails',
    ResponseDetails: 'ResponseDetails',
    DatamapResponseResponseCommon: 'DatamapResponseResponseCommon',
    DatamapResponseResponseDetails: 'DatamapResponseResponseDetails',
    SurveyManagerDatamapResponseGet: 'SurveyManagerDatamapResponseGet',
    DatamapResponseGet: 'DatamapResponseGet',
    DatamapResponseCommon: 'DatamapResponseCommon',
    DatamapResponseDetails: 'DatamapResponseDetails',
    SurveyGetBuilderFragment: 'SurveyGetBuilderFragment',
    SurveyManagerViewAnswersSession: 'SurveyManagerViewAnswersSession',
    SurveyManagerPageSession: 'SurveyManagerPageSession',
    MaturityScoreAverages: 'MaturityScoreAverages',
    AuthenticatedUser: 'AuthenticatedUser',
    AuthenticatedUserWithPositions: 'AuthenticatedUserWithPositions',
  },
}
