import { css } from '@emotion/react'

export const SpinnerCss = (props?: { width: number; height: number }) => css`
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  width: ${props?.width ?? 12}px;
  height: ${props?.height ?? 12}px;
  border-radius: 50%;
  display: inline-block;
  border-top: 1px solid white;
  border-right: 2px solid white;
  border-bottom: 3px solid white;
  border-left: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
`
